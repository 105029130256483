import { useRouter } from 'next/router';

import { pkcs12Storage } from '@workspace/certificates';

import { useUsername } from '~modules/auth/hooks';

import type { PasswordLoginFormSchema } from '../schema';

export function useHydratePasswordLoginForm(localLogin: boolean) {
    const { getUsername } = useUsername();
    const { push } = useRouter();

    return async (): Promise<PasswordLoginFormSchema> => {
        const filename = await pkcs12Storage.get('name');
        const username = await getUsername();

        const missingRequiredUsername = !username && !localLogin;

        if (missingRequiredUsername || !filename) {
            await push('/');
        }

        return {
            filename: filename ?? '',
            username: username ?? '',
            password: '',
        };
    };
}
