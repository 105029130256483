/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type GetGlobalParamRequestModelParamGroup =
    (typeof GetGlobalParamRequestModelParamGroup)[keyof typeof GetGlobalParamRequestModelParamGroup];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetGlobalParamRequestModelParamGroup = {
    WEBLIST: 'WEBLIST',
    REVFINANCING: 'REVFINANCING',
    ACCESS: 'ACCESS',
    EXPORT: 'EXPORT',
    EXPORT_NOTICE: 'EXPORT_NOTICE',
    SMS: 'SMS',
    CERTIFICATE: 'CERTIFICATE',
} as const;
