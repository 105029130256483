import { useIntl } from 'react-intl';

import type { Parts } from '~modules/intl/utils/formatAndReplaceParts';

const LARGE_ENOUGH_NUMBER_TO_FORMAT = 10000.5;

/**
 * Get number separators based on current locale or sensible defaults.
 */
export default function useDefaultSeparators(): Parts {
    const { locale, formatNumberToParts } = useIntl();

    const numberParts = formatNumberToParts(LARGE_ENOUGH_NUMBER_TO_FORMAT);
    const intlDecimalSeparator = numberParts.find(value => value.type === 'decimal')?.value;
    const intlThousandSeparator = numberParts.find(value => value.type === 'group')?.value;

    if (intlDecimalSeparator && intlThousandSeparator) {
        // replace non-breaking space with regular, if present
        const nonNbspThousandSeparator = intlThousandSeparator.codePointAt(0) === 160 ? ' ' : intlThousandSeparator;

        return {
            decimalSeparator: intlDecimalSeparator,
            thousandsSeparator: nonNbspThousandSeparator,
        };
    } else {
        if (locale === 'en') {
            return {
                decimalSeparator: '.',
                thousandsSeparator: ',',
            };
        } else {
            // assume Czech by default
            return {
                decimalSeparator: ',',
                thousandsSeparator: ' ',
            };
        }
    }
}
