import type { Certificate } from 'pkijs';

import { AuthError } from '@workspace/errors';
import { getIDBStore } from '@workspace/storage';

import { FileType } from '../constants';
import { parseAttributes } from './pki/attributes';
import { getSubjectFields } from './pki/parsedCertificate';
import { readFileAsBase64, removeDiacritic } from './utils';

export const pkcs12Storage = getIDBStore('pkcs12');

export const isValidPkcs12 = (file: File) => {
    return Boolean(file && file.type === FileType.PersonalInformationContainer && file.name.endsWith('.p12'));
};

export async function storePkcs12({ pkcs12, name }: { pkcs12: string; name?: string }) {
    await pkcs12Storage.set('content', pkcs12);
    name && (await pkcs12Storage.set('name', name));
}

export async function retrievePkcs12() {
    const content = await pkcs12Storage.get('content');
    const name = await pkcs12Storage.get('name');

    if (!content || !name) {
        throw new AuthError('NO_CERT_IN_STORAGE');
    }

    return { content, name };
}

export async function storePkcs12FromFile(file: File) {
    const fileAsBase64 = await readFileAsBase64(file);

    await storePkcs12({ pkcs12: fileAsBase64, name: file.name });
}

export function createFilenameFromCertificate(certificate: Certificate) {
    const subject = getSubjectFields(parseAttributes(certificate.subject.typesAndValues));
    const nameChunks = removeDiacritic(subject.commonName).split(' ');

    return `${nameChunks.join('_')}` as const;
}

/**
 * Create PKCS 12 filename based on certificate subject's name and surname.
 * @returns `${lastName}_${firstName}.p12`
 */
export function createPKCS12Filename(certificate: Certificate) {
    return `${createFilenameFromCertificate(certificate)}.p12` as const;
}
