class TimeoutError extends Error {}

export function createTimeoutError() {
    return new TimeoutError();
}

export function isTimeoutError(e: any): boolean {
    if (e && e instanceof TimeoutError) {
        return true;
    }

    return false;
}
