import type { Path } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { TextField, type UnknownFormValues } from '@workspace/ui';

export interface SignatureFieldProps<FormValues extends UnknownFormValues> {
    name: Path<FormValues>;
    placeholder?: string;
}

export const SignatureField = <FormValues extends UnknownFormValues>({
    name,
    placeholder,
}: SignatureFieldProps<FormValues>) => {
    const { formatMessage } = useIntl();

    return (
        <TextField<FormValues>
            name={name}
            placeholder={placeholder ?? formatMessage({ id: 'phoneNumber.signature.placeholder' })}
            multiline
            rows={3}
            readOnly
        />
    );
};
