export default function Word(props) {
    return (<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='none' {...props}>
            <g clipPath='url(#clip0_1393_3214)'>
                <path stroke='#000' strokeMiterlimit='10' strokeWidth='1.5' d='M7.54 12.55V4.57c0-1.01.8-1.82 1.81-1.82h9.46s.06 0 .09.02c.15.01.3.08.4.18l6.19 6.19c.1.1.17.25.18.4.01.02.01.05.01.07V25.8c0 1.01-.82 1.81-1.82 1.81H9.35c-1.01 0-1.81-.8-1.81-1.81v-1.29'/>
                <path stroke='#000' strokeMiterlimit='10' strokeWidth='1.5' d='M18.9 2.75v6.06c0 .4.33.73.73.73h6.06'/>
                <path stroke='#06F' strokeMiterlimit='10' strokeWidth='1.5' d='M18.385 13H5.615c-.34 0-.615.26-.615.58v9.84c0 .32.275.58.615.58h12.77c.34 0 .615-.26.615-.58v-9.84c0-.32-.275-.58-.615-.58Z'/>
                <path stroke='#06F' strokeLinecap='round' strokeLinejoin='round' d='m15.26 16.22-1.57 5.05-1.77-4.77-1.77 4.77-1.56-5.05M9.34 16.22H7.83M16.01 16.22H14.5'/>
            </g>
            <defs>
                <clipPath id='clip0_1393_3214'>
                    <path fill='#fff' d='M4 2h22.43v26.36H4z'/>
                </clipPath>
            </defs>
        </svg>);
}
