import { getNames } from 'i18n-iso-countries';

import { languages } from '../config';
import type { Language } from '../types';
import { registerLocalizedCountries } from '../utils/registerLocalizedCountries';

async function getCountryCode(lang: Language, localizedCountryName: string) {
    await registerLocalizedCountries(lang);

    const countryNames = getNames(lang);

    const country = Object.entries(countryNames).find(([, value]) => value === localizedCountryName);

    return country?.[0] ?? null;
}

export async function findCountryCode(localizedCountryName: string) {
    for (const lang of Object.values(languages)) {
        const countryCode = await getCountryCode(lang, localizedCountryName);

        if (countryCode) {
            return countryCode;
        }
    }

    return null;
}
