import { Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { SmallTypography } from './AppInfo.styles';
import { useAppVersion } from './hooks/useAppVersion';
import { useNodeInfo } from './hooks/useNodeInfo';

const AppInfo = () => {
    const { data } = useAppVersion();
    const nodeInfo = useNodeInfo();

    return (
        <Stack direction='row' gap={0.5} mx={1.25}>
            <SmallTypography variant='paragraphSmall'>
                <FormattedMessage id='app.title' />
            </SmallTypography>
            <SmallTypography variant='paragraphSmall'>
                <FormattedMessage id='app.version' values={{ version: data?.data ?? '-' }} />
            </SmallTypography>
            {nodeInfo.data && <SmallTypography variant='paragraphSmall'>{nodeInfo.data}</SmallTypography>}
        </Stack>
    );
};

export default AppInfo;
