import { z } from 'zod';

import * as validators from '~modules/form/validators';

export const loginFormSchema = z.object({
    username: validators.optionalNullableString,
    // Even though file is required for both form submits,
    //  this way we can show both errors for login after manual validation
    filename: validators.optionalNullableString,
});

export type LoginFormSchema = z.infer<typeof loginFormSchema>;

export const loginFormKey = loginFormSchema.keyof().enum;
