import { getIDBStore } from '@workspace/storage';

import { retrievePkcs12, storePkcs12 } from '../lib';
import { getIssuedCertificate, parseKeysAndCertificates, parsePersonalInformationContainer } from '../lib/pki';

export async function loadCertificates() {
    const { content: pkcs12b64 } = await retrievePkcs12();

    const pkcs12 = await parsePersonalInformationContainer(pkcs12b64);

    const certificates = await parseKeysAndCertificates(pkcs12);

    if (pkcs12b64 !== pkcs12.toString('base64')) {
        await storePkcs12({ pkcs12: pkcs12.toString('base64') });
    }

    return certificates;
}

/**
 * - Loads valid and issued (i.e. not self-signed) certificate from local storage.
 * - Updates the `issuedCertificateValidTo` IDB store entry with the validTo date of the issued certificate.
 */
export async function loadIssuedCertificate() {
    const certitifcates = await loadCertificates();
    const issuedCertificate = getIssuedCertificate(certitifcates);

    await getIDBStore('pkcs12').set('issuedCertificateValidTo', issuedCertificate.validTo.toISOString());

    return issuedCertificate;
}
