/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type CheckCertificateResponseModelGlobalResponse =
    (typeof CheckCertificateResponseModelGlobalResponse)[keyof typeof CheckCertificateResponseModelGlobalResponse];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckCertificateResponseModelGlobalResponse = {
    CERTIFICATE_NOT_FOUND: 'CERTIFICATE_NOT_FOUND',
    NOT_YET_VALID: 'NOT_YET_VALID',
    EXPIRED: 'EXPIRED',
    REVOKED: 'REVOKED',
    BLOCKED: 'BLOCKED',
    NOT_BOUND_TO_USER: 'NOT_BOUND_TO_USER',
    OK: 'OK',
} as const;
