import { useQuery } from '@tanstack/react-query';

import { QueryKey, security_getUserData } from '@workspace/api';

export function useUserData() {
    return useQuery({
        queryKey: [QueryKey.AUTH_USER_DATA],
        queryFn: () => security_getUserData(true),
        refetchOnMount: false,
    });
}
