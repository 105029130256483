import { z } from 'zod';

import { formError } from '~modules/form/errors';
import type { TypedZodIssueBase } from '~modules/form/types';
import { newPassword, oldPassword } from '~modules/form/validators';

export const changePasswordFormSchemaBase = z.object({
    oldPassword,
    newPassword,
    verifyNewPassword: newPassword,
});

export type ChangePasswordSchema = z.infer<typeof changePasswordFormSchemaBase>;

type ZodIssue = TypedZodIssueBase<ChangePasswordSchema>;

export const changePasswordFormSchema = changePasswordFormSchemaBase
    .refine(val => val.newPassword === val.verifyNewPassword, {
        path: ['verifyNewPassword'],
        message: formError.passwordEquality,
    } satisfies ZodIssue)
    .refine(val => val.oldPassword !== val.newPassword, {
        path: ['newPassword'],
        message: 'passwordChange.form.oldEqualsNewPassword',
    } satisfies ZodIssue);
