import { get } from 'radash';
import { useController, useFormContext } from 'react-hook-form';
import { InputAutocomplete } from '../../../types';
import { FieldError } from '../../FieldError';
import { TextInput } from '../../inputs';
import { InputContainer, StyledContainer } from './TextField.styles';
export const TextField = ({ name, withError = true, fullWidth = true, disabled, endAdornment, ...props }) => {
    const { control, formState: { errors, defaultValues, isSubmitting }, } = useFormContext();
    const defaultValue = get(defaultValues, name);
    const { field, fieldState: { error }, } = useController({
        control,
        name,
        defaultValue,
    });
    return (<StyledContainer fullWidth={fullWidth}>
            <InputContainer fullWidth={fullWidth}>
                <TextInput autoComplete={InputAutocomplete.Off} {...props} value={field.value ?? ''} name={field.name} onChange={field.onChange} onBlur={e => {
            field.onBlur();
            props.onBlur?.(e);
        }} ref={field.ref} disabled={Boolean(disabled || field.disabled || isSubmitting)} error={!!error} fullWidth={fullWidth}/>
                {endAdornment}
            </InputContainer>
            {withError && <FieldError errors={errors} name={name}/>}
        </StyledContainer>);
};
