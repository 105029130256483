import { useAuthContext } from './useAuthContext';
import { useLocalSign } from './useLocalSignIn';
import { useSignIn } from './useSignIn';
import { useSignOut } from './useSignOut';

export function useSession() {
    const { session, setSession } = useAuthContext();

    return {
        ...session,
        localSignIn: useLocalSign(setSession),
        signIn: useSignIn(setSession),
        signOut: useSignOut(setSession),
    } as const;
}
