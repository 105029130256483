import { outlinedInputClasses, styled, TextField } from '@mui/material';
export const StyledTextInput = styled(TextField)(({ theme, fullWidth, width = fullWidth ? '100%' : 'auto', multiline, value, readOnly }) => ({
    width: typeof width === 'number' ? theme.spacing(width) : width,
    maxWidth: '100%',
    /* Chorme, Safari - input type number - value arrows removal */
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
    /* Firefox - input type number - value arrows removal*/
    'input[type=number]': {
        MozAppearance: 'textfield',
    },
    '& .MuiOutlinedInput-root': {
        ...theme.typography.formItem,
        backgroundColor: theme.color.inputs.background,
        borderRadius: 2,
        minHeight: 32,
        ...(!multiline && { height: 32 }),
        ' fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.color.inputs.border,
        },
        ':hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.color.inputs.borderHover,
        },
        '&.Mui-focused fieldset': {
            border: `1.625px solid ${theme.color.inputs.borderHover}`,
        },
        '&.Mui-disabled': {
            backgroundColor: theme.color.inputs.grey,
        },
        '& .MuiOutlinedInput-input': {
            padding: `0 ${theme.spacing(0.625)}`,
            height: 'inherit',
            ...(readOnly && {
                backgroundColor: theme.color.inputs.grey,
                color: theme.color.inputs.text,
            }),
            '&:focus + .MuiOutlinedInput-notchedOutline': {
                color: theme.color.foregrounds.primary,
                border: `1.625px solid ${theme.color.foregrounds.primary}`,
            },
        },
        ...(String(value)?.length > 0 && {
            color: theme.color.inputs.borderHover,
            '& fieldset.MuiOutlinedInput-notchedOutline': {
                borderColor: theme.color.inputs.text,
                borderWidth: '0.625px',
            },
        }),
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.color.alerts.error,
        },
        ...(String(value)?.length > 0 && {
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.color.alerts.error,
            },
        }),
        '&.Mui-error .MuiOutlinedInput-input': {
            color: theme.color.alerts.error,
        },
        '&.MuiInputBase-multiline': {
            padding: 'unset',
            '& textarea': {
                padding: `${theme.spacing(0.5)} ${theme.spacing(0.875)}`,
                width: '100%',
                height: '100%',
                '&[readonly]': {
                    backgroundColor: theme.color.inputs.grey,
                },
                '&:focus ~ .MuiOutlinedInput-notchedOutline': {
                    border: `1.625px solid ${theme.color.foregrounds.primary}`,
                },
            },
        },
    },
    [`& .${outlinedInputClasses.notchedOutline} > legend`]: {
        fontSize: 'unset',
        '& > span': {
            fontSize: '0.75rem',
        },
    },
    '& .MuiInputLabel-root': {
        ...theme.typography.formItem,
        transform: 'translate(10px, 7px)',
        color: theme.color.inputs.textLabel,
        '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -11px)',
            fontSize: '0.75rem',
            color: theme.color.inputs.textLabel,
        },
        '&.Mui-disabled': {
            opacity: readOnly ? 1 : 0.4,
        },
        '&.Mui-error': {
            color: theme.color.alerts.error,
        },
    },
}));
