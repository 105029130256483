/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    GetDocumentDownloadRequestModel,
    GetDocumentDownloadResponseModel,
    GetNoticeBoardRequestModel,
    GetNoticeBoardResponseModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Get list of documents to be displayed for the download
 */
export const xmlService_getDocumentDownload = (
    getDocumentDownloadRequestModel: BodyType<GetDocumentDownloadRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<GetDocumentDownloadResponseModel | void>(
        {
            url: `/factoring/xmlservice/getDocumentDownload`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getDocumentDownloadRequestModel,
        },
        options,
    );
};

export const getXmlServiceGetDocumentDownloadMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof xmlService_getDocumentDownload>>,
        TError,
        { data: BodyType<GetDocumentDownloadRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof xmlService_getDocumentDownload>>,
    TError,
    { data: BodyType<GetDocumentDownloadRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof xmlService_getDocumentDownload>>,
        { data: BodyType<GetDocumentDownloadRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return xmlService_getDocumentDownload(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type XmlServiceGetDocumentDownloadMutationResult = NonNullable<
    Awaited<ReturnType<typeof xmlService_getDocumentDownload>>
>;
export type XmlServiceGetDocumentDownloadMutationBody = BodyType<GetDocumentDownloadRequestModel>;
export type XmlServiceGetDocumentDownloadMutationError = ErrorType<unknown>;

/**
 * @summary Get list of documents to be displayed for the download
 */
export const useXmlServiceGetDocumentDownload = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof xmlService_getDocumentDownload>>,
        TError,
        { data: BodyType<GetDocumentDownloadRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getXmlServiceGetDocumentDownloadMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get notice board data to be displayed to the user
 */
export const xmlService_getNoticeBoard = (
    getNoticeBoardRequestModel: BodyType<GetNoticeBoardRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<GetNoticeBoardResponseModel | void>(
        {
            url: `/factoring/xmlservice/getNoticeBoard`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getNoticeBoardRequestModel,
        },
        options,
    );
};

export const getXmlServiceGetNoticeBoardMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof xmlService_getNoticeBoard>>,
        TError,
        { data: BodyType<GetNoticeBoardRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof xmlService_getNoticeBoard>>,
    TError,
    { data: BodyType<GetNoticeBoardRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof xmlService_getNoticeBoard>>,
        { data: BodyType<GetNoticeBoardRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return xmlService_getNoticeBoard(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type XmlServiceGetNoticeBoardMutationResult = NonNullable<Awaited<ReturnType<typeof xmlService_getNoticeBoard>>>;
export type XmlServiceGetNoticeBoardMutationBody = BodyType<GetNoticeBoardRequestModel>;
export type XmlServiceGetNoticeBoardMutationError = ErrorType<unknown>;

/**
 * @summary Get notice board data to be displayed to the user
 */
export const useXmlServiceGetNoticeBoard = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof xmlService_getNoticeBoard>>,
        TError,
        { data: BodyType<GetNoticeBoardRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getXmlServiceGetNoticeBoardMutationOptions(options);

    return useMutation(mutationOptions);
};
