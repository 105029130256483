/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type DashboardDataExportResponseModelErrorCode =
    (typeof DashboardDataExportResponseModelErrorCode)[keyof typeof DashboardDataExportResponseModelErrorCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DashboardDataExportResponseModelErrorCode = {
    TOO_MANY_ITEMS_TO_EXPORT: 'TOO_MANY_ITEMS_TO_EXPORT',
} as const;
