import { styled } from '@mui/material';

export const Input = styled('input')({
    all: 'unset',
    height: 0,
    width: 0,
    border: 'none',
    textIndent: -9999,
    opacity: 0,
    lineHeight: 0,
    fontSize: 0,
    position: 'absolute',
});
