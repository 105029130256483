/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvInFileModel,
    GetByIdRequestModelOfEfcvInFileModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvInFileModel,
    ViewDataResultModelOfEfcvInFileModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const inFile_findByAll = (
    efcvInFileModel: BodyType<EfcvInFileModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvInFileModel[] | void>(
        {
            url: `/factoring/efcvinfile/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvInFileModel,
        },
        options,
    );
};

export const getInFileFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFile_findByAll>>,
        TError,
        { data: BodyType<EfcvInFileModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof inFile_findByAll>>,
    TError,
    { data: BodyType<EfcvInFileModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof inFile_findByAll>>,
        { data: BodyType<EfcvInFileModel> }
    > = props => {
        const { data } = props ?? {};

        return inFile_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type InFileFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof inFile_findByAll>>>;
export type InFileFindByAllMutationBody = BodyType<EfcvInFileModel>;
export type InFileFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useInFileFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFile_findByAll>>,
        TError,
        { data: BodyType<EfcvInFileModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getInFileFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const inFile_findById = (
    getByIdRequestModelOfEfcvInFileModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvInFileModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvInFileModel | void>(
        {
            url: `/factoring/efcvinfile/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvInFileModelAndbigdecimal,
        },
        options,
    );
};

export const getInFileFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFile_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvInFileModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof inFile_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvInFileModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof inFile_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvInFileModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return inFile_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type InFileFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof inFile_findById>>>;
export type InFileFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvInFileModelAndbigdecimal>;
export type InFileFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useInFileFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFile_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvInFileModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getInFileFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const inFile_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvInFileModel | void>(
        {
            url: `/factoring/efcvinfile/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getInFileFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFile_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof inFile_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof inFile_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return inFile_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type InFileFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof inFile_findByLightDataRequest>>
>;
export type InFileFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type InFileFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useInFileFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFile_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getInFileFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const inFile_findByViewDataRequest = (
    viewDataRequestModelOfEfcvInFileModel: BodyType<ViewDataRequestModelOfEfcvInFileModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvInFileModel | void>(
        {
            url: `/factoring/efcvinfile/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvInFileModel,
        },
        options,
    );
};

export const getInFileFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFile_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvInFileModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof inFile_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvInFileModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof inFile_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvInFileModel> }
    > = props => {
        const { data } = props ?? {};

        return inFile_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type InFileFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof inFile_findByViewDataRequest>>
>;
export type InFileFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvInFileModel>;
export type InFileFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useInFileFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFile_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvInFileModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getInFileFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
