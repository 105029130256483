import NextLink from 'next/link';
import { Box, Link, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { externalRoutes } from '~constants';
import { generatePath } from '~utils/generatePath';

import { Container } from './FooterLink.styles';

export const FooterLink = () => {
    const { locale } = useIntl();
    const path = generatePath(externalRoutes.arbesWebsite, { locale });

    return (
        <Container>
            <Box py={1}>
                <Link component={NextLink} href={path} target='_blank' rel='noopener'>
                    <Typography variant='paragraphSmall'>
                        <FormattedMessage
                            id='footer.arbes.copyright'
                            values={{ currentYear: new Date().getFullYear() }}
                        />{' '}
                    </Typography>
                </Link>
            </Box>
        </Container>
    );
};
