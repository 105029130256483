import { formControlLabelClasses, FormControlLabel as MUIFormControlLabel, styled, typographyClasses, } from '@mui/material';
export const FormControlLabel = styled(MUIFormControlLabel)(({ theme }) => ({
    marginLeft: 0,
    marginRight: 0,
    gap: theme.spacing(0.375),
    ...theme.typography.formItem,
    [`& .${typographyClasses.root}`]: {
        ...theme.typography.formItem,
    },
    [`&.${formControlLabelClasses.root}`]: {
        color: theme.color.inputs.text,
        [`&:has(.Mui-checked)`]: {
            color: theme.color.foregrounds.primary,
        },
        [`&:has(.Mui-disabled)`]: {
            color: theme.color.inputs.text,
            opacity: 0.9,
        },
    },
}));
