import { PFX } from 'pkijs';

import { AuthError } from '@workspace/errors';
import { logger } from '@workspace/logger';

import { getFriendlyNameAttribute, getLocalIdAttribute, parseAttributes } from '../attributes';
import {
    createParsedCertificate,
    getSubjectFields,
    updateParsedCertificateChain,
    type ParsedCertificate,
} from '../parsedCertificate';
import { findAllBags } from '../safeBags';
import { SafeBagId, type AuthSafeSafeContent } from '../types';

/**
 * Parse keys and certificates from PFX object.
 */
export async function parseKeysAndCertificates(pkcs12: PFX) {
    const safeContents: AuthSafeSafeContent[] | undefined =
        pkcs12.parsedValue?.authenticatedSafe!.parsedValue?.safeContents;

    if (!Array.isArray(safeContents)) {
        throw new AuthError('UNKNOWN', 'Failed to parse PKCS#12 file, safeContents is empty.');
    }

    const safeBags = findAllBags(safeContents);

    const parsedCertificates = new Map<string, ParsedCertificate>();

    const knownKeyIds = safeBags[SafeBagId.PKCS8ShroudedKeyBag]
        .map(bag => getLocalIdAttribute(bag.bagAttributes))
        .filter(Boolean);

    const uniqueKeyIds = new Set(knownKeyIds);
    const localKeyIdsAreUnique = knownKeyIds.length === uniqueKeyIds.size;

    for (const certBag of safeBags[SafeBagId.CertBag]) {
        const localKeyId = getLocalIdAttribute(certBag.bagAttributes);
        const certificate = certBag.bagValue.parsedValue;

        if (localKeyId) {
            const friendlyName = getFriendlyNameAttribute(certBag.bagAttributes);
            const key = localKeyIdsAreUnique && localKeyId ? localKeyId : `${friendlyName}|${localKeyId}`;

            let parsedCertificate = parsedCertificates.get(key);

            if (parsedCertificate) {
                parsedCertificate = updateParsedCertificateChain(parsedCertificate, [certificate]);
            } else {
                parsedCertificate = await createParsedCertificate(key, certificate);
            }

            parsedCertificates.set(key, parsedCertificate);
        } else {
            // If bag does not have localKeyId, it is probably an issuer certificate
            const certificateSubject = getSubjectFields(parseAttributes(certificate.subject.typesAndValues));

            // Go over already parsed certificates and check matching issuer
            const matchingCertificateEntry = Array.from(parsedCertificates.entries()).find(
                ([, certificate]) => certificateSubject.commonName === certificate.issuer.commonName,
            );

            if (!matchingCertificateEntry) {
                logger.debug(
                    'Failed to find matching certificate for certificate without localKeyId:',
                    certificateSubject,
                );

                continue;
            }

            const [matchingKey, matchingCertificate] = matchingCertificateEntry;

            // Append the current `certificate` to the matching chain of an issuer certificate
            if (!matchingCertificate.certificateChain.includes(certificate)) {
                parsedCertificates.set(matchingKey, updateParsedCertificateChain(matchingCertificate, [certificate]));
            }
        }
    }

    // Find private key for each certificate based on localKeyId or friendlyName attribute
    safeBags[SafeBagId.PKCS8ShroudedKeyBag].forEach(shroudedKeyBag => {
        const localKeyId = getLocalIdAttribute(shroudedKeyBag.bagAttributes);
        const friendlyName = getFriendlyNameAttribute(shroudedKeyBag.bagAttributes);

        if (!friendlyName && !localKeyId) {
            logger.error(
                'Failed to parse certificate, the key bag does not have friendlyName or localKeyId attribute.',
            );

            return;
        }

        const key = localKeyIdsAreUnique && localKeyId ? localKeyId : `${friendlyName}|${localKeyId}`;

        if (!localKeyId) {
            return;
        }

        const certificate = parsedCertificates.get(key);

        if (certificate) {
            parsedCertificates.set(key, {
                ...certificate,
                privateKey: shroudedKeyBag.bagValue.parsedValue ?? null,
            });
        } else {
            logger.warn(`Found private key with key "${key}", but no matching certificate.`);
        }
    });

    const result = Array.from(parsedCertificates.values());

    // Verify that all certificates have private keys
    const certificatesWithoutPrivateKey = result.filter(cert => !cert.privateKey);

    if (certificatesWithoutPrivateKey.length > 0) {
        logger.warn(
            'PKCS #12 integrity error, following certificates do not have private keys:',
            certificatesWithoutPrivateKey,
        );
    }

    return result.filter(cert => cert.privateKey) as ParsedCertificate<true>[];
}
