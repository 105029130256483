import type { QueryClient } from '@tanstack/react-query';

import {
    QueryKey,
    userSupplierBind_findByLightDataRequest,
    type ViewDataResultModelOfEfcvUserSupplierBindModel,
} from '@workspace/api';

export async function getSubjects(loginName: string | undefined, qc: QueryClient) {
    const queryKey = [QueryKey.SUPPLIER_BIND, loginName];

    await qc.prefetchQuery({
        queryKey,
        queryFn: async () => {
            const { data } = await userSupplierBind_findByLightDataRequest({
                filter: [{ operator: 'EQUALS', property: 'username', value: loginName }],
            });

            return data!;
        },
    });
    const data = qc.getQueryData<ViewDataResultModelOfEfcvUserSupplierBindModel>(queryKey);

    return data?.result;
}
