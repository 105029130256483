import { InputAutocomplete } from '../../../../../modules/form/types';
import { get } from 'radash';
import { useController, useFormContext } from 'react-hook-form';
import { FieldContainer } from '../../FieldContainer';
import { DatePicker } from '../../inputs';
export const DatePickerField = ({ name, onChange, disabled, slotProps, ...props }) => {
    const { control, formState: { errors, defaultValues, isSubmitting }, } = useFormContext();
    const displayError = get(errors, name);
    const defaultValue = get(defaultValues, name);
    // DO NOT pass disabled to useController as it will remove the value :---)
    const { field } = useController({ name, control, defaultValue: defaultValue });
    return (<FieldContainer errors={errors} name={name}>
            <DatePicker slotProps={{
            textField: {
                autoComplete: InputAutocomplete.Off,
                ...slotProps?.textField,
            },
            ...slotProps,
        }} {...props} defaultValue={defaultValue} onChange={value => {
            onChange?.(value);
            field.onChange({ target: { value } });
        }} 
    // @ts-expect-error
    error={!!displayError} disabled={disabled ?? field.disabled ?? isSubmitting} value={field.value}/>
        </FieldContainer>);
};
