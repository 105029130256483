/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type EfctPristupyFoModelProductGroup =
    (typeof EfctPristupyFoModelProductGroup)[keyof typeof EfctPristupyFoModelProductGroup];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EfctPristupyFoModelProductGroup = {
    F: 'F',
    I: 'I',
    Z: 'Z',
    D: 'D',
} as const;
