/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type GetPdfExportTypeListRequestModelDisplayType =
    (typeof GetPdfExportTypeListRequestModelDisplayType)[keyof typeof GetPdfExportTypeListRequestModelDisplayType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPdfExportTypeListRequestModelDisplayType = {
    L: 'L',
    D: 'D',
} as const;
