import { buttonClasses, checkboxClasses, formControlClasses, iconButtonClasses, paginationItemClasses, styled, tablePaginationClasses, } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { LayoutComponentSize } from '../Container';
export const CreateStyledTable = () => styled((DataGrid))(({ theme, onRowClick, rows }) => ({
    border: 'none',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateAreas: `
            "pagination toolbar"
            "table table"
        `,
    rowGap: theme.spacing(0.5),
    gridTemplateRows: 'auto 1fr',
    maxHeight: `calc(100vh - 14px - ${LayoutComponentSize.headerHeight}px - ${LayoutComponentSize.navigationHeight}px - ${LayoutComponentSize.footerLinkHeight}px)`,
    [theme.breakpoints.down(1600)]: {
        gridTemplateColumns: 'auto',
        gridTemplateAreas: `
            "toolbar"
            "pagination"
            "table"
            `,
        gridTemplateRows: 'auto auto 1fr',
        maxHeight: `calc(100vh - 14px - ${LayoutComponentSize.headerHeight}px - ${LayoutComponentSize.navigationHeight}px - ${LayoutComponentSize.footerLinkHeight}px)`,
    },
    // HEADER
    [`& .${gridClasses.columnHeaders}`]: {
        backgroundColor: theme.color.foregrounds.primary,
        color: theme.color.buttons.primary.text,
        borderBottom: 'unset',
    },
    [`& .${gridClasses.columnHeaders} .${gridClasses.columnHeaderTitleContainerContent} > .${checkboxClasses.checked}`]: {
        color: theme.color.backgrounds.primary,
    },
    [`& .${gridClasses.columnSeparator}`]: {
        display: 'none',
    },
    [`& .${gridClasses.columnHeaderTitle}`]: {
        fontSize: '0.675rem',
        lineHeight: '1.5rem',
    },
    [`& .${gridClasses.columnHeaders}:focus-within`]: {
        outline: 'none',
    },
    [`& .${gridClasses.iconButtonContainer}`]: {
        width: 'auto',
        visibility: 'visible',
    },
    [`& .${gridClasses.menuIcon}`]: {
        display: 'none',
    },
    [`& .${gridClasses.iconButtonContainer} > .${buttonClasses.root}`]: {
        color: theme.color.icons.table,
    },
    [`& .${gridClasses.columnHeader}`]: {
        borderRight: `1px solid ${theme.color.buttons.primary.hover}`,
        ['&:last-of-type']: {
            borderRight: 'unset',
        },
    },
    [`& .${gridClasses.columnHeader}:not(.${gridClasses['columnHeader--sorted']}):hover .${gridClasses.sortIcon}`]: {
        opacity: 1,
    },
    [`& .${gridClasses.columnHeader}:not(.${gridClasses['columnHeader--sorted']}) .${gridClasses.sortIcon}`]: {
        opacity: 1,
    },
    [`& .${gridClasses.columnHeader} > .${gridClasses.sortIcon}`]: {
        opacity: 1,
    },
    [`& .${gridClasses.iconButtonContainer} .${gridClasses.sortIcon}`]: {
        width: 18,
        height: 18,
        stroke: theme.color.icons.table,
        color: theme.color.icons.table,
    },
    [`& .${gridClasses.cellContent}, & .${gridClasses.cell}`]: {
        fontSize: '0.875rem',
    },
    [`& .${gridClasses.cell}:focus-within`]: {
        outline: 'none',
    },
    [`& .${gridClasses.cell}:focus`]: {
        outline: 'none',
    },
    [`& .${gridClasses.columnHeader}:focus`]: {
        outline: 'none',
    },
    [`& .${gridClasses.columnHeader}:focus-within`]: {
        outline: 'none',
    },
    // ROWS:
    [`& .${gridClasses.row}, .${gridClasses.row}.Mui-selected`]: {
        backgroundColor: theme.color.table.background,
        cursor: onRowClick ? 'pointer' : 'normal',
        [`&:nth-of-type(even)`]: {
            backgroundColor: theme.color.inputs.grey,
        },
        [`&:hover`]: {
            backgroundColor: theme.color.table.hover,
        },
    },
    // MAIN:
    [`& .${gridClasses.main}`]: {
        gridArea: 'table',
        boxShadow: theme.shadows[1],
        borderRadius: '2px',
        [`& .${gridClasses.virtualScroller}[style="overflow-x: hidden;"] .${gridClasses.cell}:last-of-type`]: {
            borderLeft: 'unset',
        },
    },
    // TOOLBAR:
    [`& .${gridClasses.toolbarContainer}`]: {
        gridArea: 'toolbar',
        padding: 'unset',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        [`& .${formControlClasses.root}`]: {
            width: 'auto',
        },
    },
    // FOOTER:
    [`& .${gridClasses.footerContainer}`]: {
        borderTop: 'none',
        gridArea: 'pagination',
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'end',
    },
    [`& .${gridClasses.footerContainer} .${tablePaginationClasses.root}`]: {
        width: 'max-content',
    },
    [`& .${gridClasses.footerContainer} .${tablePaginationClasses.actions}`]: {
        order: -2,
        marginLeft: 0,
    },
    [`& .${gridClasses.footerContainer} .${tablePaginationClasses.actions} > .${iconButtonClasses.root}`]: {
        padding: 0,
    },
    [`& .${gridClasses.footerContainer} .${tablePaginationClasses.toolbar}`]: {
        paddingLeft: 0,
        display: 'flex',
        gap: '0.75rem',
        marginRight: '0.75rem',
    },
    [`& .${gridClasses.footerContainer} .${tablePaginationClasses.toolbar} > .${tablePaginationClasses.input}`]: {
        marginRight: 0,
        marginLeft: 0,
    },
    [`& .${paginationItemClasses.root}`]: {
        fontSize: '0.875rem',
    },
    [`& .${tablePaginationClasses.displayedRows}`]: {
        fontSize: '0.75rem',
        order: -1,
    },
    [`& .${tablePaginationClasses.select}`]: {
        fontSize: '0.675rem',
        paddingLeft: 0,
    },
    [`& .${tablePaginationClasses.selectLabel}`]: {
        fontSize: '0.675rem',
    },
    [`& .${tablePaginationClasses.selectLabel},& .${tablePaginationClasses.input}, & .${tablePaginationClasses.spacer}`]: {
        display: 'none !important',
    },
    [`& .${gridClasses.selectedRowCount}`]: {
        order: 1,
        margin: 0,
        fontSize: '0.675rem',
    },
    [`& .${gridClasses.virtualScroller}`]: {
        minHeight: !rows.length ? theme.spacing(5) : null,
    },
    [`& .${gridClasses.cell}`]: {
        paddingTop: theme.spacing(0.125),
        paddingBottom: theme.spacing(0.125),
        borderRight: `1px solid ${theme.color.table.border}`,
        ['&:nth-last-of-type(2)']: {
            borderRight: 'unset',
        },
        ['&:last-of-type']: {
            borderLeft: `1px solid ${theme.color.table.border}`,
        },
    },
    [`& .${gridClasses.row}:not(.${gridClasses['row--dynamicHeight']}) > .${gridClasses.cell}`]: {
        whiteSpace: 'unset',
    },
}));
