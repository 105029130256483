import { AntonioError, type RequestResult } from '@ackee/antonio-core';

import { createApiRequest, type FetcherOptions, type FetcherProps } from './createApiRequest';
import { clientChangeProxyFetcher } from './handleClientChange';

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AntonioError<Error>;

// In case you want to wrap the body type (optional)
export type BodyType<BodyData> = BodyData;

export const fetcher = async <T>(props: FetcherProps, options?: FetcherOptions): Promise<RequestResult<T>> => {
    return clientChangeProxyFetcher<T>(createApiRequest, props, options);
};
