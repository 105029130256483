import type { CertBag, CRLBag, PKCS8ShroudedKeyBag, PrivateKeyInfo, SafeBag, SecretBag } from 'pkijs';

import { logger } from '@workspace/logger';

import { SafeBagId, type AuthSafeSafeContent } from './types';

export type BagsResult = Readonly<{
    [SafeBagId.SecretBag]: SafeBag<SecretBag>[];
    [SafeBagId.CertBag]: SafeBag<CertBag>[];
    [SafeBagId.PKCS8ShroudedKeyBag]: SafeBag<PKCS8ShroudedKeyBag>[];
    [SafeBagId.CRLBag]: SafeBag<CRLBag>[];
    [SafeBagId.PrivateKeyInfo]: SafeBag<PrivateKeyInfo>[];
}>;

export type BagResultId = keyof BagsResult;

export function* iterateSafeBags(safeContents: AuthSafeSafeContent[] = [], filter: BagResultId[] = []) {
    const filterSet = new Set(filter);
    const safeBagIds = new Set(Object.values(SafeBagId));

    for (const safeContent of safeContents) {
        for (const safeBag of safeContent.value.safeBags) {
            const bagId = safeBag.bagId as BagResultId;
            const isBagSupported = safeBagIds.has(bagId);
            const isBagPartOfFilter = !filterSet.size || filterSet.has(bagId);

            if (!isBagSupported) {
                logger.warn(`Safe contents contain unsupported bag id "${bagId}".`);
            }

            if (isBagSupported && isBagPartOfFilter) {
                yield safeBag;
            }
        }
    }
}

export function findAllBags(safeContents: AuthSafeSafeContent[]) {
    const result: BagsResult = {
        [SafeBagId.CertBag]: [],
        [SafeBagId.PKCS8ShroudedKeyBag]: [],
        [SafeBagId.CRLBag]: [],
        [SafeBagId.SecretBag]: [],
        [SafeBagId.PrivateKeyInfo]: [],
    } as const satisfies BagsResult;

    for (const safeBag of iterateSafeBags(safeContents, Object.keys(result) as BagResultId[])) {
        // @ts-expect-error
        result[safeBag.bagId as BagResultId].push(safeBag);
    }

    return result;
}
