import { formControlLabelClasses, styled } from '@mui/material';
import { FormControlLabel } from '../../inputs';
export const FieldFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    [`& .${formControlLabelClasses.label}`]: {
        ...theme.typography.formItem,
        color: theme.color.inputs.text,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    gap: theme.spacing(0.25),
}));
