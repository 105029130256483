import { Stack } from '@mui/material';
import Skeleton from 'react-loading-skeleton';

import { FieldsGrid } from '@workspace/ui';

interface FormSkeletonProps {
    numberOfFields: number;
    withoutButtons?: boolean;
}

export const FormSkeleton = ({ numberOfFields, withoutButtons = false }: FormSkeletonProps) => {
    const itemsArray = new Array(numberOfFields).fill('');

    return (
        <>
            <FieldsGrid>
                {itemsArray.map((_, index) => (
                    <Skeleton key={index} height={32} />
                ))}
            </FieldsGrid>
            {!withoutButtons && (
                <Stack direction='row' justifyContent='space-between'>
                    <Skeleton height={32} width={130} />
                    <Skeleton height={32} width={130} />
                </Stack>
            )}
        </>
    );
};
