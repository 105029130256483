import type { ReactNode } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';

import { handleErrorBoundaryError, useErrorLogger } from '../../hooks';

export interface ErrorBoundaryProps {
    children: ReactNode;
}

export const ErrorBoundary = ({ children, ...props }: ErrorBoundaryProps) => {
    useErrorLogger();

    return (
        <ReactErrorBoundary
            {...props}
            fallback={
                <section
                    style={{
                        fontSize: '0.8125rem',
                        margin: '24px auto',
                        maxWidth: '996px',
                        padding: '24px',
                        backgroundColor: '#fbeaed',
                        color: '#58141f',
                        borderRadius: '2px',
                        boxShadow: '1px 2px 9px 0px #0003',
                    }}
                >
                    <FormattedMessage id='error.contactAdmin' />
                </section>
            }
            onError={handleErrorBoundaryError}
        >
            {children}
        </ReactErrorBoundary>
    );
};
