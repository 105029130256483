/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    GetByIdRequestModelOfSchedulePlanModelAndbigdecimal,
    LightDataRequestBody,
    SchedulePlanModel,
    ViewDataRequestModelOfSchedulePlanModel,
    ViewDataResultModelOfSchedulePlanModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const schedulePlan_findByAll = (
    schedulePlanModel: BodyType<SchedulePlanModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<SchedulePlanModel[] | void>(
        {
            url: `/factoring/scheduleplan/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: schedulePlanModel,
        },
        options,
    );
};

export const getSchedulePlanFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof schedulePlan_findByAll>>,
        TError,
        { data: BodyType<SchedulePlanModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof schedulePlan_findByAll>>,
    TError,
    { data: BodyType<SchedulePlanModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof schedulePlan_findByAll>>,
        { data: BodyType<SchedulePlanModel> }
    > = props => {
        const { data } = props ?? {};

        return schedulePlan_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SchedulePlanFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof schedulePlan_findByAll>>>;
export type SchedulePlanFindByAllMutationBody = BodyType<SchedulePlanModel>;
export type SchedulePlanFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useSchedulePlanFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof schedulePlan_findByAll>>,
        TError,
        { data: BodyType<SchedulePlanModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSchedulePlanFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const schedulePlan_findById = (
    getByIdRequestModelOfSchedulePlanModelAndbigdecimal: BodyType<GetByIdRequestModelOfSchedulePlanModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<SchedulePlanModel | void>(
        {
            url: `/factoring/scheduleplan/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfSchedulePlanModelAndbigdecimal,
        },
        options,
    );
};

export const getSchedulePlanFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof schedulePlan_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfSchedulePlanModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof schedulePlan_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfSchedulePlanModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof schedulePlan_findById>>,
        { data: BodyType<GetByIdRequestModelOfSchedulePlanModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return schedulePlan_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SchedulePlanFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof schedulePlan_findById>>>;
export type SchedulePlanFindByIdMutationBody = BodyType<GetByIdRequestModelOfSchedulePlanModelAndbigdecimal>;
export type SchedulePlanFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useSchedulePlanFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof schedulePlan_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfSchedulePlanModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSchedulePlanFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const schedulePlan_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfSchedulePlanModel | void>(
        {
            url: `/factoring/scheduleplan/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getSchedulePlanFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof schedulePlan_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof schedulePlan_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof schedulePlan_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return schedulePlan_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SchedulePlanFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof schedulePlan_findByLightDataRequest>>
>;
export type SchedulePlanFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type SchedulePlanFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useSchedulePlanFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof schedulePlan_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSchedulePlanFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const schedulePlan_findByViewDataRequest = (
    viewDataRequestModelOfSchedulePlanModel: BodyType<ViewDataRequestModelOfSchedulePlanModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfSchedulePlanModel | void>(
        {
            url: `/factoring/scheduleplan/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfSchedulePlanModel,
        },
        options,
    );
};

export const getSchedulePlanFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof schedulePlan_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfSchedulePlanModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof schedulePlan_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfSchedulePlanModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof schedulePlan_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfSchedulePlanModel> }
    > = props => {
        const { data } = props ?? {};

        return schedulePlan_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SchedulePlanFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof schedulePlan_findByViewDataRequest>>
>;
export type SchedulePlanFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfSchedulePlanModel>;
export type SchedulePlanFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useSchedulePlanFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof schedulePlan_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfSchedulePlanModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSchedulePlanFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
