/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvDoidContractLimitHistModel,
    GetByIdRequestModelOfEfcvDoidContractLimitHistModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvDoidContractLimitHistModel,
    ViewDataResultModelOfEfcvDoidContractLimitHistModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const doidContractLimitHist_findByAll = (
    efcvDoidContractLimitHistModel: BodyType<EfcvDoidContractLimitHistModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvDoidContractLimitHistModel[] | void>(
        {
            url: `/factoring/efcvdoidcontractlimithist/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvDoidContractLimitHistModel,
        },
        options,
    );
};

export const getDoidContractLimitHistFindByAllMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidContractLimitHist_findByAll>>,
        TError,
        { data: BodyType<EfcvDoidContractLimitHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof doidContractLimitHist_findByAll>>,
    TError,
    { data: BodyType<EfcvDoidContractLimitHistModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof doidContractLimitHist_findByAll>>,
        { data: BodyType<EfcvDoidContractLimitHistModel> }
    > = props => {
        const { data } = props ?? {};

        return doidContractLimitHist_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DoidContractLimitHistFindByAllMutationResult = NonNullable<
    Awaited<ReturnType<typeof doidContractLimitHist_findByAll>>
>;
export type DoidContractLimitHistFindByAllMutationBody = BodyType<EfcvDoidContractLimitHistModel>;
export type DoidContractLimitHistFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useDoidContractLimitHistFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidContractLimitHist_findByAll>>,
        TError,
        { data: BodyType<EfcvDoidContractLimitHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDoidContractLimitHistFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const doidContractLimitHist_findById = (
    getByIdRequestModelOfEfcvDoidContractLimitHistModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvDoidContractLimitHistModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvDoidContractLimitHistModel | void>(
        {
            url: `/factoring/efcvdoidcontractlimithist/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvDoidContractLimitHistModelAndbigdecimal,
        },
        options,
    );
};

export const getDoidContractLimitHistFindByIdMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidContractLimitHist_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvDoidContractLimitHistModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof doidContractLimitHist_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvDoidContractLimitHistModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof doidContractLimitHist_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvDoidContractLimitHistModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return doidContractLimitHist_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DoidContractLimitHistFindByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof doidContractLimitHist_findById>>
>;
export type DoidContractLimitHistFindByIdMutationBody =
    BodyType<GetByIdRequestModelOfEfcvDoidContractLimitHistModelAndbigdecimal>;
export type DoidContractLimitHistFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useDoidContractLimitHistFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidContractLimitHist_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvDoidContractLimitHistModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDoidContractLimitHistFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const doidContractLimitHist_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvDoidContractLimitHistModel | void>(
        {
            url: `/factoring/efcvdoidcontractlimithist/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getDoidContractLimitHistFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidContractLimitHist_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof doidContractLimitHist_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof doidContractLimitHist_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return doidContractLimitHist_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DoidContractLimitHistFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof doidContractLimitHist_findByLightDataRequest>>
>;
export type DoidContractLimitHistFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type DoidContractLimitHistFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useDoidContractLimitHistFindByLightDataRequest = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidContractLimitHist_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDoidContractLimitHistFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const doidContractLimitHist_findByViewDataRequest = (
    viewDataRequestModelOfEfcvDoidContractLimitHistModel: BodyType<ViewDataRequestModelOfEfcvDoidContractLimitHistModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvDoidContractLimitHistModel | void>(
        {
            url: `/factoring/efcvdoidcontractlimithist/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvDoidContractLimitHistModel,
        },
        options,
    );
};

export const getDoidContractLimitHistFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidContractLimitHist_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvDoidContractLimitHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof doidContractLimitHist_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvDoidContractLimitHistModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof doidContractLimitHist_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvDoidContractLimitHistModel> }
    > = props => {
        const { data } = props ?? {};

        return doidContractLimitHist_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DoidContractLimitHistFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof doidContractLimitHist_findByViewDataRequest>>
>;
export type DoidContractLimitHistFindByViewDataRequestMutationBody =
    BodyType<ViewDataRequestModelOfEfcvDoidContractLimitHistModel>;
export type DoidContractLimitHistFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useDoidContractLimitHistFindByViewDataRequest = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidContractLimitHist_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvDoidContractLimitHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDoidContractLimitHistFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
