/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type LoginResponseModelGlobalTwoFactorAuthType =
    (typeof LoginResponseModelGlobalTwoFactorAuthType)[keyof typeof LoginResponseModelGlobalTwoFactorAuthType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LoginResponseModelGlobalTwoFactorAuthType = {
    DISABLED: 'DISABLED',
    SMS: 'SMS',
    PIN: 'PIN',
} as const;
