import { z } from 'zod';

import { APIStringBoolean } from '@workspace/api/types';

import { formError } from '../errors';

export const required = z.string({
    required_error: formError.required,
    invalid_type_error: formError.required,
});

export const requiredNonEmpty = required.min(1, { message: formError.required });

export const email = required.email({ message: formError.email }).max(100, { message: 'form.error.max100Chars' });

const dateErrorsMap = {
    invalid_date: formError.invalidDate,
    invalid_type: formError.required,
} as const;

export const date = z
    .date({
        // NOTE: Workaround for a bug with the ignored `invalid_type_error` inside the ZOD date definition https://github.com/colinhacks/zod/issues/1526
        errorMap: (issue, { defaultError }) => ({
            // @ts-expect-error
            message: dateErrorsMap[issue.code] ?? defaultError,
        }),
    })
    .min(new Date('1900-01-01'), { message: formError.minDate })
    .max(new Date('2999-12-31'), { message: formError.maxDate });

export const idNumber = requiredNonEmpty.max(20, { message: formError.max20Chars });

export const optionalNullableString = z.string().nullable().optional();
export const nullableDate = date.nullable();
export const nullableCheckbox = z.boolean().nullable();

export const optionalNullablePositiveNumber = z
    .string()
    .transform(value => (value.trim() === '' ? null : value))
    .transform(v => (v === null ? v : Number(v)))
    .refine(v => v === null || !isNaN(v), { message: formError.mustBeNumber })
    .refine(v => v === null || v > 0, { message: formError.mustBePositiveNumber })
    .nullish();

export const optionalNullableNumberOrString = z
    .string()
    .transform(v => {
        if (!v?.trim()) {
            return null;
        }

        return v;
    })
    .nullish();

export const optionalNullableDecimal = optionalNullableNumberOrString
    .transform(v => {
        if (!v) {
            return null;
        }
        const valueAsNumber = Number(v);

        if (isNaN(valueAsNumber)) {
            return null;
        }

        return v;
    })
    .optional();

export const nullableBooleanRadio = z
    .nativeEnum(APIStringBoolean)
    .nullable()
    .transform(v => v ?? APIStringBoolean.BOTH)
    .default(APIStringBoolean.BOTH);

export const pin = required.max(20, { message: formError.max20Chars }).refine(value => /^\+?[0-9 ]{5,}$/.test(value), {
    message: formError.pin,
});

export const phoneNumber = required.refine(value => /^\+?[0-9 ]{6,24}$/.test(value), {
    message: formError.phoneNumber,
});

export const variableSymbol = requiredNonEmpty
    .max(20, { message: formError.variableSymbolTooLong })
    .refine(value => !/([&<>\\,@*#%.])/g.test(value), {
        message: formError.specialCharacters,
    });

export const specificSymbol = z.string().max(20, { message: formError.specificSymbolTooLong }).optional();

export const firstName = requiredNonEmpty.max(50, { message: 'form.error.max50Chars' });
export const lastName = requiredNonEmpty.max(50, { message: 'form.error.max50Chars' });
export const personalId = requiredNonEmpty.max(50, { message: 'form.error.max50Chars' });
export const address = requiredNonEmpty.max(150, { message: 'form.error.max150Chars' });
