/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvInterestModel,
    GetByIdRequestModelOfEfcvInterestModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvInterestModel,
    ViewDataResultModelOfEfcvInterestModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const interest_findByAll = (
    efcvInterestModel: BodyType<EfcvInterestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvInterestModel[] | void>(
        {
            url: `/factoring/efcvinterest/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvInterestModel,
        },
        options,
    );
};

export const getInterestFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof interest_findByAll>>,
        TError,
        { data: BodyType<EfcvInterestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof interest_findByAll>>,
    TError,
    { data: BodyType<EfcvInterestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof interest_findByAll>>,
        { data: BodyType<EfcvInterestModel> }
    > = props => {
        const { data } = props ?? {};

        return interest_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type InterestFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof interest_findByAll>>>;
export type InterestFindByAllMutationBody = BodyType<EfcvInterestModel>;
export type InterestFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useInterestFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof interest_findByAll>>,
        TError,
        { data: BodyType<EfcvInterestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getInterestFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const interest_findById = (
    getByIdRequestModelOfEfcvInterestModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvInterestModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvInterestModel | void>(
        {
            url: `/factoring/efcvinterest/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvInterestModelAndbigdecimal,
        },
        options,
    );
};

export const getInterestFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof interest_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvInterestModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof interest_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvInterestModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof interest_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvInterestModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return interest_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type InterestFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof interest_findById>>>;
export type InterestFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvInterestModelAndbigdecimal>;
export type InterestFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useInterestFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof interest_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvInterestModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getInterestFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const interest_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvInterestModel | void>(
        {
            url: `/factoring/efcvinterest/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getInterestFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof interest_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof interest_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof interest_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return interest_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type InterestFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof interest_findByLightDataRequest>>
>;
export type InterestFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type InterestFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useInterestFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof interest_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getInterestFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const interest_findByViewDataRequest = (
    viewDataRequestModelOfEfcvInterestModel: BodyType<ViewDataRequestModelOfEfcvInterestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvInterestModel | void>(
        {
            url: `/factoring/efcvinterest/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvInterestModel,
        },
        options,
    );
};

export const getInterestFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof interest_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvInterestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof interest_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvInterestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof interest_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvInterestModel> }
    > = props => {
        const { data } = props ?? {};

        return interest_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type InterestFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof interest_findByViewDataRequest>>
>;
export type InterestFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvInterestModel>;
export type InterestFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useInterestFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof interest_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvInterestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getInterestFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
