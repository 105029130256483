import { ErrorMessage } from '@hookform/error-message';
import { get } from 'radash';
import * as Styled from './FieldError.styles';
export const FieldError = ({ errors, name }) => {
    const error = get(errors, name);
    if (!error)
        return null;
    return (<ErrorMessage errors={errors} 
    // The type is incompatible with Path<T>, however it means the same
    // @ts-expect-error
    name={name} render={({ message, messages }) => {
            if (messages) {
                return Object.entries(messages).map(([type, message]) => (<Styled.ErrorMessage key={type}>{message}</Styled.ErrorMessage>));
            }
            else {
                return <Styled.ErrorMessage>{message}</Styled.ErrorMessage>;
            }
        }}/>);
};
