import type { SaveCertificateRequestRequestModel } from '@workspace/api';
import { AuthError } from '@workspace/errors';

import { createPKCS12Filename, storePkcs12 } from '../lib';
import {
    createCertificateAttributes,
    createCSRFromSelfSignedCertificate,
    createPersonalInformationContainer,
    createSelfSignedCertificate,
    createSignature,
    getIssuedCertificate,
    getIssuedCertificates,
    getMainCertificate,
    getSelfSignedCertificate,
    type CreateCertificateAttributes,
    type ParsedCertificate,
} from '../lib/pki';
import { loadCertificates } from './loadCertificates';

type SignedCertificateRequestPayload = Omit<
    Required<SaveCertificateRequestRequestModel>,
    'captcha' | 'captchaHash' | 'caName' | 'signature' | 'phoneNumber' | 'challengePassword'
> & {
    /**
     * - Required only if requesting renewal of the certificate.
     * - This field then gonna include current certificate signature.
     */
    signature?: string;

    /**
     * Required only if creating new certificate request, not while cert. renewal.
     */
    phoneNumber?: string;

    /**
     * Required only if creating new certificate request, not while cert. renewal.
     */
    challengePassword?: string;
};

interface CreateCertificateRequestPayloadProps {
    parsedCertificate: ParsedCertificate<true>;
    csrPem: string;
    signature?: string;
}

function createCertificateRequestPayload({
    parsedCertificate,
    csrPem,
    signature,
}: CreateCertificateRequestPayloadProps) {
    const {
        subject: { commonName, address, countryName, personalId, idNumber, email, phoneNumber, challengePassword },
        serialNumber,
    } = parsedCertificate;

    const signedPayload = {
        name: commonName,
        address: [address, countryName].filter(Boolean).join(', '),
        phoneNumber,
        email,
        idNumber,
        personalId,
        challengePassword,
        serialNumber,
        content: csrPem,
        signature,
    } as const satisfies SignedCertificateRequestPayload;

    return signedPayload;
}

/**
 * - The 1st step of certificate request process.
 * - Creates new self-signed certificate and stores it in new PKCS #12 file.
 */
export async function createAndStorePKCS12WithSelfSignedCertificate(attributes: CreateCertificateAttributes) {
    const { certificate: selfSignedCertificate, privateKeyInfo } = await createSelfSignedCertificate(
        createCertificateAttributes(attributes),
    );

    const pkcs12 = await createPersonalInformationContainer([privateKeyInfo], [[selfSignedCertificate]]);

    await storePkcs12({
        pkcs12: pkcs12.toString('base64'),
        name: createPKCS12Filename(selfSignedCertificate),
    });
}

/**
 * - The 1st step of certificate renewal process.
 * – Creates new self-signed certificate and stores it in new PKCS #12 file (prev. certificates and keys are preserved).
 */
export async function createAndStoreCertificateRenewalRequestInPKCS12(attributes: CreateCertificateAttributes) {
    const certificates = await loadCertificates();
    const issuedCertificates = getIssuedCertificates(certificates);
    const issuedCertificate = getMainCertificate(issuedCertificates);

    if (!issuedCertificate) {
        throw new AuthError('NO_ISSUED_CERTIFICATE', `Can't renew certificate without issued certificate.`);
    }

    const { certificate: selfSignedCertificate, privateKeyInfo } = await createSelfSignedCertificate(
        createCertificateAttributes(attributes),
    );

    const keys = [privateKeyInfo, ...issuedCertificates.map(cert => cert.privateKey)];
    const certificatesChains = [[selfSignedCertificate], ...issuedCertificates.map(cert => cert.certificateChain)];

    const pkcs12 = await createPersonalInformationContainer(keys, certificatesChains);

    await storePkcs12({
        pkcs12: pkcs12.toString('base64'),
        name: createPKCS12Filename(selfSignedCertificate),
    });
}

function signWithIssuedCertificate(certificates: ParsedCertificate<true>[], csrPem: string) {
    const issuedCertificate = getIssuedCertificate(certificates);

    return createSignature(issuedCertificate, csrPem);
}

/**
 * The 2nd step of certificate request (creation or renewal) process.
 * @param withSignature
 *     - If true, then the CSR will be signed with the issued certificate and the signature will be included in the payload.
 *     - Used for certificate renewal. On the other hand, creating certificate request doesn't require signature.
 */
export async function createSignedPayloadFromSelfSignedCertificateInPKCS12(withSignature = false) {
    const certificates = await loadCertificates();
    const selfSignedCertificate = getSelfSignedCertificate(certificates);

    const csrPem = await createCSRFromSelfSignedCertificate(selfSignedCertificate);

    const signature = withSignature ? await signWithIssuedCertificate(certificates, csrPem) : undefined;

    const signedPayload = createCertificateRequestPayload({
        parsedCertificate: selfSignedCertificate,
        csrPem,
        signature,
    });

    return signedPayload;
}
