import { useController, useFormContext, type Path } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { InputAutocomplete, TextField, type TextFieldProps, type UnknownFormValues } from '@workspace/ui';

import { FormatSettingsContext } from '~modules/form/contexts';

import { CustomNumericFormat } from './CustomNumericFormat';

/**
 * Type is omitted, as it causes weird behaviour
 */
export interface AmountFieldProps<FormValues extends UnknownFormValues>
    extends Omit<TextFieldProps<FormValues>, 'type'> {
    name: Path<FormValues>;
    decimalScale?: number;
}

function parseNumber(value: any) {
    const parsedValue = Number.parseFloat(value);

    return Number.isNaN(parsedValue) ? null : parsedValue;
}

export const AmountField = <FormValues extends UnknownFormValues>({
    name,
    decimalScale,
    ...rest
}: AmountFieldProps<FormValues>) => {
    const { control } = useFormContext<FormValues>();

    const { field } = useController<FormValues>({
        control,
        name,
    });

    function handleBlur() {
        const value = parseNumber(field.value);

        if (value !== null) {
            const rounded = Math.round(value * 100) / 100;

            field.onChange(String(rounded));
        }
    }

    return (
        <FormatSettingsContext.Provider value={typeof decimalScale === 'number' ? { decimalScale } : null}>
            <TextField<FormValues>
                label={<FormattedMessage id='newPurchasedInvoice.amount' />}
                {...rest}
                name={name}
                autoComplete={InputAutocomplete.Off}
                InputProps={{
                    inputComponent: CustomNumericFormat as any,
                }}
                onBlur={handleBlur}
            />
        </FormatSettingsContext.Provider>
    );
};
