/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type CertificateBlockingResponseModelResult =
    (typeof CertificateBlockingResponseModelResult)[keyof typeof CertificateBlockingResponseModelResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CertificateBlockingResponseModelResult = {
    USERNAME_OR_PIN_ERROR: 'USERNAME_OR_PIN_ERROR',
    CAPTCHA_ERROR: 'CAPTCHA_ERROR',
    CAPTCHA_TIMEOUT: 'CAPTCHA_TIMEOUT',
    OK: 'OK',
} as const;
