import { formError } from '../errors';
import { requiredNonEmpty } from './general';

export const MIN_LENGTH = 10;

export const includesThreeDigits = /(?:\D*\d){3}/;
export const includesOneUpperCase = /(?=.*[A-Z])/;
export const includesOneSpecialChar = /[A-Za-z0-9 ]*[^A-Za-z0-9 ][A-Za-z0-9 ]*/;

const isDigit = (char: string) => Number.isInteger(Number.parseInt(char, 10));

/**
 * Find a number sequence that is at least {length} digits long. and is consisted of sequentially following digits.
 */
function findSequentiallyFollowingDigits(chars: (number | null)[], length: number = 3) {
    if (chars.length < length) {
        return null;
    }

    let sequence: number[] = [];

    for (let i = 0; i < chars.length; i++) {
        const char = chars[i];

        if (char === null) {
            sequence = [];
            continue;
        }

        if (sequence.length === 0) {
            sequence = [char];
            continue;
        }

        const lastItem = sequence[sequence.length - 1];

        if (lastItem + 1 === char) {
            sequence.push(char);

            if (sequence.length === length) break;
        } else {
            sequence = [char];
        }
    }

    return sequence.length === length ? sequence : null;
}

/**
 * Find a number sequence that is at least {length} digits long. and is consisted of equal digits.
 */
function findSeqentiallyEqualDigits(chars: (number | null)[], length: number = 3) {
    if (chars.length < length) {
        return null;
    }

    let sequence: number[] = [];

    for (let i = 0; i < chars.length; i++) {
        const char = chars[i];

        if (char === null) {
            sequence = [];
            continue;
        }

        if (sequence.length === 0) {
            sequence = [char];
            continue;
        }

        const lastItem = sequence[sequence.length - 1];

        if (lastItem === char) {
            sequence.push(char);

            if (sequence.length === length) {
                break;
            }
        } else {
            sequence = [char];
        }
    }

    return sequence.length === length ? sequence : null;
}

export function isWithoutNumberSequence(value: string) {
    const chars = value.split('').map(char => (isDigit(char) ? Number.parseInt(char, 10) : null));

    return findSequentiallyFollowingDigits(chars, 3) === null && findSeqentiallyEqualDigits(chars, 3) === null;
}

export const newPassword = requiredNonEmpty
    .min(MIN_LENGTH, formError.passwordLength)
    .refine(value => includesThreeDigits.test(value), {
        message: formError.passwordNumeric,
    })
    .refine(value => includesOneUpperCase.test(value), {
        message: formError.passwordCasing,
    })
    .refine(value => includesOneSpecialChar.test(value), {
        message: formError.passwordSpecialChar,
    })
    .refine(isWithoutNumberSequence, {
        message: formError.passwordSequence,
    });

// NOTE: Only make the password required (we have to support passwords with older security requirements)
export const oldPassword = requiredNonEmpty;
