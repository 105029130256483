import { buttonClasses, Container as MUIContainer, Stack as MUIStack, styled } from '@mui/material';

import { IconChevronDown, Button as UIButton } from '@workspace/ui';

export const BackgroundContainer = styled('div')({
    position: 'relative',
});

export const Container = styled(MUIContainer)(({ theme }) => ({
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
        maxWidth: 'unset',
    },
}));

export const Stack = styled(MUIStack, { shouldForwardProp: propName => propName !== 'compact' })<{ compact: boolean }>(
    ({ compact, theme }) => ({
        borderBottom: compact ? `1px solid ${theme.color.inputs.border}` : undefined,
        paddingTop: theme.spacing(compact ? 0.75 : 1),
        paddingBottom: theme.spacing(compact ? 0.5 : 1),
    }),
);

export const ChevronDown = styled(IconChevronDown)(({ theme }) => ({
    color: theme.color.accent.secondary,
}));

export const Button = styled(UIButton, {
    shouldForwardProp: prop => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
    '&:hover': {
        background: 'none',
        color: theme.color.accent.secondary,
    },

    ...(active && {
        color: theme.color.accent.secondary,

        [`& .${buttonClasses.endIcon}`]: {
            transform: 'rotate(180deg)',
        },
    }),
}));

export const MenuSelectionItem = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
}));
