/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type PinVerificationResponseModelResult =
    (typeof PinVerificationResponseModelResult)[keyof typeof PinVerificationResponseModelResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PinVerificationResponseModelResult = {
    PIN_ERROR: 'PIN_ERROR',
    CAPTCHA_ERROR: 'CAPTCHA_ERROR',
    CAPTCHA_TIMEOUT: 'CAPTCHA_TIMEOUT',
    OK: 'OK',
} as const;
