import { useMemo } from 'react';
import type { GridColDef } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';

import type { EfcvBlockedCertificateModel } from '@workspace/api';

import { useFormatDate } from '~modules/intl/hooks';

interface EfcvBlockedCertificateModelWithID extends EfcvBlockedCertificateModel {
    id: string;
}

export type BlockedCertificatesGridColumn = GridColDef<EfcvBlockedCertificateModel> & {
    field?: keyof EfcvBlockedCertificateModelWithID;
};

export function useBlockedCertificatesColumns() {
    const { formatMessage, formatNumber } = useIntl();
    const { formatDate } = useFormatDate();

    const columns = useMemo<Array<BlockedCertificatesGridColumn>>(() => {
        return [
            {
                field: 'serialNumber',
                minWidth: 250,
                headerName: formatMessage({ id: `certificateBlockingResult.table.col.serialNumber` }),
                sortable: false,
                flex: 1,
            },
            {
                field: 'blockedAt',
                valueFormatter: params => formatDate(params.value),
                minWidth: 150,
                headerName: formatMessage({ id: `certificateBlockingResult.table.col.blockedAt` }),
                sortable: false,
                flex: 1,
            },
            {
                field: 'validityDateFrom',
                valueFormatter: params => formatDate(params.value),
                minWidth: 150,
                headerName: formatMessage({ id: `certificateBlockingResult.table.col.validityDateFrom` }),
                sortable: false,
                flex: 1,
            },
            {
                field: 'validityDateTo',
                valueFormatter: params => formatDate(params.value),
                minWidth: 150,
                headerName: formatMessage({ id: `certificateBlockingResult.table.col.validityDateTo` }),
                sortable: false,
                flex: 1,
            },
            {
                field: 'requesterName',
                minWidth: 180,
                headerName: formatMessage({ id: `certificateBlockingResult.table.col.requesterName` }),
                sortable: false,
                flex: 1,
            },
            {
                field: 'requesterEmail',
                minWidth: 200,
                headerName: formatMessage({ id: `certificateBlockingResult.table.col.requesterEmail` }),
                sortable: false,
                flex: 1,
            },
            {
                field: 'idCertificate',
                minWidth: 130,
                headerName: formatMessage({ id: `certificateBlockingResult.table.col.idCertificate` }),
                renderCell: params => formatNumber(params.value),
                sortable: false,
                flex: 1,
                align: 'right',
            },
        ];
    }, [formatMessage, formatDate, formatNumber]);

    return columns;
}
