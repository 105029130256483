import type { OctetString, Utf8String } from 'asn1js';
import type { Attribute, AttributeTypeAndValue } from 'pkijs';

import { AttributeType } from './types';
import { bufferToHexCodes } from './utils';

function findAttribute<T extends unknown>(attributes: Attribute[] | undefined, type: AttributeType): T | undefined {
    return (attributes ?? []).find(attr => attr.type === type)?.values[0];
}

export function getLocalIdAttribute(attributes: Attribute[] | undefined) {
    const localKeyId = findAttribute<OctetString>(attributes, AttributeType.LocalKeyId);

    if (!localKeyId) {
        return undefined;
    }

    const buffer = localKeyId.getValue();

    return bufferToHexCodes(buffer);
}

export function getFriendlyNameAttribute(attributes: Attribute[] | undefined) {
    const friendlyName = findAttribute<Utf8String>(attributes, AttributeType.FriendlyName);

    return friendlyName?.getValue();
}

export function parseAttributes(attributes: AttributeTypeAndValue[]) {
    return Object.fromEntries(attributes.map(attribute => [attribute.type, attribute.value.getValue()])) as Record<
        string,
        string
    >;
}
