import type { ReactNode } from 'react';
import type { UseQueryResult } from '@tanstack/react-query';

import { Loader, LoaderContainer, type LoaderContainerHeight } from '@workspace/ui';

type QueryResultBase = Pick<UseQueryResult, 'isLoading' | 'isError' | 'isSuccess'>;

export interface QueryLoaderProps<QueryResult extends QueryResultBase> {
    children: ReactNode | ReactNode[];
    result: QueryResult;
    height?: LoaderContainerHeight;
}

export const QueryLoader = <QueryResult extends QueryResultBase>({
    result,
    children,
    height,
}: QueryLoaderProps<QueryResult>) => {
    if ((!result.isSuccess && !result.isError) || result.isLoading) {
        return (
            <LoaderContainer height={height}>
                <Loader />
            </LoaderContainer>
        );
    }

    return <>{children}</>;
};
