/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvEnumerationModel,
    GetByIdRequestModelOfEfcvEnumerationModelAndbigdecimal,
    ViewDataRequestModelOfEfcvEnumerationModel,
    ViewDataResultModelOfEfcvEnumerationModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const enumeration_findByAll = (
    efcvEnumerationModel: BodyType<EfcvEnumerationModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvEnumerationModel[] | void>(
        {
            url: `/factoring/efcvenumeration/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvEnumerationModel,
        },
        options,
    );
};

export const getEnumerationFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof enumeration_findByAll>>,
        TError,
        { data: BodyType<EfcvEnumerationModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof enumeration_findByAll>>,
    TError,
    { data: BodyType<EfcvEnumerationModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof enumeration_findByAll>>,
        { data: BodyType<EfcvEnumerationModel> }
    > = props => {
        const { data } = props ?? {};

        return enumeration_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type EnumerationFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof enumeration_findByAll>>>;
export type EnumerationFindByAllMutationBody = BodyType<EfcvEnumerationModel>;
export type EnumerationFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useEnumerationFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof enumeration_findByAll>>,
        TError,
        { data: BodyType<EfcvEnumerationModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getEnumerationFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const enumeration_findById = (
    getByIdRequestModelOfEfcvEnumerationModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvEnumerationModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvEnumerationModel | void>(
        {
            url: `/factoring/efcvenumeration/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvEnumerationModelAndbigdecimal,
        },
        options,
    );
};

export const getEnumerationFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof enumeration_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvEnumerationModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof enumeration_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvEnumerationModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof enumeration_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvEnumerationModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return enumeration_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type EnumerationFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof enumeration_findById>>>;
export type EnumerationFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvEnumerationModelAndbigdecimal>;
export type EnumerationFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useEnumerationFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof enumeration_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvEnumerationModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getEnumerationFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const enumeration_findByViewDataRequest = (
    viewDataRequestModelOfEfcvEnumerationModel: BodyType<ViewDataRequestModelOfEfcvEnumerationModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvEnumerationModel | void>(
        {
            url: `/factoring/efcvenumeration/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvEnumerationModel,
        },
        options,
    );
};

export const getEnumerationFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof enumeration_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvEnumerationModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof enumeration_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvEnumerationModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof enumeration_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvEnumerationModel> }
    > = props => {
        const { data } = props ?? {};

        return enumeration_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type EnumerationFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof enumeration_findByViewDataRequest>>
>;
export type EnumerationFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvEnumerationModel>;
export type EnumerationFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useEnumerationFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof enumeration_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvEnumerationModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getEnumerationFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
