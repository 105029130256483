import { useRef, type ChangeEvent } from 'react';
import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { isValidPkcs12, pkcs12Storage, storePkcs12FromFile, worker } from '@workspace/certificates';
import { logger } from '@workspace/logger';
import { Button, Icon, IconDotsHorizontal } from '@workspace/ui';

import { CertificateNameField } from '~modules/form/components';
import { useShowMessagePopup } from '~modules/message-popup/hooks';

import type { LoginFormSchema } from '../schema';

export const CertificateFileField = () => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { setValue, formState } = useFormContext<LoginFormSchema>();
    const showMessage = useShowMessagePopup();

    async function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
        const [file] = Array.from<File>(e.target.files ?? []);

        if (!file) return;

        if (!isValidPkcs12(file)) {
            await showMessage('error', {
                id: 'form.error.fileType',
                values: { name: file.name },
            });

            return;
        }

        try {
            await Promise.all([worker.resetStoredPassword(), pkcs12Storage.clear()]);

            await storePkcs12FromFile(file);
            setValue('filename', file.name, { shouldValidate: true });
        } catch (error) {
            logger.error(error);
            await showMessage('error', { id: 'general.error' });
        }
    }

    return (
        <Stack spacing={0.5} direction='row'>
            <CertificateNameField<LoginFormSchema>
                name='filename'
                endAdornment={
                    <Button
                        iconOnly
                        variant='secondary'
                        onClick={() => fileInputRef.current?.click()}
                        disabled={formState.isSubmitting}
                    >
                        <Icon icon={IconDotsHorizontal} />
                    </Button>
                }
            />
            <input
                type='file'
                accept='.p12,.key'
                onChange={handleFileChange}
                ref={fileInputRef}
                hidden
                multiple={false}
            />
        </Stack>
    );
};
