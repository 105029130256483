import Link from 'next/link';
import { useRouter } from 'next/router';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Button, IconBack } from '@workspace/ui';

export interface FormBackButtonProps {
    backRoute?: string;
}

export const FormBackButton = ({ backRoute }: FormBackButtonProps) => {
    const { back } = useRouter();
    const {
        formState: { isSubmitting },
    } = useFormContext();

    const commonProps = {
        variant: 'primary',
        startIcon: <IconBack />,
        disabled: isSubmitting,
        children: <FormattedMessage id='button.back' />,
    } as const;

    if (backRoute) {
        return <Button {...commonProps} LinkComponent={Link} href={backRoute} />;
    }

    return <Button {...commonProps} onClick={() => back()} />;
};
