import { useState } from 'react';
import { ClickAwayListener } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { usePermittedNavigationItems } from '../../hooks';
import { Menu } from './Menu';
import * as Styled from './Navigation.styles';

export interface NavigationProps {
    compact?: boolean;
}
export interface KeyDownProps {
    keyCode: string;
    title: string;
}

export const Navigation = ({ compact }: NavigationProps) => {
    const [activeItem, setActiveItem] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    const navItems = usePermittedNavigationItems();

    const handleHover = (title: string) => {
        setOpen(true);
        setActiveItem(title);
    };

    const handleClose = () => {
        if (open) {
            setOpen(false);
            setActiveItem(null);
        }
    };

    const handleKeyDown = ({ keyCode, title }: KeyDownProps) => {
        if (keyCode === 'Space') {
            if (open) handleClose();
            else handleHover(title);

            return;
        }
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Styled.BackgroundContainer>
                <Styled.Container>
                    <Styled.Stack direction='row' gap={1.5} compact={!!compact} onMouseLeave={handleClose}>
                        {navItems.map(item => (
                            <Styled.MenuSelectionItem key={item.title}>
                                <Styled.Button
                                    variant='unstyled'
                                    endIcon={<Styled.ChevronDown />}
                                    onMouseEnter={() => handleHover(item.title)}
                                    onKeyDown={e => handleKeyDown({ keyCode: e.code, title: item.title })}
                                    active={item.title === activeItem}
                                    tabIndex={1}
                                >
                                    <FormattedMessage id={item.title} />
                                </Styled.Button>
                                <Menu
                                    item={item}
                                    compact={!!compact}
                                    activeItem={activeItem}
                                    handleClose={handleClose}
                                />
                            </Styled.MenuSelectionItem>
                        ))}
                    </Styled.Stack>
                </Styled.Container>
            </Styled.BackgroundContainer>
        </ClickAwayListener>
    );
};
