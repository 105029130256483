import { useQuery } from '@tanstack/react-query';

import { authServices_sendSmsCode, QueryKey } from '@workspace/api';

export const useSmsCodeValidity = (phoneNumber: string) => {
    const smsCodeValidity = useQuery({
        queryKey: [QueryKey.AUTH_SEND_SMS_CODE, phoneNumber],
        queryFn: async () => {
            const result = await authServices_sendSmsCode({ request: phoneNumber });

            return result?.data?.validityPeriod! * 1000;
        },
    });

    return smsCodeValidity.data;
};
