import { Grid, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { TextField } from '@workspace/ui';

import { useLocalizedResolver } from '~modules/form/hooks';

import { CertificateFileField } from './CertificateFileField';
import { FormButtons } from './FormButtons';
import { useHydrateLoginForm } from './hooks/useHydrateLoginForm';
import { loginFormSchema, type LoginFormSchema } from './schema';

export const LoginForm = () => {
    const resolver = useLocalizedResolver(loginFormSchema);
    const defaultValues = useHydrateLoginForm();
    const form = useForm<LoginFormSchema>({
        resolver,
        defaultValues,
    });

    return (
        <FormProvider<LoginFormSchema> {...form}>
            <form>
                <Grid container spacing={1}>
                    <Grid item xs={7}>
                        <Stack justifyContent='space-between' spacing={0.625} height='100%'>
                            <TextField<LoginFormSchema>
                                name='username'
                                label={<FormattedMessage id='login.form.user' />}
                                fullWidth
                            />
                            <CertificateFileField />
                        </Stack>
                    </Grid>
                    <Grid item xs={5}>
                        <FormButtons />
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
