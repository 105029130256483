import { useCallback, useEffect, useState } from 'react';

const COUNTDOWN_STEP = 1000;

export interface UseCountdownProps {
    timeout: number;
    onStart?: () => void;
    onEnd?: () => void;
}

export function useCountdown({ timeout, onEnd, onStart }: UseCountdownProps) {
    const [timeToCountdown, updateTimeToCountdown] = useState(0);
    const [active, setActive] = useState(false);

    const startCountdown = useCallback(() => {
        updateTimeToCountdown(timeout);
        setActive(true);
        onStart?.();
    }, [timeout, onStart]);

    useEffect(() => {
        if (!active) {
            return;
        }

        const intervalId = setInterval(() => {
            updateTimeToCountdown(timeToCountdown => {
                const nextTimeToCountdown = timeToCountdown - COUNTDOWN_STEP;

                if (nextTimeToCountdown === 0) {
                    clearInterval(intervalId);
                    onEnd?.();
                    setActive(false);

                    return 0;
                }

                return nextTimeToCountdown;
            });
        }, COUNTDOWN_STEP);

        return () => {
            clearInterval(intervalId);
        };
    }, [active, onEnd]);

    return [timeToCountdown / 1000, startCountdown] as const;
}
