import { useRouter } from 'next/router';
import { useAtomValue } from 'jotai';
import type { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { authServices_verifySmsCode, dataService_setUsersPhoneNumber } from '@workspace/api';
import { useFinish2FA } from '@workspace/auth';
import { worker } from '@workspace/certificates';
import { isAuthError } from '@workspace/errors';
import { logger } from '@workspace/logger';

import { routes } from '~constants';
import { phoneNumberAtom } from '~modules/auth/atoms';
import { usePromptLogin } from '~modules/auth/hooks';

import type { SmsCodeFormSchema } from '../schema';

export type UseSubmitVerifyNumberFormProps = UseFormReturn<SmsCodeFormSchema>;

export const useSubmitVerifyNumberForm = ({ setValue, setError }: UseSubmitVerifyNumberFormProps) => {
    const phoneNumber = useAtomValue(phoneNumberAtom);
    const { promptUser } = usePromptLogin();
    const { push } = useRouter();
    const { formatMessage } = useIntl();
    const finish2FA = useFinish2FA();

    async function changePhoneNumber(phoneNumber: string, code: string) {
        const hasStoredPassword = await worker.hasStoredPassword();

        if (!hasStoredPassword) {
            const response = await promptUser();

            if (response === 'no') return false;
        }

        const signature = await worker.signWithIssuedCertificate(`${phoneNumber}|${code}`);

        setValue('signature', signature);

        await dataService_setUsersPhoneNumber({
            signature,
            content: `${phoneNumber}|${code}`,
            phoneNumber,
            smsVerificationCode: code,
        });

        return true;
    }

    async function onSubmit({ code }: SmsCodeFormSchema) {
        try {
            const { data } = await authServices_verifySmsCode(code);

            if (!data) {
                setError('code', {
                    message: formatMessage({ id: 'phoneNumber.code.invalid' }),
                });

                return;
            }

            if (phoneNumber) {
                const result = await changePhoneNumber(phoneNumber, code);

                if (!result) return;
            }

            await finish2FA();
            await push(routes.home);
        } catch (e) {
            logger.error(e);

            if (isAuthError(e)) {
                setError('code', {
                    message: formatMessage({ id: `error.auth.${e.code}` }),
                });
            } else {
                setError('code', {
                    message: formatMessage({ id: 'phoneNumber.serviceOutOfOrder' }),
                });
            }
        }
    }

    return onSubmit;
};
