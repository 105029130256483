import { Dialog, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Button, IconCheck, IconWarning, IconXClose } from '@workspace/ui';

import { useControlMessagePopup } from '~modules/message-popup/hooks';

import {
    ButtonContainer,
    CloseButton,
    Content,
    DialogBody,
    DialogContent,
    DialogHeader,
    IconWrapper,
} from './MessagePopup.styles';

export const MessagePopup = () => {
    const { opened, close, content } = useControlMessagePopup();

    return (
        <Dialog open={opened} onClose={close}>
            {content && (
                <DialogBody>
                    <DialogHeader type={content.type}>
                        <CloseButton onClick={close}>
                            <IconXClose />
                        </CloseButton>
                    </DialogHeader>
                    <DialogContent>
                        <Stack direction='row' alignItems='flex-start' gap={0.625}>
                            <IconWrapper>
                                {content.type === 'success' ? <IconCheck fontSize='1.5rem' /> : <IconWarning />}
                            </IconWrapper>
                            <Content>
                                {content.message && <FormattedMessage {...content.message} />}
                                {content.rawContent}
                            </Content>
                        </Stack>
                        <ButtonContainer>
                            <Button variant='primary' onClick={close}>
                                <FormattedMessage id='button.ok' />
                            </Button>
                        </ButtonContainer>
                    </DialogContent>
                </DialogBody>
            )}
        </Dialog>
    );
};
