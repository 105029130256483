import Link from 'next/link';

import { routes } from '~constants';

import { HeaderAuthNavigation } from '../HeaderAuthNavigation';
import { LanguageSwitch } from '../LanguageSwitch';
import * as Styled from './Header.styles';

export interface HeaderProps {
    isAuth?: boolean;
}

export const Header = ({ isAuth }: HeaderProps) => {
    return (
        <Styled.Container>
            <Styled.Content>
                <Link href={routes.home} tabIndex={1}>
                    <Styled.Logo />
                </Link>

                {isAuth ? <HeaderAuthNavigation /> : <LanguageSwitch />}
            </Styled.Content>
        </Styled.Container>
    );
};
