/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type SessionActionRequestModelAction =
    (typeof SessionActionRequestModelAction)[keyof typeof SessionActionRequestModelAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SessionActionRequestModelAction = {
    PURCHASEDINVOICE_FILTER: 'PURCHASEDINVOICE_FILTER',
    PURCHASEDINVOICE_OVERVIEW: 'PURCHASEDINVOICE_OVERVIEW',
    PURCHASEDINVOICE_DETAIL: 'PURCHASEDINVOICE_DETAIL',
    PURCHASEDINVOICE_REVERSE_FINANCING: 'PURCHASEDINVOICE_REVERSE_FINANCING',
    PURCHASEDINVOICE_PDF_REPORT_100025: 'PURCHASEDINVOICE_PDF_REPORT_100025',
    PURCHASEDINVOICE_PDF_REPORT_100011: 'PURCHASEDINVOICE_PDF_REPORT_100011',
    PURCHASEDINVOICE_PDF_REPORT_100012: 'PURCHASEDINVOICE_PDF_REPORT_100012',
    PURCHASEDINVOICE_PDF_REPORT_100019: 'PURCHASEDINVOICE_PDF_REPORT_100019',
    PURCHASEDINVOICE_FO: 'PURCHASEDINVOICE_FO',
    PURCHASEDINVOICE_FS: 'PURCHASEDINVOICE_FS',
    PURCHASEDINVOICE_FT: 'PURCHASEDINVOICE_FT',
    PURCHASEDINVOICE_FF: 'PURCHASEDINVOICE_FF',
    SUPPLIERINVOICE_FILTER: 'SUPPLIERINVOICE_FILTER',
    SUPPLIERINVOICE_OVERVIEW: 'SUPPLIERINVOICE_OVERVIEW',
    SUPPLIERINVOICE_DETAIL: 'SUPPLIERINVOICE_DETAIL',
    SUPPLIERINVOICE_PDF_REPORT_100027: 'SUPPLIERINVOICE_PDF_REPORT_100027',
    OPENINVOICE_FILTER: 'OPENINVOICE_FILTER',
    OPENINVOICE_OVERVIEW: 'OPENINVOICE_OVERVIEW',
    OPENINVOICE_PDF_REPORT_100028: 'OPENINVOICE_PDF_REPORT_100028',
    OPENINVOICE_PDF_REPORT_100045: 'OPENINVOICE_PDF_REPORT_100045',
    OPENINVOICE_FS: 'OPENINVOICE_FS',
    OPENINVOICE_FT: 'OPENINVOICE_FT',
    ISSUEDINVOICE_FILTER: 'ISSUEDINVOICE_FILTER',
    ISSUEDINVOICE_OVERVIEW: 'ISSUEDINVOICE_OVERVIEW',
    ISSUEDINVOICE_DETAIL: 'ISSUEDINVOICE_DETAIL',
    ISSUEDINVOICE_FP: 'ISSUEDINVOICE_FP',
    ISSUEDINVOICE_FU: 'ISSUEDINVOICE_FU',
    ISSUEDINVOICE_FV: 'ISSUEDINVOICE_FV',
    ISSUEDINVOICE_CSV_REPORT_100030: 'ISSUEDINVOICE_CSV_REPORT_100030',
    ISSUEDINVOICE_CSV_REPORT_100031: 'ISSUEDINVOICE_CSV_REPORT_100031',
    ISSUEDINVOICE_CSV_REPORT_100032: 'ISSUEDINVOICE_CSV_REPORT_100032',
    ISSUEDINVOICE_CSV_REPORT_100033: 'ISSUEDINVOICE_CSV_REPORT_100033',
    ISSUEDINVOICE_PDF_REPORT_100016: 'ISSUEDINVOICE_PDF_REPORT_100016',
    ISSUEDINVOICE_PDF_REPORT_100029: 'ISSUEDINVOICE_PDF_REPORT_100029',
    ISSUEDINVOICE_PDF_REPORT_100026: 'ISSUEDINVOICE_PDF_REPORT_100026',
    DUEINVOICE_FILTER: 'DUEINVOICE_FILTER',
    DUEINVOICE_OVERVIEW: 'DUEINVOICE_OVERVIEW',
    DUEINVOICE_DETAIL: 'DUEINVOICE_DETAIL',
    SUPPLIERLIMIT_OVERVIEW: 'SUPPLIERLIMIT_OVERVIEW',
    SUPPLIERLIMIT_PDF_REPORT_100015: 'SUPPLIERLIMIT_PDF_REPORT_100015',
    SUPPLIERPRODUCTLIMIT_OVERVIEW: 'SUPPLIERPRODUCTLIMIT_OVERVIEW',
    SUPPLIERPRODUCTLIMIT_PDF_REPORT_100013: 'SUPPLIERPRODUCTLIMIT_PDF_REPORT_100013',
    SUPPLIERCUSTOMERLIMIT_FILTER: 'SUPPLIERCUSTOMERLIMIT_FILTER',
    SUPPLIERCUSTOMERLIMIT_OVERVIEW: 'SUPPLIERCUSTOMERLIMIT_OVERVIEW',
    SUPPLIERCUSTOMERLIMIT_DETAIL: 'SUPPLIERCUSTOMERLIMIT_DETAIL',
    SUPPLIERCUSTOMERLIMIT_PDF_REPORT_100014: 'SUPPLIERCUSTOMERLIMIT_PDF_REPORT_100014',
    SUPPLIERCUSTOMERLIMIT_FL: 'SUPPLIERCUSTOMERLIMIT_FL',
    SUPPLIERPAYMENT_FILTER: 'SUPPLIERPAYMENT_FILTER',
    SUPPLIERPAYMENT_OVERVIEW: 'SUPPLIERPAYMENT_OVERVIEW',
    SUPPLIERPAYMENT_DETAIL: 'SUPPLIERPAYMENT_DETAIL',
    SUPPLIERPAYMENT_PDF_REPORT_100017: 'SUPPLIERPAYMENT_PDF_REPORT_100017',
    SUPPLIERPAYMENT_PDF_REPORT_100018: 'SUPPLIERPAYMENT_PDF_REPORT_100018',
    SUPPLIERPAYMENT_FA: 'SUPPLIERPAYMENT_FA',
    PURCHASEDINVOICE_BATCH_FILTER: 'PURCHASEDINVOICE_BATCH_FILTER',
    PURCHASEDINVOICE_BATCH_OVERVIEW: 'PURCHASEDINVOICE_BATCH_OVERVIEW',
    PURCHASEDINVOICE_BATCH_INVOICE_INPUT: 'PURCHASEDINVOICE_BATCH_INVOICE_INPUT',
    PURCHASEDINVOICE_BATCH_INVOICE_CONFIRMATION: 'PURCHASEDINVOICE_BATCH_INVOICE_CONFIRMATION',
    SUPPLIERINVOICE_BATCH_FILTER: 'SUPPLIERINVOICE_BATCH_FILTER',
    SUPPLIERINVOICE_BATCH_OVERVIEW: 'SUPPLIERINVOICE_BATCH_OVERVIEW',
    SUPPLIERINVOICE_BATCH_INVOICE_INPUT: 'SUPPLIERINVOICE_BATCH_INVOICE_INPUT',
    SUPPLIERINVOICE_BATCH_INVOICE_CONFIRMATION: 'SUPPLIERINVOICE_BATCH_INVOICE_CONFIRMATION',
    PURCHASEDINVOICE_MANUAL_INVOICE_CONFIRMATION: 'PURCHASEDINVOICE_MANUAL_INVOICE_CONFIRMATION',
    SUPPLIERINVOICE_MANUAL_INVOICE_CONFIRMATION: 'SUPPLIERINVOICE_MANUAL_INVOICE_CONFIRMATION',
    BBS_EXPORTING_FILTER: 'BBS_EXPORTING_FILTER',
    BBS_EXPORTING_OVERVIEW: 'BBS_EXPORTING_OVERVIEW',
    PDF_EXPORTING_FILTER: 'PDF_EXPORTING_FILTER',
    PDF_EXPORTING_OVERVIEW: 'PDF_EXPORTING_OVERVIEW',
    EXPORTING_EXPORT_SCHEDULE: 'EXPORTING_EXPORT_SCHEDULE',
    SYSTEM_DOCUMENTS: 'SYSTEM_DOCUMENTS',
    SYSTEM_USERSETTINGS: 'SYSTEM_USERSETTINGS',
    SYSTEM_PARTNERSETTINGS: 'SYSTEM_PARTNERSETTINGS',
    SYSTEM_LOGOUT: 'SYSTEM_LOGOUT',
    SYSTEM_HOME: 'SYSTEM_HOME',
    SYSTEM_HELP: 'SYSTEM_HELP',
    SYSTEM_PASSWORD_CHANGE: 'SYSTEM_PASSWORD_CHANGE',
    SYSTEM_CERTIFICATES_OVERVIEW: 'SYSTEM_CERTIFICATES_OVERVIEW',
    PURCHASEDINVOICE_CSV_EXPORT: 'PURCHASEDINVOICE_CSV_EXPORT',
    SUPPLIERINVOICE_CSV_EXPORT: 'SUPPLIERINVOICE_CSV_EXPORT',
    OPENINVOICE_CSV_EXPORT: 'OPENINVOICE_CSV_EXPORT',
    ISSUEDINVOICE_CSV_EXPORT: 'ISSUEDINVOICE_CSV_EXPORT',
    DUEINVOICE_CSV_EXPORT: 'DUEINVOICE_CSV_EXPORT',
    SUPPLIERLIMIT_CSV_EXPORT: 'SUPPLIERLIMIT_CSV_EXPORT',
    SUPPLIERPAYMENT_CSV_EXPORT: 'SUPPLIERPAYMENT_CSV_EXPORT',
    BBS_EXPORTING_CSV_EXPORT: 'BBS_EXPORTING_CSV_EXPORT',
    PDF_EXPORTING_CSV_EXPORT: 'PDF_EXPORTING_CSV_EXPORT',
    SYSTEM_SUPPLIERCUSTOMER_CHOOSE: 'SYSTEM_SUPPLIERCUSTOMER_CHOOSE',
    PURCHASEDINVOICE_PDF_REPORT_1000002: 'PURCHASEDINVOICE_PDF_REPORT_1000002',
    SYSTEM_CLIENT_PDF_REPORT: 'SYSTEM_CLIENT_PDF_REPORT',
    PURCHASEDINVOICE_BATCH_DETAIL: 'PURCHASEDINVOICE_BATCH_DETAIL',
    SUPPLIERINVOICE_BATCH_DETAIL: 'SUPPLIERINVOICE_BATCH_DETAIL',
    SUPPLIERPRODUCTLIMIT_CSV_EXPORT: 'SUPPLIERPRODUCTLIMIT_CSV_EXPORT',
    SUPPLIERCUSTOMERLIMIT_CSV_EXPORT: 'SUPPLIERCUSTOMERLIMIT_CSV_EXPORT',
    PURCHASEDINVOICE_BATCH_CSV_EXPORT: 'PURCHASEDINVOICE_BATCH_CSV_EXPORT',
    SUPPLIERINVOICE_BATCH_CSV_EXPORT: 'SUPPLIERINVOICE_BATCH_CSV_EXPORT',
    LIMITSHISTORY_OVERVIEW: 'LIMITSHISTORY_OVERVIEW',
    LIMITSHISTORY_CSV_EXPORT: 'LIMITSHISTORY_CSV_EXPORT',
    OPENINVOICE_DETAIL: 'OPENINVOICE_DETAIL',
    SYSTEM_CERTIFICATES_RENEWAL_REQUEST: 'SYSTEM_CERTIFICATES_RENEWAL_REQUEST',
    SYSTEM_CERTIFICATES_REGISTER_EXTERNAL: 'SYSTEM_CERTIFICATES_REGISTER_EXTERNAL',
    SYSTEM_CERTIFICATES_SEND_REQUEST: 'SYSTEM_CERTIFICATES_SEND_REQUEST',
    SYSTEM_PIN_SETTING: 'SYSTEM_PIN_SETTING',
    PURCHASEDINVOICE_CEDING_BATCH_CSV_EXPORT: 'PURCHASEDINVOICE_CEDING_BATCH_CSV_EXPORT',
    PURCHASEDINVOICE_CEDING_BATCH_DETAIL: 'PURCHASEDINVOICE_CEDING_BATCH_DETAIL',
    PURCHASEDINVOICE_CEDING_BATCH_FILTER: 'PURCHASEDINVOICE_CEDING_BATCH_FILTER',
    PURCHASEDINVOICE_CEDING_BATCH_OVERVIEW: 'PURCHASEDINVOICE_CEDING_BATCH_OVERVIEW',
    PURCHASEDINVOICE_CEDING_BATCH_INVOICE_INPUT: 'PURCHASEDINVOICE_CEDING_BATCH_INVOICE_INPUT',
    PURCHASEDINVOICE_CEDING_BATCH_INVOICE_CONFIRMATION: 'PURCHASEDINVOICE_CEDING_BATCH_INVOICE_CONFIRMATION',
    PURCHASEDINVOICE_MANUAL_INVOICE_SAVE: 'PURCHASEDINVOICE_MANUAL_INVOICE_SAVE',
    PURCHASEDINVOICE_MANUAL_INVOICE_SAVE_OK: 'PURCHASEDINVOICE_MANUAL_INVOICE_SAVE_OK',
    PURCHASEDINVOICE_MANUAL_INVOICE_SAVE_FAILED: 'PURCHASEDINVOICE_MANUAL_INVOICE_SAVE_FAILED',
    SUPPLIERINVOICE_MANUAL_INVOICE_SAVE: 'SUPPLIERINVOICE_MANUAL_INVOICE_SAVE',
    SUPPLIERINVOICE_MANUAL_INVOICE_SAVE_OK: 'SUPPLIERINVOICE_MANUAL_INVOICE_SAVE_OK',
    SUPPLIERINVOICE_MANUAL_INVOICE_SAVE_FAILED: 'SUPPLIERINVOICE_MANUAL_INVOICE_SAVE_FAILED',
    PURCHASEDINVOICE_BATCH_INVOICE_SAVE: 'PURCHASEDINVOICE_BATCH_INVOICE_SAVE',
    PURCHASEDINVOICE_BATCH_INVOICE_SAVE_OK: 'PURCHASEDINVOICE_BATCH_INVOICE_SAVE_OK',
    PURCHASEDINVOICE_BATCH_INVOICE_SAVE_FAILED: 'PURCHASEDINVOICE_BATCH_INVOICE_SAVE_FAILED',
    SUPPLIERINVOICE_BATCH_INVOICE_SAVE: 'SUPPLIERINVOICE_BATCH_INVOICE_SAVE',
    SUPPLIERINVOICE_BATCH_INVOICE_SAVE_OK: 'SUPPLIERINVOICE_BATCH_INVOICE_SAVE_OK',
    SUPPLIERINVOICE_BATCH_INVOICE_SAVE_FAILED: 'SUPPLIERINVOICE_BATCH_INVOICE_SAVE_FAILED',
    PURCHASEDINVOICE_CEDING_BATCH_INVOICE_SAVE: 'PURCHASEDINVOICE_CEDING_BATCH_INVOICE_SAVE',
    PURCHASEDINVOICE_CEDING_BATCH_INVOICE_SAVE_OK: 'PURCHASEDINVOICE_CEDING_BATCH_INVOICE_SAVE_OK',
    PURCHASEDINVOICE_CEDING_BATCH_INVOICE_SAVE_FAILED: 'PURCHASEDINVOICE_CEDING_BATCH_INVOICE_SAVE_FAILED',
    PURCHASEDINVOICE_MANUAL_INVOICE_INPUT_FORM: 'PURCHASEDINVOICE_MANUAL_INVOICE_INPUT_FORM',
    SUPPLIERINVOICE_MANUAL_INVOICE_INPUT_FORM: 'SUPPLIERINVOICE_MANUAL_INVOICE_INPUT_FORM',
    ISSUEDINVOICE_FB: 'ISSUEDINVOICE_FB',
    PURCHASEDINVOICE_PDF_REPORT_100044: 'PURCHASEDINVOICE_PDF_REPORT_100044',
    SUPPLIER_CUSTOMER_CHOOSE_FILTER: 'SUPPLIER_CUSTOMER_CHOOSE_FILTER',
    SUPPLIER_CUSTOMER_CHOOSE_OVERVIEW: 'SUPPLIER_CUSTOMER_CHOOSE_OVERVIEW',
    NONFINANCEDINVOICE_FILTER: 'NONFINANCEDINVOICE_FILTER',
    NONFINANCEDINVOICE_OVERVIEW: 'NONFINANCEDINVOICE_OVERVIEW',
    SMS_AUTHENTICATION_PHONE_NUMBER_SETTING: 'SMS_AUTHENTICATION_PHONE_NUMBER_SETTING',
    SMS_AUTHENTICATION_CODE_VERIFICATION: 'SMS_AUTHENTICATION_CODE_VERIFICATION',
    PIN_VERIFICATION: 'PIN_VERIFICATION',
    SUPPLIERCONTRACTLIMIT_OVERVIEW: 'SUPPLIERCONTRACTLIMIT_OVERVIEW',
    SUPPLIERCONTRACTLIMIT_PDF_REPORT_100047: 'SUPPLIERCONTRACTLIMIT_PDF_REPORT_100047',
    SUPPLIERCONTRACTLIMIT_CSV_EXPORT: 'SUPPLIERCONTRACTLIMIT_CSV_EXPORT',
    PURCHASEDINVOICE_REVERSE_MANUAL_INVOICE_INPUT_FORM: 'PURCHASEDINVOICE_REVERSE_MANUAL_INVOICE_INPUT_FORM',
    PURCHASEDINVOICE_REVERSE_MANUAL_INVOICE_CONFIRMATION: 'PURCHASEDINVOICE_REVERSE_MANUAL_INVOICE_CONFIRMATION',
    PURCHASEDINVOICE_REVERSE_MANUAL_INVOICE_SAVE: 'PURCHASEDINVOICE_REVERSE_MANUAL_INVOICE_SAVE',
    PURCHASEDINVOICE_REVERSE_MANUAL_INVOICE_SAVE_OK: 'PURCHASEDINVOICE_REVERSE_MANUAL_INVOICE_SAVE_OK',
    PURCHASEDINVOICE_REVERSE_MANUAL_INVOICE_SAVE_FAILED: 'PURCHASEDINVOICE_REVERSE_MANUAL_INVOICE_SAVE_FAILED',
    PURCHASEDINVOICE_REVERSE_BATCH_CSV_EXPORT: 'PURCHASEDINVOICE_REVERSE_BATCH_CSV_EXPORT',
    PURCHASEDINVOICE_REVERSE_BATCH_DETAIL: 'PURCHASEDINVOICE_REVERSE_BATCH_DETAIL',
    PURCHASEDINVOICE_REVERSE_BATCH_FILTER: 'PURCHASEDINVOICE_REVERSE_BATCH_FILTER',
    PURCHASEDINVOICE_REVERSE_BATCH_OVERVIEW: 'PURCHASEDINVOICE_REVERSE_BATCH_OVERVIEW',
    PURCHASEDINVOICE_REVERSE_BATCH_INVOICE_INPUT: 'PURCHASEDINVOICE_REVERSE_BATCH_INVOICE_INPUT',
    PURCHASEDINVOICE_REVERSE_BATCH_INVOICE_CONFIRMATION: 'PURCHASEDINVOICE_REVERSE_BATCH_INVOICE_CONFIRMATION',
    PURCHASEDINVOICE_REVERSE_BATCH_INVOICE_SAVE: 'PURCHASEDINVOICE_REVERSE_BATCH_INVOICE_SAVE',
    PURCHASEDINVOICE_REVERSE_BATCH_INVOICE_SAVE_OK: 'PURCHASEDINVOICE_REVERSE_BATCH_INVOICE_SAVE_OK',
    PURCHASEDINVOICE_REVERSE_BATCH_INVOICE_SAVE_FAILED: 'PURCHASEDINVOICE_REVERSE_BATCH_INVOICE_SAVE_FAILED',
    DISCOUNTINVOICE_FILTER: 'DISCOUNTINVOICE_FILTER',
    DISCOUNTINVOICE_OVERVIEW: 'DISCOUNTINVOICE_OVERVIEW',
    DISCOUNTINVOICE_CSV_EXPORT: 'DISCOUNTINVOICE_CSV_EXPORT',
    DISCOUNTINVOICE_DETAIL: 'DISCOUNTINVOICE_DETAIL',
    DISCOUNTINVOICE_MANUAL_INVOICE_INPUT_FORM: 'DISCOUNTINVOICE_MANUAL_INVOICE_INPUT_FORM',
    DISCOUNTINVOICE_MANUAL_INVOICE_CONFIRMATION: 'DISCOUNTINVOICE_MANUAL_INVOICE_CONFIRMATION',
    DISCOUNTINVOICE_REVERSE_MANUAL_INVOICE_INPUT_FORM: 'DISCOUNTINVOICE_REVERSE_MANUAL_INVOICE_INPUT_FORM',
    DISCOUNTINVOICE_REVERSE_MANUAL_INVOICE_CONFIRMATION: 'DISCOUNTINVOICE_REVERSE_MANUAL_INVOICE_CONFIRMATION',
    DISCOUNTINVOICE_BATCH_OVERVIEW: 'DISCOUNTINVOICE_BATCH_OVERVIEW',
    DISCOUNTINVOICE_BATCH_FILTER: 'DISCOUNTINVOICE_BATCH_FILTER',
    DISCOUNTINVOICE_BATCH_CSV_EXPORT: 'DISCOUNTINVOICE_BATCH_CSV_EXPORT',
    DISCOUNTINVOICE_BATCH_INVOICE_INPUT: 'DISCOUNTINVOICE_BATCH_INVOICE_INPUT',
    DISCOUNTINVOICE_BATCH_INVOICE_CONFIRMATION: 'DISCOUNTINVOICE_BATCH_INVOICE_CONFIRMATION',
    DISCOUNTINVOICE_BATCH_DETAIL: 'DISCOUNTINVOICE_BATCH_DETAIL',
    DISCOUNTINVOICE_REVERSE_BATCH_OVERVIEW: 'DISCOUNTINVOICE_REVERSE_BATCH_OVERVIEW',
    DISCOUNTINVOICE_REVERSE_BATCH_FILTER: 'DISCOUNTINVOICE_REVERSE_BATCH_FILTER',
    DISCOUNTINVOICE_REVERSE_BATCH_CSV_EXPORT: 'DISCOUNTINVOICE_REVERSE_BATCH_CSV_EXPORT',
    DISCOUNTINVOICE_REVERSE_BATCH_INVOICE_INPUT: 'DISCOUNTINVOICE_REVERSE_BATCH_INVOICE_INPUT',
    DISCOUNTINVOICE_REVERSE_BATCH_INVOICE_CONFIRMATION: 'DISCOUNTINVOICE_REVERSE_BATCH_INVOICE_CONFIRMATION',
    DISCOUNTINVOICE_REVERSE_BATCH_DETAIL: 'DISCOUNTINVOICE_REVERSE_BATCH_DETAIL',
    DISCOUNTINVOICE_BATCH_INVOICE_SAVE: 'DISCOUNTINVOICE_BATCH_INVOICE_SAVE',
    DISCOUNTINVOICE_REVERSE_BATCH_INVOICE_SAVE: 'DISCOUNTINVOICE_REVERSE_BATCH_INVOICE_SAVE',
    DISCOUNTINVOICE_BATCH_INVOICE_SAVE_OK: 'DISCOUNTINVOICE_BATCH_INVOICE_SAVE_OK',
    DISCOUNTINVOICE_BATCH_INVOICE_SAVE_FAILED: 'DISCOUNTINVOICE_BATCH_INVOICE_SAVE_FAILED',
    DISCOUNTINVOICE_REVERSE_BATCH_INVOICE_SAVE_OK: 'DISCOUNTINVOICE_REVERSE_BATCH_INVOICE_SAVE_OK',
    DISCOUNTINVOICE_REVERSE_BATCH_INVOICE_SAVE_FAILED: 'DISCOUNTINVOICE_REVERSE_BATCH_INVOICE_SAVE_FAILED',
    DISCOUNTINVOICE_MANUAL_INVOICE_SAVE: 'DISCOUNTINVOICE_MANUAL_INVOICE_SAVE',
    DISCOUNTINVOICE_REVERSE_MANUAL_INVOICE_SAVE: 'DISCOUNTINVOICE_REVERSE_MANUAL_INVOICE_SAVE',
    DISCOUNTINVOICE_MANUAL_INVOICE_SAVE_OK: 'DISCOUNTINVOICE_MANUAL_INVOICE_SAVE_OK',
    DISCOUNTINVOICE_MANUAL_INVOICE_SAVE_FAILED: 'DISCOUNTINVOICE_MANUAL_INVOICE_SAVE_FAILED',
    DISCOUNTINVOICE_REVERSE_MANUAL_INVOICE_SAVE_OK: 'DISCOUNTINVOICE_REVERSE_MANUAL_INVOICE_SAVE_OK',
    DISCOUNTINVOICE_REVERSE_MANUAL_INVOICE_SAVE_FAILED: 'DISCOUNTINVOICE_REVERSE_MANUAL_INVOICE_SAVE_FAILED',
    DISCOUNTINVOICE_PDF_REPORT_100048: 'DISCOUNTINVOICE_PDF_REPORT_100048',
    DISCOUNTINVOICE_FD: 'DISCOUNTINVOICE_FD',
    DISCOUNTINVOICE_FC: 'DISCOUNTINVOICE_FC',
    DISCOUNTINVOICE_FE: 'DISCOUNTINVOICE_FE',
    DISCOUNTINVOICE_FH: 'DISCOUNTINVOICE_FH',
    DISCOUNTINVOICE_PDF_REPORT_100049: 'DISCOUNTINVOICE_PDF_REPORT_100049',
    DISCOUNTINVOICE_PDF_REPORT_100050: 'DISCOUNTINVOICE_PDF_REPORT_100050',
    DISCOUNTINVOICE_PDF_REPORT_100051: 'DISCOUNTINVOICE_PDF_REPORT_100051',
} as const;
