import { fromBER } from 'asn1js';
import { getAlgorithmParameters, PrivateKeyInfo, type CryptoEngineAlgorithmOperation } from 'pkijs';

import { ASYMMETRIC_ENCRYPTION_CONFIG } from './config';

/**
 * Converts `CryptoKey` to `PrivateKeyInfo` class corresponding to PKCS #8
 * @example
    const pkcs8 = await exportPrivateKey(key);

    const privateKeyInfo = new PrivateKeyInfo({
        schema: fromBER(pkcs8).result,
    });
 */
export function exportPrivateKey(key: CryptoKey) {
    return crypto.subtle.exportKey('pkcs8', key);
}

/**
 * Generates a new RSA key pair with:
 * - `name`: `RSA-PSS`
 * - `modulusLength`: `4096`
 * - `publicExponent`: `new Uint8Array([0x01, 0x00, 0x01])` (65537)
 * - `hash`: `SHA-256`
 */
export async function generateKeyPair() {
    const algorithmParams = getAlgorithmParameters(
        ASYMMETRIC_ENCRYPTION_CONFIG.selfSignedCertificate.name,
        'generateKey',
    );

    const algorithm = {
        ...(algorithmParams.algorithm as RsaHashedKeyGenParams),
        // Override the default hash SHA-1 function with SHA-256 for better security
        hash: {
            name: ASYMMETRIC_ENCRYPTION_CONFIG.selfSignedCertificate.hash,
        },
    } as const satisfies RsaHashedKeyGenParams;

    const keypair = await crypto.subtle.generateKey(
        algorithm,

        // Whether the key is extractable (i.e. can be used in `exportKey`)
        true,

        algorithmParams.usages,
    );

    return {
        ...keypair,
        algorithm,
    } as const;
}

export type KeyPair = Awaited<ReturnType<typeof generateKeyPair>>;

/**
 * Wrap CryptoKey into PrivateKeyInfo class corresponding to PKCS #8 standard
 */
export async function createPrivateKeyInfo(key: CryptoKey) {
    const pkcs8 = await exportPrivateKey(key);

    const privateKeyInfo = new PrivateKeyInfo({
        schema: fromBER(pkcs8).result,
    });

    return privateKeyInfo;
}

export type ImportPrivateKeyProps = {
    operation: CryptoEngineAlgorithmOperation & KeyUsage;
} & (typeof ASYMMETRIC_ENCRYPTION_CONFIG)[keyof typeof ASYMMETRIC_ENCRYPTION_CONFIG];

/**
 * Converts a PKCS #8 PrivateKeyInfo to a CryptoKey
 */
export async function importPrivateKey(privateKeyInfo: PrivateKeyInfo, props: ImportPrivateKeyProps) {
    const pkcs8Buffer = privateKeyInfo.toSchema().toBER(false);
    const algorithmParams = getAlgorithmParameters(props.name, props.operation);

    const algorithm = {
        ...(algorithmParams.algorithm as RsaHashedKeyGenParams),
        // Override the default hash SHA-1 function with SHA-256 for better security
        hash: { name: props.hash },
    } as const satisfies RsaHashedKeyGenParams;

    const cryptoKey = await crypto.subtle.importKey('pkcs8', pkcs8Buffer, algorithm, false, [props.operation]);

    return {
        privateKey: cryptoKey,
        algorithm,
    } as const;
}
