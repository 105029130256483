import { Box, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { FormattedMessage } from 'react-intl';

import { Alert } from '@workspace/ui';

import { AppLayout } from '~modules/layout';
import { LogActions } from '~modules/session-log/constants';
import { useLogActionOnShow } from '~modules/session-log/hooks';

import { phoneNumberAtom } from '../../atoms';
import { VerifyPhoneNumberForm } from './VerifyPhoneNumberForm';

export const VerifyPhoneNumberPage = () => {
    useLogActionOnShow(LogActions.SMS_AUTHENTICATION_CODE_VERIFICATION);
    const phoneNumber = useAtomValue(phoneNumberAtom);

    return (
        <AppLayout maxWidth='md'>
            <Typography variant='h1'>
                <FormattedMessage id='phoneNumber.verify.title' />
            </Typography>

            <Alert severity='info'>
                <Typography variant='paragraphNormal'>
                    <FormattedMessage id='phoneNumber.verify.subtitle' />
                </Typography>
            </Alert>

            <Box mb={1} />

            <VerifyPhoneNumberForm phoneNumber={phoneNumber} />
        </AppLayout>
    );
};
