/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvSuppCustBankAccountModel,
    GetByIdRequestModelOfEfcvSuppCustBankAccountModelAndbigdecimal,
    ViewDataRequestModelOfEfcvSuppCustBankAccountModel,
    ViewDataResultModelOfEfcvSuppCustBankAccountModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const suppCustBankAccount_findByAll = (
    efcvSuppCustBankAccountModel: BodyType<EfcvSuppCustBankAccountModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSuppCustBankAccountModel[] | void>(
        {
            url: `/factoring/efcvsuppcustbankaccount/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvSuppCustBankAccountModel,
        },
        options,
    );
};

export const getSuppCustBankAccountFindByAllMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof suppCustBankAccount_findByAll>>,
        TError,
        { data: BodyType<EfcvSuppCustBankAccountModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof suppCustBankAccount_findByAll>>,
    TError,
    { data: BodyType<EfcvSuppCustBankAccountModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof suppCustBankAccount_findByAll>>,
        { data: BodyType<EfcvSuppCustBankAccountModel> }
    > = props => {
        const { data } = props ?? {};

        return suppCustBankAccount_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SuppCustBankAccountFindByAllMutationResult = NonNullable<
    Awaited<ReturnType<typeof suppCustBankAccount_findByAll>>
>;
export type SuppCustBankAccountFindByAllMutationBody = BodyType<EfcvSuppCustBankAccountModel>;
export type SuppCustBankAccountFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useSuppCustBankAccountFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof suppCustBankAccount_findByAll>>,
        TError,
        { data: BodyType<EfcvSuppCustBankAccountModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSuppCustBankAccountFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const suppCustBankAccount_findById = (
    getByIdRequestModelOfEfcvSuppCustBankAccountModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvSuppCustBankAccountModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSuppCustBankAccountModel | void>(
        {
            url: `/factoring/efcvsuppcustbankaccount/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvSuppCustBankAccountModelAndbigdecimal,
        },
        options,
    );
};

export const getSuppCustBankAccountFindByIdMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof suppCustBankAccount_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSuppCustBankAccountModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof suppCustBankAccount_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvSuppCustBankAccountModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof suppCustBankAccount_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvSuppCustBankAccountModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return suppCustBankAccount_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SuppCustBankAccountFindByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof suppCustBankAccount_findById>>
>;
export type SuppCustBankAccountFindByIdMutationBody =
    BodyType<GetByIdRequestModelOfEfcvSuppCustBankAccountModelAndbigdecimal>;
export type SuppCustBankAccountFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useSuppCustBankAccountFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof suppCustBankAccount_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSuppCustBankAccountModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSuppCustBankAccountFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const suppCustBankAccount_findByViewDataRequest = (
    viewDataRequestModelOfEfcvSuppCustBankAccountModel: BodyType<ViewDataRequestModelOfEfcvSuppCustBankAccountModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvSuppCustBankAccountModel | void>(
        {
            url: `/factoring/efcvsuppcustbankaccount/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvSuppCustBankAccountModel,
        },
        options,
    );
};

export const getSuppCustBankAccountFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof suppCustBankAccount_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSuppCustBankAccountModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof suppCustBankAccount_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvSuppCustBankAccountModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof suppCustBankAccount_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvSuppCustBankAccountModel> }
    > = props => {
        const { data } = props ?? {};

        return suppCustBankAccount_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SuppCustBankAccountFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof suppCustBankAccount_findByViewDataRequest>>
>;
export type SuppCustBankAccountFindByViewDataRequestMutationBody =
    BodyType<ViewDataRequestModelOfEfcvSuppCustBankAccountModel>;
export type SuppCustBankAccountFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useSuppCustBankAccountFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof suppCustBankAccount_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSuppCustBankAccountModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSuppCustBankAccountFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
