import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Card, Container } from '@workspace/ui';

import { PasswordLoginForm } from '../PasswordLoginForm';

export const PasswordLoginPage = () => (
    <Container size='sm'>
        <Typography variant='h1'>
            <FormattedMessage id='login.passwordForm.title' />
        </Typography>
        <Card>
            <PasswordLoginForm isAuthModal={false} localLogin={false} />
        </Card>
    </Container>
);
