import { useMemo } from 'react';

import { useUserData } from './useUserData';

export function useAuthUserCustomData() {
    const { data } = useUserData();

    return useMemo(() => {
        const userData = data?.data;

        return (userData as Exclude<typeof userData, void>)?.userCustomData ?? null;
    }, [data]);
}
