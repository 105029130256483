import type { ReactNode } from 'react';

import { IconCz, IconEn } from '@workspace/ui';

import { languages, type Language } from '~modules/intl';

type LanguageConfig = Record<Language, { locale: Language; flag: ReactNode }>;

export const languagesConfig = {
    [languages.EN]: { locale: languages.CS, flag: <IconCz /> },
    [languages.CS]: { locale: languages.EN, flag: <IconEn /> },
} as const satisfies LanguageConfig;
