import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { worker } from '@workspace/certificates';

import { routesWithPasswordAction } from './constants';

export interface PasswordInMemoryResetProps {
    children?: React.ReactNode;
}

export const PasswordInMemoryReset = ({ children }: PasswordInMemoryResetProps) => {
    const { route } = useRouter();

    useEffect(() => {
        if (routesWithPasswordAction.has(route)) {
            worker.restartPasswordClearTimer();
        } else {
            worker.resetStoredPassword();
        }
    }, [route]);

    return <>{children}</>;
};
