import * as asn1js from 'asn1js';
import {
    AlgorithmIdentifier,
    Attribute,
    ContentInfo,
    EncapsulatedContentInfo,
    id_sha512,
    IssuerAndSerialNumber,
    SignedAndUnsignedAttributes,
    SignedData,
    SignerInfo,
    type Certificate,
} from 'pkijs';

import { ASYMMETRIC_ENCRYPTION_CONFIG } from './config';
import { importPrivateKey } from './keyPair';
import type { ParsedCertificate } from './parsedCertificate';
import { cleanPemHeaderAndFooter, encodeUTF16LEHex, PemType, toPem } from './utils';

enum OID {
    ContentType = '1.2.840.113549.1.9.3',
    Data = '1.2.840.113549.1.7.1',
    SigningTime = '1.2.840.113549.1.9.5',
    MessageDigest = '1.2.840.113549.1.9.4',
}

/**
 * Creates PKCS #7 v1.5 CMS signed data
 * - data are detached: The results includes the signature and certificate without the signed data.
 */
async function getSignedData(certificate: Certificate, privateKey: CryptoKey, data: ArrayBuffer) {
    const dataDigest = await crypto.subtle.digest(ASYMMETRIC_ENCRYPTION_CONFIG.login.hash, data);

    const signedAttrs = new SignedAndUnsignedAttributes({
        type: 0,
        attributes: [
            new Attribute({
                type: OID.ContentType,
                values: [new asn1js.ObjectIdentifier({ value: OID.Data })],
            }),
            new Attribute({
                type: OID.MessageDigest,
                values: [new asn1js.OctetString({ valueHex: dataDigest })],
            }),
            new Attribute({
                type: OID.SigningTime,
                values: [new asn1js.UTCTime({ valueDate: new Date() })],
            }),
        ],
    });

    const signedData = new SignedData({
        version: 1,
        encapContentInfo: new EncapsulatedContentInfo({
            eContentType: OID.Data,
        }),
        digestAlgorithms: [new AlgorithmIdentifier({ algorithmId: id_sha512 })],
        signerInfos: [
            new SignerInfo({
                version: 1,
                signedAttrs,
                digestAlgorithm: new AlgorithmIdentifier({ algorithmId: id_sha512 }),
                sid: new IssuerAndSerialNumber({
                    issuer: certificate.issuer,
                    serialNumber: certificate.serialNumber,
                }),
            }),
        ],
        certificates: [certificate],
    });

    await signedData.sign(privateKey, 0, ASYMMETRIC_ENCRYPTION_CONFIG.login.hash, data);

    const pkcs7 = new ContentInfo({
        contentType: ContentInfo.SIGNED_DATA,
        content: signedData.toSchema(true),
    });

    return pkcs7;
}

/**
 * Sign `data` with the private key of `issuedCertificate` and return the signature in format of PKCS #7 encoded as PEM (without header and footer).
 */
export async function createSignature(issuedCertificate: ParsedCertificate<true>, data: string) {
    const { privateKey } = await importPrivateKey(issuedCertificate.privateKey, {
        name: ASYMMETRIC_ENCRYPTION_CONFIG.login.name,
        hash: ASYMMETRIC_ENCRYPTION_CONFIG.login.hash,
        operation: 'sign',
    });

    const encodedDataBuffer = encodeUTF16LEHex(data);
    const signedData = await getSignedData(issuedCertificate.certificate, privateKey, encodedDataBuffer);

    return cleanPemHeaderAndFooter(toPem(signedData, PemType.SignedData));
}
