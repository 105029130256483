import createCache, {} from '@emotion/cache';
import createEmotionServer from '@emotion/server/create-instance';
const isBrowser = typeof document !== 'undefined';
// On the client side, Create a meta tag at the top of the <head> and set it as insertionPoint.
// This assures that MUI styles are loaded first.
// It allows developers to easily override MUI styles with other styling solutions, like CSS modules.
export function createEmotionCache() {
    let insertionPoint;
    if (isBrowser) {
        const emotionInsertionPoint = document.querySelector('meta[name="emotion-insertion-point"]');
        insertionPoint = emotionInsertionPoint ?? undefined;
    }
    return createCache({ key: 'mui-style', insertionPoint });
}
export { CacheProvider } from '@emotion/react';
export { createEmotionServer };
