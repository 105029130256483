/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type GetFileUsingGETType = (typeof GetFileUsingGETType)[keyof typeof GetFileUsingGETType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetFileUsingGETType = {
    PDF: 'PDF',
    BBS: 'BBS',
    EXPORT: 'EXPORT',
} as const;
