// import { Radio } from '@mui/material';
import { get } from 'radash';
import { useController, useFormContext } from 'react-hook-form';
import { FieldError } from '../../FieldError';
import { RadioGroup } from '../../inputs';
export const RadioGroupField = ({ name, children, ...rest }) => {
    const { control, formState: { errors, defaultValues }, } = useFormContext();
    const { field, fieldState: { error: fieldError }, } = useController({ name, control });
    const error = Boolean(fieldError) ? <FieldError errors={errors} name={name}/> : null;
    const defaultValue = get(defaultValues, name);
    return (<RadioGroup {...rest} {...field} defaultValue={defaultValue} error={error}>
            {children}
        </RadioGroup>);
};
