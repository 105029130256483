import { z } from 'zod';

import { optionalNullableString, requiredNonEmpty } from '~modules/form/validators';

export const passwordLoginFormSchema = z.object({
    filename: requiredNonEmpty,

    // NOTE: Don't use `password` validator, only make the password required (we have to support passwords with older security requirements)
    password: requiredNonEmpty,

    // NOTE: It must be optional: local login flow doesn't require username.
    username: optionalNullableString,
});

export type PasswordLoginFormSchema = z.infer<typeof passwordLoginFormSchema>;
