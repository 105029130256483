import { z } from 'zod';

import { requiredNonEmpty } from '~modules/form/validators';

export const smsCodeFormSchema = z.object({
    code: requiredNonEmpty,

    signature: z.string().optional(),
});

export type SmsCodeFormSchema = z.infer<typeof smsCodeFormSchema>;
