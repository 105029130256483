/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import {
    useQuery,
    type QueryFunction,
    type QueryKey,
    type UseQueryOptions,
    type UseQueryResult,
} from '@tanstack/react-query';

import { fetcher, type ErrorType } from '../../fetcher/fetcher';
import type { GetFileUsingGETParams } from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Get file generated from automatic or user initiated export
 */
export const downloadService_getFile = (
    fileIdent: string,
    params: GetFileUsingGETParams,
    options?: SecondParameter<typeof fetcher>,
    signal?: AbortSignal,
) => {
    return fetcher<void>({ url: `/download/${fileIdent}`, method: 'GET', params, signal }, options);
};

export const getDownloadServiceGetFileQueryKey = (fileIdent: string, params: GetFileUsingGETParams) => {
    return [`/download/${fileIdent}`, ...(params ? [params] : [])] as const;
};

export const getDownloadServiceGetFileQueryOptions = <
    TData = Awaited<ReturnType<typeof downloadService_getFile>>,
    TError = ErrorType<unknown>,
>(
    fileIdent: string,
    params: GetFileUsingGETParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof downloadService_getFile>>, TError, TData>;
        request?: SecondParameter<typeof fetcher>;
    },
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getDownloadServiceGetFileQueryKey(fileIdent, params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadService_getFile>>> = ({ signal }) =>
        downloadService_getFile(fileIdent, params, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!fileIdent, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof downloadService_getFile>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type DownloadServiceGetFileQueryResult = NonNullable<Awaited<ReturnType<typeof downloadService_getFile>>>;
export type DownloadServiceGetFileQueryError = ErrorType<unknown>;

/**
 * @summary Get file generated from automatic or user initiated export
 */
export const useDownloadServiceGetFile = <
    TData = Awaited<ReturnType<typeof downloadService_getFile>>,
    TError = ErrorType<unknown>,
>(
    fileIdent: string,
    params: GetFileUsingGETParams,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof downloadService_getFile>>, TError, TData>;
        request?: SecondParameter<typeof fetcher>;
    },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getDownloadServiceGetFileQueryOptions(fileIdent, params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
