import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { QueryKey, useInvalidateQuery } from '@workspace/api';
import { useSession } from '@workspace/auth';

import { useCountdown } from '~hooks';

import { TimeoutValue } from './AutoLogout.styles';
import { useGetSessionTimeout } from './hooks/useGetSessionTimeout';
import { InactivityPromptDialog } from './InactivityPromptDialog';
import { formatCountdown } from './utils/formatCountdown';

export const AutoLogout = () => {
    const timeout = useGetSessionTimeout();
    const { signOut } = useSession();
    const [countdown, startCountdown] = useCountdown({ timeout, onEnd: signOut });
    const [dialogOpened, setDialogOpened] = useState(false);
    const { invalidateQuery } = useInvalidateQuery();

    useEffect(() => {
        if (timeout) {
            startCountdown();
        }
    }, [startCountdown, timeout]);

    // Show dialog when there are 2 minutes left
    useEffect(() => {
        const TWO_MINUTES = 120;

        if (countdown === TWO_MINUTES && !dialogOpened) {
            setDialogOpened(true);
        }
    }, [setDialogOpened, countdown, dialogOpened]);

    return (
        <>
            <Typography variant='paragraphSmall'>
                <FormattedMessage
                    id='autoLogout.label'
                    values={{
                        value: () => <TimeoutValue>{formatCountdown(countdown)}</TimeoutValue>,
                    }}
                />
            </Typography>
            <InactivityPromptDialog
                open={dialogOpened}
                onCancel={async () => {
                    setDialogOpened(false);

                    await signOut();
                }}
                onSubmit={async () => {
                    setDialogOpened(false);

                    // Refetch session timeout to tell the server that the user is still active
                    await invalidateQuery(QueryKey.SESSION_TIMEOUT);

                    // Restart countdown
                    startCountdown();
                }}
            />
        </>
    );
};
