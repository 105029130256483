import { dialogClasses, dialogContentClasses, Dialog as MUIDialog, DialogActions as MUIDialogActions, DialogContent as MUIDialogContent, DialogTitle as MUIDialogTitle, styled, } from '@mui/material';
export const Dialog = styled(MUIDialog, {
    shouldForwardProp: prop => prop !== 'minWidth',
})(({ theme, minWidth = theme.spacing(53) }) => ({
    [`& .${dialogClasses.paper}`]: {
        padding: theme.spacing(2),
        minWidth,
        maxWidth: 600,
    },
    [`& .${dialogClasses.root}`]: {
        padding: 0,
        paddingBottom: theme.spacing(1),
    },
}));
export const DialogContent = styled(MUIDialogContent)({
    [`&.${dialogContentClasses.root}`]: {
        padding: 0,
        overflowY: 'unset',
    },
});
export const DialogTitle = styled(MUIDialogTitle)(({ theme }) => ({
    paddingLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: theme.spacing(1),
}));
export const DialogActions = styled(MUIDialogActions)(({ theme }) => ({
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: theme.spacing(2),
}));
