import type { Certificate, PrivateKeyInfo } from 'pkijs';

import { parseAttributes } from '../attributes';
import { extractSerialNumber } from '../createSelfSignedCertificate';
import { CertificateAttributeType } from '../types';
import { bufferToHexCodes } from '../utils';

export const getSubjectFields = <Subject extends Record<string, string>>(subject: Subject) => ({
    commonName: subject[CertificateAttributeType.CommonName],
    personalId: subject[CertificateAttributeType.PersonalId],
    idNumber: subject[CertificateAttributeType.IdNumber],
    email: subject[CertificateAttributeType.Email],
    phoneNumber: subject[CertificateAttributeType.PhoneNumber],
    address: subject[CertificateAttributeType.Address],
    countryName: subject[CertificateAttributeType.CountryName],
    countryCode: subject[CertificateAttributeType.CountryCode],
    challengePassword: subject[CertificateAttributeType.ChallengePassword],
});

export const getIssuerFields = <Issuer extends Record<string, string>>(issuer: Issuer) => ({
    commonName: issuer[CertificateAttributeType.CommonName],
    personalId: issuer[CertificateAttributeType.PersonalId],
    idNumber: issuer[CertificateAttributeType.IdNumber],
    email: issuer[CertificateAttributeType.Email],
    address: issuer[CertificateAttributeType.Address],
    countryName: issuer[CertificateAttributeType.CountryName],
    countryCode: issuer[CertificateAttributeType.CountryCode],
});

/**
 * Creates a fingerprint of given certificate.
 * - Certificate is converted to ASN.1 object encoded to BER in ArrayBuffer.
 * - The return value is SHA-1 hash of the certificate in hex format.
 */
async function createCertificateFingerprint(cert: Certificate) {
    const certBuffer = cert.toSchema().toBER();

    /**
     * Backend expects SHA-1 hash of the certificate, if you change it, the login will stop working.
     * For backward compatibility, we have to use SHA-1 hash of the certificate (each fingerprint is stored in databae and used for searching).
     * - Discuss with Matěj Holý at 29th of January 2024.
     */
    const hashBuffer = await crypto.subtle.digest('SHA-1', certBuffer);

    return bufferToHexCodes(hashBuffer);
}

export type ParsedCertificate<WithPrivateKey extends boolean = false> = Readonly<{
    key: string;

    certificate: Certificate;
    certificateChain: Readonly<Certificate[]>;

    privateKey: WithPrivateKey extends true ? PrivateKeyInfo : PrivateKeyInfo | null;

    serialNumber: string;
    validFrom: Date;
    validTo: Date;

    fingerprint: string;

    subject: ReturnType<typeof getSubjectFields>;
    issuer: ReturnType<typeof getIssuerFields>;
}>;

export async function createParsedCertificate(key: string, certificate: Certificate) {
    return {
        key,
        certificate,
        certificateChain: [certificate],
        privateKey: null,
        serialNumber: extractSerialNumber(certificate),
        validFrom: certificate.notBefore.value,
        validTo: certificate.notAfter.value,
        fingerprint: await createCertificateFingerprint(certificate),
        subject: getSubjectFields(parseAttributes(certificate.subject.typesAndValues)),
        issuer: getIssuerFields(parseAttributes(certificate.issuer.typesAndValues)),
    } as const satisfies ParsedCertificate;
}

export const updateParsedCertificateChain = (certificate: ParsedCertificate, certificateChain: Certificate[]) =>
    ({
        ...certificate,
        certificateChain: [...certificate.certificateChain, ...certificateChain],
    }) as const satisfies ParsedCertificate;
