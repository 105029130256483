export function stringToArrayBuffer(value: string) {
    const buffer = new ArrayBuffer(value.length);
    const bufferView = new Uint8Array(buffer);

    for (let i = 0; i < value.length; i++) {
        bufferView[i] = value.charCodeAt(i);
    }

    return buffer;
}

export function arrayBufferToString(buffer: ArrayBuffer) {
    const view = new Uint8Array(buffer);

    return String.fromCharCode(...view);
}

export const decodeBase64 = (value: string) => atob(value);

export const encodeBase64 = (value: string) => btoa(value);

export function bufferToHexCodes(buffer: ArrayBuffer) {
    const view = new Uint8Array(buffer);

    // Convert each byte to a two-digit hexadecimal string and concatenate
    let hexStr = '';

    for (let i = 0; i < view.length; i++) {
        hexStr += view[i].toString(16).padStart(2, '0');
    }

    return hexStr;
}

/**
 * Encode `text` to UTF-16 little-endian hexademical encoding.
 */
export function encodeUTF16LEHex(text: string): ArrayBuffer {
    const byteArray = new Uint8Array(text.length * 2);

    for (let i = 0; i < text.length; i++) {
        byteArray[i * 2] = text.charCodeAt(i); // & 0xff;
        byteArray[i * 2 + 1] = text.charCodeAt(i) >> 8; // & 0xff;
    }

    return byteArray.buffer;
}

/**
 * Convert hex number encoded as ArrayBuffer to decimal string
 */
export function arrayBufferToDecimal(buffer: ArrayBuffer) {
    const hexNumber = bufferToHexCodes(buffer);

    return Number.parseInt(hexNumber, 16);
}
