import type { ServiceName, ServiceTaskFailureResult, ServiceTaskRequest, ServiceTaskSuccessResult } from './types';

export const executeServiceTaskRequest = <Service extends ServiceName>(
    name: Service,
    args: ServiceTaskRequest<Service>['args'],
) =>
    ({
        type: 'EXECUTE_SERVICE_TASK',
        task: {
            id: crypto.randomUUID(),
            name,
            args,
        } as const satisfies ServiceTaskRequest<Service>,
    }) as const;

export const executeServiceTaskSuccess = <Service extends ServiceName>(
    id: string,
    name: Service,
    result: ServiceTaskSuccessResult<Service>['result'],
) =>
    ({
        type: 'EXECUTE_SERVICE_TASK_SUCCESS',
        task: {
            id,
            name,
            result,
        } as const satisfies ServiceTaskSuccessResult<Service>,
    }) as const;

export const executeServiceTaskFailure = <Service extends ServiceName>(id: string, name: Service, error: unknown) =>
    ({
        type: 'EXECUTE_SERVICE_TASK_FAILURE',
        task: {
            id,
            name,
            error: error instanceof Error ? error.toString() : error,
        } as const satisfies ServiceTaskFailureResult<Service>,
    }) as const;

/**
 * Register service with callback argument
 */
export const registerServiceTask = <Service extends ServiceName>(name: Service) =>
    ({
        type: 'REGISTER_SERVICE_TASK',
        task: {
            id: crypto.randomUUID(),
            name,
        } as const,
    }) as const;

export const unregisterServiceTask = <Service extends ServiceName>(id: string, name: Service) =>
    ({
        type: 'UNREGISTER_SERVICE_TASK',
        task: {
            id,
            name,
        } as const,
    }) as const;
