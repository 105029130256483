/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type ImportInvoiceRequestModelImportType =
    (typeof ImportInvoiceRequestModelImportType)[keyof typeof ImportInvoiceRequestModelImportType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImportInvoiceRequestModelImportType = {
    PURCHASED_INVOICE: 'PURCHASED_INVOICE',
    PURCHASED_INVOICE_CEDING: 'PURCHASED_INVOICE_CEDING',
    LIABILITY_INVOICE: 'LIABILITY_INVOICE',
    PURCHASED_INVOICE_REVERSE: 'PURCHASED_INVOICE_REVERSE',
    DISCOUNT_INVOICE: 'DISCOUNT_INVOICE',
    DISCOUNT_INVOICE_REVERSE: 'DISCOUNT_INVOICE_REVERSE',
} as const;
