import { useEffect } from 'react';

import { useSession } from '@workspace/auth';

import type { LogAction } from '../constants';
import { isValidSessionStatusForLogging } from '../utils';
import { useLogSessionActionMutation } from './useLogSessionActionMutation';

export function useLogActionOnShow(action: LogAction | null) {
    const { status } = useSession();
    const { mutate: logAction } = useLogSessionActionMutation();

    useEffect(() => {
        if (!action || !isValidSessionStatusForLogging(status)) {
            return;
        }

        logAction({ action });
    }, [action, logAction, status]);
}
