import { useController, useFormContext } from 'react-hook-form';
import { Checkbox } from '../../inputs';
import { FieldFormControlLabel } from './CheckboxField.styles';
export const CheckboxField = ({ name, label, disabled, onChange, ...rest }) => {
    const { control, formState: { isSubmitting }, } = useFormContext();
    const { field } = useController({ name, control });
    return (<FieldFormControlLabel control={<Checkbox {...rest} ref={field.ref} disabled={disabled ?? field.disabled ?? isSubmitting} name={field.name} checked={!!field.value} onBlur={field.onBlur} onChange={event => {
                field.onChange(event);
                onChange?.();
            }}/>} label={label}/>);
};
