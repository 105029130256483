import { useAtom } from 'jotai';

import { confirmationModalAtom, confirmationPayloadAtom, confirmationResultAtom } from '../atoms';
import { ConfirmationResponse } from '../constants';
import { usePrompt } from './usePrompt';

export function useConfirmation() {
    const { promptUser } = usePrompt<ConfirmationResponse>({
        openAtom: confirmationModalAtom,
        resultAtom: confirmationResultAtom,
    });

    const [confirmationContent, setConfirmationContent] = useAtom(confirmationPayloadAtom);

    return {
        promptUser,
        /**
         * Array of messages passed to the confirmation modal
         */
        confirmationContent,

        /**
         * Set array of messages passed to the confirmation modal
         */
        setConfirmationContent,

        /**
         * Boolean check if there is any content to confirm
         */
        shouldConfirm: confirmationContent.length > 0,
    };
}
