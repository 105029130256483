import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Alert } from '@workspace/ui';

import { AppLayout } from '~modules/layout';
import { LogActions } from '~modules/session-log/constants';
import { useLogActionOnShow } from '~modules/session-log/hooks';

import { SetPhoneNumberForm } from './SetPhoneNumberForm';

export const SetPhoneNumberPage = () => {
    useLogActionOnShow(LogActions.SMS_AUTHENTICATION_PHONE_NUMBER_SETTING);

    return (
        <AppLayout maxWidth='md'>
            <Typography variant='h1'>
                <FormattedMessage id='auth.phone.title' />
            </Typography>

            <Alert severity='info'>
                <Typography variant='paragraphNormal' component='p'>
                    <FormattedMessage id='auth.phone.text1' />
                </Typography>
                <Typography variant='paragraphNormal' component='p'>
                    <FormattedMessage id='auth.phone.text2' />
                </Typography>
            </Alert>

            <Box mb={1} />

            <SetPhoneNumberForm />
        </AppLayout>
    );
};
