/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type ScheduleExportUsingPOSTExportSettingType =
    (typeof ScheduleExportUsingPOSTExportSettingType)[keyof typeof ScheduleExportUsingPOSTExportSettingType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScheduleExportUsingPOSTExportSettingType = {
    INTEREST_INVOICE: 'INTEREST_INVOICE',
    COMMISSION_INVOICE: 'COMMISSION_INVOICE',
    SUPPLIER_PENALTY_INVOICE: 'SUPPLIER_PENALTY_INVOICE',
    ISSUED_FEE_INVOICE: 'ISSUED_FEE_INVOICE',
    PRE_FINANCING_OVERPAYMENT: 'PRE_FINANCING_OVERPAYMENT',
    PRE_FINANCING_OVERPAYMENT_WITH_PARTICIPATION: 'PRE_FINANCING_OVERPAYMENT_WITH_PARTICIPATION',
    GENERAL: 'GENERAL',
    BACK_CESSION: 'BACK_CESSION',
    LIABILITY_ACCESSION_CLAIM: 'LIABILITY_ACCESSION_CLAIM',
    PAYMENTS_BREAKDOWN: 'PAYMENTS_BREAKDOWN',
    PAYMENTS_BREAKDOWN_WITH_CUSTOMERS: 'PAYMENTS_BREAKDOWN_WITH_CUSTOMERS',
    WITHDRAWAL_INFORMATION_DISCOUNT: 'WITHDRAWAL_INFORMATION_DISCOUNT',
} as const;
