import type { Path } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { TextField, type TextFieldProps, type UnknownFormValues } from '@workspace/ui';

export interface IdNumberFieldProps<FormValues extends UnknownFormValues> extends TextFieldProps<FormValues> {
    name: Path<FormValues>;
}

export const IdNumberField = <FormValues extends UnknownFormValues>({
    name,
    ...rest
}: IdNumberFieldProps<FormValues>) => {
    return (
        <TextField<FormValues> label={<FormattedMessage id='cert.renewal.field.idNumber' />} {...rest} name={name} />
    );
};
