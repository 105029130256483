import type { Path } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { TextField, type TextFieldProps, type UnknownFormValues } from '@workspace/ui';

export interface CertificateNameFieldProps<FormValues extends UnknownFormValues> extends TextFieldProps<FormValues> {
    name: Path<FormValues>;
}

export const CertificateNameField = <FormValues extends UnknownFormValues>({
    name,
    ...props
}: CertificateNameFieldProps<FormValues>) => {
    return (
        <TextField<FormValues>
            {...props}
            name={name}
            readOnly
            label={<FormattedMessage id='login.form.certificate' />}
            InputProps={{
                ...props.InputProps,
                autoComplete: 'off',
            }}
            inputProps={{
                ...props.inputProps,
                tabIndex: -1,
                'aria-readonly': true,
                'aria-autocomplete': 'none',
            }}
        />
    );
};
