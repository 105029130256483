import { Icon as MUIIcon, styled } from '@mui/material';
export const Icon = styled(MUIIcon)(() => ({
    color: 'currentColor',
    display: 'flex',
    alignItems: 'center',
    height: 18,
    width: 18,
    '& > svg': {
        width: '100%',
        height: '100%',
    },
}));
