import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Card, Container } from '@workspace/ui';

import { CertificateBlockingForm } from '../CertificateBlockingForm';

export const CertificateBlockingPage = () => {
    return (
        <Container size='sm'>
            <Typography variant='h1'>
                <FormattedMessage id='certificateBlocking.title' />
            </Typography>
            <Box mb={1}>
                <Card>
                    <Typography>
                        <FormattedMessage id='certificateBlocking.subtitle' />
                    </Typography>
                </Card>
            </Box>

            <Card>
                <CertificateBlockingForm />
            </Card>
        </Container>
    );
};
