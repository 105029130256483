import { PFX } from 'pkijs';

import { AuthError } from '@workspace/errors';
import { logger } from '@workspace/logger';

import { decodeBase64, stringToArrayBuffer } from '../utils';
import { checkIntegrityAndDecryptPkcs12 } from './checkIntegrityAndDecryptPkcs12';
import { migratePkcs12FromNodeForgeToPkijs } from './migratePkcs12FromNodeForgeToPkijs';

enum ExpectedError {
    /**
     * This sucks indeed. The old eFactoring (node-forge) library used `pbeWithSHA1And3-KeyTripleDES-CB` algorithm for encrypting private keys.
     * This is not considered secure by modern standards and is not supported Web Crypto API, thus by pkijs (which heavily relies on Web Crypto API instead of its own implementations).
     * In order to resolve it, we have to use node-forge to parse the PKCS #12 in such a case and then migrate all the keys and certificates to pkijs and use modern encryption algorithms (such as are PBKDF2 with SHA-256 and AES-256-GCM).
     */
    UnsupportedEncryptionAlgorithm = 'Unknown "contentEncryptionAlgorithm": 1.2.840.113549.1.12.1.3',

    BrokenIntegrity = 'Integrity for the PKCS#12 data is broken!',
}

/**
 * Parse, check integrity and decypher PKCS #12 file encoded in base64 to reconstruct PFX object.
 * It supports both modern and legacy encryption algorithms (if parsing fails with modern ones, it tries to parse it with legacy ones and migrate the content to modern one).
 */
export async function parsePersonalInformationContainer(pkcs12b64: string) {
    try {
        const pkcs12Buffer = stringToArrayBuffer(decodeBase64(pkcs12b64));

        const pkcs12 = PFX.fromBER(pkcs12Buffer);

        return await checkIntegrityAndDecryptPkcs12(pkcs12);
    } catch (err) {
        const error = err as Error;

        switch (error.message) {
            case ExpectedError.UnsupportedEncryptionAlgorithm:
                logger.debug('Migrating PKCS #12 from node-forge to pkijs');

                return await migratePkcs12FromNodeForgeToPkijs(pkcs12b64);

            case ExpectedError.BrokenIntegrity:
                throw new AuthError('CERTIFICATE_INVALID_PASSWORD', error.message, error);

            default:
                throw error;
        }
    }
}
