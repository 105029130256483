import { alertClasses, Alert as MUIAlert, styled } from '@mui/material';
export const Alert = styled(MUIAlert)(({ theme, severity }) => ({
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(2.5),
    boxShadow: theme.shadows[1],
    ...(severity === 'info' && {
        backgroundColor: theme.color.alerts.info,
        color: theme.color.foregrounds.secondary,
    }),
    ...(severity === 'success' && {
        backgroundColor: theme.color.alerts.successAlt,
        color: theme.color.foregrounds.primary,
    }),
    borderRadius: 2,
    fontSize: '0.8125rem',
    lineHeight: '1.25rem',
    fontWeight: 300,
    display: 'flex',
    alignItems: 'center',
    [`& .${alertClasses.icon}`]: {
        marginRight: theme.spacing(1),
        color: theme.color.icons.secondary,
        '& > svg': {
            width: 30,
            height: 30,
            borderRadius: theme.spacing(0.625),
        },
    },
}));
