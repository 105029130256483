import { useCallback, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, IconRefreshCcw05 } from '@workspace/ui';

import { useCountdown } from '~hooks';

import { useResendVerificationCode } from './hooks/useResendVerificationCode';

export interface ResendConfirmationCodeProps {
    validity?: number;
    setCodeExpired: (value: boolean) => void;
}

export const ResendEmailVerificationCode = ({ validity = 0, setCodeExpired }: ResendConfirmationCodeProps) => {
    const [countdown, startCountdown] = useCountdown({
        timeout: validity,
        onStart: useCallback(() => setCodeExpired(false), [setCodeExpired]),
        onEnd: useCallback(() => setCodeExpired(true), [setCodeExpired]),
    });

    const [resendButtonCountdown, startResendButtonCountdown] = useCountdown({ timeout: 15000 });

    const { resendVerificationCode } = useResendVerificationCode(startCountdown);
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (validity) {
            startCountdown();
            startResendButtonCountdown();
        }
    }, [validity, startCountdown, startResendButtonCountdown]);

    return (
        <Stack direction='row' justifyContent='flex-end' alignItems='center' columnGap={1}>
            <Typography variant='paragraphNormal'>
                <FormattedMessage
                    id={countdown > 0 ? `phoneNumber.code.timer` : `phoneNumber.code.exired`}
                    values={{ seconds: countdown }}
                />
            </Typography>

            <Button
                variant='primary'
                onClick={() => {
                    resendVerificationCode();
                    startResendButtonCountdown();
                }}
                startIcon={<IconRefreshCcw05 />}
                disabled={resendButtonCountdown > 0}
                title={resendButtonCountdown > 0 ? formatMessage({ id: 'phoneNumber.code.title' }) : undefined}
            >
                <FormattedMessage id='phoneNumber.button.refetch' />
            </Button>
        </Stack>
    );
};
