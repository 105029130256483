import { forwardRef, useId } from 'react';
import { Box } from '@mui/material';
import * as Styled from './Tabs.styles';
export const TabPanel = (props) => {
    const { children, value, selected, ...other } = props;
    const id = useId();
    return (<Styled.CustomTabPanel role='tabpanel' value={value} id={`tabpanel-${id}`} aria-labelledby={`tab-${id}`} {...other}>
            {value === selected && (<>
                    <div className='coverup-divider'/>
                    <Box>{children}</Box>
                </>)}
        </Styled.CustomTabPanel>);
};
export const Tabs = forwardRef(function InnerTab(props, ref) {
    return (<Styled.Tabs ref={ref} TabIndicatorProps={{
            style: { display: 'none' },
        }} {...props}/>);
});
export const Tab = forwardRef(function InnerTab(props, ref) {
    return <Styled.Tab disableRipple ref={ref} {...props}/>;
});
