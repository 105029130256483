/**
 * Usage:
 * - For encrypting p12 file
 * - For encrypting user password before storing it in the runtime memory
 */
export const SYMMETRIC_ENCRYPTION_CONFIG = {
    name: 'AES-GCM',

    length: 256, // AES-256-GCM
    /**
     * - AES-GCM is designed to use a 96-bit (12-byte) IV for optimal security and performance (GCM uses the IV as a counter in the encryption process. Using a 12 byte IV is optimal because it aligns with the block size of AES and the size of the counter used in GCM.)
     * - Longer IVs can lead to inefficiencies in the encryption process without providing additional security benefits.
     * @link https://developer.mozilla.org/en-US/docs/Web/API/AesGcmParams
     */
    IVBytesLength: 12,
} as const;

/**
 * Hasing config for `PFX` object (PKCS #12).
 */
export const P12_HASHING_CONFIG = {
    /**
     * @url https://cheatsheetseries.owasp.org/cheatsheets/Password_Storage_Cheat_Sheet.html#pbkdf2
     */
    privacy: {
        hash: 'SHA-512',
        iterations: 210_000,
    },
    integrity: {
        hash: 'SHA-512',
        iterations: 50_000,
    },
} as const;

/**
 * Used for signing
 */
export const ASYMMETRIC_ENCRYPTION_CONFIG = {
    login: {
        /**
         * It'd be better to use RSA-PSS instead of RSA-PKCS1-v1_5, but it is not currently supported by the BE.
         * The authentication server will reject the signature if it is created with RSA-PSS.
         * - Discuss with Matěj Holý at 29th of January 2024.
         */
        name: 'RSASSA-PKCS1-v1_5',
        hash: 'SHA-512',
    },
    selfSignedCertificate: {
        name: 'RSA-PSS',
        hash: 'SHA-256',
    },
} as const;
