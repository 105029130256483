import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import type { AuthContextType } from '../contexts';
import { signOut } from '../services/signOut';

export function useSignOut(setSession: AuthContextType['setSession']) {
    const queryClient = useQueryClient();

    return useCallback(async () => {
        await signOut();

        setSession({
            status: 'unauthenticated',
            data: null,
        });

        queryClient.clear();
    }, [queryClient, setSession]);
}
