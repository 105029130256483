export default function Pdf(props) {
    return (<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='none' {...props}>
            <g clipPath='url(#clip0_1393_3501)'>
                <path stroke='#000' strokeMiterlimit='10' strokeWidth='1.5' d='M7.74 22.32v3.48c0 1.01.81 1.81 1.81 1.81h14.51c1.01 0 1.83-.81 1.83-1.81V9.61s0-.05-.01-.07a.655.655 0 0 0-.19-.4L19.5 2.95c-.1-.1-.25-.17-.4-.19-.02-.01-.06-.01-.09-.01H9.55c-1.01 0-1.81.81-1.81 1.81v8.92'/>
                <path stroke='#000' strokeMiterlimit='10' strokeWidth='1.5' d='M19.1 2.75v6.06c0 .4.33.73.73.73h6.06'/>
                <path stroke='#D60022' strokeMiterlimit='10' strokeWidth='1.5' d='M21.36 13.48H4.38a.63.63 0 0 0-.63.63v7.57c0 .348.282.63.63.63h16.98a.63.63 0 0 0 .63-.63v-7.57a.63.63 0 0 0-.63-.63Z'/>
                <path stroke='#D60022' strokeLinecap='round' strokeLinejoin='round' d='M11.66 19.73h1.04c1.02 0 1.84-.82 1.84-1.83 0-1.01-.82-1.84-1.84-1.84h-1.04v3.67ZM7.26 16.06h1.39c.65 0 1.18.53 1.18 1.18 0 .65-.53 1.18-1.18 1.18H7.26M7.26 19.74v-3.68M16.57 19.74v-3.68h1.91M16.57 17.9h1.6'/>
            </g>
            <defs>
                <clipPath id='clip0_1393_3501'>
                    <path fill='#fff' d='M3 2h23.64v26.36H3z'/>
                </clipPath>
            </defs>
        </svg>);
}
