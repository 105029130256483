import { useRouter } from 'next/router';
import type { UseFormSetError } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { logger } from '@workspace/logger';

import { routes } from '~constants';
import { useUsername } from '~modules/auth/hooks';
import { formError } from '~modules/form/errors';
import { useShowMessagePopup } from '~modules/message-popup/hooks';

import { loginFormKey, type LoginFormSchema } from '../../schema';

export function useSubmitLoginForm(setError: UseFormSetError<LoginFormSchema>) {
    const { push } = useRouter();
    const { setUsername } = useUsername();
    const { formatMessage } = useIntl();
    const showMessage = useShowMessagePopup();

    async function onSubmit(data: LoginFormSchema) {
        if (!data.username) {
            setError(loginFormKey.username, { message: formatMessage({ id: formError.required }) });
        }
        if (!data.filename) {
            setError(loginFormKey.filename, { message: formatMessage({ id: formError.required }) });
        }
        if (!data.username || !data.filename) return;

        try {
            await setUsername(data.username);
            await push(routes.passwordLogin);
        } catch (error) {
            logger.error(error);
            await showMessage('error', { id: 'general.error' });
        }
    }

    return onSubmit;
}
