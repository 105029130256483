function withPadZeros(value: number) {
    return String(value).padStart(2, '0');
}

export function formatCountdown(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${withPadZeros(minutes)}:${withPadZeros(remainingSeconds)}`;
}
