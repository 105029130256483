import { Stack as MUIStack, styled, Tooltip, tooltipClasses, Typography } from '@mui/material';
export const StyledTooltip = styled(({ className, ...props }) => (<Tooltip {...props} classes={{ popper: className }}/>))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
        borderRadius: '2px',
        backgroundColor: theme.color.buttons.primary,
        color: theme.color.tooltip.text,
        whiteSpace: 'pre-line',
        fontSize: theme.typography.fontSize,
    },
    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
        marginBottom: theme.spacing(1),
    },
}));
export const StyledStack = styled(MUIStack)(({ theme }) => ({
    borderBottom: `1px solid ${theme.color.inputs.border}`,
    ['&:last-of-type']: {
        borderBottom: 'unset',
    },
}));
export const CapitalizedBoldTypography = styled(Typography)(() => ({
    textTransform: 'uppercase',
    fontWeight: 600,
}));
