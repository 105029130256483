type PromptErrorCode =
    /**
     * Prompt was closed without any result
     */
    'MODAL_CLOSED_WITHOUT_RESULT';

enum ErrorId {
    PROMPT_ERROR = 'PROMPT_ERROR',
}

export class PromptError<
    Code extends PromptErrorCode = PromptErrorCode,
    Description extends string = string,
    OriginalError extends Error = Error,
> extends Error {
    public readonly code: Code;
    public readonly id: ErrorId.PROMPT_ERROR;
    public static readonly id = ErrorId.PROMPT_ERROR;
    public readonly originalError: OriginalError | null;

    constructor(code: Code, description?: Description, originalError: OriginalError | null = null) {
        super(description ? `${code}: ${description}` : code);

        this.id = ErrorId.PROMPT_ERROR;
        this.code = code;
        this.name = this.constructor.name;
        this.originalError = originalError;
    }
}

export function isError(error: unknown): error is Error {
    return error instanceof Error;
}

export function isPromptError(error: unknown): error is PromptError {
    return error instanceof PromptError && error.id === ErrorId.PROMPT_ERROR;
}

export function isPromptErrorWithCodes<Codes extends PromptErrorCode[]>(
    error: unknown,
    codes: Codes,
): error is PromptError<Codes[number]> {
    return isPromptError(error) && codes.includes(error.code);
}
