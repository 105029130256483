import { langs, registerLocale, type LocaleData } from 'i18n-iso-countries';

import { logger } from '@workspace/logger';

import type { Language } from '../types';

export async function registerLocalizedCountries(lang: Language) {
    try {
        if (langs().includes(lang)) {
            return true;
        }

        const localData: LocaleData = await import(`i18n-iso-countries/langs/${lang}.json`);

        registerLocale(localData);

        return true;
    } catch (e) {
        logger.error(e);

        return false;
    }
}
