import { useRouter } from 'next/router';

import { AuthProvider } from '@workspace/auth';
import { Loader, LoaderContainer } from '@workspace/ui';

import { authRoutes, routes } from '~constants';

export interface AuthProviderContainerProps {
    children: React.ReactNode;
}

export const AuthProviderContainer = ({ children }: AuthProviderContainerProps) => {
    const { push, route } = useRouter();

    return (
        <AuthProvider
            onSessionRetrieved={async session => {
                const onAuthRoute = route !== routes.login && Object.hasOwn(authRoutes, route);

                if (session.status === 'unauthenticated' && onAuthRoute) {
                    await push(routes.login);
                }
            }}
            Loader={() => (
                <LoaderContainer height='fullPage'>
                    <Loader />
                </LoaderContainer>
            )}
        >
            {children}
        </AuthProvider>
    );
};
