const SvgCz = (props) => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 24 24' {...props}>
        <g clipPath='url(#CZ_svg__a)'>
            <g clipPath='url(#CZ_svg__b)'>
                <rect width={16} height={11.636} x={4} y={6.182} fill='#F93939' rx={2}/>
                <path fill='#fff' fillRule='evenodd' d='M4 6.182h16V12H4V6.182Z' clipRule='evenodd'/>
                <path fill='#1A47B8' fillRule='evenodd' d='M4 6.182 11.619 12 4 17.818V6.182Z' clipRule='evenodd'/>
            </g>
        </g>
        <defs>
            <clipPath id='CZ_svg__a'>
                <path fill='#fff' d='M4 6.182h16v11.636H4z'/>
            </clipPath>
            <clipPath id='CZ_svg__b'>
                <rect width={16} height={11.636} x={4} y={6.182} fill='#fff' rx={2}/>
            </clipPath>
        </defs>
    </svg>);
export default SvgCz;
