import { useMemo } from 'react';

import { authRoutes, EntityBatchType } from '~constants';
import { useGenerateDoIdPath } from '~hooks/useGenerateDoIdPath';
import { modulePermissions, type PermissionsCheckFn } from '~modules/auth/constants';
import { InvoiceImportType } from '~modules/batch-import/constants';
import type { MessageKey } from '~modules/intl';
import { useSelectedSupplier } from '~modules/suppliers/hooks';

export interface ChildrenItem {
    title: MessageKey;
    href: string;
    uniqueQueryParam?: boolean;
}

interface ChildrenItemWithPermissions extends ChildrenItem {
    requiredPermissions: PermissionsCheckFn | null;
}

export interface NavigationItems<T = ChildrenItem> {
    title: MessageKey;
    childrenItems: ReadonlyArray<ReadonlyArray<T>>;
}

export function useNavigationItems() {
    const { doId } = useSelectedSupplier();

    const generateDoIdPath = useGenerateDoIdPath();

    return useMemo<ReadonlyArray<NavigationItems<ChildrenItemWithPermissions>>>(() => {
        if (!doId) return [];

        return [
            {
                title: 'layout.navigation.limits.title',
                childrenItems: [
                    [
                        {
                            title: 'layout.navigation.limits.supplierLimits.title',
                            href: generateDoIdPath(authRoutes.supplierLimits),
                            requiredPermissions: modulePermissions.supplierLimits,
                        },
                        {
                            title: 'layout.navigation.limits.supplierProductLimits.title',
                            href: generateDoIdPath(authRoutes.supplierProductLimits),
                            requiredPermissions: modulePermissions.supplierProductLimits,
                        },
                        {
                            title: 'layout.navigation.limits.supplierContractLimits.title',
                            href: generateDoIdPath(authRoutes.supplierContractLimits),
                            requiredPermissions: modulePermissions.supplierContractLimits,
                        },
                        {
                            title: 'layout.navigation.limits.supplierCustomerLimits.title',
                            href: generateDoIdPath(authRoutes.supplierCustomerLimits),
                            requiredPermissions: modulePermissions.supplierCustomerLimits,
                        },
                    ],
                ],
            },
            {
                title: 'layout.navigation.invoices.title',
                childrenItems: [
                    [
                        {
                            title: 'layout.navigation.invoices.purchased',
                            href: generateDoIdPath(authRoutes.purchasedInvoices),
                            requiredPermissions: modulePermissions.purchasedInvoices,
                        },
                        {
                            title: 'layout.navigation.invoices.nonfinanced',
                            href: generateDoIdPath(authRoutes.nonfinancedInvoices),
                            requiredPermissions: modulePermissions.nonFinancedInvoices,
                        },
                        {
                            title: 'layout.navigation.invoices.open',
                            href: generateDoIdPath(authRoutes.openInvoices),
                            requiredPermissions: modulePermissions.openInvoices,
                        },
                    ],
                    [
                        {
                            title: 'layout.navigation.invoices.discount',
                            href: generateDoIdPath(authRoutes.discountInvoices),
                            requiredPermissions: modulePermissions.discountInvoices,
                        },
                    ],
                    [
                        {
                            title: 'layout.navigation.invoices.liability',
                            href: generateDoIdPath(authRoutes.liabilityInvoices),
                            requiredPermissions: modulePermissions.liabilityInvoices,
                        },
                    ],
                    [
                        {
                            title: 'layout.navigation.invoices.issued',
                            href: generateDoIdPath(authRoutes.issuedInvoices),
                            requiredPermissions: modulePermissions.issuedInvoices,
                        },
                        {
                            title: 'layout.navigation.invoices.due',
                            href: generateDoIdPath(authRoutes.dueInvoices),
                            requiredPermissions: modulePermissions.dueInvoices,
                        },
                    ],
                    [
                        {
                            title: 'layout.navigation.invoices.payments',
                            href: generateDoIdPath(authRoutes.payments),
                            requiredPermissions: modulePermissions.payments,
                        },
                    ],
                ],
            },
            {
                title: 'layout.navigation.newInvoice.title',
                childrenItems: [
                    [
                        {
                            title: 'layout.navigation.newInvoice.purchased',
                            href: generateDoIdPath(authRoutes.newPurchasedInvoice),
                            requiredPermissions: modulePermissions.newPurchasedInvoice,
                            uniqueQueryParam: true,
                        },
                        {
                            title: 'layout.navigation.newInvoice.discount',
                            href: generateDoIdPath(authRoutes.newDiscountInvoice),
                            requiredPermissions: modulePermissions.newDiscountInvoice,
                            uniqueQueryParam: true,
                        },
                        {
                            title: 'layout.navigation.newInvoice.liability',
                            href: generateDoIdPath(authRoutes.newInvoiceLiability),
                            requiredPermissions: modulePermissions.newLiabilityInvoice,
                            uniqueQueryParam: true,
                        },
                        {
                            title: 'layout.navigation.newInvoice.reverse',
                            href: generateDoIdPath(authRoutes.newInvoicePurchasedReverse),
                            requiredPermissions: modulePermissions.newPurchasedInvoiceReverse,
                            uniqueQueryParam: true,
                        },
                        {
                            title: 'layout.navigation.newInvoice.discountReverse',
                            href: generateDoIdPath(authRoutes.newInvoiceDiscountReverse),
                            requiredPermissions: modulePermissions.newDiscountInvoiceReverse,
                            uniqueQueryParam: true,
                        },
                    ],
                ],
            },
            {
                title: 'layout.navigation.batches.title',
                childrenItems: [
                    [
                        {
                            title: 'layout.navigation.batches.purchased',
                            href: generateDoIdPath(authRoutes.batchEntityTable, {
                                invoiceType: EntityBatchType.Purchased,
                            }),
                            requiredPermissions: modulePermissions.purchasedInvoiceBatches,
                        },
                        {
                            title: 'layout.navigation.batches.purchasedImport',
                            href: generateDoIdPath(authRoutes.batchImport, {
                                invoiceType: InvoiceImportType.PURCHASED,
                            }),
                            requiredPermissions: modulePermissions.purchasedInvoiceImport,
                        },
                    ],
                    [
                        {
                            title: 'layout.navigation.batches.purchasedCeding',
                            href: generateDoIdPath(authRoutes.batchEntityTable, {
                                invoiceType: EntityBatchType.PurchasedCeding,
                            }),
                            requiredPermissions: modulePermissions.purchasedInvoiceForCedingBatches,
                        },
                        {
                            title: 'layout.navigation.batches.purchasedCedingImport',
                            href: generateDoIdPath(authRoutes.batchImport, {
                                invoiceType: InvoiceImportType.PURCHASED_INVOICE_CEDING,
                            }),
                            requiredPermissions: modulePermissions.importOfPurchasedInvoiceForCeding,
                        },
                    ],
                    [
                        {
                            title: 'layout.navigation.batches.discount',
                            href: generateDoIdPath(authRoutes.batchEntityTable, {
                                invoiceType: EntityBatchType.Discount,
                            }),
                            requiredPermissions: modulePermissions.discountInvoiceBatches,
                        },
                        {
                            title: 'layout.navigation.batches.discountImport',
                            href: generateDoIdPath(authRoutes.batchImport, {
                                invoiceType: InvoiceImportType.DISCOUNT,
                            }),
                            requiredPermissions: modulePermissions.discountInvoiceImport,
                        },
                    ],
                    [
                        {
                            title: 'layout.navigation.batches.liability',
                            href: generateDoIdPath(authRoutes.batchEntityTable, {
                                invoiceType: EntityBatchType.Liability,
                            }),
                            requiredPermissions: modulePermissions.liabilityInvoiceBatches,
                        },
                        {
                            title: 'layout.navigation.batches.liabilityImport',
                            href: generateDoIdPath(authRoutes.batchImport, {
                                invoiceType: InvoiceImportType.LIABILITY,
                            }),
                            requiredPermissions: modulePermissions.liabilityInvoiceImport,
                        },
                    ],
                    [
                        {
                            title: 'layout.navigation.batches.reverse',
                            href: generateDoIdPath(authRoutes.batchEntityTable, {
                                invoiceType: EntityBatchType.Reverse,
                            }),
                            requiredPermissions: modulePermissions.reverseInvoiceBatches,
                        },
                        {
                            title: 'layout.navigation.batches.reverseImport',
                            href: generateDoIdPath(authRoutes.batchImport, {
                                invoiceType: InvoiceImportType.PURCHASED_REVERSE,
                            }),
                            requiredPermissions: modulePermissions.reverseInvoiceImport,
                        },
                    ],
                    [
                        {
                            title: 'layout.navigation.batches.discountReverse',
                            href: generateDoIdPath(authRoutes.batchEntityTable, {
                                invoiceType: EntityBatchType.DiscountReverse,
                            }),
                            requiredPermissions: modulePermissions.discountInvoiceBatchesReverse,
                        },
                        {
                            title: 'layout.navigation.batches.discountImportReverse',
                            href: generateDoIdPath(authRoutes.batchImport, {
                                invoiceType: InvoiceImportType.DISCOUNT_REVERSE,
                            }),
                            requiredPermissions: modulePermissions.discountInvoiceImportReverse,
                        },
                    ],
                ],
            },
            {
                title: 'layout.navigation.certificates.title',
                childrenItems: [
                    [
                        {
                            title: 'layout.navigation.certificates.management',
                            href: generateDoIdPath(authRoutes.certificateManagement),
                            requiredPermissions: null,
                        },
                        {
                            title: 'layout.navigation.certificates.revewal',
                            href: generateDoIdPath(authRoutes.createCertificateRenewalRequest),
                            requiredPermissions: null,
                        },
                        {
                            title: 'layout.navigation.certificates.changePassword',
                            href: generateDoIdPath(authRoutes.changePassword),
                            requiredPermissions: null,
                        },
                        {
                            title: 'layout.navigation.certificates.pinSetting',
                            href: generateDoIdPath(authRoutes.changePin),
                            requiredPermissions: null,
                        },
                    ],
                ],
            },
            {
                title: 'layout.navigation.exports.title',
                childrenItems: [
                    [
                        {
                            title: 'layout.navigation.exports.dat',
                            href: generateDoIdPath(authRoutes.datExports),
                            requiredPermissions: modulePermissions.datExports,
                        },
                        {
                            title: 'layout.navigation.exports.pdf',
                            href: generateDoIdPath(authRoutes.pdfExports),
                            requiredPermissions: modulePermissions.pdfExports,
                        },
                        {
                            title: 'layout.navigation.exports.schedules',
                            href: generateDoIdPath(authRoutes.exportSchedule),
                            requiredPermissions: modulePermissions.exportsSchedule,
                        },
                    ],
                ],
            },
            {
                title: 'layout.navigation.tools.title',
                childrenItems: [
                    [
                        {
                            title: 'layout.navigation.tools.download',
                            href: generateDoIdPath(authRoutes.documentDownload),
                            requiredPermissions: null,
                        },
                        {
                            title: 'layout.navigation.tools.userSettings',
                            href: generateDoIdPath(authRoutes.userSettings),
                            requiredPermissions: null,
                        },
                        {
                            title: 'layout.navigation.tools.organizationSettings',
                            href: generateDoIdPath(authRoutes.partnerPreferences),
                            requiredPermissions: null,
                        },
                        {
                            title: 'layout.navigation.tools.loginHistory',
                            href: generateDoIdPath(authRoutes.loginHistory),
                            requiredPermissions: null,
                        },
                    ],
                ],
            },
        ] as const satisfies ReadonlyArray<NavigationItems<ChildrenItemWithPermissions>>;
    }, [doId, generateDoIdPath]);
}
