import { formControlLabelClasses, formGroupClasses, FormLabel, FormControl as MUIFormControl, RadioGroup as MUIRadioGroup, styled, typographyClasses, } from '@mui/material';
export const FormControl = styled(MUIFormControl)({});
export const Label = styled(FormLabel)(({ theme }) => ({
    ...theme.typography.formItem,
    marginBottom: theme.spacing(0.5),
}));
export const RadioGroup = styled(MUIRadioGroup)(({ theme }) => ({
    gap: theme.spacing(1.25),
    [`&.${formGroupClasses.root}`]: {
        gap: theme.spacing(0.75),
        [`& .${formControlLabelClasses.label}`]: {
            ...theme.typography.formItem,
        },
        [`& .${formControlLabelClasses.root}`]: {
            color: theme.color.inputs.text,
            [`&:has(.Mui-checked) ~ span.${typographyClasses.root}`]: {
                color: theme.color.foregrounds.primary,
            },
            [`&:has(.Mui-disabled) ~ span.${typographyClasses.root}`]: {
                color: theme.color.inputs.text,
                opacity: 0.9,
            },
        },
    },
}));
