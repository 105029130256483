import { useCallback } from 'react';

import type { DoIdAuthRoute } from '~constants';
import { useSelectedSupplier } from '~modules/suppliers/hooks';
import { generatePath, type GeneratePathParams } from '~utils/generatePath';

export function useGenerateDoIdPath() {
    const { doId } = useSelectedSupplier();

    return useCallback(
        <R extends DoIdAuthRoute>(route: R, params?: Omit<GeneratePathParams<R>, 'doId'>) => {
            if (!doId) {
                throw new Error('SelectedSupplierContext is not set above');
            }

            return generatePath(route, { doId, ...params });
        },
        [doId],
    );
}

export type DoIdParams = Parameters<ReturnType<typeof useGenerateDoIdPath>>[1];
