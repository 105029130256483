import type { PropsWithChildren } from 'react';
import { useAtom } from 'jotai/index';

import { loginPromptModalAtom } from '~modules/auth/atoms/loginPrompt';
import { AuthenticateModal } from '~modules/auth/components';
import { PasswordPrompt } from '~modules/auth/contexts';

export const PromptForPassword = ({ children }: PropsWithChildren) => {
    const [openAuthModal, setOpenAuthModal] = useAtom(loginPromptModalAtom);

    return (
        <PasswordPrompt.Provider value>
            <AuthenticateModal open={openAuthModal} onClose={setOpenAuthModal} backShouldClose />
            {children}
        </PasswordPrompt.Provider>
    );
};
