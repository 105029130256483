import type {
    Certificate,
    CertificateRevocationList,
    CertificationRequest,
    ContentInfo,
    PKCS8ShroudedKeyBag,
    PrivateKeyInfo,
    PublicKeyInfo,
    SignedData,
} from 'pkijs';

import { decodeBase64, stringToArrayBuffer } from './convert';

export enum PemType {
    CertificateRequest = 'CERTIFICATE REQUEST',
    CertificateRevocationList = 'X509 CRL',
    Certificate = 'CERTIFICATE',
    PublicKey = 'PUBLIC KEY',
    /**
     * PKCS #8 PrivateKeyInfo
     */
    PrivateKey = 'PRIVATE KEY',
    SignedData = 'PKCS7',
}

/**
 * Converts complex PKI object to PEM (Privacy enhanced mail) format.
 * - The result is base 64 encoded string with max line length of 64 chars.,
 * - It's prepended with header and appended with footer based on object type (`PemType`).
 */
export function toPem(
    object:
        | CertificationRequest
        | Certificate
        | PublicKeyInfo
        | PrivateKeyInfo
        | CertificateRevocationList
        | PKCS8ShroudedKeyBag
        | SignedData
        | ContentInfo,
    type: PemType,
) {
    const b64 = object.toString('base64');

    // Split the encoded string into lines of 64 characters
    const lineLength = 64;
    const formattedBase64 = b64.match(new RegExp('.{1,' + lineLength + '}', 'g'))!.join('\n');

    // Add the PEM headers and footers
    const pem = `-----BEGIN ${type}-----\n${formattedBase64}\n-----END ${type}-----`;

    return pem;
}

export function cleanPemHeaderAndFooter(pem: string) {
    return pem
        .split('\n')
        .filter(row => !row.includes('-----BEGIN') && !row.includes('-----END') && row.length)
        .join('');
}

/**
 * This method doesn't support PEM with multiple objects, thus multiple headers and footers.
 */
export function parsePem(pem: string): ArrayBuffer {
    // Yes, it could be done by replace and regex but this is more readable
    const pemWithoutHeaderAndFooter = cleanPemHeaderAndFooter(pem);

    const decodedPem = decodeBase64(pemWithoutHeaderAndFooter);

    return stringToArrayBuffer(decodedPem);
}
