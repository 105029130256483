import { SYMMETRIC_ENCRYPTION_CONFIG } from './config';
import { getRandomBytes } from './utils';

export async function generateKey() {
    return crypto.subtle.generateKey(
        {
            name: SYMMETRIC_ENCRYPTION_CONFIG.name,
            length: SYMMETRIC_ENCRYPTION_CONFIG.length,
        },
        false,
        ['encrypt', 'decrypt'],
    );
}

/**
 * Encrypts a data with AES-GCM
 */
export async function encrypt(data: ArrayBuffer) {
    const key = await generateKey();
    const iv = getRandomBytes(SYMMETRIC_ENCRYPTION_CONFIG.IVBytesLength);

    const encryptedData = await crypto.subtle.encrypt(
        {
            name: SYMMETRIC_ENCRYPTION_CONFIG.name,
            iv,
        },
        key,
        data,
    );

    return {
        encryptedData,
        key,
        iv,
    } as const;
}

export interface DecryptParams {
    key: CryptoKey;
    iv: ArrayBuffer;
}

export async function decrypt(encryptedData: ArrayBuffer, { key, iv }: DecryptParams) {
    return await crypto.subtle.decrypt(
        {
            name: SYMMETRIC_ENCRYPTION_CONFIG.name,
            iv,
        },
        key,
        encryptedData,
    );
}
