import { useMemo } from 'react';

import { EfctPristupyFoModelProductGroup, EfctPristupyFoModelRole } from '@workspace/api';

import { OperationPermission } from '~modules/auth/constants';

import { usePermissionData } from './usePermissionData';
import { useUserUtils } from './useUserUtils';

export function usePermissionUtils() {
    const data = usePermissionData();
    const userUtils = useUserUtils();

    return useMemo(() => {
        function hasRoleAndProductGroup(
            role: EfctPristupyFoModelRole,
            ...productGroups: EfctPristupyFoModelProductGroup[]
        ) {
            return data?.pristupyFo?.some(v => v.role === role && productGroups.includes(v.productGroup!)) ?? false;
        }

        function hasRoleProductGroupAndScf(
            role: EfctPristupyFoModelRole,
            productGroup: EfctPristupyFoModelProductGroup,
            scfActive: boolean | null,
            scfInactive: boolean | null,
        ) {
            return (
                data?.pristupyFo?.some(
                    v =>
                        v.role === role &&
                        v.productGroup === productGroup &&
                        (scfActive == null || scfActive === v.scfActive) &&
                        (scfInactive == null || scfInactive === v.scfInactive),
                ) ?? false
            );
        }

        return {
            hasRoleDInGroupF: hasRoleAndProductGroup(
                EfctPristupyFoModelRole.SUPPLIER,
                EfctPristupyFoModelProductGroup.F,
            ),
            hasRoleDInGroupD: hasRoleAndProductGroup(
                EfctPristupyFoModelRole.SUPPLIER,
                EfctPristupyFoModelProductGroup.D,
            ),
            hasRoleOInGroupZ: hasRoleAndProductGroup(
                EfctPristupyFoModelRole.CUSTOMER,
                EfctPristupyFoModelProductGroup.Z,
            ),
            hasRoleDProductFAndScfInactive: hasRoleProductGroupAndScf(
                EfctPristupyFoModelRole.SUPPLIER,
                EfctPristupyFoModelProductGroup.F,
                null,
                true,
            ),
            hasRoleDInOneOfGroupsFZD: hasRoleAndProductGroup(
                EfctPristupyFoModelRole.SUPPLIER,
                EfctPristupyFoModelProductGroup.F,
                EfctPristupyFoModelProductGroup.Z,
                EfctPristupyFoModelProductGroup.D,
            ),
            hasRoleOInOneOfGroupsFID: hasRoleAndProductGroup(
                EfctPristupyFoModelRole.CUSTOMER,
                EfctPristupyFoModelProductGroup.F,
                EfctPristupyFoModelProductGroup.I,
                EfctPristupyFoModelProductGroup.D,
            ),
            hasRoleDProductDAndScfInactive: hasRoleProductGroupAndScf(
                EfctPristupyFoModelRole.SUPPLIER,
                EfctPristupyFoModelProductGroup.D,
                null,
                true,
            ),
            hasRoleOProductFAndScfActive: hasRoleProductGroupAndScf(
                EfctPristupyFoModelRole.CUSTOMER,
                EfctPristupyFoModelProductGroup.F,
                true,
                null,
            ),
            hasRoleOProductDAndScfActive: hasRoleProductGroupAndScf(
                EfctPristupyFoModelRole.CUSTOMER,
                EfctPristupyFoModelProductGroup.D,
                true,
                null,
            ),
            canViewInFileDetail: userUtils.isAllowedOperation(OperationPermission.VIEW_INFILE_DETAIL),
        };
    }, [data, userUtils]);
}

export type PermUtils = ReturnType<typeof usePermissionUtils>;
