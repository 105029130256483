import { useQuery } from '@tanstack/react-query';

import { supplierCustomer_findByAll as findByAllContact } from '@workspace/api';

import { useCurrentDoId } from './useCurrentDoId';

export function useSelectedSupplierContactInfo() {
    const doId = useCurrentDoId();

    const query = useQuery({
        queryKey: ['findByAllContact', doId],
        queryFn: () => findByAllContact({ doId: doId }),
        enabled: !!doId,
    });

    return { query, data: query.data?.data?.[0] };
}
