const FileCopy = (props) => (<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <g clipPath='url(#clip0_1868_3963)'>
            <path d='M8.75 8.7525C8.75 7.65 9.6425 6.75 10.7525 6.75H17.255C18.3575 6.75 19.2575 7.6425 19.2575 8.7525V18.255C19.2575 19.3575 18.365 20.2575 17.255 20.2575H10.7525C9.65 20.2575 8.75 19.365 8.75 18.255V8.7525Z' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
            <path d='M6.5075 17.055C6.0425 16.785 5.75 16.29 5.75 15.75V5.25C5.75 4.425 6.425 3.75 7.25 3.75H14.75C15.3125 3.75 15.62 4.035 15.875 4.5' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
        </g>
        <defs>
            <clipPath id='clip0_1868_3963'>
                <rect width='15' height='18' fill='white' transform='translate(5 3)'/>
            </clipPath>
        </defs>
    </svg>);
export default FileCopy;
