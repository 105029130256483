/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvSupplierCustomerLimitModel,
    EfcvSupplierCustomerLimitModelBody,
    GetByIdRequestModelOfEfcvSupplierCustomerLimitModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvSupplierCustomerLimitModel,
    ViewDataResultModelOfEfcvSupplierCustomerLimitModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const supplierCustomerLimit_findByAll = (
    efcvSupplierCustomerLimitModelBody: BodyType<EfcvSupplierCustomerLimitModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSupplierCustomerLimitModel[] | void>(
        {
            url: `/factoring/efcvsuppliercustomerlimit/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvSupplierCustomerLimitModelBody,
        },
        options,
    );
};

export const getSupplierCustomerLimitFindByAllMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerLimit_findByAll>>,
        TError,
        { data: BodyType<EfcvSupplierCustomerLimitModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierCustomerLimit_findByAll>>,
    TError,
    { data: BodyType<EfcvSupplierCustomerLimitModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierCustomerLimit_findByAll>>,
        { data: BodyType<EfcvSupplierCustomerLimitModelBody> }
    > = props => {
        const { data } = props ?? {};

        return supplierCustomerLimit_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierCustomerLimitFindByAllMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierCustomerLimit_findByAll>>
>;
export type SupplierCustomerLimitFindByAllMutationBody = BodyType<EfcvSupplierCustomerLimitModelBody>;
export type SupplierCustomerLimitFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useSupplierCustomerLimitFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerLimit_findByAll>>,
        TError,
        { data: BodyType<EfcvSupplierCustomerLimitModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierCustomerLimitFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const supplierCustomerLimit_findById = (
    getByIdRequestModelOfEfcvSupplierCustomerLimitModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerLimitModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSupplierCustomerLimitModel | void>(
        {
            url: `/factoring/efcvsuppliercustomerlimit/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvSupplierCustomerLimitModelAndbigdecimal,
        },
        options,
    );
};

export const getSupplierCustomerLimitFindByIdMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerLimit_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerLimitModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierCustomerLimit_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerLimitModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierCustomerLimit_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerLimitModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return supplierCustomerLimit_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierCustomerLimitFindByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierCustomerLimit_findById>>
>;
export type SupplierCustomerLimitFindByIdMutationBody =
    BodyType<GetByIdRequestModelOfEfcvSupplierCustomerLimitModelAndbigdecimal>;
export type SupplierCustomerLimitFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useSupplierCustomerLimitFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerLimit_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerLimitModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierCustomerLimitFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const supplierCustomerLimit_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvSupplierCustomerLimitModel | void>(
        {
            url: `/factoring/efcvsuppliercustomerlimit/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getSupplierCustomerLimitFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerLimit_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierCustomerLimit_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierCustomerLimit_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return supplierCustomerLimit_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierCustomerLimitFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierCustomerLimit_findByLightDataRequest>>
>;
export type SupplierCustomerLimitFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type SupplierCustomerLimitFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useSupplierCustomerLimitFindByLightDataRequest = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerLimit_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierCustomerLimitFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const supplierCustomerLimit_findByViewDataRequest = (
    viewDataRequestModelOfEfcvSupplierCustomerLimitModel: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerLimitModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvSupplierCustomerLimitModel | void>(
        {
            url: `/factoring/efcvsuppliercustomerlimit/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvSupplierCustomerLimitModel,
        },
        options,
    );
};

export const getSupplierCustomerLimitFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerLimit_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerLimitModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierCustomerLimit_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerLimitModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierCustomerLimit_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerLimitModel> }
    > = props => {
        const { data } = props ?? {};

        return supplierCustomerLimit_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierCustomerLimitFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierCustomerLimit_findByViewDataRequest>>
>;
export type SupplierCustomerLimitFindByViewDataRequestMutationBody =
    BodyType<ViewDataRequestModelOfEfcvSupplierCustomerLimitModel>;
export type SupplierCustomerLimitFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useSupplierCustomerLimitFindByViewDataRequest = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerLimit_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerLimitModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierCustomerLimitFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
