/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type LoginResponseModelGlobalResult =
    (typeof LoginResponseModelGlobalResult)[keyof typeof LoginResponseModelGlobalResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LoginResponseModelGlobalResult = {
    USERNAME_OR_CERTIFICATE_ERROR: 'USERNAME_OR_CERTIFICATE_ERROR',
    AUTHORIZATION_SERVICE_ERROR: 'AUTHORIZATION_SERVICE_ERROR',
    CHALLENGE_EXPIRED: 'CHALLENGE_EXPIRED',
    LOGIN_INCORRECT: 'LOGIN_INCORRECT',
    ACCESS_BLOCKED: 'ACCESS_BLOCKED',
    OK: 'OK',
    PROXY_ONE_TIME_PASSWORD_ERROR: 'PROXY_ONE_TIME_PASSWORD_ERROR',
} as const;
