/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    CurrencySumToPrefinanceResponseModel,
    ExportBbsRequestModel,
    ExportBbsResponseModel,
    ExportCsvRequestModel,
    ExportCsvResponseModel,
    ExportPdfRequestModel,
    ExportPdfResponseModel,
    GetPdfExportTypeListRequestModel,
    GetPdfExportTypeListResponseModel,
    MarkToPrefinanceResponseModel,
    ReverseFinancingRequestModelBody,
    ReverseFinancingResponseModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Do a BBS export
 */
export const exportService_exportBbs = (
    exportBbsRequestModel: BodyType<ExportBbsRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ExportBbsResponseModel | void>(
        {
            url: `/factoring/exportservice/exportBbs`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: exportBbsRequestModel,
        },
        options,
    );
};

export const getExportServiceExportBbsMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportService_exportBbs>>,
        TError,
        { data: BodyType<ExportBbsRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportService_exportBbs>>,
    TError,
    { data: BodyType<ExportBbsRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportService_exportBbs>>,
        { data: BodyType<ExportBbsRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return exportService_exportBbs(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportServiceExportBbsMutationResult = NonNullable<Awaited<ReturnType<typeof exportService_exportBbs>>>;
export type ExportServiceExportBbsMutationBody = BodyType<ExportBbsRequestModel>;
export type ExportServiceExportBbsMutationError = ErrorType<unknown>;

/**
 * @summary Do a BBS export
 */
export const useExportServiceExportBbs = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportService_exportBbs>>,
        TError,
        { data: BodyType<ExportBbsRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getExportServiceExportBbsMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Do a CSV export
 */
export const exportService_exportCsv = (
    exportCsvRequestModel: BodyType<ExportCsvRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ExportCsvResponseModel | void>(
        {
            url: `/factoring/exportservice/exportCsv`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: exportCsvRequestModel,
        },
        options,
    );
};

export const getExportServiceExportCsvMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportService_exportCsv>>,
        TError,
        { data: BodyType<ExportCsvRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportService_exportCsv>>,
    TError,
    { data: BodyType<ExportCsvRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportService_exportCsv>>,
        { data: BodyType<ExportCsvRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return exportService_exportCsv(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportServiceExportCsvMutationResult = NonNullable<Awaited<ReturnType<typeof exportService_exportCsv>>>;
export type ExportServiceExportCsvMutationBody = BodyType<ExportCsvRequestModel>;
export type ExportServiceExportCsvMutationError = ErrorType<unknown>;

/**
 * @summary Do a CSV export
 */
export const useExportServiceExportCsv = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportService_exportCsv>>,
        TError,
        { data: BodyType<ExportCsvRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getExportServiceExportCsvMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Do a PDF export
 */
export const exportService_exportPdf = (
    exportPdfRequestModel: BodyType<ExportPdfRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ExportPdfResponseModel | void>(
        {
            url: `/factoring/exportservice/exportPdf`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: exportPdfRequestModel,
        },
        options,
    );
};

export const getExportServiceExportPdfMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportService_exportPdf>>,
        TError,
        { data: BodyType<ExportPdfRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportService_exportPdf>>,
    TError,
    { data: BodyType<ExportPdfRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportService_exportPdf>>,
        { data: BodyType<ExportPdfRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return exportService_exportPdf(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportServiceExportPdfMutationResult = NonNullable<Awaited<ReturnType<typeof exportService_exportPdf>>>;
export type ExportServiceExportPdfMutationBody = BodyType<ExportPdfRequestModel>;
export type ExportServiceExportPdfMutationError = ErrorType<unknown>;

/**
 * @summary Do a PDF export
 */
export const useExportServiceExportPdf = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportService_exportPdf>>,
        TError,
        { data: BodyType<ExportPdfRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getExportServiceExportPdfMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get amounts to prefinance grouped by currency
 */
export const exportService_getCurrencySumsToPrefinance = (
    reverseFinancingRequestModelBody: BodyType<ReverseFinancingRequestModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<CurrencySumToPrefinanceResponseModel | void>(
        {
            url: `/factoring/exportservice/getCurrencySumsToPrefinance`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: reverseFinancingRequestModelBody,
        },
        options,
    );
};

export const getExportServiceGetCurrencySumsToPrefinanceMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportService_getCurrencySumsToPrefinance>>,
        TError,
        { data: BodyType<ReverseFinancingRequestModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportService_getCurrencySumsToPrefinance>>,
    TError,
    { data: BodyType<ReverseFinancingRequestModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportService_getCurrencySumsToPrefinance>>,
        { data: BodyType<ReverseFinancingRequestModelBody> }
    > = props => {
        const { data } = props ?? {};

        return exportService_getCurrencySumsToPrefinance(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportServiceGetCurrencySumsToPrefinanceMutationResult = NonNullable<
    Awaited<ReturnType<typeof exportService_getCurrencySumsToPrefinance>>
>;
export type ExportServiceGetCurrencySumsToPrefinanceMutationBody = BodyType<ReverseFinancingRequestModelBody>;
export type ExportServiceGetCurrencySumsToPrefinanceMutationError = ErrorType<unknown>;

/**
 * @summary Get amounts to prefinance grouped by currency
 */
export const useExportServiceGetCurrencySumsToPrefinance = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportService_getCurrencySumsToPrefinance>>,
        TError,
        { data: BodyType<ReverseFinancingRequestModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getExportServiceGetCurrencySumsToPrefinanceMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get invoices that should be selected in view based on filter
 */
export const exportService_getMarkedToPrefinance = (
    reverseFinancingRequestModelBody: BodyType<ReverseFinancingRequestModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<MarkToPrefinanceResponseModel | void>(
        {
            url: `/factoring/exportservice/getMarkedToPrefinance`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: reverseFinancingRequestModelBody,
        },
        options,
    );
};

export const getExportServiceGetMarkedToPrefinanceMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportService_getMarkedToPrefinance>>,
        TError,
        { data: BodyType<ReverseFinancingRequestModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportService_getMarkedToPrefinance>>,
    TError,
    { data: BodyType<ReverseFinancingRequestModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportService_getMarkedToPrefinance>>,
        { data: BodyType<ReverseFinancingRequestModelBody> }
    > = props => {
        const { data } = props ?? {};

        return exportService_getMarkedToPrefinance(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportServiceGetMarkedToPrefinanceMutationResult = NonNullable<
    Awaited<ReturnType<typeof exportService_getMarkedToPrefinance>>
>;
export type ExportServiceGetMarkedToPrefinanceMutationBody = BodyType<ReverseFinancingRequestModelBody>;
export type ExportServiceGetMarkedToPrefinanceMutationError = ErrorType<unknown>;

/**
 * @summary Get invoices that should be selected in view based on filter
 */
export const useExportServiceGetMarkedToPrefinance = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportService_getMarkedToPrefinance>>,
        TError,
        { data: BodyType<ReverseFinancingRequestModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getExportServiceGetMarkedToPrefinanceMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get available reports/exports for given view (list or detail)
 */
export const exportService_getPdfExportTypeList = (
    getPdfExportTypeListRequestModel: BodyType<GetPdfExportTypeListRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<GetPdfExportTypeListResponseModel | void>(
        {
            url: `/factoring/exportservice/getPdfExportTypeList`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getPdfExportTypeListRequestModel,
        },
        options,
    );
};

export const getExportServiceGetPdfExportTypeListMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportService_getPdfExportTypeList>>,
        TError,
        { data: BodyType<GetPdfExportTypeListRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportService_getPdfExportTypeList>>,
    TError,
    { data: BodyType<GetPdfExportTypeListRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportService_getPdfExportTypeList>>,
        { data: BodyType<GetPdfExportTypeListRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return exportService_getPdfExportTypeList(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportServiceGetPdfExportTypeListMutationResult = NonNullable<
    Awaited<ReturnType<typeof exportService_getPdfExportTypeList>>
>;
export type ExportServiceGetPdfExportTypeListMutationBody = BodyType<GetPdfExportTypeListRequestModel>;
export type ExportServiceGetPdfExportTypeListMutationError = ErrorType<unknown>;

/**
 * @summary Get available reports/exports for given view (list or detail)
 */
export const useExportServiceGetPdfExportTypeList = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportService_getPdfExportTypeList>>,
        TError,
        { data: BodyType<GetPdfExportTypeListRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getExportServiceGetPdfExportTypeListMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Reverse finance invoices
 */
export const exportService_reverseFinancing = (
    reverseFinancingRequestModelBody: BodyType<ReverseFinancingRequestModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ReverseFinancingResponseModel | void>(
        {
            url: `/factoring/exportservice/reverseFinancing`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: reverseFinancingRequestModelBody,
        },
        options,
    );
};

export const getExportServiceReverseFinancingMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportService_reverseFinancing>>,
        TError,
        { data: BodyType<ReverseFinancingRequestModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportService_reverseFinancing>>,
    TError,
    { data: BodyType<ReverseFinancingRequestModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportService_reverseFinancing>>,
        { data: BodyType<ReverseFinancingRequestModelBody> }
    > = props => {
        const { data } = props ?? {};

        return exportService_reverseFinancing(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportServiceReverseFinancingMutationResult = NonNullable<
    Awaited<ReturnType<typeof exportService_reverseFinancing>>
>;
export type ExportServiceReverseFinancingMutationBody = BodyType<ReverseFinancingRequestModelBody>;
export type ExportServiceReverseFinancingMutationError = ErrorType<unknown>;

/**
 * @summary Reverse finance invoices
 */
export const useExportServiceReverseFinancing = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportService_reverseFinancing>>,
        TError,
        { data: BodyType<ReverseFinancingRequestModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getExportServiceReverseFinancingMutationOptions(options);

    return useMutation(mutationOptions);
};
