import { useQuery } from '@tanstack/react-query';
import { differenceInDays, startOfDay, startOfToday } from 'date-fns';

import { dataService_getCertificateWarningLimit, QueryKey } from '@workspace/api';
import { getIDBStore } from '@workspace/storage';

export function useCertificateValidity() {
    return useQuery({
        queryKey: [QueryKey.CERTIFICATE_WARNING_LIMIT],
        queryFn: async () => {
            const issuedCertificateValidTo = await getIDBStore('pkcs12').get('issuedCertificateValidTo');

            const result = await dataService_getCertificateWarningLimit();
            const warningLimit = result.data!.warningLimit;
            const criticalLimit = result.data!.criticalLimit;

            const remainingDays = issuedCertificateValidTo
                ? differenceInDays(startOfDay(new Date(issuedCertificateValidTo)), startOfToday())
                : null;

            return {
                notifyUser: Boolean(warningLimit && typeof remainingDays === 'number' && remainingDays < warningLimit),
                warnUser: Boolean(criticalLimit && typeof remainingDays === 'number' && remainingDays < criticalLimit),
                remainingDays,
            } as const;
        },
        staleTime: Infinity,
        gcTime: Infinity,
    });
}
