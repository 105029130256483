import { forwardRef } from 'react';
import { CircularProgress } from '@mui/material';
import { StyledLoadingButton } from './Button.styles';
export const Button = forwardRef(function InnerButton({ children, loading, startIcon, disabled, ...buttonProps }, ref) {
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && ref && 'current' in ref && ref.current) {
            ref?.current.click();
        }
    };
    return (<StyledLoadingButton ref={ref} startIcon={loading ? <CircularProgress color='inherit' size={16}/> : startIcon} disabled={loading || disabled} onKeyDown={handleKeyDown} {...buttonProps}>
            {children}
        </StyledLoadingButton>);
});
