/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type EfctPristupyFoModelRole = (typeof EfctPristupyFoModelRole)[keyof typeof EfctPristupyFoModelRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EfctPristupyFoModelRole = {
    SUPPLIER: 'SUPPLIER',
    CUSTOMER: 'CUSTOMER',
} as const;
