export const color = {
    accent: {
        primary: '#020203',
        secondary: '#D60022',
        primaryRed: '#E60028',
    },
    backgrounds: {
        primary: '#FFF',
    },
    foregrounds: {
        primary: '#020203',
        secondary: '#78787D',
    },
    buttons: {
        primary: {
            enabled: '#020203',
            hover: '#2F2F2F',
            pressed: '#4F4F4F',
            text: '#FFFFFF',
            disabled: '#02020333',
            green: '#5CB85C',
            greenHover: '#449D44',
            greenPressed: '#2E7E2E',
            greenDisabled: '#5CB85C66',
            red: '#D60022',
            redHover: '#AB263C',
            redPressed: '#8E192C',
            redDisabled: '#D600224D',
        },
        secondary: {
            background: '#FFFFFF',
            enabled: '#020203',
            hover: '#5F5F5F',
            pressed: '#979797',
            disabled: '#02020333',
        },
    },
    icons: {
        table: '#949694',
        onPrimary: '#FFFFFF',
        secondary: '#020203',
        inputs: '#78787D',
        word: '#06F',
    },
    alerts: {
        success: '#CBFFD0',
        successAlt: '#E2FFE5',
        error: '#D60022',
        info: '#DEF7FF',
    },
    inputs: {
        background: '#FFFFFF',
        text: '#78787D',
        border: '#D9D9DD',
        borderHover: '#020203',
        textLabel: '#949494',
        grey: '#F5F5F5',
    },
    table: {
        hover: '#F5F7F9',
        background: '#ffffff',
        border: '#E0E0E0',
    },
    container: {
        border: '#0000001F',
    },
    tooltip: {
        text: '#FFFFFF',
    },
};
