import type { ReactNode } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import cs from 'date-fns/locale/cs';
import enGB from 'date-fns/locale/en-GB';

import { useLang, type Language } from '~modules/intl';

interface MUILocalizationProviderProps {
    children: ReactNode;
}

const adapterLocaleByLang: Record<Language, any> = {
    cs: cs,
    en: enGB,
};

export const MUILocalizationProvider = ({ children }: MUILocalizationProviderProps) => {
    const lang = useLang();

    return (
        <LocalizationProvider adapterLocale={adapterLocaleByLang[lang]} dateAdapter={AdapterDateFns}>
            {children}
        </LocalizationProvider>
    );
};
