import { createSignature } from '../lib/pki';
import { loadIssuedCertificate } from './loadCertificates';

export async function signInSignatureAndFingerprint(challenge: string) {
    const issuedCertificate = await loadIssuedCertificate();

    const signature = await createSignature(issuedCertificate, challenge);

    return {
        signature,
        fingerprint: issuedCertificate.fingerprint,
    } as const;
}
