import { Stack, styled, typographyClasses } from '@mui/material';

export const MessageStack = styled(Stack)(({ theme }) => ({
    [`& .info span.${typographyClasses.root}`]: {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.color.icons.word,
    },
    [`& .warning span.${typographyClasses.root}`]: {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.color.buttons.primary.red,
    },

    [`& .red span.${typographyClasses.root}`]: {
        color: theme.color.buttons.primary.red,
    },
    [`& .green span.${typographyClasses.root}`]: {
        color: theme.color.buttons.primary.green,
    },
    [`& .blue span.${typographyClasses.root}`]: {
        color: theme.color.icons.word,
    },
}));
