import {
    Antonio,
    AntonioError,
    type RequestBodyData,
    type RequestConfig,
    type RequestMethod,
    type RequestResult,
} from '@ackee/antonio-core';
import { upperCase } from 'string-ts';

import { env } from '@workspace/env';

import { getApiBaseUrl } from '../config';

export type FetcherProps = RequestConfig & {
    url: string;
    method: Lowercase<RequestMethod> | RequestMethod;
    data?: RequestBodyData;
};

export type FetcherOptions = Omit<RequestConfig, 'data' | 'url' | 'method'>;

let apiClient: Antonio | null = null;

export const api = () => {
    if (!apiClient) {
        apiClient = new Antonio({
            baseURL: getApiBaseUrl(),
        });
    }

    return apiClient;
};

export function createApiRequest<T>(props: FetcherProps, options?: FetcherOptions): Promise<RequestResult<T>> {
    const { data, method, ...requestConfig } = props;
    const isDeployedProductionEnv =
        env.NEXT_PUBLIC_BUILD_ENV === 'production' && env.NEXT_PUBLIC_NODE_ENV === 'production';

    return api().request<Uppercase<FetcherProps['method']>, T, AntonioError<T>>({
        ...requestConfig,
        ...options,
        method: upperCase(method),
        headers: {
            ...requestConfig.headers,
            ...options?.headers,
        },
        body: data,
        /**
         * - Required in order to maintain session cookie attached
         * - Restrict credentials to same-origin in production since the API will be deployed on the same domain
         */
        credentials: isDeployedProductionEnv ? 'same-origin' : 'include',
    });
}

export type CreateApiRequest<T> = typeof createApiRequest<T>;
