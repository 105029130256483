import { useCallback } from 'react';

import { useUserData } from '@workspace/auth';

import type { OperationPermissionType } from '../constants';

export function useUserUtils() {
    const { data: userData } = useUserData();

    const isAllowedOperation = useCallback(
        (permission: OperationPermissionType) => {
            const data = userData?.data;
            const operations = (data as Exclude<typeof data, void>)?.securityData?.operations;

            if (!operations?.length) {
                return false;
            }

            return operations.includes(permission);
        },
        [userData],
    );

    return { isAllowedOperation };
}

export type UserUtils = ReturnType<typeof useUserUtils>;
