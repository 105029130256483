import { useRouter } from 'next/router';
import { Stack, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAuthUserInfo } from '@workspace/auth';
import { Button, ButtonLink, IconHelpCircle, IconHome02 } from '@workspace/ui';

import { authRoutes, routes } from '~constants';
import { useGenerateDoIdPath } from '~hooks/useGenerateDoIdPath';
import { AutoLogout } from '~modules/auth/components';
import { useLang } from '~modules/intl';
import { LogActions } from '~modules/session-log/constants';
import { useLogAction } from '~modules/session-log/hooks';
import { useSelectedSupplier } from '~modules/suppliers/hooks';
import { generatePath } from '~utils/generatePath';

import AppInfo from '../AppInfo/AppInfo';
import { CertificateValidity } from '../CertificateValidity';
import { LanguageSwitch } from '../LanguageSwitch';
import * as Styled from './HeaderAuthNavigation.styles';
import { LogoutButton } from './LogoutButton';

export const HeaderAuthNavigation = () => {
    const { push, route } = useRouter();
    const { doId } = useSelectedSupplier();
    const userInfo = useAuthUserInfo();
    const lang = useLang();
    const { formatMessage } = useIntl();

    const generateDoIdPath = useGenerateDoIdPath();

    const logActionHelp = useLogAction(LogActions.SYSTEM_HELP);

    return (
        <Stack direction='row' gap={1.5}>
            <AppInfo />
            <Typography variant='paragraphSmall'>{userInfo?.name}</Typography>
            <Styled.Separator />
            <Typography variant='paragraphSmall'>
                <FormattedMessage id='layout.header.user' values={{ username: userInfo?.loginName }} />
            </Typography>
            <Styled.Separator />
            <Stack direction='column' gap={1}>
                <AutoLogout />
            </Stack>
            <Styled.Separator />
            {doId && (
                <>
                    <Stack direction='column' gap={1}>
                        <CertificateValidity />
                    </Stack>
                    <Styled.Separator />
                </>
            )}
            {route === authRoutes.home && (
                <>
                    <LanguageSwitch />
                    <Styled.Separator />
                </>
            )}
            <Styled.IconButtons>
                <Button
                    startIcon={<IconHome02 />}
                    variant='text'
                    title={formatMessage({ id: 'layout.header.home' })}
                    disabled={!doId}
                    tabIndex={1}
                    onClick={async () => {
                        if (!doId) {
                            return;
                        }

                        await push(generateDoIdPath(authRoutes.supplierDetail));
                    }}
                />
                <ButtonLink
                    startIcon={<IconHelpCircle />}
                    variant='text'
                    title={formatMessage({ id: 'layout.header.faq.title' })}
                    href={`${location.origin + generatePath(routes.help, { lang })}`}
                    onClick={logActionHelp}
                    target='_blank'
                    tabIndex={1}
                />
                <LogoutButton />
            </Styled.IconButtons>
        </Stack>
    );
};
