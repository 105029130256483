import { useState } from 'react';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import { useSession } from '@workspace/auth';
import { Button, IconLogOut01 } from '@workspace/ui';

import { routes } from '~constants';
import { LogoutDialog } from '~modules/layout/components/HeaderAuthNavigation/LogoutButton/LogoutDialog';
import { LogActions } from '~modules/session-log/constants';
import { useLogAction } from '~modules/session-log/hooks';

export const LogoutButton = () => {
    const { signOut } = useSession();
    const logActionLogout = useLogAction(LogActions.SYSTEM_LOGOUT, { wait: false });
    const { push } = useRouter();
    const [dialogOpen, setDialogOpen] = useState(false);

    const { formatMessage } = useIntl();

    async function handleLogout() {
        await logActionLogout();
        await signOut();
        await push(routes.login);
    }

    return (
        <>
            <Button
                startIcon={<IconLogOut01 />}
                variant='text'
                onClick={() => setDialogOpen(true)}
                title={formatMessage({ id: 'layout.header.logout' })}
                tabIndex={1}
            />
            <LogoutDialog open={dialogOpen} onConfirm={handleLogout} onCancel={() => setDialogOpen(false)} />
        </>
    );
};
