/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    BooleanResponseModel,
    BtCurrencyRequestModel,
    BtCurrencyResponseModel,
    CertificateWarningLimitModel,
    ComboModel,
    EfcvSupplierCustomerLimitModelBody,
    IntegerResponseModel,
    MerumRequestModel,
    PinSetModel,
    PinSetResponseModel,
    SaveInvoiceRequestModel,
    SaveInvoiceResponseModel,
    SessionActionRequestModel,
    SetUsersPhoneNumberRequestModel,
    SetUsersPhoneNumberResponseModel,
    StringRequestModel,
    StringResponseModel,
    ViewDataRequestModelOfEfcvBoundInvoiceExtendedModel,
    ViewDataRequestModelOfEfcvOpenInvoiceModelBody,
    ViewDataResultModelOfEfcvBoundInvoiceExtendedModel,
    ViewDataResultModelOfEfcvOpenInvoiceModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @deprecated
 * @summary Get list of invoices for the bound invoices tab on invoice detail
 */
export const dataService_findBoundInvoice = (
    viewDataRequestModelOfEfcvBoundInvoiceExtendedModel: BodyType<ViewDataRequestModelOfEfcvBoundInvoiceExtendedModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvBoundInvoiceExtendedModel | void>(
        {
            url: `/factoring/dataservice/findBoundInvoice`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvBoundInvoiceExtendedModel,
        },
        options,
    );
};

export const getDataServiceFindBoundInvoiceMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_findBoundInvoice>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvBoundInvoiceExtendedModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dataService_findBoundInvoice>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvBoundInvoiceExtendedModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_findBoundInvoice>>,
        { data: BodyType<ViewDataRequestModelOfEfcvBoundInvoiceExtendedModel> }
    > = props => {
        const { data } = props ?? {};

        return dataService_findBoundInvoice(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceFindBoundInvoiceMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_findBoundInvoice>>
>;
export type DataServiceFindBoundInvoiceMutationBody = BodyType<ViewDataRequestModelOfEfcvBoundInvoiceExtendedModel>;
export type DataServiceFindBoundInvoiceMutationError = ErrorType<unknown>;

/**
 * @deprecated
 * @summary Get list of invoices for the bound invoices tab on invoice detail
 */
export const useDataServiceFindBoundInvoice = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_findBoundInvoice>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvBoundInvoiceExtendedModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceFindBoundInvoiceMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get list of open invoices
 */
export const dataService_findOpenInvoice = (
    viewDataRequestModelOfEfcvOpenInvoiceModelBody: BodyType<ViewDataRequestModelOfEfcvOpenInvoiceModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvOpenInvoiceModel | void>(
        {
            url: `/factoring/dataservice/findOpenInvoice`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvOpenInvoiceModelBody,
        },
        options,
    );
};

export const getDataServiceFindOpenInvoiceMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_findOpenInvoice>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvOpenInvoiceModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dataService_findOpenInvoice>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvOpenInvoiceModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_findOpenInvoice>>,
        { data: BodyType<ViewDataRequestModelOfEfcvOpenInvoiceModelBody> }
    > = props => {
        const { data } = props ?? {};

        return dataService_findOpenInvoice(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceFindOpenInvoiceMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_findOpenInvoice>>
>;
export type DataServiceFindOpenInvoiceMutationBody = BodyType<ViewDataRequestModelOfEfcvOpenInvoiceModelBody>;
export type DataServiceFindOpenInvoiceMutationError = ErrorType<unknown>;

/**
 * @summary Get list of open invoices
 */
export const useDataServiceFindOpenInvoice = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_findOpenInvoice>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvOpenInvoiceModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceFindOpenInvoiceMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get list of distinct currencies from the point of supplier or customer from all their business cases
 */
export const dataService_getBusinessTransactionCurrencies = (
    btCurrencyRequestModel: BodyType<BtCurrencyRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<BtCurrencyResponseModel | void>(
        {
            url: `/factoring/dataservice/getBusinessTransactionCurrencies`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: btCurrencyRequestModel,
        },
        options,
    );
};

export const getDataServiceGetBusinessTransactionCurrenciesMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_getBusinessTransactionCurrencies>>,
        TError,
        { data: BodyType<BtCurrencyRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dataService_getBusinessTransactionCurrencies>>,
    TError,
    { data: BodyType<BtCurrencyRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_getBusinessTransactionCurrencies>>,
        { data: BodyType<BtCurrencyRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return dataService_getBusinessTransactionCurrencies(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceGetBusinessTransactionCurrenciesMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_getBusinessTransactionCurrencies>>
>;
export type DataServiceGetBusinessTransactionCurrenciesMutationBody = BodyType<BtCurrencyRequestModel>;
export type DataServiceGetBusinessTransactionCurrenciesMutationError = ErrorType<unknown>;

/**
 * @summary Get list of distinct currencies from the point of supplier or customer from all their business cases
 */
export const useDataServiceGetBusinessTransactionCurrencies = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_getBusinessTransactionCurrencies>>,
        TError,
        { data: BodyType<BtCurrencyRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceGetBusinessTransactionCurrenciesMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get day limits for displaying certificate expiration warnings
 */
export const dataService_getCertificateWarningLimit = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<CertificateWarningLimitModel | void>(
        { url: `/factoring/dataservice/getCertificateWarningLimit`, method: 'POST' },
        options,
    );
};

export const getDataServiceGetCertificateWarningLimitMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_getCertificateWarningLimit>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<Awaited<ReturnType<typeof dataService_getCertificateWarningLimit>>, TError, void, TContext> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_getCertificateWarningLimit>>,
        void
    > = () => {
        return dataService_getCertificateWarningLimit(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceGetCertificateWarningLimitMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_getCertificateWarningLimit>>
>;

export type DataServiceGetCertificateWarningLimitMutationError = ErrorType<unknown>;

/**
 * @summary Get day limits for displaying certificate expiration warnings
 */
export const useDataServiceGetCertificateWarningLimit = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_getCertificateWarningLimit>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceGetCertificateWarningLimitMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get list of distinct currencies for the non-financed invoices filter
 */
export const dataService_getCurrenciesForNonFinancedInvoiceFilter = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<BtCurrencyResponseModel | void>(
        { url: `/factoring/dataservice/getCurrenciesForNonFinancedInvoiceFilter`, method: 'POST' },
        options,
    );
};

export const getDataServiceGetCurrenciesForNonFinancedInvoiceFilterMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_getCurrenciesForNonFinancedInvoiceFilter>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dataService_getCurrenciesForNonFinancedInvoiceFilter>>,
    TError,
    void,
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_getCurrenciesForNonFinancedInvoiceFilter>>,
        void
    > = () => {
        return dataService_getCurrenciesForNonFinancedInvoiceFilter(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceGetCurrenciesForNonFinancedInvoiceFilterMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_getCurrenciesForNonFinancedInvoiceFilter>>
>;

export type DataServiceGetCurrenciesForNonFinancedInvoiceFilterMutationError = ErrorType<unknown>;

/**
 * @summary Get list of distinct currencies for the non-financed invoices filter
 */
export const useDataServiceGetCurrenciesForNonFinancedInvoiceFilter = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_getCurrenciesForNonFinancedInvoiceFilter>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceGetCurrenciesForNonFinancedInvoiceFilterMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get certificate request data matching currently used certificate
 */
export const dataService_getCurrentCertificatesRequest = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<MerumRequestModel | void>(
        { url: `/factoring/dataservice/getCurrentCertificatesRequest`, method: 'POST' },
        options,
    );
};

export const getDataServiceGetCurrentCertificatesRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_getCurrentCertificatesRequest>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dataService_getCurrentCertificatesRequest>>,
    TError,
    void,
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_getCurrentCertificatesRequest>>,
        void
    > = () => {
        return dataService_getCurrentCertificatesRequest(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceGetCurrentCertificatesRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_getCurrentCertificatesRequest>>
>;

export type DataServiceGetCurrentCertificatesRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get certificate request data matching currently used certificate
 */
export const useDataServiceGetCurrentCertificatesRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_getCurrentCertificatesRequest>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceGetCurrentCertificatesRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get list of distinct currencies for the issued invoices filter
 */
export const dataService_getIssuedInvoiceCurrencies = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<BtCurrencyResponseModel | void>(
        { url: `/factoring/dataservice/getIssuedInvoiceCurrencies`, method: 'POST' },
        options,
    );
};

export const getDataServiceGetIssuedInvoiceCurrenciesMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_getIssuedInvoiceCurrencies>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<Awaited<ReturnType<typeof dataService_getIssuedInvoiceCurrencies>>, TError, void, TContext> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_getIssuedInvoiceCurrencies>>,
        void
    > = () => {
        return dataService_getIssuedInvoiceCurrencies(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceGetIssuedInvoiceCurrenciesMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_getIssuedInvoiceCurrencies>>
>;

export type DataServiceGetIssuedInvoiceCurrenciesMutationError = ErrorType<unknown>;

/**
 * @summary Get list of distinct currencies for the issued invoices filter
 */
export const useDataServiceGetIssuedInvoiceCurrencies = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_getIssuedInvoiceCurrencies>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceGetIssuedInvoiceCurrenciesMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * Only these fields are used: IdCustomer, ProductSuppCustType, FctTyp, DoId, IdProduct
 * @summary Get distinct products matching the request model from the supplier and customer business transactions table
 */
export const dataService_getProducts = (
    efcvSupplierCustomerLimitModelBody: BodyType<EfcvSupplierCustomerLimitModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ComboModel[] | void>(
        {
            url: `/factoring/dataservice/getProducts`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvSupplierCustomerLimitModelBody,
        },
        options,
    );
};

export const getDataServiceGetProductsMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_getProducts>>,
        TError,
        { data: BodyType<EfcvSupplierCustomerLimitModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dataService_getProducts>>,
    TError,
    { data: BodyType<EfcvSupplierCustomerLimitModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_getProducts>>,
        { data: BodyType<EfcvSupplierCustomerLimitModelBody> }
    > = props => {
        const { data } = props ?? {};

        return dataService_getProducts(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceGetProductsMutationResult = NonNullable<Awaited<ReturnType<typeof dataService_getProducts>>>;
export type DataServiceGetProductsMutationBody = BodyType<EfcvSupplierCustomerLimitModelBody>;
export type DataServiceGetProductsMutationError = ErrorType<unknown>;

/**
 * @summary Get distinct products matching the request model from the supplier and customer business transactions table
 */
export const useDataServiceGetProducts = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_getProducts>>,
        TError,
        { data: BodyType<EfcvSupplierCustomerLimitModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceGetProductsMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get current session timeout in seconds
 */
export const dataService_getSessionTimeout = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<IntegerResponseModel | void>(
        { url: `/factoring/dataservice/getSessionTimeout`, method: 'POST' },
        options,
    );
};

export const getDataServiceGetSessionTimeoutMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof dataService_getSessionTimeout>>, TError, void, TContext>;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<Awaited<ReturnType<typeof dataService_getSessionTimeout>>, TError, void, TContext> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof dataService_getSessionTimeout>>, void> = () => {
        return dataService_getSessionTimeout(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceGetSessionTimeoutMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_getSessionTimeout>>
>;

export type DataServiceGetSessionTimeoutMutationError = ErrorType<unknown>;

/**
 * @summary Get current session timeout in seconds
 */
export const useDataServiceGetSessionTimeout = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof dataService_getSessionTimeout>>, TError, void, TContext>;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceGetSessionTimeoutMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get user's phone number
 */
export const dataService_getUsersPhoneNumber = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<StringResponseModel | void>(
        { url: `/factoring/dataservice/getUsersPhoneNumber`, method: 'POST' },
        options,
    );
};

export const getDataServiceGetUsersPhoneNumberMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof dataService_getUsersPhoneNumber>>, TError, void, TContext>;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<Awaited<ReturnType<typeof dataService_getUsersPhoneNumber>>, TError, void, TContext> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof dataService_getUsersPhoneNumber>>, void> = () => {
        return dataService_getUsersPhoneNumber(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceGetUsersPhoneNumberMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_getUsersPhoneNumber>>
>;

export type DataServiceGetUsersPhoneNumberMutationError = ErrorType<unknown>;

/**
 * @summary Get user's phone number
 */
export const useDataServiceGetUsersPhoneNumber = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof dataService_getUsersPhoneNumber>>, TError, void, TContext>;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceGetUsersPhoneNumberMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Check if new issued certificate is available for the user to download
 */
export const dataService_isNewCertificateAvailable = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<BooleanResponseModel | void>(
        { url: `/factoring/dataservice/isNewCertificateAvailable`, method: 'POST' },
        options,
    );
};

export const getDataServiceIsNewCertificateAvailableMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_isNewCertificateAvailable>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<Awaited<ReturnType<typeof dataService_isNewCertificateAvailable>>, TError, void, TContext> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_isNewCertificateAvailable>>,
        void
    > = () => {
        return dataService_isNewCertificateAvailable(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceIsNewCertificateAvailableMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_isNewCertificateAvailable>>
>;

export type DataServiceIsNewCertificateAvailableMutationError = ErrorType<unknown>;

/**
 * @summary Check if new issued certificate is available for the user to download
 */
export const useDataServiceIsNewCertificateAvailable = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_isNewCertificateAvailable>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceIsNewCertificateAvailableMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Log user action in system
 */
export const dataService_logSessionAction = (
    sessionActionRequestModel: BodyType<SessionActionRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<boolean | void>(
        {
            url: `/factoring/dataservice/logSessionAction`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: sessionActionRequestModel,
        },
        options,
    );
};

export const getDataServiceLogSessionActionMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_logSessionAction>>,
        TError,
        { data: BodyType<SessionActionRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dataService_logSessionAction>>,
    TError,
    { data: BodyType<SessionActionRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_logSessionAction>>,
        { data: BodyType<SessionActionRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return dataService_logSessionAction(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceLogSessionActionMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_logSessionAction>>
>;
export type DataServiceLogSessionActionMutationBody = BodyType<SessionActionRequestModel>;
export type DataServiceLogSessionActionMutationError = ErrorType<unknown>;

/**
 * @summary Log user action in system
 */
export const useDataServiceLogSessionAction = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_logSessionAction>>,
        TError,
        { data: BodyType<SessionActionRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceLogSessionActionMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Log error description for current session
 */
export const dataService_logSessionErrDescription = (
    stringRequestModel: BodyType<StringRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<boolean | void>(
        {
            url: `/factoring/dataservice/logSessionErrDescription`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: stringRequestModel,
        },
        options,
    );
};

export const getDataServiceLogSessionErrDescriptionMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_logSessionErrDescription>>,
        TError,
        { data: BodyType<StringRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dataService_logSessionErrDescription>>,
    TError,
    { data: BodyType<StringRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_logSessionErrDescription>>,
        { data: BodyType<StringRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return dataService_logSessionErrDescription(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceLogSessionErrDescriptionMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_logSessionErrDescription>>
>;
export type DataServiceLogSessionErrDescriptionMutationBody = BodyType<StringRequestModel>;
export type DataServiceLogSessionErrDescriptionMutationError = ErrorType<unknown>;

/**
 * @summary Log error description for current session
 */
export const useDataServiceLogSessionErrDescription = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_logSessionErrDescription>>,
        TError,
        { data: BodyType<StringRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceLogSessionErrDescriptionMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Save manually entered invoice into the system
 */
export const dataService_saveInvoice = (
    saveInvoiceRequestModel: BodyType<SaveInvoiceRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<SaveInvoiceResponseModel | void>(
        {
            url: `/factoring/dataservice/saveInvoice`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: saveInvoiceRequestModel,
        },
        options,
    );
};

export const getDataServiceSaveInvoiceMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_saveInvoice>>,
        TError,
        { data: BodyType<SaveInvoiceRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dataService_saveInvoice>>,
    TError,
    { data: BodyType<SaveInvoiceRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_saveInvoice>>,
        { data: BodyType<SaveInvoiceRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return dataService_saveInvoice(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceSaveInvoiceMutationResult = NonNullable<Awaited<ReturnType<typeof dataService_saveInvoice>>>;
export type DataServiceSaveInvoiceMutationBody = BodyType<SaveInvoiceRequestModel>;
export type DataServiceSaveInvoiceMutationError = ErrorType<unknown>;

/**
 * @summary Save manually entered invoice into the system
 */
export const useDataServiceSaveInvoice = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_saveInvoice>>,
        TError,
        { data: BodyType<SaveInvoiceRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceSaveInvoiceMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Set user's pin for blocking certificates
 */
export const dataService_setCertificateBlockingPin = (
    pinSetModel: BodyType<PinSetModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<PinSetResponseModel | void>(
        {
            url: `/factoring/dataservice/setCertificateBlockingPin`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: pinSetModel,
        },
        options,
    );
};

export const getDataServiceSetCertificateBlockingPinMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_setCertificateBlockingPin>>,
        TError,
        { data: BodyType<PinSetModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dataService_setCertificateBlockingPin>>,
    TError,
    { data: BodyType<PinSetModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_setCertificateBlockingPin>>,
        { data: BodyType<PinSetModel> }
    > = props => {
        const { data } = props ?? {};

        return dataService_setCertificateBlockingPin(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceSetCertificateBlockingPinMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_setCertificateBlockingPin>>
>;
export type DataServiceSetCertificateBlockingPinMutationBody = BodyType<PinSetModel>;
export type DataServiceSetCertificateBlockingPinMutationError = ErrorType<unknown>;

/**
 * @summary Set user's pin for blocking certificates
 */
export const useDataServiceSetCertificateBlockingPin = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_setCertificateBlockingPin>>,
        TError,
        { data: BodyType<PinSetModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceSetCertificateBlockingPinMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Set ID of supplier or customer selected during login to the session
 */
export const dataService_setDoid = (
    stringRequestModel: BodyType<StringRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<BooleanResponseModel | void>(
        {
            url: `/factoring/dataservice/setDoid`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: stringRequestModel,
        },
        options,
    );
};

export const getDataServiceSetDoidMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_setDoid>>,
        TError,
        { data: BodyType<StringRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dataService_setDoid>>,
    TError,
    { data: BodyType<StringRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_setDoid>>,
        { data: BodyType<StringRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return dataService_setDoid(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceSetDoidMutationResult = NonNullable<Awaited<ReturnType<typeof dataService_setDoid>>>;
export type DataServiceSetDoidMutationBody = BodyType<StringRequestModel>;
export type DataServiceSetDoidMutationError = ErrorType<unknown>;

/**
 * @summary Set ID of supplier or customer selected during login to the session
 */
export const useDataServiceSetDoid = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_setDoid>>,
        TError,
        { data: BodyType<StringRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceSetDoidMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Store information about the expiration notice being shown to the user into the system
 */
export const dataService_setExpirationNoticeDisplayDate = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<BooleanResponseModel | void>(
        { url: `/factoring/dataservice/setExpirationNoticeDisplayDate`, method: 'POST' },
        options,
    );
};

export const getDataServiceSetExpirationNoticeDisplayDateMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_setExpirationNoticeDisplayDate>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dataService_setExpirationNoticeDisplayDate>>,
    TError,
    void,
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_setExpirationNoticeDisplayDate>>,
        void
    > = () => {
        return dataService_setExpirationNoticeDisplayDate(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceSetExpirationNoticeDisplayDateMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_setExpirationNoticeDisplayDate>>
>;

export type DataServiceSetExpirationNoticeDisplayDateMutationError = ErrorType<unknown>;

/**
 * @summary Store information about the expiration notice being shown to the user into the system
 */
export const useDataServiceSetExpirationNoticeDisplayDate = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_setExpirationNoticeDisplayDate>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceSetExpirationNoticeDisplayDateMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Set user's phone number
 */
export const dataService_setUsersPhoneNumber = (
    setUsersPhoneNumberRequestModel: BodyType<SetUsersPhoneNumberRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<SetUsersPhoneNumberResponseModel | void>(
        {
            url: `/factoring/dataservice/setUsersPhoneNumber`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: setUsersPhoneNumberRequestModel,
        },
        options,
    );
};

export const getDataServiceSetUsersPhoneNumberMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_setUsersPhoneNumber>>,
        TError,
        { data: BodyType<SetUsersPhoneNumberRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dataService_setUsersPhoneNumber>>,
    TError,
    { data: BodyType<SetUsersPhoneNumberRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dataService_setUsersPhoneNumber>>,
        { data: BodyType<SetUsersPhoneNumberRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return dataService_setUsersPhoneNumber(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceSetUsersPhoneNumberMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_setUsersPhoneNumber>>
>;
export type DataServiceSetUsersPhoneNumberMutationBody = BodyType<SetUsersPhoneNumberRequestModel>;
export type DataServiceSetUsersPhoneNumberMutationError = ErrorType<unknown>;

/**
 * @summary Set user's phone number
 */
export const useDataServiceSetUsersPhoneNumber = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dataService_setUsersPhoneNumber>>,
        TError,
        { data: BodyType<SetUsersPhoneNumberRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceSetUsersPhoneNumberMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Checks if a certificate is about to expire and a notice should be shown to the user
 */
export const dataService_showExpirationNotice = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<BooleanResponseModel | void>(
        { url: `/factoring/dataservice/showExpirationNotice`, method: 'POST' },
        options,
    );
};

export const getDataServiceShowExpirationNoticeMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof dataService_showExpirationNotice>>, TError, void, TContext>;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<Awaited<ReturnType<typeof dataService_showExpirationNotice>>, TError, void, TContext> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof dataService_showExpirationNotice>>, void> = () => {
        return dataService_showExpirationNotice(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DataServiceShowExpirationNoticeMutationResult = NonNullable<
    Awaited<ReturnType<typeof dataService_showExpirationNotice>>
>;

export type DataServiceShowExpirationNoticeMutationError = ErrorType<unknown>;

/**
 * @summary Checks if a certificate is about to expire and a notice should be shown to the user
 */
export const useDataServiceShowExpirationNotice = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof dataService_showExpirationNotice>>, TError, void, TContext>;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDataServiceShowExpirationNoticeMutationOptions(options);

    return useMutation(mutationOptions);
};
