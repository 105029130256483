/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type GetCertificateChainForRequestResponseModelResult =
    (typeof GetCertificateChainForRequestResponseModelResult)[keyof typeof GetCertificateChainForRequestResponseModelResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCertificateChainForRequestResponseModelResult = {
    OK: 'OK',
    NO_ISSUED_CERTIFICATE_FOUND: 'NO_ISSUED_CERTIFICATE_FOUND',
    TOO_MANY_ISSUED_CERTIFICATES_FOUND: 'TOO_MANY_ISSUED_CERTIFICATES_FOUND',
    BLOCKED_REQUEST: 'BLOCKED_REQUEST',
} as const;
