import { CheckCertificateResponseModelGlobalResponse } from '@workspace/api';

import type { MessageKey } from '~modules/intl';

export function getCertificateErrorMessage(response: CheckCertificateResponseModelGlobalResponse | undefined) {
    let result: MessageKey[];

    switch (response) {
        case 'CERTIFICATE_NOT_FOUND':
            result = ['error.certificate.unkownCertificate'];
            break;
        case 'NOT_BOUND_TO_USER':
            result = ['error.certificate.wrongCredentialsCombo'];
            break;
        case 'NOT_YET_VALID':
            result = ['error.certificate.notYetValid'];
            break;
        case 'EXPIRED':
            result = ['error.certificate.expired'];
            break;
        case 'REVOKED':
            result = ['error.certificate.revoked'];
            break;
        case 'BLOCKED':
            result = ['error.certificate.blockedAccess'];
            break;
        default:
            result = ['error.contactAdmin'];
    }
    if (
        response == CheckCertificateResponseModelGlobalResponse.CERTIFICATE_NOT_FOUND ||
        response == CheckCertificateResponseModelGlobalResponse.NOT_BOUND_TO_USER ||
        response == CheckCertificateResponseModelGlobalResponse.NOT_YET_VALID ||
        response == CheckCertificateResponseModelGlobalResponse.EXPIRED ||
        response == CheckCertificateResponseModelGlobalResponse.REVOKED
    )
        result.push('error.certificate.checkNewCertificate');

    return result;
}
