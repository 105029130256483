/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type ErrorType } from '../../fetcher/fetcher';
import type { PermData } from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Get permission (not security) data based on current user and selected supplier/customer
 */
export const permissionService_getPermissionData = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<PermData | void>({ url: `/factoring/permissionservice/getPermissionData`, method: 'POST' }, options);
};

export const getPermissionServiceGetPermissionDataMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof permissionService_getPermissionData>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<Awaited<ReturnType<typeof permissionService_getPermissionData>>, TError, void, TContext> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof permissionService_getPermissionData>>, void> = () => {
        return permissionService_getPermissionData(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PermissionServiceGetPermissionDataMutationResult = NonNullable<
    Awaited<ReturnType<typeof permissionService_getPermissionData>>
>;

export type PermissionServiceGetPermissionDataMutationError = ErrorType<unknown>;

/**
 * @summary Get permission (not security) data based on current user and selected supplier/customer
 */
export const usePermissionServiceGetPermissionData = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof permissionService_getPermissionData>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPermissionServiceGetPermissionDataMutationOptions(options);

    return useMutation(mutationOptions);
};
