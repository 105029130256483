import { IconButton, DialogContent as MuiDialogContent, styled, Typography } from '@mui/material';

import type { MessageType } from '~modules/message-popup/types';

export const DialogBody = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    minWidth: 520,
    borderRadius: theme.shape.borderRadius,
    whiteSpace: 'pre-line',
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
    padding: theme.spacing(1.5, 2),
    display: 'flex',
    minHeight: 140,
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    gap: theme.spacing(1),
    flexDirection: 'column',
}));

export const DialogHeader = styled('div', {
    shouldForwardProp: propName => propName !== 'type',
})<{ type: MessageType }>(({ theme, type }) => ({
    minHeight: theme.spacing(2),
    backgroundColor: type === 'error' ? theme.palette.error.main : theme.color.buttons.primary.green,
    paddingInline: theme.spacing(0.25),
    display: 'flex',
    justifyContent: 'flex-end',
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.common.white,
}));

export const Content = styled(Typography)(({ theme }) => ({
    padding: 'unset',
    color: theme.palette.text.secondary,
}));

export const IconWrapper = styled('div')(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

export const ButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});
