import { AuthError } from '@workspace/errors';

import { PASSWORD_RESET_TIMEOUT } from './config/password';
import { decrypt, encrypt } from './pki/symetricEncryption';
import { createTimer } from './utils/timer';

let key: CryptoKey | null = null;
let iv: ArrayBuffer | null = null;
let encryptedPassword: ArrayBuffer | null = null;
let onChangeCallback: ((hasPassword: boolean) => void) | null = null;

const timer = createTimer(PASSWORD_RESET_TIMEOUT);

/**
 * Store encrypted password in runtime app memory
 */
export async function storePassword(password: string | ArrayBuffer) {
    const passwordBuffer = typeof password === 'string' ? new TextEncoder().encode(password) : password;

    const result = await encrypt(passwordBuffer);

    key = result.key;
    iv = result.iv;
    encryptedPassword = result.encryptedData;

    onChangeCallback?.(true);

    timer.start(resetStoredPassword);
}

export function restartPasswordClearTimer() {
    timer.start(resetStoredPassword);
}

/**
 * Is password encrypted in runtime app memory?
 */
export async function hasStoredPassword() {
    return Boolean(encryptedPassword && key && iv);
}

/**
 * Reset password in runtime app memory
 */
export async function resetStoredPassword() {
    timer.stop();

    encryptedPassword = null;
    key = null;
    iv = null;

    onChangeCallback?.(false);
}

/**
 * Decrypt password from runtime app memory
 */
export async function getStoredPassword() {
    if (!encryptedPassword || !key || !iv) {
        resetStoredPassword();
        throw new AuthError('PASSWORD_NOT_SET');
    }

    return decrypt(encryptedPassword, { key, iv });
}

export function onStoredPasswordChange(cb: (hasPassword: boolean) => void) {
    onChangeCallback = cb;

    return () => {
        onChangeCallback = null;
    };
}
