import { useSession } from '@workspace/auth';

import { useStartTwoFactorAuth } from '~modules/auth/hooks';
import { useLang } from '~modules/intl';

export function useStartLoginProcess() {
    const { signIn } = useSession();
    const locale = useLang();
    const startTwoFactorAuth = useStartTwoFactorAuth();

    return async function startLoginProcess(username: string) {
        const result = await signIn({
            username: username!,
            locale,
        });

        if (result.status === '2FA-requirement') {
            await startTwoFactorAuth(result.data);
        }

        return result;
    };
}
