import { useMemo, useState } from 'react';

import { AuthContext, type AuthSession } from '../../../contexts';

export interface AuthSessionProviderProps {
    children: React.ReactNode;
    initialSession?: AuthSession;
}

export const AuthSessionProvider = ({
    children,
    initialSession = {
        data: null,
        status: 'loading',
    },
}: AuthSessionProviderProps) => {
    const [session, setSession] = useState<AuthSession>(initialSession);

    const ctx = useMemo(
        () =>
            ({
                session,
                setSession,
            }) as const,
        [session],
    );

    return <AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>;
};
