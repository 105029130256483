/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type BtCurrencyRequestModelFilterBy =
    (typeof BtCurrencyRequestModelFilterBy)[keyof typeof BtCurrencyRequestModelFilterBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BtCurrencyRequestModelFilterBy = {
    DOID: 'DOID',
    ODID: 'ODID',
} as const;
