import { authServices_logout, removeCSRFTokenInterceptor } from '@workspace/api';
import { worker } from '@workspace/certificates';
import { AuthError, isAuthError } from '@workspace/errors';
import { logger } from '@workspace/logger';

export async function signOut() {
    try {
        const result = await authServices_logout();

        if (result.data === false) {
            throw new AuthError('LOGOUT_FAILED');
        }

        await removeCSRFTokenInterceptor();
    } catch (e) {
        if (isAuthError(e)) {
            throw e;
        }

        logger.error(e);
    } finally {
        await worker.resetStoredPassword();
    }
}
