import { createPKCS12Filename, storePkcs12 } from '../lib';
import { createPersonalInformationContainer, parseCertificatesChain } from '../lib/pki';
import { loadCertificates } from './loadCertificates';

/**
 * - Import issued certificate (and its whole certificate chain) into the PKCS #12 keystore.
 * - The self-signed certificate will be replaced with the imported (issued) certificate.
 */
export async function importIssuedCertificate(certificateChainPem: string, selfSignedCertificateSerialNumber: string) {
    const issuedCertificateChain = await parseCertificatesChain(certificateChainPem);

    const certificates = await loadCertificates();

    // Replace the self-signed certificate with the new one
    const keys = certificates.map(cert => cert.privateKey);
    const certificateChains = certificates.map(cert =>
        cert.serialNumber === selfSignedCertificateSerialNumber ? issuedCertificateChain : cert.certificateChain,
    );

    // Create a new PKCS #12 file with the new certificate chain
    const newPkcs12 = await createPersonalInformationContainer(keys, certificateChains);

    await storePkcs12({
        pkcs12: newPkcs12.toString('base64'),
        name: createPKCS12Filename(issuedCertificateChain[0]),
    });
}
