import { Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { FieldsStack, TextField } from '@workspace/ui';

import { appConfig } from '~config';
import { routes } from '~constants';
import { QueryError, QueryLoader } from '~modules/api/components';
import { useBlockCertificate, useUsername } from '~modules/auth/hooks';
import { CaptchaWidgetContent } from '~modules/captcha/components/CaptchaWidgetContent';
import { useFetchCaptcha } from '~modules/captcha/hooks';
import { FormBackButton, FormSubmitButton, PinField } from '~modules/form/components';
import { useLocalizedResolver } from '~modules/form/hooks';
import { ConfirmationDialog } from '~modules/prompt/components/ConfirmationDialog/ConfirmationDialog';

import { certificateBlockingFormSchema, type CertificateBlockingFormSchema } from './schema';

export const CertificateBlockingForm = () => {
    const { getUsername } = useUsername();
    const form = useForm<CertificateBlockingFormSchema>({
        resolver: useLocalizedResolver(certificateBlockingFormSchema),
        defaultValues: async () => {
            return {
                username: (await getUsername()) ?? '',
                captcha: '',
                pin: '',
            };
        },
        mode: appConfig.formValidationMode,
    });

    const { handleSubmit } = form;
    const captchaResult = useFetchCaptcha();

    const onSubmit = useBlockCertificate(captchaResult.data?.hash!, form.resetField);

    return (
        <QueryError result={captchaResult} message={{ id: 'general.error' }}>
            <QueryLoader result={captchaResult} height={10}>
                <FormProvider<CertificateBlockingFormSchema> {...form}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ConfirmationDialog />
                        <FieldsStack>
                            <TextField<CertificateBlockingFormSchema>
                                name='username'
                                label={<FormattedMessage id='certificateBlocking.form.user' />}
                                fullWidth
                            />
                            <PinField<CertificateBlockingFormSchema> name='pin' />
                            <CaptchaWidgetContent result={captchaResult} />
                        </FieldsStack>
                        <Stack direction='row' justifyContent='space-between' mt={1.5}>
                            <FormBackButton backRoute={routes.home} />
                            <FormSubmitButton color='error'>
                                <FormattedMessage id='certificateBlocking.form.buttons.block' />
                            </FormSubmitButton>
                        </Stack>
                    </form>
                </FormProvider>
            </QueryLoader>
        </QueryError>
    );
};
