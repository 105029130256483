import { getApiOrigin, GetFileUsingGETType } from '@workspace/api';

import { useDownload } from '~hooks';

export type DocumentDownloadParams = {
    path: string;
    name: string;
};

export function useDownloadDocument() {
    const downloadFile = useDownload();

    const downloadDocument = ({ path, name }: DocumentDownloadParams) => {
        const downloadUrl = new URL(`${getApiOrigin()}${path}`);

        downloadFile(downloadUrl);
    };

    return downloadDocument;
}
