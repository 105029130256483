import { DialogTitle, styled, Typography } from '@mui/material';

import { sizeAndHeight } from '@workspace/ui';

export const DialogBody = styled('div')(({ theme }) => ({
    minWidth: 436,
    padding: theme.spacing(3, 5),
}));

export const Title = styled(DialogTitle)(({ theme }) => ({
    padding: 'unset',
    marginBottom: theme.spacing(2),
}));

export const Caption = styled(Typography)(({ theme }) => ({
    ...sizeAndHeight(16, 24),
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(0.75),
}));
