import { AuthError } from '@workspace/errors';

import { pkcs12Storage } from '../lib';
import { storePassword } from '../lib/password';
import { encryptPKCS12, parsePersonalInformationContainer } from '../lib/pki';

export async function changePKCS12Password(oldPassword: string, newPassword: string) {
    const oldPasswordPkcs12b64 = await pkcs12Storage.get('content');

    if (!oldPasswordPkcs12b64) {
        throw new AuthError('NO_CERT_IN_STORAGE');
    }

    await storePassword(oldPassword);
    const pkcs12 = await parsePersonalInformationContainer(oldPasswordPkcs12b64);

    await storePassword(newPassword);
    await encryptPKCS12(pkcs12);

    await pkcs12Storage.set('content', pkcs12.toString('base64'));
}
