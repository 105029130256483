import { useEffect } from 'react';
import { capture, OutputType } from 'html-screen-capture-js';
import JSZip from 'jszip';

import { coreClientErrorLog_insert } from '@workspace/api';
import { env } from '@workspace/env';

/**
 * Bind to window.error event and log uncaught errors globally.
 */
export const useErrorLogger = () => {
    useEffect(() => {
        const errorHandler = (errorEvent: ErrorEvent) => {
            sendErrorToServer(errorEvent.message, serializeError(errorEvent));
        };

        window.addEventListener('error', errorHandler);

        return () => {
            window.removeEventListener('error', errorHandler);
        };
    }, []);
};

const NEW_LINES = '\n\n';

export const handleErrorBoundaryError = (error: Error, info: React.ErrorInfo) => {
    sendErrorToServer(error.message, serializeError(error) + NEW_LINES + JSON.stringify(info));
};

export const logErrorToApi = (error: any) => {
    sendErrorToServer(error.message, serializeError(error));
};

/**
 * Custom serializer for Errors, since JSON.stringify() does not work with them out of the box.
 *
 * @param error Error or ErrorEvent instance
 */
const serializeError = (error: any): string => {
    try {
        return JSON.stringify(
            error,
            function debugReplacer(key, value) {
                // Define an array of properties that are useful for debugging
                const debugProperties = ['message', 'stack', 'lineNumber', 'columnNumber', 'name', 'fileName', 'cause'];

                // If the value is an object (including errors), filter its properties
                if (value && typeof value === 'object') {
                    const debugObject: { [key: string]: any } = {};

                    for (const prop of debugProperties) {
                        if (value[prop] !== undefined) {
                            // Recursively process the cause property if it is an object
                            if (prop === 'cause' && typeof value === 'object') {
                                debugObject[prop] = JSON.stringify(value[prop], debugReplacer, 2);
                            } else {
                                debugObject[prop] = value[prop];
                            }
                        }
                    }

                    return debugObject;
                }

                return value;
            },
            2,
        );
    } catch (e: any) {
        return `Failed to stringify error object (${error?.message}). Error: ${e?.message}`;
    }
};

/**
 * Create html snapshot of the page and return a Base64 zip file containing the html.
 */
const getPageHtmlAsZip = async () => {
    const pageHtml = capture(OutputType.STRING) as string;

    if (pageHtml) {
        const jsZip = new JSZip();

        jsZip.file('screen-capture.html', pageHtml);

        return await jsZip.generateAsync({ type: 'base64', compression: 'DEFLATE' });
    }

    return null;
};

/**
 * Send error to server, but only for production builds.
 *
 * @param errorMessage Caught error (message only)
 * @param clientLogs Additional client logs
 */
const sendErrorToServer = async (errorMessage: string, clientLogs?: string) => {
    if (env.NEXT_PUBLIC_BUILD_ENV === 'production') {
        const pageHtmlZip = await getPageHtmlAsZip();

        // add additional information to client logs
        let clientLogsToSend = `URL: ${location.href}`;

        clientLogsToSend += '\n' + `User Agent: ${navigator.userAgent}`;
        if (clientLogs) {
            clientLogsToSend += NEW_LINES + clientLogs;
        }

        const coreClientErrorLogModel = {
            createTime: new Date().getTime().toString(),
            exception: errorMessage,
            logClient: clientLogsToSend,
            currentHtml: pageHtmlZip || undefined,
        };

        coreClientErrorLog_insert(coreClientErrorLogModel);
    }
};
