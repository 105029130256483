import { useCallback } from 'react';

import type { AuthContextType } from '../contexts';
import { localSignIn } from '../services/localSignIn';

export function useLocalSign(setSession: AuthContextType['setSession']) {
    return useCallback(async () => {
        await localSignIn();

        // If the session is already authenticated, we don't want to be only pre-authenticated, thus not able make authenticated requests.
        setSession(session =>
            session.status === 'authenticated'
                ? session
                : {
                      status: 'pre-authenticated',
                      data: null,
                  },
        );
    }, [setSession]);
}
