import { styled } from '@mui/material';

export const Layout = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.color.table.hover,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
}));

export const Container = styled('div')<{ largeNav: boolean }>(({ theme, largeNav }) => ({
    padding: `${theme.spacing(largeNav ? 1.75 : 1, 3, 1.75)} !important`,
    backgroundColor: theme.color.table.hover,
    flex: '1 0 auto',
}));
