/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvSupplierSettlementModel,
    GetByIdRequestModelOfEfcvSupplierSettlementModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvSupplierSettlementModel,
    ViewDataResultModelOfEfcvSupplierSettlementModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const supplierSettlement_findByAll = (
    efcvSupplierSettlementModel: BodyType<EfcvSupplierSettlementModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSupplierSettlementModel[] | void>(
        {
            url: `/factoring/efcvsuppliersettlement/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvSupplierSettlementModel,
        },
        options,
    );
};

export const getSupplierSettlementFindByAllMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierSettlement_findByAll>>,
        TError,
        { data: BodyType<EfcvSupplierSettlementModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierSettlement_findByAll>>,
    TError,
    { data: BodyType<EfcvSupplierSettlementModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierSettlement_findByAll>>,
        { data: BodyType<EfcvSupplierSettlementModel> }
    > = props => {
        const { data } = props ?? {};

        return supplierSettlement_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierSettlementFindByAllMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierSettlement_findByAll>>
>;
export type SupplierSettlementFindByAllMutationBody = BodyType<EfcvSupplierSettlementModel>;
export type SupplierSettlementFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useSupplierSettlementFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierSettlement_findByAll>>,
        TError,
        { data: BodyType<EfcvSupplierSettlementModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierSettlementFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const supplierSettlement_findById = (
    getByIdRequestModelOfEfcvSupplierSettlementModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvSupplierSettlementModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSupplierSettlementModel | void>(
        {
            url: `/factoring/efcvsuppliersettlement/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvSupplierSettlementModelAndbigdecimal,
        },
        options,
    );
};

export const getSupplierSettlementFindByIdMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierSettlement_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierSettlementModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierSettlement_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvSupplierSettlementModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierSettlement_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierSettlementModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return supplierSettlement_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierSettlementFindByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierSettlement_findById>>
>;
export type SupplierSettlementFindByIdMutationBody =
    BodyType<GetByIdRequestModelOfEfcvSupplierSettlementModelAndbigdecimal>;
export type SupplierSettlementFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useSupplierSettlementFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierSettlement_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierSettlementModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierSettlementFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const supplierSettlement_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvSupplierSettlementModel | void>(
        {
            url: `/factoring/efcvsuppliersettlement/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getSupplierSettlementFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierSettlement_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierSettlement_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierSettlement_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return supplierSettlement_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierSettlementFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierSettlement_findByLightDataRequest>>
>;
export type SupplierSettlementFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type SupplierSettlementFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useSupplierSettlementFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierSettlement_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierSettlementFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const supplierSettlement_findByViewDataRequest = (
    viewDataRequestModelOfEfcvSupplierSettlementModel: BodyType<ViewDataRequestModelOfEfcvSupplierSettlementModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvSupplierSettlementModel | void>(
        {
            url: `/factoring/efcvsuppliersettlement/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvSupplierSettlementModel,
        },
        options,
    );
};

export const getSupplierSettlementFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierSettlement_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierSettlementModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierSettlement_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvSupplierSettlementModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierSettlement_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierSettlementModel> }
    > = props => {
        const { data } = props ?? {};

        return supplierSettlement_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierSettlementFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierSettlement_findByViewDataRequest>>
>;
export type SupplierSettlementFindByViewDataRequestMutationBody =
    BodyType<ViewDataRequestModelOfEfcvSupplierSettlementModel>;
export type SupplierSettlementFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useSupplierSettlementFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierSettlement_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierSettlementModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierSettlementFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
