/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type WhereItemWhereOperator = (typeof WhereItemWhereOperator)[keyof typeof WhereItemWhereOperator];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WhereItemWhereOperator = {
    AND: 'AND',
    OR: 'OR',
} as const;
