import { Stack as MuiStack, styled } from '@mui/material';
import { IconDownInput, IconUpInput } from '../../Icons';
export const Stack = styled(MuiStack)(({ theme }) => ({
    paddingBlock: theme.spacing(0.15),
    height: '1.7rem !important',
}));
export function createIcon(asc) {
    return styled(asc ? IconUpInput : IconDownInput, {
        shouldForwardProp: propName => propName !== 'active',
    })(({ theme, active }) => ({
        color: 'inherit',
        stroke: 'inherit',
        flexShrink: 1,
        ...(active && {
            color: theme.color.icons.onPrimary,
        }),
        ['&:hover']: {
            color: theme.color.icons.onPrimary,
        },
    }));
}
