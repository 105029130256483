import { useMemo, type ReactNode } from 'react';
import { QueryCache, type QueryClientConfig } from '@tanstack/react-query';

import { isAntonioError, QueryProvider } from '@workspace/api';
import { isAuthErrorWithCodes } from '@workspace/errors';

import { clientChanged } from './services/clientChanged';
import { unauthorized } from './services/unauthorized';

export interface AppQueryProviderProps {
    children: ReactNode;
}

export function AppQueryProvider({ children }: AppQueryProviderProps) {
    const queryClientConfig = useMemo<QueryClientConfig>(() => {
        return {
            queryCache: new QueryCache({
                async onError(error) {
                    if (isAntonioError(error) && error.response.status === 401) {
                        await unauthorized();
                    }

                    if (isAuthErrorWithCodes(error, ['CLIENT_CHANGED'])) {
                        await clientChanged();
                    }
                },
            }),
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                    retry: (failureCount, error) => {
                        // Don't retry quires on localhost to speed up development
                        const isLocalDevelopment =
                            process.env.NODE_ENV === 'development' && window.location.hostname === 'localhost';

                        if (isLocalDevelopment || isAuthErrorWithCodes(error, ['CLIENT_CHANGED'])) {
                            return false;
                        }

                        if (isAntonioError(error) && error.response.status === 401) {
                            return failureCount < 2;
                        }

                        return failureCount < 3;
                    },
                },
            },
        };
    }, []);

    return <QueryProvider queryClientConfig={queryClientConfig}>{children}</QueryProvider>;
}
