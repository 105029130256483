import { Tab as MUITab, Tabs as MUITabs, styled, tabClasses, tabsClasses } from '@mui/material';
export const Tabs = styled(MUITabs)(({ theme }) => ({
    backgroundColor: 'unset',
    fontSize: theme.typography.fontSize,
    minHeight: 38,
    overflow: 'visible',
    [`.${tabsClasses.scrollButtons}`]: {
        display: 'none',
    },
    [`& .${tabsClasses.fixed}.${tabsClasses.scroller}`]: {
        overflow: 'visible!important',
    },
    [`& .${tabsClasses.flexContainer}`]: {
        gap: theme.spacing(0.5),
        [`& .${tabClasses.root}`]: {
            animation: 'unset',
            minHeight: 38,
            padding: `0 ${theme.spacing(1.25)}`,
            borderRadius: '2px 2px 0 0',
            backgroundColor: theme.color.backgrounds.primary,
            color: theme.color.accent.primary,
            opacity: 0.5,
            textTransform: 'unset',
            borderBottomColor: 'unset',
            alignItems: 'flex-start',
            minWidth: 1,
            boxShadow: theme.shadows[1],
            '&:focus:not(:focus-visible)': {
                outline: 'none',
            },
            '&:focus-within': {
                outline: '2px solid gray',
                outlineOffset: '-2px',
            },
            [`&.${tabClasses.selected}`]: {
                animation: 'unset',
                opacity: 1,
            },
        },
    },
}));
export const CustomTabPanel = styled('div')(({ theme }) => ({
    width: '100%',
    backgroundColor: theme.color.backgrounds.primary,
    boxShadow: theme.shadows[1],
    [`& .MuiBox-root`]: {
        padding: `${theme.spacing(0.5, 1.25, 2, 1.25)}`,
    },
    '& .coverup-divider': {
        backgroundColor: 'white',
        height: '14px',
        zIndex: 5,
        position: 'relative',
    },
}));
export const Tab = styled(MUITab)(({ theme }) => ({
    ...theme.typography.formItem,
    minHeight: 32,
}));
