import type { Path } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { TextField, type TextFieldProps, type UnknownFormValues } from '@workspace/ui';

export interface PasswordFieldProps<FormValues extends UnknownFormValues> extends TextFieldProps<FormValues> {
    name: Path<FormValues>;
    confirmation?: boolean;
}

export const PasswordField = <FormValues extends UnknownFormValues>({
    name,
    confirmation = false,
    ...rest
}: PasswordFieldProps<FormValues>) => {
    return (
        <TextField<FormValues>
            label={
                <FormattedMessage
                    id={`certificateRequest.passwordForm.${confirmation ? 'verifyPassword' : 'password'}`}
                />
            }
            {...rest}
            type='password'
            name={name}
        />
    );
};
