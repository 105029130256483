/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvFeeModel,
    GetByIdRequestModelOfEfcvFeeModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvFeeModel,
    ViewDataResultModelOfEfcvFeeModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const fee_findByAll = (efcvFeeModel: BodyType<EfcvFeeModel>, options?: SecondParameter<typeof fetcher>) => {
    return fetcher<EfcvFeeModel[] | void>(
        {
            url: `/factoring/efcvfee/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvFeeModel,
        },
        options,
    );
};

export const getFeeFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof fee_findByAll>>,
        TError,
        { data: BodyType<EfcvFeeModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof fee_findByAll>>,
    TError,
    { data: BodyType<EfcvFeeModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof fee_findByAll>>,
        { data: BodyType<EfcvFeeModel> }
    > = props => {
        const { data } = props ?? {};

        return fee_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type FeeFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof fee_findByAll>>>;
export type FeeFindByAllMutationBody = BodyType<EfcvFeeModel>;
export type FeeFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useFeeFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof fee_findByAll>>,
        TError,
        { data: BodyType<EfcvFeeModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getFeeFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const fee_findById = (
    getByIdRequestModelOfEfcvFeeModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvFeeModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvFeeModel | void>(
        {
            url: `/factoring/efcvfee/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvFeeModelAndbigdecimal,
        },
        options,
    );
};

export const getFeeFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof fee_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvFeeModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof fee_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvFeeModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof fee_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvFeeModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return fee_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type FeeFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof fee_findById>>>;
export type FeeFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvFeeModelAndbigdecimal>;
export type FeeFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useFeeFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof fee_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvFeeModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getFeeFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const fee_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvFeeModel | void>(
        {
            url: `/factoring/efcvfee/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getFeeFindByLightDataRequestMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof fee_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof fee_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof fee_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return fee_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type FeeFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof fee_findByLightDataRequest>>
>;
export type FeeFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type FeeFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useFeeFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof fee_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getFeeFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const fee_findByViewDataRequest = (
    viewDataRequestModelOfEfcvFeeModel: BodyType<ViewDataRequestModelOfEfcvFeeModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvFeeModel | void>(
        {
            url: `/factoring/efcvfee/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvFeeModel,
        },
        options,
    );
};

export const getFeeFindByViewDataRequestMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof fee_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvFeeModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof fee_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvFeeModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof fee_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvFeeModel> }
    > = props => {
        const { data } = props ?? {};

        return fee_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type FeeFindByViewDataRequestMutationResult = NonNullable<Awaited<ReturnType<typeof fee_findByViewDataRequest>>>;
export type FeeFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvFeeModel>;
export type FeeFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useFeeFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof fee_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvFeeModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getFeeFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
