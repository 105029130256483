export function createTimer(timeout: number) {
    let timer: number | null = null;

    function stopTimer() {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }

    function startTimer(onFinish: () => void | Promise<void>) {
        stopTimer();

        timer = self.setTimeout(onFinish, timeout);
    }

    return {
        stop: stopTimer,
        start: startTimer,
    } as const;
}
