/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvDoidLimitHistModel,
    GetByIdRequestModelOfEfcvDoidLimitHistModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvDoidLimitHistModel,
    ViewDataResultModelOfEfcvDoidLimitHistModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const doidLimitHist_findByAll = (
    efcvDoidLimitHistModel: BodyType<EfcvDoidLimitHistModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvDoidLimitHistModel[] | void>(
        {
            url: `/factoring/efcvdoidlimithist/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvDoidLimitHistModel,
        },
        options,
    );
};

export const getDoidLimitHistFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidLimitHist_findByAll>>,
        TError,
        { data: BodyType<EfcvDoidLimitHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof doidLimitHist_findByAll>>,
    TError,
    { data: BodyType<EfcvDoidLimitHistModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof doidLimitHist_findByAll>>,
        { data: BodyType<EfcvDoidLimitHistModel> }
    > = props => {
        const { data } = props ?? {};

        return doidLimitHist_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DoidLimitHistFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof doidLimitHist_findByAll>>>;
export type DoidLimitHistFindByAllMutationBody = BodyType<EfcvDoidLimitHistModel>;
export type DoidLimitHistFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useDoidLimitHistFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidLimitHist_findByAll>>,
        TError,
        { data: BodyType<EfcvDoidLimitHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDoidLimitHistFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const doidLimitHist_findById = (
    getByIdRequestModelOfEfcvDoidLimitHistModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvDoidLimitHistModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvDoidLimitHistModel | void>(
        {
            url: `/factoring/efcvdoidlimithist/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvDoidLimitHistModelAndbigdecimal,
        },
        options,
    );
};

export const getDoidLimitHistFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidLimitHist_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvDoidLimitHistModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof doidLimitHist_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvDoidLimitHistModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof doidLimitHist_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvDoidLimitHistModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return doidLimitHist_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DoidLimitHistFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof doidLimitHist_findById>>>;
export type DoidLimitHistFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvDoidLimitHistModelAndbigdecimal>;
export type DoidLimitHistFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useDoidLimitHistFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidLimitHist_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvDoidLimitHistModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDoidLimitHistFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const doidLimitHist_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvDoidLimitHistModel | void>(
        {
            url: `/factoring/efcvdoidlimithist/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getDoidLimitHistFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidLimitHist_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof doidLimitHist_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof doidLimitHist_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return doidLimitHist_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DoidLimitHistFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof doidLimitHist_findByLightDataRequest>>
>;
export type DoidLimitHistFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type DoidLimitHistFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useDoidLimitHistFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidLimitHist_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDoidLimitHistFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const doidLimitHist_findByViewDataRequest = (
    viewDataRequestModelOfEfcvDoidLimitHistModel: BodyType<ViewDataRequestModelOfEfcvDoidLimitHistModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvDoidLimitHistModel | void>(
        {
            url: `/factoring/efcvdoidlimithist/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvDoidLimitHistModel,
        },
        options,
    );
};

export const getDoidLimitHistFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidLimitHist_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvDoidLimitHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof doidLimitHist_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvDoidLimitHistModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof doidLimitHist_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvDoidLimitHistModel> }
    > = props => {
        const { data } = props ?? {};

        return doidLimitHist_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DoidLimitHistFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof doidLimitHist_findByViewDataRequest>>
>;
export type DoidLimitHistFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvDoidLimitHistModel>;
export type DoidLimitHistFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useDoidLimitHistFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidLimitHist_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvDoidLimitHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDoidLimitHistFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
