import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { LayoutComponentSize } from '../../../../../../components';
import { SelectMenuModalRoot } from '../SelectMenuModalRoot';
export function useMenuProps(menuProps) {
    const theme = useTheme();
    if (menuProps) {
        // TODO implement, try deepmerge from @mui/utils
        throw new Error('merging of menuProps is not implemented');
    }
    return useMemo(() => ({
        marginThreshold: LayoutComponentSize.navigationHeight,
        slotProps: {
            root: {
                // This should be enough, but Popover overrides this with theme default value
                //  disableScrollLock: true,
                slots: {
                    root: SelectMenuModalRoot,
                },
                slotProps: {
                    backdrop: {
                        style: {
                            display: 'none',
                        },
                    },
                },
            },
            paper: {
                style: {
                    position: 'fixed',
                    zIndex: theme.zIndex.selectMenuPaper,
                },
            },
        },
    }), [theme]);
}
