import type { KeyboardEvent } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

import type { ChildrenItem } from '~modules/layout/hooks';

import * as Styled from '../Menu.styles';

export interface MenuItemProps {
    childrenItem: ChildrenItem;
    handleClose: () => void;
}

export const MenuItem = ({ childrenItem, handleClose }: MenuItemProps) => {
    const { push } = useRouter();

    const href =
        childrenItem.href + `${childrenItem.uniqueQueryParam ? `?uniq=${Math.round(Math.random() * 100)}` : ''}`;

    const handleKeyDown = (event: KeyboardEvent<HTMLSpanElement>) => {
        if (event.key === 'Enter') {
            push(href);
        }
    };

    return (
        <span tabIndex={1} onKeyDown={handleKeyDown}>
            <Styled.MenuItem component={Link} href={href} onClick={handleClose}>
                <FormattedMessage id={childrenItem.title} />
            </Styled.MenuItem>
        </span>
    );
};
