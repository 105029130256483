import { useCallback } from 'react';

import { getApiBaseUrl } from '@workspace/api';

import { useDownload, type DownloadParams } from '~hooks';

export type DownloadFileParams = {
    id: number | string;
} & DownloadParams;

export function useDownloadFile() {
    const download = useDownload();

    const downloadFile = useCallback(
        ({ id, name, type }: DownloadFileParams) => {
            const downloadUrl = new URL(`${getApiBaseUrl()}/download/${id}`);

            download(downloadUrl, { name, type });
        },
        [download],
    );

    return downloadFile;
}
