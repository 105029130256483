/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type GetPdfExportTypeListRequestModelClassName =
    (typeof GetPdfExportTypeListRequestModelClassName)[keyof typeof GetPdfExportTypeListRequestModelClassName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPdfExportTypeListRequestModelClassName = {
    BUSINESS_TRANSACTION: 'BUSINESS_TRANSACTION',
    CERTIFICATE: 'CERTIFICATE',
    COLLECTION_LETTER_PLAN: 'COLLECTION_LETTER_PLAN',
    DISCOUNT_INVOICE: 'DISCOUNT_INVOICE',
    DUE_INVOICE: 'DUE_INVOICE',
    IN_FILE: 'IN_FILE',
    OUT_FILE: 'OUT_FILE',
    ISSUED_INVOICE: 'ISSUED_INVOICE',
    LIABILITY_INVOICE: 'LIABILITY_INVOICE',
    NON_FINANCED_INVOICE: 'NON_FINANCED_INVOICE',
    OPEN_INVOICE: 'OPEN_INVOICE',
    PAYMENT: 'PAYMENT',
    PURCHASED_INVOICE: 'PURCHASED_INVOICE',
    SCHEDULE_PLAN: 'SCHEDULE_PLAN',
    SUMMARY_LIMIT: 'SUMMARY_LIMIT',
    PRODUCT_LIMIT: 'PRODUCT_LIMIT',
    CONTRACT_LIMIT: 'CONTRACT_LIMIT',
    SUPPLIER_LIMIT_HIST: 'SUPPLIER_LIMIT_HIST',
    SUPPLIER_CUSTOMER_LIMIT_HIST: 'SUPPLIER_CUSTOMER_LIMIT_HIST',
    SUPPLIER_PRODUCT_LIMIT_HIST: 'SUPPLIER_PRODUCT_LIMIT_HIST',
    SUPPLIER_CONTRACT_LIMIT_HIST: 'SUPPLIER_CONTRACT_LIMIT_HIST',
    USER: 'USER',
    VIRTUAL_MULTIPLE_PAYMENT_ORDER: 'VIRTUAL_MULTIPLE_PAYMENT_ORDER',
    VOID: 'VOID',
} as const;
