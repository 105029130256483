import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { CapitalizedBoldTypography, StyledStack } from './Tooltip.styles';
export const TooltipTitle = ({ description, label }) => {
    return (<StyledStack key={label} mb={0.375}>
            <CapitalizedBoldTypography variant='paragraphSmall'>
                <FormattedMessage id={label}/>
            </CapitalizedBoldTypography>
            <Typography variant='paragraphSmall'>
                <FormattedMessage id={description}/>
            </Typography>
        </StyledStack>);
};
