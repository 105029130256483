import { z } from 'zod';

import type { UnknownSchema } from '../types';

/**
 * To use IndexedDB with multiple stores, we have to known the store names before hand,
 * i.e. at this time of creating the connection to IndexedDB database.
 * Stores can't be created later on.
 */
export const STORES = {
    auth: z.object({
        username: z.string().optional(),
        csrfToken: z.string().optional(),
    }),

    pkcs12: z.object({
        /**
         * Base64 encoded file content of PKCS #12
         */
        content: z.string().optional(),
        name: z.string().optional(),

        /**
         * The date when ends the validity of the issued certificate in PKCS #12.
         */
        issuedCertificateValidTo: z.string().optional(),
    }),
} as const satisfies Record<string, UnknownSchema>;

export const DATABASE_NAME = 'eFactoring';
export const DATABASE_VERSION = 3;

export type Stores = typeof STORES;
export type StoreName = keyof Stores;

export const STORE_NAMES = Object.keys(STORES) as StoreName[];
