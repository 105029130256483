import { createSignature } from '../lib/pki';
import { loadIssuedCertificate } from './loadCertificates';

/**
 * - NOTE: Requires password in app memory in order to unlock PKCS #12 keystore with the issued certificate.
 * - Sign data with the issued certificate and get PEM encoded signature.
 */
export async function signWithIssuedCertificate(data: string) {
    const issuedCertificate = await loadIssuedCertificate();

    const signature = await createSignature(issuedCertificate, data);

    return signature;
}
