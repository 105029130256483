import { useCallback } from 'react';

import { pkcs12Storage, storePkcs12 } from '@workspace/certificates';

import { useUsername } from '~modules/auth/hooks';

import type { LoginFormSchema } from '../schema';

export function useHydrateLoginForm() {
    const { getUsername, setUsername } = useUsername();

    return useCallback<() => Promise<LoginFormSchema>>(async () => {
        let username = (await getUsername()) ?? '';
        let filename = (await pkcs12Storage.get('name')) ?? '';

        // one-time migration of username and certificate data from old app
        if (localStorage.getItem('data-migrated') !== 'true') {
            const oldAppUsername = localStorage.getItem('defaultUsername');

            if (oldAppUsername && !username) {
                username = oldAppUsername;
                await setUsername(username);
            }
            const oldAppCertificateFilename = localStorage.getItem('certificateFilename');
            const oldAppCertificate = localStorage.getItem('certificate');

            if (oldAppCertificateFilename && oldAppCertificate && !filename) {
                filename = oldAppCertificateFilename;
                await storePkcs12({ pkcs12: oldAppCertificate, name: oldAppCertificateFilename });
            }
            localStorage.setItem('data-migrated', 'true');
        }

        return {
            username,
            filename,
        };
    }, [getUsername, setUsername]);
}
