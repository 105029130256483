import type { HTMLAttributes } from 'react';

import * as Styled from './PreventPasswordAutofill.styles';

export interface PreventPasswordAutoFillProps extends Partial<HTMLAttributes<HTMLInputElement>> {
    name?: string;
}

const noop = () => {};

/**
 * It catches the value filled by Chrome,
 * thus preventing autofill to the rest of inputs in the form.
 */
export const PreventPasswordAutoFill = ({ name = 'password', ...props }: PreventPasswordAutoFillProps) => {
    return (
        <Styled.Input
            {...props}
            tabIndex={-1}
            onChange={noop}
            type='password'
            name={name}
            // NOTE: just set random values with proper format for given type so browser thinks it's filled, muhahaha
            value='radnomtext'
        />
    );
};
