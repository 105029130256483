import { AuthError } from '@workspace/errors/errors';
import { logger } from '@workspace/logger';

import type { ParsedCertificate } from './parsedCertificate';

type ParsedCertificates = ParsedCertificate<true>[];

export function getValidCertificates(certificates: ParsedCertificates) {
    const now = new Date();
    const validCertificates = certificates.filter(({ validFrom, validTo }) => {
        return Boolean(validFrom && validTo && validFrom <= now && now <= validTo);
    });

    logger.debug(`Valid certificates ${validCertificates.length}/${certificates.length}:`, validCertificates);

    return validCertificates;
}

export function isSelfSigned<
    C extends {
        subject: ParsedCertificate['subject'];
        issuer: ParsedCertificate['issuer'];
    },
>(certificateInfo: C) {
    return certificateInfo.subject.commonName === certificateInfo.issuer.commonName;
}

export function getSelfSignedCertificate(certificates: ParsedCertificates) {
    const selfSignedCertificate =
        getValidCertificates(certificates).find(certificate => isSelfSigned(certificate)) ?? null;

    if (!selfSignedCertificate) {
        throw new AuthError('NO_SELF_SIGNED_CERTIFICATE');
    }

    return selfSignedCertificate;
}

/**
 * Get the newest valid certificate
 */
export function getMainCertificate(certificates: ParsedCertificates) {
    certificates.sort((certA, certB) => certB.validFrom.getTime() - certA.validFrom.getTime());

    return certificates.length > 0 ? certificates[0] : null;
}

export function getIssuedCertificates(certificates: ParsedCertificates) {
    const validCertificates = getValidCertificates(certificates);
    const issuedCertificates = validCertificates.filter(certificate => !isSelfSigned(certificate));

    return issuedCertificates;
}

export function getIssuedCertificate(certificates: ParsedCertificates) {
    const issuedCertificates = getIssuedCertificates(certificates);

    const issuedCertificate = getMainCertificate(issuedCertificates);

    if (!issuedCertificate) {
        throw new AuthError('NO_ISSUED_CERTIFICATE');
    }

    return issuedCertificate;
}
