import { useMemo } from 'react';
import { Pagination, PaginationItem } from '@mui/material';
import { GridPagination, useGridApiContext } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import { IconChevronLeftDouble, IconChevronRightDouble } from '../Icons';
import { SortIcon } from './SortIcon';
import { CreateStyledTable } from './Table.styles';
import { rowsWithOrder } from './utils';
function CustomPagination({ className, count }) {
    const apiRef = useGridApiContext();
    const pageCount = Math.ceil(count / apiRef.current.state.pagination.paginationModel.pageSize);
    return (<Pagination color='primary' shape='rounded' size='small' count={pageCount} defaultPage={1} siblingCount={1} boundaryCount={0} className={className} page={apiRef.current.state.pagination.paginationModel.page + 1} onChange={(_, newPage) => {
            apiRef.current.setPage(newPage - 1);
        }} showFirstButton showLastButton renderItem={item => (<PaginationItem tabIndex={1} slots={{ first: IconChevronLeftDouble, last: IconChevronRightDouble }} {...item}/>)}/>);
}
export const Table = ({ columns, rows, rowCount, localeText, slots, hideRowOrder = false, alignOrder = 'center', ...rest }) => {
    const { formatMessage } = useIntl();
    const StyledTable = useMemo(() => CreateStyledTable(), []);
    return (<StyledTable pagination columns={hideRowOrder ? columns : rowsWithOrder(columns, alignOrder)} rows={rows} rowCount={rowCount || 0} getRowHeight={() => 'auto'} columnHeaderHeight={32} disableRowSelectionOnClick sortingMode='server' paginationMode='server' localeText={{
            noRowsLabel: formatMessage({ id: 'table.noData' }),
            footerRowSelected: count => formatMessage({ id: 'table.selectedRows' }, { count }),
            MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} (${formatMessage({ id: 'table.totalPageSize' })} ${count})`,
            },
            ...localeText,
        }} initialState={{
            ...rest.initialState,
            pagination: {
                ...rest.initialState?.pagination,
                paginationModel: {
                    page: 0,
                    pageSize: 20,
                    ...rest.initialState?.pagination?.paginationModel,
                },
            },
        }} pageSizeOptions={[5, 10, 15, 20, 30, 40, 50]} slots={{
            pagination: props => <GridPagination ActionsComponent={CustomPagination} {...props} count={rowCount}/>,
            columnUnsortedIcon: SortIcon,
            columnSortedAscendingIcon: props => <SortIcon active='asc' {...props}/>,
            columnSortedDescendingIcon: props => <SortIcon active='desc' {...props}/>,
            ...slots,
        }} {...rest} sortingOrder={['asc', 'desc']}/>);
};
