import { useSelectedSupplier } from './useSelectedSupplier';

export function useCurrentDoId() {
    const { doId } = useSelectedSupplier();

    if (doId === undefined) {
        throw new Error('SelectedSupplierContext is not set above');
    }

    return doId;
}
