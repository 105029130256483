import { createElement } from 'react';
import NextLink from 'next/link';
import { Stack, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { Card, Link } from '@workspace/ui';

import { useLang } from '~modules/intl';
import { Noticeboard } from '~modules/noticeboard';
import { generatePath } from '~utils/generatePath';

import { ClientChangedErrorAlert } from './ClientChangedErrorAlert';
import { useInfoLinks } from './hooks/useInfoLinks';
import { LoginForm } from './LoginForm';

export const LoginPage = () => {
    const lang = useLang();
    const { formatMessage } = useIntl();
    const infoLinks = useInfoLinks();

    return (
        <>
            <Typography variant='h1' mb={1.5}>
                <FormattedMessage id='login.title' />
            </Typography>
            <Stack direction='column' gap={1.5}>
                <ClientChangedErrorAlert />
                <Card>
                    <LoginForm />
                </Card>
                <Card>
                    <Stack justifyContent='space-between' direction='row'>
                        {infoLinks.map(link => (
                            <Link
                                icon={createElement(link.icon)}
                                as={NextLink}
                                href={
                                    link.origin
                                        ? `${link.origin}${generatePath(link.pathname, { lang })}`
                                        : link.pathname
                                }
                                key={link.text}
                                title={formatMessage({ id: link.title })}
                                target={link.origin ? '_blank' : undefined}
                                rel='noopener'
                            >
                                <FormattedMessage id={link.text} />
                            </Link>
                        ))}
                    </Stack>
                </Card>
                <Noticeboard />
            </Stack>
        </>
    );
};
