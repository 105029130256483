import { useCallback } from 'react';

import type { LogAction } from '../constants';
import { useLogSessionActionMutation, type UseLogSessionActionMutationParams } from './useLogSessionActionMutation';

export type UseLogActionParams = Omit<UseLogSessionActionMutationParams, 'action'>;

export function useLogAction(action: LogAction | null, params?: UseLogActionParams) {
    const { mutate: logAction } = useLogSessionActionMutation();

    return useCallback(() => {
        if (!action) {
            return;
        }

        return logAction({ action, ...params });
    }, [action, logAction, params]);
}
