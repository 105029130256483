import { useRouter } from 'next/router';
import type { UseFormSetError } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { routes } from '~constants';
import { formError } from '~modules/form/errors';

import { loginFormKey, type LoginFormSchema } from '../../schema';

export function useSubmitFileOnly(setError: UseFormSetError<LoginFormSchema>) {
    const { push } = useRouter();
    const { formatMessage } = useIntl();

    async function onSubmit(data: LoginFormSchema) {
        if (!data.filename) {
            setError(loginFormKey.filename, { message: formatMessage({ id: formError.required }) });

            return;
        }

        await push(routes.certificateManagement);
    }

    return onSubmit;
}
