/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvCompanyClientContractModel,
    GetByIdRequestModelOfEfcvCompanyClientContractModelAndbigdecimal,
    ViewDataRequestModelOfEfcvCompanyClientContractModel,
    ViewDataResultModelOfEfcvCompanyClientContractModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const companyClientContract_findByAll = (
    efcvCompanyClientContractModel: BodyType<EfcvCompanyClientContractModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvCompanyClientContractModel[] | void>(
        {
            url: `/factoring/efcvcompanyclientcontract/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvCompanyClientContractModel,
        },
        options,
    );
};

export const getCompanyClientContractFindByAllMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof companyClientContract_findByAll>>,
        TError,
        { data: BodyType<EfcvCompanyClientContractModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof companyClientContract_findByAll>>,
    TError,
    { data: BodyType<EfcvCompanyClientContractModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof companyClientContract_findByAll>>,
        { data: BodyType<EfcvCompanyClientContractModel> }
    > = props => {
        const { data } = props ?? {};

        return companyClientContract_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CompanyClientContractFindByAllMutationResult = NonNullable<
    Awaited<ReturnType<typeof companyClientContract_findByAll>>
>;
export type CompanyClientContractFindByAllMutationBody = BodyType<EfcvCompanyClientContractModel>;
export type CompanyClientContractFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useCompanyClientContractFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof companyClientContract_findByAll>>,
        TError,
        { data: BodyType<EfcvCompanyClientContractModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getCompanyClientContractFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const companyClientContract_findById = (
    getByIdRequestModelOfEfcvCompanyClientContractModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvCompanyClientContractModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvCompanyClientContractModel | void>(
        {
            url: `/factoring/efcvcompanyclientcontract/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvCompanyClientContractModelAndbigdecimal,
        },
        options,
    );
};

export const getCompanyClientContractFindByIdMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof companyClientContract_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvCompanyClientContractModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof companyClientContract_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvCompanyClientContractModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof companyClientContract_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvCompanyClientContractModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return companyClientContract_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CompanyClientContractFindByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof companyClientContract_findById>>
>;
export type CompanyClientContractFindByIdMutationBody =
    BodyType<GetByIdRequestModelOfEfcvCompanyClientContractModelAndbigdecimal>;
export type CompanyClientContractFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useCompanyClientContractFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof companyClientContract_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvCompanyClientContractModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getCompanyClientContractFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const companyClientContract_findByViewDataRequest = (
    viewDataRequestModelOfEfcvCompanyClientContractModel: BodyType<ViewDataRequestModelOfEfcvCompanyClientContractModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvCompanyClientContractModel | void>(
        {
            url: `/factoring/efcvcompanyclientcontract/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvCompanyClientContractModel,
        },
        options,
    );
};

export const getCompanyClientContractFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof companyClientContract_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvCompanyClientContractModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof companyClientContract_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvCompanyClientContractModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof companyClientContract_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvCompanyClientContractModel> }
    > = props => {
        const { data } = props ?? {};

        return companyClientContract_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CompanyClientContractFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof companyClientContract_findByViewDataRequest>>
>;
export type CompanyClientContractFindByViewDataRequestMutationBody =
    BodyType<ViewDataRequestModelOfEfcvCompanyClientContractModel>;
export type CompanyClientContractFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useCompanyClientContractFindByViewDataRequest = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof companyClientContract_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvCompanyClientContractModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getCompanyClientContractFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
