import type { ComponentProps, ReactNode } from 'react';
import type { UseQueryResult } from '@tanstack/react-query';
import type { Path } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { SelectField, type DefaultSelectOption, type UnknownFormValues } from '@workspace/ui';

import { QueryError } from '~modules/api/components';

interface CurrencySelectFieldProps<TFormValues extends UnknownFormValues> {
    name: Path<TFormValues>;
    optionsQueryResult: UseQueryResult<DefaultSelectOption[]>;
    label?: ReactNode;
    multiple?: boolean;
    renderValue?: ComponentProps<typeof SelectField>['renderValue'];
}

export const CurrencySelectField = <TFormValues extends UnknownFormValues>({
    name,
    multiple,
    label = <FormattedMessage id='issuedInvoice.field.invCurrency' />,
    optionsQueryResult,
    renderValue,
}: CurrencySelectFieldProps<TFormValues>) => {
    return (
        <QueryError result={optionsQueryResult} message={{ id: 'general.error' }} compactMode='inline'>
            <SelectField
                fullWidth
                name={name}
                disabled={optionsQueryResult.isLoading}
                label={label}
                options={optionsQueryResult.data ?? []}
                multiple={multiple}
                renderValue={renderValue}
                omitEmptyOption={multiple}
            />
        </QueryError>
    );
};
