import { getIssuedCertificates, isSelfSigned, type ParsedCertificate } from '../lib/pki';
import { loadCertificates } from './loadCertificates';

function exposeCertificateInfo(certificate: ParsedCertificate<true>) {
    const { key, subject, issuer, validFrom, validTo, serialNumber, fingerprint } = certificate;

    return {
        id: key,
        selfSigned: isSelfSigned(certificate),
        subject,
        issuer,
        validFrom,
        validTo,
        serialNumber,
        fingerprint,
    } as const;
}

export type CertificateInfo = ReturnType<typeof exposeCertificateInfo>;

/**
 * - Returns list of certificates from local storage.
 * - Password in app memory required in order to decrypt certificates.
 */
export interface FetchCertificatesInfoQuery {
    serialNumber?: string;

    /**
     * issued - issued by CA
     * selfSigned - certificate request
     */
    type?: 'ISSUED' | 'SELF_SIGNED' | undefined;
}

export async function queryCertificates<Query extends FetchCertificatesInfoQuery>(query?: Query) {
    let certificates = await loadCertificates();

    switch (query?.type) {
        case 'ISSUED':
            certificates = getIssuedCertificates(certificates);
            break;

        case 'SELF_SIGNED':
            certificates = certificates.filter(isSelfSigned);
            break;
    }

    if (query?.serialNumber) {
        const certificate = certificates.find(cert => cert.serialNumber === query.serialNumber) ?? null;

        return certificate ? [exposeCertificateInfo(certificate)] : [];
    }

    return certificates.map(exposeCertificateInfo) as CertificateInfo[];
}
