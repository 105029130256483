/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvSupplierLimitModel,
    GetByIdRequestModelOfEfcvSupplierLimitModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvSupplierLimitModel,
    ViewDataResultModelOfEfcvSupplierLimitModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const supplierLimit_findByAll = (
    efcvSupplierLimitModel: BodyType<EfcvSupplierLimitModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSupplierLimitModel[] | void>(
        {
            url: `/factoring/efcvsupplierlimit/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvSupplierLimitModel,
        },
        options,
    );
};

export const getSupplierLimitFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierLimit_findByAll>>,
        TError,
        { data: BodyType<EfcvSupplierLimitModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierLimit_findByAll>>,
    TError,
    { data: BodyType<EfcvSupplierLimitModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierLimit_findByAll>>,
        { data: BodyType<EfcvSupplierLimitModel> }
    > = props => {
        const { data } = props ?? {};

        return supplierLimit_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierLimitFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof supplierLimit_findByAll>>>;
export type SupplierLimitFindByAllMutationBody = BodyType<EfcvSupplierLimitModel>;
export type SupplierLimitFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useSupplierLimitFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierLimit_findByAll>>,
        TError,
        { data: BodyType<EfcvSupplierLimitModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierLimitFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const supplierLimit_findById = (
    getByIdRequestModelOfEfcvSupplierLimitModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvSupplierLimitModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSupplierLimitModel | void>(
        {
            url: `/factoring/efcvsupplierlimit/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvSupplierLimitModelAndbigdecimal,
        },
        options,
    );
};

export const getSupplierLimitFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierLimit_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierLimitModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierLimit_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvSupplierLimitModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierLimit_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierLimitModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return supplierLimit_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierLimitFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof supplierLimit_findById>>>;
export type SupplierLimitFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvSupplierLimitModelAndbigdecimal>;
export type SupplierLimitFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useSupplierLimitFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierLimit_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierLimitModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierLimitFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const supplierLimit_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvSupplierLimitModel | void>(
        {
            url: `/factoring/efcvsupplierlimit/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getSupplierLimitFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierLimit_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierLimit_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierLimit_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return supplierLimit_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierLimitFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierLimit_findByLightDataRequest>>
>;
export type SupplierLimitFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type SupplierLimitFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useSupplierLimitFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierLimit_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierLimitFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const supplierLimit_findByViewDataRequest = (
    viewDataRequestModelOfEfcvSupplierLimitModel: BodyType<ViewDataRequestModelOfEfcvSupplierLimitModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvSupplierLimitModel | void>(
        {
            url: `/factoring/efcvsupplierlimit/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvSupplierLimitModel,
        },
        options,
    );
};

export const getSupplierLimitFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierLimit_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierLimitModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierLimit_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvSupplierLimitModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierLimit_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierLimitModel> }
    > = props => {
        const { data } = props ?? {};

        return supplierLimit_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierLimitFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierLimit_findByViewDataRequest>>
>;
export type SupplierLimitFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvSupplierLimitModel>;
export type SupplierLimitFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useSupplierLimitFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierLimit_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierLimitModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierLimitFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
