/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvOpenInvoiceModel,
    GetByIdRequestModelOfEfcvOpenInvoiceModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvOpenInvoiceModelBody,
    ViewDataResultModelOfEfcvOpenInvoiceModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const openInvoice_findByAll = (
    efcvOpenInvoiceModel: BodyType<EfcvOpenInvoiceModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvOpenInvoiceModel[] | void>(
        {
            url: `/factoring/efcvopeninvoice/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvOpenInvoiceModel,
        },
        options,
    );
};

export const getOpenInvoiceFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof openInvoice_findByAll>>,
        TError,
        { data: BodyType<EfcvOpenInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof openInvoice_findByAll>>,
    TError,
    { data: BodyType<EfcvOpenInvoiceModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof openInvoice_findByAll>>,
        { data: BodyType<EfcvOpenInvoiceModel> }
    > = props => {
        const { data } = props ?? {};

        return openInvoice_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type OpenInvoiceFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof openInvoice_findByAll>>>;
export type OpenInvoiceFindByAllMutationBody = BodyType<EfcvOpenInvoiceModel>;
export type OpenInvoiceFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useOpenInvoiceFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof openInvoice_findByAll>>,
        TError,
        { data: BodyType<EfcvOpenInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getOpenInvoiceFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const openInvoice_findById = (
    getByIdRequestModelOfEfcvOpenInvoiceModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvOpenInvoiceModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvOpenInvoiceModel | void>(
        {
            url: `/factoring/efcvopeninvoice/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvOpenInvoiceModelAndbigdecimal,
        },
        options,
    );
};

export const getOpenInvoiceFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof openInvoice_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvOpenInvoiceModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof openInvoice_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvOpenInvoiceModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof openInvoice_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvOpenInvoiceModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return openInvoice_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type OpenInvoiceFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof openInvoice_findById>>>;
export type OpenInvoiceFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvOpenInvoiceModelAndbigdecimal>;
export type OpenInvoiceFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useOpenInvoiceFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof openInvoice_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvOpenInvoiceModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getOpenInvoiceFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const openInvoice_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvOpenInvoiceModel | void>(
        {
            url: `/factoring/efcvopeninvoice/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getOpenInvoiceFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof openInvoice_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof openInvoice_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof openInvoice_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return openInvoice_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type OpenInvoiceFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof openInvoice_findByLightDataRequest>>
>;
export type OpenInvoiceFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type OpenInvoiceFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useOpenInvoiceFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof openInvoice_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getOpenInvoiceFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const openInvoice_findByViewDataRequest = (
    viewDataRequestModelOfEfcvOpenInvoiceModelBody: BodyType<ViewDataRequestModelOfEfcvOpenInvoiceModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvOpenInvoiceModel | void>(
        {
            url: `/factoring/efcvopeninvoice/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvOpenInvoiceModelBody,
        },
        options,
    );
};

export const getOpenInvoiceFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof openInvoice_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvOpenInvoiceModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof openInvoice_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvOpenInvoiceModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof openInvoice_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvOpenInvoiceModelBody> }
    > = props => {
        const { data } = props ?? {};

        return openInvoice_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type OpenInvoiceFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof openInvoice_findByViewDataRequest>>
>;
export type OpenInvoiceFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvOpenInvoiceModelBody>;
export type OpenInvoiceFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useOpenInvoiceFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof openInvoice_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvOpenInvoiceModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getOpenInvoiceFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
