/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type SaveCertificateRequestResponseModelResult =
    (typeof SaveCertificateRequestResponseModelResult)[keyof typeof SaveCertificateRequestResponseModelResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaveCertificateRequestResponseModelResult = {
    OK: 'OK',
    REQUEST_ALREADY_EXISTS: 'REQUEST_ALREADY_EXISTS',
    FAILED_TO_LOAD_CERTIFICATE: 'FAILED_TO_LOAD_CERTIFICATE',
    UNKNOWN_THIRDPARTY_AUTHORITY: 'UNKNOWN_THIRDPARTY_AUTHORITY',
    CAPTCHA_ERROR: 'CAPTCHA_ERROR',
    CAPTCHA_TIMEOUT: 'CAPTCHA_TIMEOUT',
} as const;
