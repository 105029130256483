import { useMemo } from 'react';
import Head from 'next/head';
import NextLink from 'next/link';
import { Box, Stack, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import type { EfcvBlockedCertificateModel } from '@workspace/api';
import { Alert, Button, Container, IconBack, IconCheck, IconPrinter, Table } from '@workspace/ui';

import { QueryParam, routes } from '~constants';
import { useRouterQuery } from '~hooks';
import { QueryError } from '~modules/api/components';
import { useBlockedCertificatesColumns, useHydrateBlockedCertificates } from '~modules/auth/hooks';

interface Row extends EfcvBlockedCertificateModel {
    id: number;
}

export const CertificateBlockingResultPage = () => {
    const queryResult = useHydrateBlockedCertificates();
    const certificateId = useRouterQuery([QueryParam.CertificateIdFrom]);
    const columns = useBlockedCertificatesColumns();
    const { formatMessage } = useIntl();

    const rows = useMemo(() => {
        const rowData: Row[] = [];

        if (queryResult.data?.data?.result?.result !== undefined) {
            queryResult.data?.data?.result?.result.map((cert, i) => {
                rowData.push({ id: i + 1, ...cert });
            });
        }

        return rowData;
    }, [queryResult]);

    return (
        <Container size='xl'>
            <Head>
                <title>efactoring</title>
            </Head>
            <Typography variant='h1'>
                <FormattedMessage id='certificateBlockingResult.title' />
            </Typography>

            <Box mt={1}>
                {certificateId?.certificateIdFrom === '0' ? (
                    <Alert severity='success' style={{ marginTop: '1.5rem' }} icon={<IconCheck />}>
                        <FormattedMessage id='certificateBlockingResult.message.COULD_NOT_FIND_ANY_CERTIFICATES_TO_BE_BLOCKED' />
                    </Alert>
                ) : (
                    queryResult.data?.data?.result?.rowCount! > 0 && (
                        <Alert severity='success' style={{ marginTop: '1.5rem' }} icon={<IconCheck />}>
                            <FormattedMessage id='certificateBlockingResult.message.CERTIFICATES_WERE_SUCCESFULLY_BLOCKED' />
                        </Alert>
                    )
                )}

                <QueryError
                    result={queryResult}
                    message={{
                        id: 'general.error',
                    }}
                >
                    <Table
                        columns={columns}
                        rows={rows}
                        rowCount={queryResult.data?.data?.result?.rowCount}
                        loading={queryResult.isLoading}
                        hideFooter
                    />
                </QueryError>
            </Box>

            <Stack direction='row' justifyContent='space-between' my={1}>
                <Button
                    variant='primary'
                    color='primary'
                    LinkComponent={NextLink}
                    startIcon={<IconBack />}
                    title={formatMessage({ id: 'certificateBlockingResult.button.back' })}
                    href={routes.login}
                >
                    <FormattedMessage id='certificateBlockingResult.button.back' />
                </Button>
                <Button
                    variant='primary'
                    color='primary'
                    startIcon={<IconPrinter />}
                    title={formatMessage({ id: 'certificateBlockingResult.button.print' })}
                    onClick={() => {
                        window.print();
                    }}
                >
                    <FormattedMessage id='certificateBlockingResult.button.print' />
                </Button>
            </Stack>
        </Container>
    );
};
