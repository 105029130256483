import { useMemo } from 'react';

import { useUserData } from '@workspace/auth';

type UserClientUserCustomData = {
    DecimalSeparator?: string;
    ThousandSeparator?: string;
    NumberOfRecordsInList?: string;
};

export type UserSettings = {
    DecimalSeparator?: string;
    ThousandSeparator?: string;
    NumberOfRecordsInList?: number;
    isLoading: boolean;
};

export function useUserSettings() {
    const { data, isLoading } = useUserData();

    return useMemo(() => {
        if (!data?.data) {
            return null;
        }

        const customData = data.data.userCustomData as UserClientUserCustomData;

        const NumberOfRecordsInList =
            customData.NumberOfRecordsInList && !isNaN(Number(customData.NumberOfRecordsInList))
                ? Number(customData.NumberOfRecordsInList)
                : undefined;

        return {
            ...customData,
            NumberOfRecordsInList,
            isLoading,
        } satisfies UserSettings;
    }, [data, isLoading]);
}
