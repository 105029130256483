import { useMemo, type PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { Alert, Loader, LoaderContainer } from '@workspace/ui';

import type { PermissionsCheckFn } from '../../constants';
import { usePermissionsMatch } from '../../hooks';

export interface AuthorizedProps extends PropsWithChildren {
    requiredPermissions: PermissionsCheckFn;
}

export const Authorized = ({ requiredPermissions, children }: AuthorizedProps) => {
    const permissionsMatch = usePermissionsMatch();

    const hasPermissions = useMemo(() => {
        if (!permissionsMatch) {
            return null;
        }

        return permissionsMatch(requiredPermissions);
    }, [permissionsMatch, requiredPermissions]);

    if (hasPermissions === null) {
        return (
            <LoaderContainer height='fullBox'>
                <Loader />
            </LoaderContainer>
        );
    }

    if (!hasPermissions) {
        return (
            <Alert variant='standard' severity='error'>
                <FormattedMessage id='error.auth.ACCESS_DENIED' />
            </Alert>
        );
    }

    return <>{children}</>;
};
