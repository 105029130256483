import type { ValuesOf } from '@workspace/ts-utils';

export const LogActions = {
    // Main menu - logout
    SYSTEM_LOGOUT: 'SYSTEM_LOGOUT',
    // Main Menu - click on Help button
    SYSTEM_HELP: 'SYSTEM_HELP',
    // Batch File List  - onShow
    DISCOUNTINVOICE_BATCH_OVERVIEW: 'DISCOUNTINVOICE_BATCH_OVERVIEW',
    DISCOUNTINVOICE_REVERSE_BATCH_OVERVIEW: 'DISCOUNTINVOICE_REVERSE_BATCH_OVERVIEW',
    SUPPLIERINVOICE_BATCH_OVERVIEW: 'SUPPLIERINVOICE_BATCH_OVERVIEW',
    PURCHASEDINVOICE_CEDING_BATCH_OVERVIEW: 'PURCHASEDINVOICE_CEDING_BATCH_OVERVIEW',
    PURCHASEDINVOICE_BATCH_OVERVIEW: 'PURCHASEDINVOICE_BATCH_OVERVIEW',
    PURCHASEDINVOICE_REVERSE_BATCH_OVERVIEW: 'PURCHASEDINVOICE_REVERSE_BATCH_OVERVIEW',
    // Batch File List - logCsvExport
    DISCOUNTINVOICE_BATCH_CSV_EXPORT: 'DISCOUNTINVOICE_BATCH_CSV_EXPORT',
    DISCOUNTINVOICE_REVERSE_BATCH_CSV_EXPORT: 'DISCOUNTINVOICE_REVERSE_BATCH_CSV_EXPORT',
    SUPPLIERINVOICE_BATCH_CSV_EXPORT: 'SUPPLIERINVOICE_BATCH_CSV_EXPORT',
    PURCHASEDINVOICE_CEDING_BATCH_CSV_EXPORT: 'PURCHASEDINVOICE_CEDING_BATCH_CSV_EXPORT',
    PURCHASEDINVOICE_BATCH_CSV_EXPORT: 'PURCHASEDINVOICE_BATCH_CSV_EXPORT',
    PURCHASEDINVOICE_REVERSE_BATCH_CSV_EXPORT: 'PURCHASEDINVOICE_REVERSE_BATCH_CSV_EXPORT',
    // Batch File Filter - onShow
    DISCOUNTINVOICE_BATCH_FILTER: 'DISCOUNTINVOICE_BATCH_FILTER',
    DISCOUNTINVOICE_REVERSE_BATCH_FILTER: 'DISCOUNTINVOICE_REVERSE_BATCH_FILTER',
    SUPPLIERINVOICE_BATCH_FILTER: 'SUPPLIERINVOICE_BATCH_FILTER',
    PURCHASEDINVOICE_CEDING_BATCH_FILTER: 'PURCHASEDINVOICE_CEDING_BATCH_FILTER',
    PURCHASEDINVOICE_BATCH_FILTER: 'PURCHASEDINVOICE_BATCH_FILTER',
    PURCHASEDINVOICE_REVERSE_BATCH_FILTER: 'PURCHASEDINVOICE_REVERSE_BATCH_FILTER',
    // DAT Exports filter - onShow
    BBS_EXPORTING_FILTER: 'BBS_EXPORTING_FILTER',
    // DAT exports list - logCsvExport
    BBS_EXPORTING_CSV_EXPORT: 'BBS_EXPORTING_CSV_EXPORT',
    // DAT exports list - onShow
    BBS_EXPORTING_OVERVIEW: 'BBS_EXPORTING_OVERVIEW',
    // Customer detail - onShow
    SUPPLIERCUSTOMERLIMIT_DETAIL: 'SUPPLIERCUSTOMERLIMIT_DETAIL',
    // Discount invoice filter - onShow
    DISCOUNTINVOICE_FILTER: 'DISCOUNTINVOICE_FILTER',
    // Discount invoice list - onShow
    DISCOUNTINVOICE_OVERVIEW: 'DISCOUNTINVOICE_OVERVIEW',
    // Documents and programs download - onShow
    SYSTEM_DOCUMENTS: 'SYSTEM_DOCUMENTS',
    // Due invoice detail - onShow
    DUEINVOICE_DETAIL: 'DUEINVOICE_DETAIL',
    // Due invoice filter - onShow
    DUEINVOICE_FILTER: 'DUEINVOICE_FILTER',
    // Due invoice list - onShow
    DUEINVOICE_OVERVIEW: 'DUEINVOICE_OVERVIEW',
    // Home Page (Supplier detail) - onShow
    SYSTEM_HOME: 'SYSTEM_HOME',
    // Imported file detail - onShow
    DISCOUNTINVOICE_BATCH_DETAIL: 'DISCOUNTINVOICE_BATCH_DETAIL',
    DISCOUNTINVOICE_REVERSE_BATCH_DETAIL: 'DISCOUNTINVOICE_REVERSE_BATCH_DETAIL',
    SUPPLIERINVOICE_BATCH_DETAIL: 'SUPPLIERINVOICE_BATCH_DETAIL',
    PURCHASEDINVOICE_CEDING_BATCH_DETAIL: 'PURCHASEDINVOICE_CEDING_BATCH_DETAIL',
    PURCHASEDINVOICE_BATCH_DETAIL: 'PURCHASEDINVOICE_BATCH_DETAIL',
    PURCHASEDINVOICE_REVERSE_BATCH_DETAIL: 'PURCHASEDINVOICE_REVERSE_BATCH_DETAIL',
    // Invoice detail - onShow
    DISCOUNTINVOICE_DETAIL: 'DISCOUNTINVOICE_DETAIL',
    PURCHASEDINVOICE_DETAIL: 'PURCHASEDINVOICE_DETAIL',
    SUPPLIERINVOICE_DETAIL: 'SUPPLIERINVOICE_DETAIL',
    OPENINVOICE_DETAIL: 'OPENINVOICE_DETAIL',
    // Invoice import - onShow
    DISCOUNTINVOICE_BATCH_INVOICE_INPUT: 'DISCOUNTINVOICE_BATCH_INVOICE_INPUT',
    DISCOUNTINVOICE_REVERSE_BATCH_INVOICE_INPUT: 'DISCOUNTINVOICE_REVERSE_BATCH_INVOICE_INPUT',
    SUPPLIERINVOICE_BATCH_INVOICE_INPUT: 'SUPPLIERINVOICE_BATCH_INVOICE_INPUT',
    PURCHASEDINVOICE_CEDING_BATCH_INVOICE_INPUT: 'PURCHASEDINVOICE_CEDING_BATCH_INVOICE_INPUT',
    PURCHASEDINVOICE_BATCH_INVOICE_INPUT: 'PURCHASEDINVOICE_BATCH_INVOICE_INPUT',
    PURCHASEDINVOICE_REVERSE_BATCH_INVOICE_INPUT: 'PURCHASEDINVOICE_REVERSE_BATCH_INVOICE_INPUT',
    // Invoice import - confirmation
    DISCOUNTINVOICE_BATCH_INVOICE_CONFIRMATION: 'DISCOUNTINVOICE_BATCH_INVOICE_CONFIRMATION',
    DISCOUNTINVOICE_REVERSE_BATCH_INVOICE_CONFIRMATION: 'DISCOUNTINVOICE_REVERSE_BATCH_INVOICE_CONFIRMATION',
    SUPPLIERINVOICE_BATCH_INVOICE_CONFIRMATION: 'SUPPLIERINVOICE_BATCH_INVOICE_CONFIRMATION',
    PURCHASEDINVOICE_CEDING_BATCH_INVOICE_CONFIRMATION: 'PURCHASEDINVOICE_CEDING_BATCH_INVOICE_CONFIRMATION',
    PURCHASEDINVOICE_BATCH_INVOICE_CONFIRMATION: 'PURCHASEDINVOICE_BATCH_INVOICE_CONFIRMATION',
    PURCHASEDINVOICE_REVERSE_BATCH_INVOICE_CONFIRMATION: 'PURCHASEDINVOICE_REVERSE_BATCH_INVOICE_CONFIRMATION',
    // Issued invoice detail - onShow
    ISSUEDINVOICE_DETAIL: 'ISSUEDINVOICE_DETAIL',
    // Issued invoice filter - onShow
    ISSUEDINVOICE_FILTER: 'ISSUEDINVOICE_FILTER',
    // Issued invoice list - onShow
    ISSUEDINVOICE_OVERVIEW: 'ISSUEDINVOICE_OVERVIEW',
    // Liability invoice list - onShow
    SUPPLIERINVOICE_OVERVIEW: 'SUPPLIERINVOICE_OVERVIEW',
    // Liability invoice filter - onShow
    SUPPLIERINVOICE_FILTER: 'SUPPLIERINVOICE_FILTER',
    // New discount invoice - onShow
    DISCOUNTINVOICE_MANUAL_INVOICE_INPUT_FORM: 'DISCOUNTINVOICE_MANUAL_INVOICE_INPUT_FORM',
    // New discount invoice confirmation - onShow
    DISCOUNTINVOICE_MANUAL_INVOICE_CONFIRMATION: 'DISCOUNTINVOICE_MANUAL_INVOICE_CONFIRMATION',
    // New reverse invoice (Discount and purchased) - onShow
    DISCOUNTINVOICE_REVERSE_MANUAL_INVOICE_INPUT_FORM: 'DISCOUNTINVOICE_REVERSE_MANUAL_INVOICE_INPUT_FORM',
    PURCHASEDINVOICE_REVERSE_MANUAL_INVOICE_INPUT_FORM: 'PURCHASEDINVOICE_REVERSE_MANUAL_INVOICE_INPUT_FORM',
    // New reverse invoice confirmation (discount and purchased) - onShow
    DISCOUNTINVOICE_REVERSE_MANUAL_INVOICE_CONFIRMATION: 'DISCOUNTINVOICE_REVERSE_MANUAL_INVOICE_CONFIRMATION',
    PURCHASEDINVOICE_REVERSE_MANUAL_INVOICE_CONFIRMATION: 'PURCHASEDINVOICE_REVERSE_MANUAL_INVOICE_CONFIRMATION',
    // New liability invoice - onShow
    SUPPLIERINVOICE_MANUAL_INVOICE_INPUT_FORM: 'SUPPLIERINVOICE_MANUAL_INVOICE_INPUT_FORM',
    // New liability invoice confirmation - onShow
    SUPPLIERINVOICE_MANUAL_INVOICE_CONFIRMATION: 'SUPPLIERINVOICE_MANUAL_INVOICE_CONFIRMATION',
    // New purchased invoice - onShow
    PURCHASEDINVOICE_MANUAL_INVOICE_INPUT_FORM: 'PURCHASEDINVOICE_MANUAL_INVOICE_INPUT_FORM',
    // New purchased invoice confirmation - onShow
    PURCHASEDINVOICE_MANUAL_INVOICE_CONFIRMATION: 'PURCHASEDINVOICE_MANUAL_INVOICE_CONFIRMATION',
    // Nonfinanced invoice filter - onShow
    NONFINANCEDINVOICE_FILTER: 'NONFINANCEDINVOICE_FILTER',
    // Nonfinanced invoice list - onShow
    NONFINANCEDINVOICE_OVERVIEW: 'NONFINANCEDINVOICE_OVERVIEW',
    // Open invoice filter - onShow
    OPENINVOICE_FILTER: 'OPENINVOICE_FILTER',
    // Open invoice list - onShow
    OPENINVOICE_OVERVIEW: 'OPENINVOICE_OVERVIEW',
    // Partner preferences - onShow
    SYSTEM_USERSETTINGS: 'SYSTEM_USERSETTINGS',
    // Payment detail - onShow
    SUPPLIERPAYMENT_DETAIL: 'SUPPLIERPAYMENT_DETAIL',
    // Payment filter - onShow
    SUPPLIERPAYMENT_FILTER: 'SUPPLIERPAYMENT_FILTER',
    // Payment list - onShow
    SUPPLIERPAYMENT_OVERVIEW: 'SUPPLIERPAYMENT_OVERVIEW',
    // PDF exports filter - onShow
    PDF_EXPORTING_FILTER: 'PDF_EXPORTING_FILTER',
    // PDF exports - logCsvExport
    PDF_EXPORTING_CSV_EXPORT: 'PDF_EXPORTING_CSV_EXPORT',
    // PDF exports list - onShow
    PDF_EXPORTING_OVERVIEW: 'PDF_EXPORTING_OVERVIEW',
    // Exports schedule - onShow
    EXPORTING_EXPORT_SCHEDULE: 'EXPORTING_EXPORT_SCHEDULE',
    // Purchased invoice filter - onShow
    PURCHASEDINVOICE_FILTER: 'PURCHASEDINVOICE_FILTER',
    // Purchased invoice list - onShow
    PURCHASEDINVOICE_OVERVIEW: 'PURCHASEDINVOICE_OVERVIEW',
    // When choosing supplier/customer during login - onShow
    SYSTEM_SUPPLIERCUSTOMER_CHOOSE: 'SYSTEM_SUPPLIERCUSTOMER_CHOOSE',
    // Limit history (on all) - onShow
    LIMITSHISTORY_OVERVIEW: 'LIMITSHISTORY_OVERVIEW',
    // Supplier contract limit list - onShow
    SUPPLIERCONTRACTLIMIT_OVERVIEW: 'SUPPLIERCONTRACTLIMIT_OVERVIEW',
    // Supplier customer limit filter - onShow
    SUPPLIERCUSTOMERLIMIT_FILTER: 'SUPPLIERCUSTOMERLIMIT_FILTER',
    // Supplier customer limit list - onShow
    SUPPLIERCUSTOMERLIMIT_OVERVIEW: 'SUPPLIERCUSTOMERLIMIT_OVERVIEW',
    // Filter displayed when selecting supplier/customer during manual invoice insertion - onShow
    SUPPLIER_CUSTOMER_CHOOSE_FILTER: 'SUPPLIER_CUSTOMER_CHOOSE_FILTER',
    // List displayed when selecting supplier/customer during manual/invoice insertion - onShow
    SUPPLIER_CUSTOMER_CHOOSE_OVERVIEW: 'SUPPLIER_CUSTOMER_CHOOSE_OVERVIEW',
    // Supplier limit list - onShow
    SUPPLIERLIMIT_OVERVIEW: 'SUPPLIERLIMIT_OVERVIEW',
    // Supplier product limit list - onShow
    SUPPLIERPRODUCTLIMIT_OVERVIEW: 'SUPPLIERPRODUCTLIMIT_OVERVIEW',
    // User preferences - onShow
    SYSTEM_PARTNERSETTINGS: 'SYSTEM_PARTNERSETTINGS',
    // Certificate detail - sendCertificateRequest
    SYSTEM_CERTIFICATES_SEND_REQUEST: 'SYSTEM_CERTIFICATES_SEND_REQUEST',
    // Certificate renewal - onShow
    SYSTEM_CERTIFICATES_RENEWAL_REQUEST: 'SYSTEM_CERTIFICATES_RENEWAL_REQUEST',
    // List certificates - onShow
    SYSTEM_CERTIFICATES_OVERVIEW: 'SYSTEM_CERTIFICATES_OVERVIEW',
    // Certificate file password change - onShow
    SYSTEM_PASSWORD_CHANGE: 'SYSTEM_PASSWORD_CHANGE',
    // Setting phone number - onShow
    SMS_AUTHENTICATION_PHONE_NUMBER_SETTING: 'SMS_AUTHENTICATION_PHONE_NUMBER_SETTING',
    // Setting pin - onShow
    SYSTEM_PIN_SETTING: 'SYSTEM_PIN_SETTING',
    // Verify phone number using SMS - onShow
    SMS_AUTHENTICATION_CODE_VERIFICATION: 'SMS_AUTHENTICATION_CODE_VERIFICATION',
    // Verify PIN - onShow
    PIN_VERIFICATION: 'PIN_VERIFICATION',
} as const satisfies Record<string, string>;

export type LogAction = ValuesOf<typeof LogActions>;
