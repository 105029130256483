/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    BlockedCertficatesRequestModelGlobal,
    BlockedCertficatesResponseModel,
    CaptchaResponseModel,
    CertificateBlockingModel,
    CertificateBlockingResponseModel,
    ChallengeRequestModel,
    ChallengeResponseModel,
    FailedLoginAttemptRequestModel,
    GetCertificateChainForRequestRequestModel,
    GetCertificateChainForRequestResponseModel,
    GetGlobalParamRequestModel,
    GetGlobalParamResponseModel,
    IsCaptchaOnLoginUsingPOSTInputparameterBody,
    LoginRequestModelGlobal,
    LoginResponseModelGlobal,
    PinVerificationRequestModel,
    PinVerificationResponseModel,
    SaveCertificateRequestRequestModel,
    SaveCertificateRequestResponseModel,
    SmsCodeResponseModel,
    StringRequestModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Block user's certificates
 */
export const authServices_blockCertificates = (
    certificateBlockingModel: BodyType<CertificateBlockingModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<CertificateBlockingResponseModel | void>(
        {
            url: `/factoring/authservices/blockCertificates`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: certificateBlockingModel,
        },
        options,
    );
};

export const getAuthServicesBlockCertificatesMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_blockCertificates>>,
        TError,
        { data: BodyType<CertificateBlockingModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof authServices_blockCertificates>>,
    TError,
    { data: BodyType<CertificateBlockingModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof authServices_blockCertificates>>,
        { data: BodyType<CertificateBlockingModel> }
    > = props => {
        const { data } = props ?? {};

        return authServices_blockCertificates(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesBlockCertificatesMutationResult = NonNullable<
    Awaited<ReturnType<typeof authServices_blockCertificates>>
>;
export type AuthServicesBlockCertificatesMutationBody = BodyType<CertificateBlockingModel>;
export type AuthServicesBlockCertificatesMutationError = ErrorType<unknown>;

/**
 * @summary Block user's certificates
 */
export const useAuthServicesBlockCertificates = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_blockCertificates>>,
        TError,
        { data: BodyType<CertificateBlockingModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesBlockCertificatesMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get list of blocked certificates
 */
export const authServices_getBlockedCertificates = (
    blockedCertficatesRequestModelGlobal: BodyType<BlockedCertficatesRequestModelGlobal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<BlockedCertficatesResponseModel | void>(
        {
            url: `/factoring/authservices/getBlockedCertificates`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: blockedCertficatesRequestModelGlobal,
        },
        options,
    );
};

export const getAuthServicesGetBlockedCertificatesMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_getBlockedCertificates>>,
        TError,
        { data: BodyType<BlockedCertficatesRequestModelGlobal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof authServices_getBlockedCertificates>>,
    TError,
    { data: BodyType<BlockedCertficatesRequestModelGlobal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof authServices_getBlockedCertificates>>,
        { data: BodyType<BlockedCertficatesRequestModelGlobal> }
    > = props => {
        const { data } = props ?? {};

        return authServices_getBlockedCertificates(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesGetBlockedCertificatesMutationResult = NonNullable<
    Awaited<ReturnType<typeof authServices_getBlockedCertificates>>
>;
export type AuthServicesGetBlockedCertificatesMutationBody = BodyType<BlockedCertficatesRequestModelGlobal>;
export type AuthServicesGetBlockedCertificatesMutationError = ErrorType<unknown>;

/**
 * @summary Get list of blocked certificates
 */
export const useAuthServicesGetBlockedCertificates = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_getBlockedCertificates>>,
        TError,
        { data: BodyType<BlockedCertficatesRequestModelGlobal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesGetBlockedCertificatesMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get captcha image
 */
export const authServices_getCaptcha = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<CaptchaResponseModel | void>({ url: `/factoring/authservices/getCaptcha`, method: 'POST' }, options);
};

export const getAuthServicesGetCaptchaMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof authServices_getCaptcha>>, TError, void, TContext>;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<Awaited<ReturnType<typeof authServices_getCaptcha>>, TError, void, TContext> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof authServices_getCaptcha>>, void> = () => {
        return authServices_getCaptcha(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesGetCaptchaMutationResult = NonNullable<Awaited<ReturnType<typeof authServices_getCaptcha>>>;

export type AuthServicesGetCaptchaMutationError = ErrorType<unknown>;

/**
 * @summary Get captcha image
 */
export const useAuthServicesGetCaptcha = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof authServices_getCaptcha>>, TError, void, TContext>;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesGetCaptchaMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get issued certificate with it's chain for certificate request
 */
export const authServices_getCertificateChainForRequest = (
    getCertificateChainForRequestRequestModel: BodyType<GetCertificateChainForRequestRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<GetCertificateChainForRequestResponseModel | void>(
        {
            url: `/factoring/authservices/getCertificateChainForRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getCertificateChainForRequestRequestModel,
        },
        options,
    );
};

export const getAuthServicesGetCertificateChainForRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_getCertificateChainForRequest>>,
        TError,
        { data: BodyType<GetCertificateChainForRequestRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof authServices_getCertificateChainForRequest>>,
    TError,
    { data: BodyType<GetCertificateChainForRequestRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof authServices_getCertificateChainForRequest>>,
        { data: BodyType<GetCertificateChainForRequestRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return authServices_getCertificateChainForRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesGetCertificateChainForRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof authServices_getCertificateChainForRequest>>
>;
export type AuthServicesGetCertificateChainForRequestMutationBody = BodyType<GetCertificateChainForRequestRequestModel>;
export type AuthServicesGetCertificateChainForRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get issued certificate with it's chain for certificate request
 */
export const useAuthServicesGetCertificateChainForRequest = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_getCertificateChainForRequest>>,
        TError,
        { data: BodyType<GetCertificateChainForRequestRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesGetCertificateChainForRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get login challenge to be signed using client's certificate
 */
export const authServices_getChallenge = (
    challengeRequestModel: BodyType<ChallengeRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ChallengeResponseModel | void>(
        {
            url: `/factoring/authservices/getChallenge`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: challengeRequestModel,
        },
        options,
    );
};

export const getAuthServicesGetChallengeMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_getChallenge>>,
        TError,
        { data: BodyType<ChallengeRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof authServices_getChallenge>>,
    TError,
    { data: BodyType<ChallengeRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof authServices_getChallenge>>,
        { data: BodyType<ChallengeRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return authServices_getChallenge(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesGetChallengeMutationResult = NonNullable<Awaited<ReturnType<typeof authServices_getChallenge>>>;
export type AuthServicesGetChallengeMutationBody = BodyType<ChallengeRequestModel>;
export type AuthServicesGetChallengeMutationError = ErrorType<unknown>;

/**
 * @summary Get login challenge to be signed using client's certificate
 */
export const useAuthServicesGetChallenge = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_getChallenge>>,
        TError,
        { data: BodyType<ChallengeRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesGetChallengeMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get global parameter value
 */
export const authServices_getGlobalParam = (
    getGlobalParamRequestModel: BodyType<GetGlobalParamRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<GetGlobalParamResponseModel | void>(
        {
            url: `/factoring/authservices/getGlobalParam`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getGlobalParamRequestModel,
        },
        options,
    );
};

export const getAuthServicesGetGlobalParamMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_getGlobalParam>>,
        TError,
        { data: BodyType<GetGlobalParamRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof authServices_getGlobalParam>>,
    TError,
    { data: BodyType<GetGlobalParamRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof authServices_getGlobalParam>>,
        { data: BodyType<GetGlobalParamRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return authServices_getGlobalParam(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesGetGlobalParamMutationResult = NonNullable<
    Awaited<ReturnType<typeof authServices_getGlobalParam>>
>;
export type AuthServicesGetGlobalParamMutationBody = BodyType<GetGlobalParamRequestModel>;
export type AuthServicesGetGlobalParamMutationError = ErrorType<unknown>;

/**
 * @summary Get global parameter value
 */
export const useAuthServicesGetGlobalParam = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_getGlobalParam>>,
        TError,
        { data: BodyType<GetGlobalParamRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesGetGlobalParamMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get user's IP as seen by the server
 */
export const authServices_getUserIp = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<string | void>({ url: `/factoring/authservices/getUserIp`, method: 'POST' }, options);
};

export const getAuthServicesGetUserIpMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof authServices_getUserIp>>, TError, void, TContext>;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<Awaited<ReturnType<typeof authServices_getUserIp>>, TError, void, TContext> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof authServices_getUserIp>>, void> = () => {
        return authServices_getUserIp(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesGetUserIpMutationResult = NonNullable<Awaited<ReturnType<typeof authServices_getUserIp>>>;

export type AuthServicesGetUserIpMutationError = ErrorType<unknown>;

/**
 * @summary Get user's IP as seen by the server
 */
export const useAuthServicesGetUserIp = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof authServices_getUserIp>>, TError, void, TContext>;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesGetUserIpMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Check if captcha should be displayed when logging in
 */
export const authServices_isCaptchaOnLogin = (
    isCaptchaOnLoginUsingPOSTInputparameterBody: BodyType<IsCaptchaOnLoginUsingPOSTInputparameterBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<boolean | void>(
        {
            url: `/factoring/authservices/isCaptchaOnLogin`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: isCaptchaOnLoginUsingPOSTInputparameterBody,
        },
        options,
    );
};

export const getAuthServicesIsCaptchaOnLoginMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_isCaptchaOnLogin>>,
        TError,
        { data: BodyType<IsCaptchaOnLoginUsingPOSTInputparameterBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof authServices_isCaptchaOnLogin>>,
    TError,
    { data: BodyType<IsCaptchaOnLoginUsingPOSTInputparameterBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof authServices_isCaptchaOnLogin>>,
        { data: BodyType<IsCaptchaOnLoginUsingPOSTInputparameterBody> }
    > = props => {
        const { data } = props ?? {};

        return authServices_isCaptchaOnLogin(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesIsCaptchaOnLoginMutationResult = NonNullable<
    Awaited<ReturnType<typeof authServices_isCaptchaOnLogin>>
>;
export type AuthServicesIsCaptchaOnLoginMutationBody = BodyType<IsCaptchaOnLoginUsingPOSTInputparameterBody>;
export type AuthServicesIsCaptchaOnLoginMutationError = ErrorType<unknown>;

/**
 * @summary Check if captcha should be displayed when logging in
 */
export const useAuthServicesIsCaptchaOnLogin = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_isCaptchaOnLogin>>,
        TError,
        { data: BodyType<IsCaptchaOnLoginUsingPOSTInputparameterBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesIsCaptchaOnLoginMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Log failed login attempt
 */
export const authServices_logFailedLoginAttempt = (
    failedLoginAttemptRequestModel: BodyType<FailedLoginAttemptRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<boolean | void>(
        {
            url: `/factoring/authservices/logFailedLoginAttempt`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: failedLoginAttemptRequestModel,
        },
        options,
    );
};

export const getAuthServicesLogFailedLoginAttemptMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_logFailedLoginAttempt>>,
        TError,
        { data: BodyType<FailedLoginAttemptRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof authServices_logFailedLoginAttempt>>,
    TError,
    { data: BodyType<FailedLoginAttemptRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof authServices_logFailedLoginAttempt>>,
        { data: BodyType<FailedLoginAttemptRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return authServices_logFailedLoginAttempt(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesLogFailedLoginAttemptMutationResult = NonNullable<
    Awaited<ReturnType<typeof authServices_logFailedLoginAttempt>>
>;
export type AuthServicesLogFailedLoginAttemptMutationBody = BodyType<FailedLoginAttemptRequestModel>;
export type AuthServicesLogFailedLoginAttemptMutationError = ErrorType<unknown>;

/**
 * @summary Log failed login attempt
 */
export const useAuthServicesLogFailedLoginAttempt = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_logFailedLoginAttempt>>,
        TError,
        { data: BodyType<FailedLoginAttemptRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesLogFailedLoginAttemptMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Log in user
 */
export const authServices_login = (
    loginRequestModelGlobal: BodyType<LoginRequestModelGlobal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<LoginResponseModelGlobal | void>(
        {
            url: `/factoring/authservices/login`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: loginRequestModelGlobal,
        },
        options,
    );
};

export const getAuthServicesLoginMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_login>>,
        TError,
        { data: BodyType<LoginRequestModelGlobal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof authServices_login>>,
    TError,
    { data: BodyType<LoginRequestModelGlobal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof authServices_login>>,
        { data: BodyType<LoginRequestModelGlobal> }
    > = props => {
        const { data } = props ?? {};

        return authServices_login(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesLoginMutationResult = NonNullable<Awaited<ReturnType<typeof authServices_login>>>;
export type AuthServicesLoginMutationBody = BodyType<LoginRequestModelGlobal>;
export type AuthServicesLoginMutationError = ErrorType<unknown>;

/**
 * @summary Log in user
 */
export const useAuthServicesLogin = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_login>>,
        TError,
        { data: BodyType<LoginRequestModelGlobal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesLoginMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Log out user
 */
export const authServices_logout = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<boolean | void>({ url: `/factoring/authservices/logout`, method: 'POST' }, options);
};

export const getAuthServicesLogoutMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof authServices_logout>>, TError, void, TContext>;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<Awaited<ReturnType<typeof authServices_logout>>, TError, void, TContext> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<Awaited<ReturnType<typeof authServices_logout>>, void> = () => {
        return authServices_logout(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesLogoutMutationResult = NonNullable<Awaited<ReturnType<typeof authServices_logout>>>;

export type AuthServicesLogoutMutationError = ErrorType<unknown>;

/**
 * @summary Log out user
 */
export const useAuthServicesLogout = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<typeof authServices_logout>>, TError, void, TContext>;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesLogoutMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Submit certificate request
 */
export const authServices_saveCertificateRequest = (
    saveCertificateRequestRequestModel: BodyType<SaveCertificateRequestRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<SaveCertificateRequestResponseModel | void>(
        {
            url: `/factoring/authservices/saveCertificateRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: saveCertificateRequestRequestModel,
        },
        options,
    );
};

export const getAuthServicesSaveCertificateRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_saveCertificateRequest>>,
        TError,
        { data: BodyType<SaveCertificateRequestRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof authServices_saveCertificateRequest>>,
    TError,
    { data: BodyType<SaveCertificateRequestRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof authServices_saveCertificateRequest>>,
        { data: BodyType<SaveCertificateRequestRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return authServices_saveCertificateRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesSaveCertificateRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof authServices_saveCertificateRequest>>
>;
export type AuthServicesSaveCertificateRequestMutationBody = BodyType<SaveCertificateRequestRequestModel>;
export type AuthServicesSaveCertificateRequestMutationError = ErrorType<unknown>;

/**
 * @summary Submit certificate request
 */
export const useAuthServicesSaveCertificateRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_saveCertificateRequest>>,
        TError,
        { data: BodyType<SaveCertificateRequestRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesSaveCertificateRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Send authorization SMS code to user's phone number
 */
export const authServices_sendSmsCode = (
    stringRequestModel: BodyType<StringRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<SmsCodeResponseModel | void>(
        {
            url: `/factoring/authservices/sendSmsCode`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: stringRequestModel,
        },
        options,
    );
};

export const getAuthServicesSendSmsCodeMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_sendSmsCode>>,
        TError,
        { data: BodyType<StringRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof authServices_sendSmsCode>>,
    TError,
    { data: BodyType<StringRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof authServices_sendSmsCode>>,
        { data: BodyType<StringRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return authServices_sendSmsCode(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesSendSmsCodeMutationResult = NonNullable<Awaited<ReturnType<typeof authServices_sendSmsCode>>>;
export type AuthServicesSendSmsCodeMutationBody = BodyType<StringRequestModel>;
export type AuthServicesSendSmsCodeMutationError = ErrorType<unknown>;

/**
 * @summary Send authorization SMS code to user's phone number
 */
export const useAuthServicesSendSmsCode = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_sendSmsCode>>,
        TError,
        { data: BodyType<StringRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesSendSmsCodeMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Verify user's PIN
 */
export const authServices_verifyPin = (
    pinVerificationRequestModel: BodyType<PinVerificationRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<PinVerificationResponseModel | void>(
        {
            url: `/factoring/authservices/verifyPin`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: pinVerificationRequestModel,
        },
        options,
    );
};

export const getAuthServicesVerifyPinMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_verifyPin>>,
        TError,
        { data: BodyType<PinVerificationRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof authServices_verifyPin>>,
    TError,
    { data: BodyType<PinVerificationRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof authServices_verifyPin>>,
        { data: BodyType<PinVerificationRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return authServices_verifyPin(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesVerifyPinMutationResult = NonNullable<Awaited<ReturnType<typeof authServices_verifyPin>>>;
export type AuthServicesVerifyPinMutationBody = BodyType<PinVerificationRequestModel>;
export type AuthServicesVerifyPinMutationError = ErrorType<unknown>;

/**
 * @summary Verify user's PIN
 */
export const useAuthServicesVerifyPin = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_verifyPin>>,
        TError,
        { data: BodyType<PinVerificationRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesVerifyPinMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Verify SMS code sent to user's phone number
 */
export const authServices_verifySmsCode = (
    verifySmsCodeUsingPOSTBody: BodyType<string>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<boolean | void>(
        {
            url: `/factoring/authservices/verifySmsCode`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: verifySmsCodeUsingPOSTBody,
        },
        options,
    );
};

export const getAuthServicesVerifySmsCodeMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_verifySmsCode>>,
        TError,
        { data: BodyType<string> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof authServices_verifySmsCode>>,
    TError,
    { data: BodyType<string> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof authServices_verifySmsCode>>,
        { data: BodyType<string> }
    > = props => {
        const { data } = props ?? {};

        return authServices_verifySmsCode(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AuthServicesVerifySmsCodeMutationResult = NonNullable<
    Awaited<ReturnType<typeof authServices_verifySmsCode>>
>;
export type AuthServicesVerifySmsCodeMutationBody = BodyType<string>;
export type AuthServicesVerifySmsCodeMutationError = ErrorType<unknown>;

/**
 * @summary Verify SMS code sent to user's phone number
 */
export const useAuthServicesVerifySmsCode = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof authServices_verifySmsCode>>,
        TError,
        { data: BodyType<string> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getAuthServicesVerifySmsCodeMutationOptions(options);

    return useMutation(mutationOptions);
};
