import { getIDBStore } from '@workspace/storage';

import { api } from './createApiRequest';

const headerName = 'X-CSRF-TOKEN';

type InterceptorKey = typeof headerName;

const interceptors = new Map<InterceptorKey, number>();

export async function applyCSRFTokenInterceptor(csrfToken: string, updateIDB = true) {
    if (updateIDB) {
        await getIDBStore('auth').set('csrfToken', csrfToken);
    }

    const id = api().interceptors.request.use(undefined, request => {
        request.headers.set(headerName, csrfToken);

        return request;
    });

    interceptors.set(headerName, id);
}

export async function loadCSRFTokenInterceptor() {
    const csrfToken = await getIDBStore('auth').get('csrfToken');

    if (csrfToken) {
        await applyCSRFTokenInterceptor(csrfToken, false);

        return true;
    }

    return false;
}

export async function removeCSRFTokenInterceptor() {
    const id = interceptors.get(headerName);

    if (id) {
        api().interceptors.request.eject(id);
        interceptors.delete(headerName);
    }

    await getIDBStore('auth').remove('csrfToken');
}
