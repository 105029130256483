import { useMemo } from 'react';

import type { PermissionsCheckFn } from '../constants';
import { useAuthUtils } from './useAuthUtils';

export function usePermissionsMatch() {
    const { permissionData, permissionUtils, userUtils } = useAuthUtils();

    return useMemo(() => {
        if (!permissionData) {
            return null;
        }

        return (check: PermissionsCheckFn) => {
            return check({ userUtils, permUtils: permissionUtils, permData: permissionData });
        };
    }, [permissionUtils, permissionData, userUtils]);
}
