import { useCallback, useRef } from 'react';

import { loadCSRFTokenInterceptor, security_getUserData } from '@workspace/api';
import { retrievePkcs12 } from '@workspace/certificates';
import { AuthError, isAuthErrorWithCodes } from '@workspace/errors';
import { logger } from '@workspace/logger';

import type { AuthSession } from '../../../contexts';
import { retrieveTwoFactorRequirement } from '../../../services/twoFactor';
import { isUserAuthenticated } from '../../../utils';

export function useRetrieveAuthSession(onSessionRetrieved: (session: AuthSession) => Promise<void>) {
    const inProgressRef = useRef(false);
    const onSessionRetrievedRef = useRef(onSessionRetrieved);

    const retrieveAuthSession = useCallback<() => Promise<AuthSession | null>>(async () => {
        if (inProgressRef.current) {
            return null;
        }

        try {
            inProgressRef.current = true;

            // Validate PKCS #12 is present in IndexedDB storage
            await retrievePkcs12();

            const twoFactorRequirement = retrieveTwoFactorRequirement();

            if (twoFactorRequirement) {
                const result: AuthSession = {
                    data: twoFactorRequirement,
                    status: '2FA-requirement',
                };

                await onSessionRetrievedRef.current(result);

                return result;
            }

            const retrievedCsrfToken = await loadCSRFTokenInterceptor();

            if (!retrievedCsrfToken) {
                throw new AuthError('NO_CSRF_TOKEN');
            }

            const userData = await security_getUserData(true);

            if (!isUserAuthenticated(userData.data)) {
                throw new AuthError('USER_NOT_AUTHENTICATED');
            }
            const result: AuthSession = {
                data: userData.data,
                status: 'authenticated',
            };

            await onSessionRetrievedRef.current(result);

            return result;
        } catch (e) {
            if (!isAuthErrorWithCodes(e, ['USER_NOT_AUTHENTICATED', 'NO_CSRF_TOKEN', 'NO_CERT_IN_STORAGE'])) {
                logger.error(e);
            }

            const result: AuthSession = {
                data: null,
                status: 'unauthenticated',
            };

            await onSessionRetrievedRef.current(result);

            return result;
        } finally {
            inProgressRef.current = false;
        }
    }, []);

    return retrieveAuthSession;
}
