/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type { FctTypyProduktuModel, StringRequestModel } from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findById
 */
export const fctTypyProduktu_findById = (
    stringRequestModel: BodyType<StringRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<FctTypyProduktuModel | void>(
        {
            url: `/factoring/fcttypyproduktu/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: stringRequestModel,
        },
        options,
    );
};

export const getFctTypyProduktuFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof fctTypyProduktu_findById>>,
        TError,
        { data: BodyType<StringRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof fctTypyProduktu_findById>>,
    TError,
    { data: BodyType<StringRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof fctTypyProduktu_findById>>,
        { data: BodyType<StringRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return fctTypyProduktu_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type FctTypyProduktuFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof fctTypyProduktu_findById>>>;
export type FctTypyProduktuFindByIdMutationBody = BodyType<StringRequestModel>;
export type FctTypyProduktuFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useFctTypyProduktuFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof fctTypyProduktu_findById>>,
        TError,
        { data: BodyType<StringRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getFctTypyProduktuFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
