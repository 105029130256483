/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvPenaltyModel,
    GetByIdRequestModelOfEfcvPenaltyModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvPenaltyModel,
    ViewDataResultModelOfEfcvPenaltyModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const penalty_findByAll = (
    efcvPenaltyModel: BodyType<EfcvPenaltyModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvPenaltyModel[] | void>(
        {
            url: `/factoring/efcvpenalty/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvPenaltyModel,
        },
        options,
    );
};

export const getPenaltyFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof penalty_findByAll>>,
        TError,
        { data: BodyType<EfcvPenaltyModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof penalty_findByAll>>,
    TError,
    { data: BodyType<EfcvPenaltyModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof penalty_findByAll>>,
        { data: BodyType<EfcvPenaltyModel> }
    > = props => {
        const { data } = props ?? {};

        return penalty_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PenaltyFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof penalty_findByAll>>>;
export type PenaltyFindByAllMutationBody = BodyType<EfcvPenaltyModel>;
export type PenaltyFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const usePenaltyFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof penalty_findByAll>>,
        TError,
        { data: BodyType<EfcvPenaltyModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPenaltyFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const penalty_findById = (
    getByIdRequestModelOfEfcvPenaltyModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvPenaltyModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvPenaltyModel | void>(
        {
            url: `/factoring/efcvpenalty/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvPenaltyModelAndbigdecimal,
        },
        options,
    );
};

export const getPenaltyFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof penalty_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvPenaltyModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof penalty_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvPenaltyModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof penalty_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvPenaltyModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return penalty_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PenaltyFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof penalty_findById>>>;
export type PenaltyFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvPenaltyModelAndbigdecimal>;
export type PenaltyFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const usePenaltyFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof penalty_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvPenaltyModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPenaltyFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const penalty_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvPenaltyModel | void>(
        {
            url: `/factoring/efcvpenalty/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getPenaltyFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof penalty_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof penalty_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof penalty_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return penalty_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PenaltyFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof penalty_findByLightDataRequest>>
>;
export type PenaltyFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type PenaltyFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const usePenaltyFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof penalty_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPenaltyFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const penalty_findByViewDataRequest = (
    viewDataRequestModelOfEfcvPenaltyModel: BodyType<ViewDataRequestModelOfEfcvPenaltyModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvPenaltyModel | void>(
        {
            url: `/factoring/efcvpenalty/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvPenaltyModel,
        },
        options,
    );
};

export const getPenaltyFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof penalty_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvPenaltyModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof penalty_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvPenaltyModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof penalty_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvPenaltyModel> }
    > = props => {
        const { data } = props ?? {};

        return penalty_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PenaltyFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof penalty_findByViewDataRequest>>
>;
export type PenaltyFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvPenaltyModel>;
export type PenaltyFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const usePenaltyFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof penalty_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvPenaltyModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPenaltyFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
