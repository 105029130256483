import { styled } from '@mui/material';
import { LayoutComponentSize } from './constants';
const widthBySize = {
    sm: 698,
    md: 996,
    lg: 1200,
    xl: 1536,
    fullWidth: 'unset',
    extraWidth: 1200,
};
export const StyledContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'size' && prop !== 'maxWidth',
})(({ size, maxWidth, theme }) => ({
    margin: '0 auto',
    position: 'relative',
    ...(size && { maxWidth: widthBySize[size] }),
    ...(!maxWidth && {
        maxWidth: '100%',
    }),
    ...(!maxWidth &&
        size && {
        maxWidth: widthBySize[size],
    }),
    ...(size === 'extraWidth' && {
        [theme.breakpoints.up('lg')]: {
            maxWidth: '80vw',
        },
    }),
}));
export const FixedHeightContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: `calc(100vh - ${LayoutComponentSize.headerHeight}px - ${LayoutComponentSize.navigationHeight}px - ${LayoutComponentSize.footerLinkHeight}px)`,
}));
