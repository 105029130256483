/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

/**
 * App display language
 */
export type LoginRequestModelGlobalLocale =
    (typeof LoginRequestModelGlobalLocale)[keyof typeof LoginRequestModelGlobalLocale];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LoginRequestModelGlobalLocale = {
    cs: 'cs',
    en: 'en',
} as const;
