import { forwardRef, useCallback, useContext } from 'react';
import { NumericFormat, type NumericFormatProps } from 'react-number-format';

import { FormatSettingsContext } from '~/modules/form/contexts';
import { useUserSettings } from '~modules/auth/hooks';
import useDefaultSeparators from '~modules/intl/hooks/useDefaultSeparators';

export const CustomNumericFormat = forwardRef<NumericFormatProps, NumericFormatProps & { name: string }>(
    function NumericFormatCustom({ onChange, name, ...rest }, ref) {
        const userSettings = useUserSettings();
        const defaultSeparators = useDefaultSeparators();

        const decimalSeparator = userSettings?.DecimalSeparator || defaultSeparators.decimalSeparator;
        const thousandSeparator = userSettings?.ThousandSeparator || defaultSeparators.thousandsSeparator;

        const formatProps = useContext(FormatSettingsContext);

        // possible options from UserSettings, without space
        const allowedDecimalOptions = ['.', ','];
        const indexOfThousandSeparator = allowedDecimalOptions.indexOf(thousandSeparator ?? '');

        if (indexOfThousandSeparator > -1) {
            allowedDecimalOptions.splice(indexOfThousandSeparator, 1);
        }

        const callOnChangeHandler = useCallback(
            (value: string) => {
                onChange?.({
                    // Incompatible typing, works okay with react-hook-form
                    // @ts-expect-error
                    target: {
                        name,
                        value,
                    },
                });
            },
            [name, onChange],
        );

        // fix allowedDecimalOptions being ignored when pasting into field - see https://github.com/s-yadav/react-number-format/pull/556
        const replaceSeparatorsOnPaste = (e: React.ClipboardEvent) => {
            if (e.clipboardData) {
                let valueToSet = e.clipboardData.getData('text');

                // allow using dot instead of comma and vice-versa
                if (
                    (decimalSeparator === ',' && allowedDecimalOptions.includes('.')) ||
                    (decimalSeparator === '.' && allowedDecimalOptions.includes(','))
                ) {
                    const separatorToReplace = decimalSeparator === ',' ? '.' : ',';

                    valueToSet = valueToSet.replaceAll(separatorToReplace, decimalSeparator);
                    // if valueToSet contains multiple separators, return empty string, so that validations catch that
                    if (valueToSet.indexOf(decimalSeparator) !== valueToSet.lastIndexOf(decimalSeparator)) {
                        valueToSet = '';
                    }
                }
                e.preventDefault();
                callOnChangeHandler(valueToSet);
            }
        };

        return (
            <NumericFormat
                {...rest}
                name={name}
                getInputRef={ref}
                onValueChange={({ value }) => {
                    callOnChangeHandler(value);
                }}
                onPaste={(e: React.ClipboardEvent) => {
                    replaceSeparatorsOnPaste(e);
                }}
                thousandSeparator={thousandSeparator}
                allowedDecimalSeparators={allowedDecimalOptions}
                decimalSeparator={decimalSeparator}
                valueIsNumericString
                {...formatProps}
            />
        );
    },
);
