/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvPurchasedInvoiceModel,
    GetByIdRequestModelOfEfcvPurchasedInvoiceModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvPurchasedInvoiceModel,
    ViewDataResultModelOfEfcvPurchasedInvoiceModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const purchasedInvoice_findByAll = (
    efcvPurchasedInvoiceModel: BodyType<EfcvPurchasedInvoiceModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvPurchasedInvoiceModel[] | void>(
        {
            url: `/factoring/efcvpurchasedinvoice/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvPurchasedInvoiceModel,
        },
        options,
    );
};

export const getPurchasedInvoiceFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof purchasedInvoice_findByAll>>,
        TError,
        { data: BodyType<EfcvPurchasedInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof purchasedInvoice_findByAll>>,
    TError,
    { data: BodyType<EfcvPurchasedInvoiceModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof purchasedInvoice_findByAll>>,
        { data: BodyType<EfcvPurchasedInvoiceModel> }
    > = props => {
        const { data } = props ?? {};

        return purchasedInvoice_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PurchasedInvoiceFindByAllMutationResult = NonNullable<
    Awaited<ReturnType<typeof purchasedInvoice_findByAll>>
>;
export type PurchasedInvoiceFindByAllMutationBody = BodyType<EfcvPurchasedInvoiceModel>;
export type PurchasedInvoiceFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const usePurchasedInvoiceFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof purchasedInvoice_findByAll>>,
        TError,
        { data: BodyType<EfcvPurchasedInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPurchasedInvoiceFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const purchasedInvoice_findById = (
    getByIdRequestModelOfEfcvPurchasedInvoiceModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvPurchasedInvoiceModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvPurchasedInvoiceModel | void>(
        {
            url: `/factoring/efcvpurchasedinvoice/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvPurchasedInvoiceModelAndbigdecimal,
        },
        options,
    );
};

export const getPurchasedInvoiceFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof purchasedInvoice_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvPurchasedInvoiceModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof purchasedInvoice_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvPurchasedInvoiceModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof purchasedInvoice_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvPurchasedInvoiceModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return purchasedInvoice_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PurchasedInvoiceFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof purchasedInvoice_findById>>>;
export type PurchasedInvoiceFindByIdMutationBody =
    BodyType<GetByIdRequestModelOfEfcvPurchasedInvoiceModelAndbigdecimal>;
export type PurchasedInvoiceFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const usePurchasedInvoiceFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof purchasedInvoice_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvPurchasedInvoiceModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPurchasedInvoiceFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const purchasedInvoice_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvPurchasedInvoiceModel | void>(
        {
            url: `/factoring/efcvpurchasedinvoice/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getPurchasedInvoiceFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof purchasedInvoice_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof purchasedInvoice_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof purchasedInvoice_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return purchasedInvoice_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PurchasedInvoiceFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof purchasedInvoice_findByLightDataRequest>>
>;
export type PurchasedInvoiceFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type PurchasedInvoiceFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const usePurchasedInvoiceFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof purchasedInvoice_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPurchasedInvoiceFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const purchasedInvoice_findByViewDataRequest = (
    viewDataRequestModelOfEfcvPurchasedInvoiceModel: BodyType<ViewDataRequestModelOfEfcvPurchasedInvoiceModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvPurchasedInvoiceModel | void>(
        {
            url: `/factoring/efcvpurchasedinvoice/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvPurchasedInvoiceModel,
        },
        options,
    );
};

export const getPurchasedInvoiceFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof purchasedInvoice_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvPurchasedInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof purchasedInvoice_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvPurchasedInvoiceModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof purchasedInvoice_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvPurchasedInvoiceModel> }
    > = props => {
        const { data } = props ?? {};

        return purchasedInvoice_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PurchasedInvoiceFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof purchasedInvoice_findByViewDataRequest>>
>;
export type PurchasedInvoiceFindByViewDataRequestMutationBody =
    BodyType<ViewDataRequestModelOfEfcvPurchasedInvoiceModel>;
export type PurchasedInvoiceFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const usePurchasedInvoiceFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof purchasedInvoice_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvPurchasedInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPurchasedInvoiceFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
