/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type GetGlobalParamRequestModelCode =
    (typeof GetGlobalParamRequestModelCode)[keyof typeof GetGlobalParamRequestModelCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetGlobalParamRequestModelCode = {
    FILTERVALIDFROM: 'FILTERVALIDFROM',
    SENDER: 'SENDER',
    RECIPIENT: 'RECIPIENT',
    SUBJECT: 'SUBJECT',
    BODY: 'BODY',
    PREFINANCING_DLG: 'PREFINANCING_DLG',
    STARTING_MONTH: 'STARTING_MONTH',
    LIABILITY: 'LIABILITY',
    CODE_VALIDITY_PERIOD: 'CODE_VALIDITY_PERIOD',
    NEW_CODE_REQUEST_INTERVAL: 'NEW_CODE_REQUEST_INTERVAL',
    EXPIRATION_WARNING: 'EXPIRATION_WARNING',
    EXPIRATION_CRITICAL: 'EXPIRATION_CRITICAL',
    WEB_TWO_FACTOR_AUTH_TYPE: 'WEB_TWO_FACTOR_AUTH_TYPE',
    CLEAN_DAT_FILES_BEFORE: 'CLEAN_DAT_FILES_BEFORE',
    CLEAN_PDF_FILES_BEFORE: 'CLEAN_PDF_FILES_BEFORE',
    MAX_ITEMS_FOR_EXPORT: 'MAX_ITEMS_FOR_EXPORT',
    PATH: 'PATH',
    BBS_1_ENABLED: 'BBS_1_ENABLED',
    BBS_2_ENABLED: 'BBS_2_ENABLED',
    PDF_1_ENABLED: 'PDF_1_ENABLED',
    PDF_2_ENABLED: 'PDF_2_ENABLED',
    BBS_1_SUBJECT: 'BBS_1_SUBJECT',
    BBS_1_BODY: 'BBS_1_BODY',
    BBS_2_SUBJECT: 'BBS_2_SUBJECT',
    BBS_2_BODY: 'BBS_2_BODY',
    BBS_1_2_SUBJECT: 'BBS_1_2_SUBJECT',
    BBS_1_2_BODY: 'BBS_1_2_BODY',
    PDF_1_SUBJECT: 'PDF_1_SUBJECT',
    PDF_1_BODY: 'PDF_1_BODY',
    PDF_2_SUBJECT: 'PDF_2_SUBJECT',
    PDF_2_BODY: 'PDF_2_BODY',
    PDF_1_2_SUBJECT: 'PDF_1_2_SUBJECT',
    PDF_1_2_BODY: 'PDF_1_2_BODY',
    MAX_EMAIL_ADDRESS_NUMBER: 'MAX_EMAIL_ADDRESS_NUMBER',
    EMAIL_SENDER: 'EMAIL_SENDER',
    RESULT_INFO_RECIPIENT: 'RESULT_INFO_RECIPIENT',
} as const;
