import { worker } from '@workspace/certificates';
import { getIDBStore } from '@workspace/storage';

import { routes } from '~constants';

/**
 * The server API monitors changes in client IP address or user agent and if they change, the user is logged out and error response is returned
 * @url https://192.168.103.169:9443/diagrams/client-change-security-handler.svg
 */
export async function clientChanged() {
    await Promise.all([worker.resetStoredPassword(), getIDBStore('pkcs12').clear(), getIDBStore('auth').clear()]);

    sessionStorage.clear();

    const url = new URL(window.location.href);

    url.pathname = routes.login;
    url.searchParams.set('clientChanged', 'true');

    window.location.replace(url);
}
