import { PFX, PKCS8ShroudedKeyBag, SafeBag } from 'pkijs';

import { AuthError } from '@workspace/errors';

import { getStoredPassword } from '../../password';
import { SafeBagId, type AuthSafeSafeContent } from '../types';
import { iterateSafeBags } from './../safeBags';

export async function checkIntegrityAndDecryptPkcs12(pkcs12: PFX) {
    const password = await getStoredPassword();

    // Parse "AuthenticatedSafe" value of PKCS#12 data
    await pkcs12.parseInternalValues({
        password,
        checkIntegrity: true,
    });

    const authSafe = pkcs12.parsedValue?.authenticatedSafe;

    if (!authSafe) {
        throw new AuthError('UNKNOWN', 'Failed to parse PKCS#12 file, authenticatedSafe is empty.');
    }

    // Parse "SafeContents" values
    await authSafe.parseInternalValues({
        // Decrypt all "SafeContents" with password
        safeContents: authSafe.safeContents.map(() => ({ password })),
    });

    const safeContents: AuthSafeSafeContent[] | undefined = authSafe.parsedValue?.safeContents;

    // Iterate over all SafeBagId.PKCS8ShroudedKeyBag bags and decrypt them with password
    for (const safeBag of iterateSafeBags(safeContents, [SafeBagId.PKCS8ShroudedKeyBag])) {
        const shroudedKeyBag = safeBag as SafeBag<PKCS8ShroudedKeyBag>;

        // @ts-expect-error the method exits, the type is invalid
        await shroudedKeyBag.bagValue.parseInternalValues({ password });
    }

    return pkcs12;
}
