import type { UseFormReturn } from 'react-hook-form';
import type { MultipleFieldErrors } from 'react-hook-form/dist/types/errors';
import { useIntl } from 'react-intl';

import { LoginResponseModelGlobalResult } from '@workspace/api';
import { useSession } from '@workspace/auth';
import type { AuthSession } from '@workspace/auth/contexts';
import { worker } from '@workspace/certificates';
import { isAuthError } from '@workspace/errors';
import { logger } from '@workspace/logger';

import { getCertificateErrorMessage } from '~modules/auth/utils';

import type { PasswordLoginFormSchema } from '../schema';
import { useStartLoginProcess } from './useStartLoginProcess';

export interface UseSubmitPasswordLoginFormProps {
    form: UseFormReturn<PasswordLoginFormSchema>;

    onSuccess?: (session: AuthSession) => void | Promise<void>;
    onFailure?: () => void | Promise<void>;

    localLogin: boolean;
}

function handleError({
    setError,
    formatMessage,
    error,
    localLogin,
}: {
    setError: UseSubmitPasswordLoginFormProps['form']['setError'];
    formatMessage: ReturnType<typeof useIntl>['formatMessage'];
    error: unknown;
    localLogin: boolean;
}) {
    if (!isAuthError(error)) {
        setError('root', {
            message: formatMessage({ id: `error.auth.UNKNOWN` }),
        });

        logger.error(error);

        return;
    }

    if (error.code === 'CERTIFICATE_INVALID_PASSWORD') {
        setError('password', {
            message: formatMessage({
                id: localLogin ? 'error.INVALID_KEYSTORE_PASSWORD' : `error.INCORRECT_USERNAME_OR_PASSWORD`,
            }),
        });

        return;
    }

    const message = formatMessage({ id: `error.auth.${error.code}` });

    if (error.code === 'LOGIN_INCORRECT') {
        setError('password', { message });
    } else {
        if (error.code === LoginResponseModelGlobalResult.USERNAME_OR_CERTIFICATE_ERROR && error.certificateErrorCode) {
            const errors = getCertificateErrorMessage(error.certificateErrorCode);

            let errorTypes: MultipleFieldErrors = { message: formatMessage({ id: errors[0] }) };

            if (errors.length > 1) {
                errorTypes = { ...errorTypes, remedy: formatMessage({ id: errors[1] }) };
            }

            setError('root', { types: errorTypes });
        } else {
            setError('root', { message });
        }
    }
}

export function useSubmitPasswordLoginForm({
    form,
    onSuccess = () => {},
    onFailure,
    localLogin,
}: UseSubmitPasswordLoginFormProps) {
    const { reset, setError } = form;
    const { formatMessage } = useIntl();
    const { status, localSignIn, data } = useSession();
    const startLoginProcess = useStartLoginProcess();

    const submit = async ({ username, password }: PasswordLoginFormSchema) => {
        try {
            await worker.storePassword(password);

            await localSignIn();

            let signInResult: AuthSession | null = null;

            // If user is already authenticated, we don't need to sign in again
            if (!localLogin && status !== 'authenticated') {
                signInResult = await startLoginProcess(username!);
            }

            /**
                 * !!! BE AWARE:
                 * In order to have the password garbage-collected, following must apply:
                 * - `reset` must be executed before
                 * - `onSuccess` must be called with "some delay". 
                      I assume, it's due to the reason it can be executed within the same event loop cycle.
                      TBH I haven't be able to debug it deeper.
                 * If one those conditions won't apply, the password can be found in memory dump, thus making it vulnerable.
                 */
            reset();

            requestAnimationFrame(() => {
                onSuccess(signInResult || ({ status, data } as AuthSession));
            });
        } catch (err) {
            await worker.resetStoredPassword();

            handleError({
                setError,
                formatMessage,
                error: err,
                localLogin,
            });

            await onFailure?.();
        }
    };

    return submit;
}
