import type { CheckCertificateResponseModelGlobalResponse } from '@workspace/api';

const LoginResponseModelGlobalResult = {
    USERNAME_OR_CERTIFICATE_ERROR: 'USERNAME_OR_CERTIFICATE_ERROR',
    AUTHORIZATION_SERVICE_ERROR: 'AUTHORIZATION_SERVICE_ERROR',
    CHALLENGE_EXPIRED: 'CHALLENGE_EXPIRED',
    LOGIN_INCORRECT: 'LOGIN_INCORRECT',
    ACCESS_BLOCKED: 'ACCESS_BLOCKED',
    OK: 'OK',
    PROXY_ONE_TIME_PASSWORD_ERROR: 'PROXY_ONE_TIME_PASSWORD_ERROR',
} as const;

type LoginResponseModelGlobalResult =
    (typeof LoginResponseModelGlobalResult)[keyof typeof LoginResponseModelGlobalResult];

export type AuthErrorCode =
    /**
     * Error code returned from the server on login
     */
    | Exclude<LoginResponseModelGlobalResult, 'OK'>

    /**
     * Can't sign-in because a P12 certificate couldn't be load from the storage
     */
    | 'NO_CERT_IN_STORAGE'

    /**
     * Any non self-signed certificate found among valid certificates in local storage (self-signed -> cert. subject.commonName === issuer.commonName)
     */
    | 'NO_ISSUED_CERTIFICATE'

    /**
     * Any self-signed certificate found among valid certificates in local storage (self-signed -> cert. subject.commonName === issuer.commonName)
     */
    | 'NO_SELF_SIGNED_CERTIFICATE'

    /**
     * The `X-CSRF-TOKEN` response header doesn't contain CSRF token
     */
    | 'NO_CSRF_TOKEN'

    /**
     * Password isn't avail. in runtime memory anymore. User must provide it again.
     */
    | 'PASSWORD_NOT_SET'

    /**
     * Couldn't unlock certificate with provided password
     */
    | 'CERTIFICATE_INVALID_PASSWORD'

    /**
     * If CSRF token is valid but getUserData returns negative user id
     */
    | 'USER_NOT_AUTHENTICATED'

    /**
     * Unknown logout error
     */
    | 'LOGOUT_FAILED'

    /**
     * The CSR doesn't have valid signature
     */
    | 'UNVERIFIED_CERTIFICATE_REQUEST'

    /**
     * When one of request received 403 and CLIENT_CHANGED body, all other requests will get this error until another login session.
     */
    | 'CLIENT_CHANGED'

    /**
     * Shits happen.
     */
    | 'UNKNOWN';

const errorId = {
    AuthError: 'AUTH_ERROR',
} as const;

export class AuthError<
    Code extends AuthErrorCode = AuthErrorCode,
    Description extends string = string,
    OriginalError extends Error = Error,
    CertificateErrorCode extends
        CheckCertificateResponseModelGlobalResponse = CheckCertificateResponseModelGlobalResponse,
> extends Error {
    public readonly code: Code;
    public readonly certificateErrorCode: CertificateErrorCode | undefined;
    public readonly id: typeof errorId.AuthError;
    public readonly description: Description | undefined;
    public static readonly id = errorId.AuthError;
    public readonly originalError: OriginalError | null;

    constructor(
        code: Code,
        description?: Description,
        originalError: OriginalError | null = null,
        certificateErrorCode?: CertificateErrorCode,
    ) {
        super(description ? `${code}: ${description}` : code);

        this.id = errorId.AuthError;
        this.code = code;
        this.certificateErrorCode = certificateErrorCode;
        this.description = description;
        this.name = 'AuthError';
        this.originalError = originalError;
    }

    private stringify() {
        return JSON.stringify(this, null, 2);
    }

    toString() {
        return this.stringify();
    }
}

export function isError(error: unknown): error is Error {
    return error instanceof Error;
}

export function isAuthError(error: unknown): error is AuthError {
    return error instanceof AuthError && error.id === errorId.AuthError;
}

export function isAuthErrorWithCodes<Codes extends AuthErrorCode[], Description extends string = string>(
    error: unknown,
    codes: Codes,
): error is AuthError<Codes[number], Description> {
    return isAuthError(error) && codes.includes(error.code);
}
