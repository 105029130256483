import { z } from 'zod';

export const authUserInfoSchema = z.object({
    coreUserId: z.number().gt(0),
    loginName: z.string(),
    name: z.string(),

    email: z.string().optional(),
    password: z.string().optional(),
});

export type AuthUserInfo = z.infer<typeof authUserInfoSchema>;
