import Link from 'next/link';
import { Grid, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Button, IconAbout, IconPlus, IconRight, IconSlashCircle01 } from '@workspace/ui';

import { routes } from '~constants';

import type { LoginFormSchema } from '../schema';
import { useSubmitFileOnly } from './hooks/useSubmitFileOnly';
import { useSubmitLoginForm } from './hooks/useSubmitLoginForm';

export const FormButtons = () => {
    const form = useFormContext<LoginFormSchema>();

    const continueToPasswordLogin = useSubmitLoginForm(form.setError);
    const goToCertificateManagement = useSubmitFileOnly(form.setError);

    return (
        <Grid container spacing={1}>
            <Grid item xs={5}>
                <Stack gap={0.625}>
                    <Button
                        variant='primary'
                        color='success'
                        startIcon={<IconRight />}
                        fullWidth
                        type='submit'
                        onClick={form.handleSubmit(continueToPasswordLogin)}
                    >
                        <FormattedMessage id='login.buttons.login' />
                    </Button>
                    <Button
                        variant='primary'
                        color='primary'
                        startIcon={<IconAbout />}
                        fullWidth
                        type='submit'
                        onClick={form.handleSubmit(goToCertificateManagement)}
                    >
                        <FormattedMessage id='login.buttons.management' />
                    </Button>
                    <Button
                        variant='primary'
                        color='primary'
                        startIcon={<IconPlus />}
                        fullWidth
                        LinkComponent={Link}
                        href={routes.requestCertificateDetails}
                    >
                        <FormattedMessage id='login.buttons.create' />
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={5}>
                <Stack justifyContent='flex-end' height='100%'>
                    <Button
                        variant='primary'
                        color='error'
                        startIcon={<IconSlashCircle01 />}
                        fullWidth
                        LinkComponent={Link}
                        href={routes.certificateBlocking}
                    >
                        <FormattedMessage id='login.buttons.block' />
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
};
