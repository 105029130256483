/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvPaymentOrderModel,
    GetByIdRequestModelOfEfcvPaymentOrderModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvPaymentOrderModel,
    ViewDataResultModelOfEfcvPaymentOrderModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const paymentOrder_findByAll = (
    efcvPaymentOrderModel: BodyType<EfcvPaymentOrderModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvPaymentOrderModel[] | void>(
        {
            url: `/factoring/efcvpaymentorder/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvPaymentOrderModel,
        },
        options,
    );
};

export const getPaymentOrderFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof paymentOrder_findByAll>>,
        TError,
        { data: BodyType<EfcvPaymentOrderModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof paymentOrder_findByAll>>,
    TError,
    { data: BodyType<EfcvPaymentOrderModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof paymentOrder_findByAll>>,
        { data: BodyType<EfcvPaymentOrderModel> }
    > = props => {
        const { data } = props ?? {};

        return paymentOrder_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PaymentOrderFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof paymentOrder_findByAll>>>;
export type PaymentOrderFindByAllMutationBody = BodyType<EfcvPaymentOrderModel>;
export type PaymentOrderFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const usePaymentOrderFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof paymentOrder_findByAll>>,
        TError,
        { data: BodyType<EfcvPaymentOrderModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPaymentOrderFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const paymentOrder_findById = (
    getByIdRequestModelOfEfcvPaymentOrderModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvPaymentOrderModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvPaymentOrderModel | void>(
        {
            url: `/factoring/efcvpaymentorder/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvPaymentOrderModelAndbigdecimal,
        },
        options,
    );
};

export const getPaymentOrderFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof paymentOrder_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvPaymentOrderModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof paymentOrder_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvPaymentOrderModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof paymentOrder_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvPaymentOrderModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return paymentOrder_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PaymentOrderFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof paymentOrder_findById>>>;
export type PaymentOrderFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvPaymentOrderModelAndbigdecimal>;
export type PaymentOrderFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const usePaymentOrderFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof paymentOrder_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvPaymentOrderModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPaymentOrderFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const paymentOrder_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvPaymentOrderModel | void>(
        {
            url: `/factoring/efcvpaymentorder/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getPaymentOrderFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof paymentOrder_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof paymentOrder_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof paymentOrder_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return paymentOrder_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PaymentOrderFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof paymentOrder_findByLightDataRequest>>
>;
export type PaymentOrderFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type PaymentOrderFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const usePaymentOrderFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof paymentOrder_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPaymentOrderFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const paymentOrder_findByViewDataRequest = (
    viewDataRequestModelOfEfcvPaymentOrderModel: BodyType<ViewDataRequestModelOfEfcvPaymentOrderModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvPaymentOrderModel | void>(
        {
            url: `/factoring/efcvpaymentorder/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvPaymentOrderModel,
        },
        options,
    );
};

export const getPaymentOrderFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof paymentOrder_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvPaymentOrderModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof paymentOrder_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvPaymentOrderModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof paymentOrder_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvPaymentOrderModel> }
    > = props => {
        const { data } = props ?? {};

        return paymentOrder_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PaymentOrderFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof paymentOrder_findByViewDataRequest>>
>;
export type PaymentOrderFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvPaymentOrderModel>;
export type PaymentOrderFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const usePaymentOrderFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof paymentOrder_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvPaymentOrderModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPaymentOrderFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
