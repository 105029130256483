/**
 * @url https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
 * Thanks ChatGPT :))
 */
export var InputAutocomplete;
(function (InputAutocomplete) {
    /**
     * The browser is not permitted to automatically enter or select a value for this field.
     */
    InputAutocomplete["Off"] = "off";
    /**
     * The browser is allowed to automatically complete the input.
     */
    InputAutocomplete["On"] = "on";
    /**
     * The field expects the value to be a person's full name.
     */
    InputAutocomplete["Name"] = "name";
    /**
     * The prefix or title, such as "Mrs.", "Mr.", "Miss", "Ms.", "Dr.", or "Mlle.".
     */
    InputAutocomplete["HonorificPrefix"] = "honorific-prefix";
    /**
     * The given (or "first") name.
     */
    InputAutocomplete["GivenName"] = "given-name";
    /**
     * The middle name.
     */
    InputAutocomplete["AdditionalName"] = "additional-name";
    /**
     * The family (or "last") name.
     */
    InputAutocomplete["FamilyName"] = "family-name";
    /**
     * The suffix, such as "Jr.", "B.Sc.", "PhD.", "MBASW", or "IV".
     */
    InputAutocomplete["HonorificSuffix"] = "honorific-suffix";
    /**
     * A nickname or handle.
     */
    InputAutocomplete["Nickname"] = "nickname";
    /**
     * An email address.
     */
    InputAutocomplete["Email"] = "email";
    /**
     * A username or account name.
     */
    InputAutocomplete["Username"] = "username";
    /**
     * A new password.
     */
    InputAutocomplete["NewPassword"] = "new-password";
    /**
     * The user's current password.
     */
    InputAutocomplete["CurrentPassword"] = "current-password";
    /**
     * A one-time password (OTP) for verifying user information.
     */
    InputAutocomplete["OneTimeCode"] = "one-time-code";
    /**
     * A job title, or the title a person has within an organization.
     */
    InputAutocomplete["OrganizationTitle"] = "organization-title";
    /**
     * A company or organization name.
     */
    InputAutocomplete["Organization"] = "organization";
    /**
     * A street address.
     */
    InputAutocomplete["StreetAddress"] = "street-address";
    /**
     * The street address to send the product.
     */
    InputAutocomplete["Shipping"] = "shipping";
    /**
     * The street address to associate with the form of payment used.
     */
    InputAutocomplete["Billing"] = "billing";
    /**
     * Each individual line of the street address.
     */
    InputAutocomplete["AddressLine1"] = "address-line1";
    InputAutocomplete["AddressLine2"] = "address-line2";
    InputAutocomplete["AddressLine3"] = "address-line3";
    /**
     * The finest-grained administrative level in addresses with four levels.
     */
    InputAutocomplete["AddressLevel4"] = "address-level4";
    /**
     * The third administrative level in addresses with at least three levels.
     */
    InputAutocomplete["AddressLevel3"] = "address-level3";
    /**
     * The second administrative level in addresses with at least two levels.
     */
    InputAutocomplete["AddressLevel2"] = "address-level2";
    /**
     * The first administrative level in the address.
     */
    InputAutocomplete["AddressLevel1"] = "address-level1";
    /**
     * A country or territory code.
     */
    InputAutocomplete["Country"] = "country";
    /**
     * A country or territory name.
     */
    InputAutocomplete["CountryName"] = "country-name";
    /**
     * A postal code (in the United States, this is the ZIP code).
     */
    InputAutocomplete["PostalCode"] = "postal-code";
    /**
     * The full name as printed on or associated with a payment instrument such as a credit card.
     */
    InputAutocomplete["CcName"] = "cc-name";
    /**
     * A given (first) name as given on a payment instrument like a credit card.
     */
    InputAutocomplete["CcGivenName"] = "cc-given-name";
    /**
     * A middle name as given on a payment instrument or credit card.
     */
    InputAutocomplete["CcAdditionalName"] = "cc-additional-name";
    /**
     * A family name, as given on a credit card.
     */
    InputAutocomplete["CcFamilyName"] = "cc-family-name";
    /**
     * A credit card number or other number identifying a payment method.
     */
    InputAutocomplete["CcNumber"] = "cc-number";
    /**
     * A payment method expiration date, typically in the form "MM/YY" or "MM/YYYY".
     */
    InputAutocomplete["CcExp"] = "cc-exp";
    /**
     * The month in which the payment method expires.
     */
    InputAutocomplete["CcExpMonth"] = "cc-exp-month";
    /**
     * The year in which the payment method expires.
     */
    InputAutocomplete["CcExpYear"] = "cc-exp-year";
    /**
     * The security code for the payment instrument; on credit cards, this is the 3-digit verification number on the back of the card.
     */
    InputAutocomplete["CcCsc"] = "cc-csc";
    /**
     * The type of payment instrument (such as "Visa" or "Master Card").
     */
    InputAutocomplete["CcType"] = "cc-type";
    /**
     * The currency in which the transaction is to take place.
     */
    InputAutocomplete["TransactionCurrency"] = "transaction-currency";
    /**
     * The amount, given in the currency specified by "transaction-currency", of the transaction, for a payment form.
     */
    InputAutocomplete["TransactionAmount"] = "transaction-amount";
    /**
     * A preferred language, given as a valid BCP 47 language tag.
     */
    InputAutocomplete["Language"] = "language";
    /**
     * A birth date, as a full date.
     */
    InputAutocomplete["Bday"] = "bday";
    /**
     * The day of the month of a birth date.
     */
    InputAutocomplete["BdayDay"] = "bday-day";
    /**
     * The month of the year of a birth date.
     */
    InputAutocomplete["BdayMonth"] = "bday-month";
    /**
     * The year of a birth date.
     */
    InputAutocomplete["BdayYear"] = "bday-year";
    /**
     * A gender identity (such as "Female", "Fa'afafine", "Hijra", "Male", "Nonbinary"), as freeform text without newlines.
     */
    InputAutocomplete["Sex"] = "sex";
    /**
     * A full telephone number, including the country code.
     */
    InputAutocomplete["Tel"] = "tel";
    /**
     * The country code, such as "1" for the United States, Canada, and other areas in North America and parts of the Caribbean.
     */
    InputAutocomplete["TelCountryCode"] = "tel-country-code";
    /**
     * The entire phone number without the country code component, including a country-internal prefix.
     */
    InputAutocomplete["TelNational"] = "tel-national";
    /**
     * The area code, with any country-internal prefix applied if appropriate.
     */
    InputAutocomplete["TelAreaCode"] = "tel-area-code";
    /**
     * The phone number without the country or area code.
     */
    InputAutocomplete["TelLocal"] = "tel-local";
    /**
     * A telephone extension code within the phone number, such as a room or suite number in a hotel or an office extension in a company.
     */
    InputAutocomplete["TelExtension"] = "tel-extension";
    /**
     * A URL for an instant messaging protocol endpoint.
     */
    InputAutocomplete["Impp"] = "impp";
    /**
     * A URL, such as a home page or company website address as appropriate given the context of the other fields in the form.
     */
    InputAutocomplete["Url"] = "url";
    /**
     * The URL of an image representing the person, company, or contact information given in the other fields in the form.
     */
    InputAutocomplete["Photo"] = "photo";
    /**
     * Passkeys generated by the Web Authentication API, as requested by a conditional navigator.credentials.get() call.
     */
    InputAutocomplete["Webauthn"] = "webauthn";
})(InputAutocomplete || (InputAutocomplete = {}));
