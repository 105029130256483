/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvDodXodbHistModel,
    GetByIdRequestModelOfEfcvDodXodbHistModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvDodXodbHistModel,
    ViewDataResultModelOfEfcvDodXodbHistModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const dodXodbHist_findByAll = (
    efcvDodXodbHistModel: BodyType<EfcvDodXodbHistModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvDodXodbHistModel[] | void>(
        {
            url: `/factoring/efcvdodxodbhist/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvDodXodbHistModel,
        },
        options,
    );
};

export const getDodXodbHistFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dodXodbHist_findByAll>>,
        TError,
        { data: BodyType<EfcvDodXodbHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dodXodbHist_findByAll>>,
    TError,
    { data: BodyType<EfcvDodXodbHistModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dodXodbHist_findByAll>>,
        { data: BodyType<EfcvDodXodbHistModel> }
    > = props => {
        const { data } = props ?? {};

        return dodXodbHist_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DodXodbHistFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof dodXodbHist_findByAll>>>;
export type DodXodbHistFindByAllMutationBody = BodyType<EfcvDodXodbHistModel>;
export type DodXodbHistFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useDodXodbHistFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dodXodbHist_findByAll>>,
        TError,
        { data: BodyType<EfcvDodXodbHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDodXodbHistFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const dodXodbHist_findById = (
    getByIdRequestModelOfEfcvDodXodbHistModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvDodXodbHistModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvDodXodbHistModel | void>(
        {
            url: `/factoring/efcvdodxodbhist/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvDodXodbHistModelAndbigdecimal,
        },
        options,
    );
};

export const getDodXodbHistFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dodXodbHist_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvDodXodbHistModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dodXodbHist_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvDodXodbHistModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dodXodbHist_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvDodXodbHistModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return dodXodbHist_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DodXodbHistFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof dodXodbHist_findById>>>;
export type DodXodbHistFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvDodXodbHistModelAndbigdecimal>;
export type DodXodbHistFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useDodXodbHistFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dodXodbHist_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvDodXodbHistModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDodXodbHistFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const dodXodbHist_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvDodXodbHistModel | void>(
        {
            url: `/factoring/efcvdodxodbhist/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getDodXodbHistFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dodXodbHist_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dodXodbHist_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dodXodbHist_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return dodXodbHist_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DodXodbHistFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof dodXodbHist_findByLightDataRequest>>
>;
export type DodXodbHistFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type DodXodbHistFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useDodXodbHistFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dodXodbHist_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDodXodbHistFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const dodXodbHist_findByViewDataRequest = (
    viewDataRequestModelOfEfcvDodXodbHistModel: BodyType<ViewDataRequestModelOfEfcvDodXodbHistModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvDodXodbHistModel | void>(
        {
            url: `/factoring/efcvdodxodbhist/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvDodXodbHistModel,
        },
        options,
    );
};

export const getDodXodbHistFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dodXodbHist_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvDodXodbHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dodXodbHist_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvDodXodbHistModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dodXodbHist_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvDodXodbHistModel> }
    > = props => {
        const { data } = props ?? {};

        return dodXodbHist_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DodXodbHistFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof dodXodbHist_findByViewDataRequest>>
>;
export type DodXodbHistFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvDodXodbHistModel>;
export type DodXodbHistFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useDodXodbHistFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dodXodbHist_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvDodXodbHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDodXodbHistFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
