import { buttonBaseClasses, inputAdornmentClasses, inputBaseClasses, inputLabelClasses, outlinedInputClasses, styled, } from '@mui/material';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers';
export const DatePicker = styled(MUIDatePicker)(({ theme, value, error }) => ({
    width: '100%',
    [`& .${outlinedInputClasses.root}`]: {
        borderRadius: 2,
        ...theme.typography.formItem,
    },
    [`& .${outlinedInputClasses.input}`]: {
        padding: theme.spacing(0.375, 0.75),
        paddingRight: 0,
    },
    [`& .${outlinedInputClasses.notchedOutline} > legend`]: {
        fontSize: 'unset',
        '& > span': {
            fontSize: '0.75rem',
        },
    },
    ...(String(value)?.length > 0 && {
        [`& .${inputBaseClasses.input}  ~  fieldset`]: {
            borderColor: theme.color.inputs.text,
        },
    }),
    ...(!value && {
        [`& .${inputBaseClasses.input}  ~  fieldset`]: {
            borderColor: theme.color.inputs.border,
        },
    }),
    ...(error && {
        [`& .${inputBaseClasses.input}  ~  fieldset`]: {
            borderColor: theme.color.alerts.error,
        },
    }),
    [`& .${inputLabelClasses.root}`]: {
        ...theme.typography.formItem,
        transform: 'translate(10px, 6px)',
        color: theme.color.inputs.textLabel,
        '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -11px)',
            fontSize: '0.75rem',
            color: theme.color.inputs.textLabel,
        },
        ...(error && {
            color: theme.color.alerts.error,
        }),
    },
    [`& .${inputAdornmentClasses.positionEnd} .${buttonBaseClasses.root} svg`]: {
        fontSize: 18,
    },
}));
