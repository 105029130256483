import { getRowIndex } from './getRowIndex';
export function rowsWithOrder(columns, alignOrder) {
    return [
        {
            field: 'index',
            width: 24,
            valueGetter: getRowIndex,
            value: 1,
            sortable: false,
            flex: 0,
            headerName: '',
            align: alignOrder,
        },
        ...columns,
    ];
}
