import { useMemo } from 'react';

import { authUserInfoSchema, type AuthUserInfo } from '../schemas';
import { useSession } from './useSession';

export function useAuthUserInfo() {
    const { data, status } = useSession();

    return useMemo<AuthUserInfo | null>(() => {
        if (status !== 'authenticated' || !data.securityData?.user) {
            return null;
        }

        const result = authUserInfoSchema.safeParse(data.securityData.user);

        return result.success ? result.data : null;
    }, [data, status]);
}
