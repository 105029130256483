import { useMemo, type SVGProps } from 'react';

import { getApiOrigin } from '@workspace/api';
import { IconDownload01, IconHelpCircle, IconInfoSquare } from '@workspace/ui';

import { routes } from '~constants';
import type { MessageKey } from '~modules/intl';

export interface LoginInfoLink {
    pathname: (typeof routes)['faq' | 'news' | 'downloadable'];
    origin?: string;
    text: MessageKey;
    icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
    title: MessageKey;
}

export function useInfoLinks() {
    return useMemo<ReadonlyArray<LoginInfoLink>>(
        () =>
            [
                {
                    pathname: routes.news,
                    origin: location.origin,
                    text: 'login.links.newReleases',
                    icon: IconInfoSquare,
                    title: 'login.links.newReleases.title',
                },
                {
                    pathname: routes.faq,
                    origin: location.origin,
                    text: 'login.links.faq',
                    icon: IconHelpCircle,
                    title: 'login.links.faq.title',
                },
                {
                    pathname: routes.downloadable,
                    text: 'login.links.downloadable',
                    icon: IconDownload01,
                    title: 'login.links.downloadable.title',
                },
            ] as const satisfies ReadonlyArray<LoginInfoLink>,
        [],
    );
}
