import { useCallback } from 'react';

import { dataService_logSessionAction } from '@workspace/api';
import { logger } from '@workspace/logger';

import type { LogAction } from '../constants';

export interface UseLogSessionActionMutationParams {
    wait?: boolean;
    action: LogAction;
}

export function useLogSessionActionMutation() {
    const mutate = useCallback(async ({ wait = true, action }: UseLogSessionActionMutationParams) => {
        try {
            if (wait) {
                // If requestIdleCallback is supported
                if ('requestIdleCallback' in window) {
                    await new Promise(resolve => requestIdleCallback(resolve, { timeout: 1000 }));
                } else {
                    await new Promise(resolve => requestAnimationFrame(resolve));
                }
            }

            await dataService_logSessionAction({ action });
        } catch (e) {
            logger.error(e);
        }
    }, []);

    return { mutate };
}
