import { forwardRef } from 'react';
import { Stack } from '@mui/material';
import { IconXClose } from '../../components/Icons';
import { Button } from '../Button';
import * as Styled from './Dialog.styles';
export const Dialog = forwardRef(function AlertDialogInner({ open, heading, buttons, variant = 'default', children, minWidth, icon, ...rest }, ref) {
    return (<Styled.Dialog ref={ref} open={open} minWidth={minWidth} {...rest}>
            {(icon || buttons?.close) && (<Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <div>{icon ?? null}</div>
                    {buttons?.close && <Button {...buttons.close} iconOnly startIcon={<IconXClose />}/>}
                </Stack>)}
            {heading && (<Styled.DialogTitle id='alert-dialog-title' variant='h1'>
                    {heading}
                </Styled.DialogTitle>)}
            <Styled.DialogContent>{children}</Styled.DialogContent>
            {Boolean(buttons?.cancel || buttons?.submit) && (<Styled.DialogActions>
                    <Stack direction='row' justifyContent='space-between' width='100%'>
                        <div>
                            {buttons?.cancel && (<Button {...buttons.cancel} variant='primary' color='primary' onClick={buttons.cancel.onClick}>
                                    {buttons.cancel.children}
                                </Button>)}
                        </div>
                        {buttons?.submit && (<Button {...buttons.submit} variant='primary' color={variant === 'destructive' ? 'error' : 'success'} autoFocus>
                                {buttons.submit.children}
                            </Button>)}
                    </Stack>
                </Styled.DialogActions>)}
        </Styled.Dialog>);
});
