import type { ValuesOf } from '@workspace/ts-utils';

export const OperationPermission = {
    VIEW_CLIENT_DETAIL: 'v_EFCWWW_Client_Detail',
    VIEW_DISCOUNT_INVOICE_DETAIL: 'v_EFCWWW_DiscountInvoice_Detail',
    VIEW_PURCHASED_INVOICE_DETAIL: 'v_EFCWWW_PurchasedInvoice_Detail',
    VIEW_LIABILITY_INVOICE_DETAIL: 'v_EFCWWW_LiabilityInvoice_Detail',
    VIEW_ISSUED_INVOICE_DETAIL: 'v_EFCWWW_IssuedInvoice_Detail',
    VIEW_DUE_INVOICE_DETAIL: 'v_EFCWWW_DueInvoice_Detail',
    VIEW_PAYMENT_DETAIL: 'v_EFCWWW_Payment_Detail',
    VIEW_INFILE_DETAIL: 'v_EFCWWW_InFile_Detail',
    VIEW_SUPPLIER_LIMIT: 'v_EFCWWW_SupplierLimit',
    VIEW_SUPPLIER_PRODUCT_LIMIT: 'v_EFCWWW_SupplierProductLimit',
    VIEW_SUPPLIER_CONTRACT_LIMIT: 'v_EFCWWW_SupplierContractLimit',
    VIEW_SUPPLIER_CUSTOMER_LIMIT: 'v_EFCWWW_SupplierCustomerLimit',
    VIEW_PURCHASED_INVOICE: 'v_EFCWWW_PurchasedInvoice',
    VIEW_LIABILITY_INVOICE: 'v_EFCWWW_LiabilityInvoice',
    VIEW_OPEN_INVOICE: 'v_EFCWWW_OpenInvoice',
    VIEW_ISSUED_INVOICE: 'v_EFCWWW_IssuedInvoice',
    VIEW_DUE_INVOICE: 'v_EFCWWW_DueInvoice',
    VIEW_DISCOUNT_INVOICE: 'v_EFCWWW_DiscountInvoice',
    VIEW_PAYMENT: 'v_EFCWWW_Payment',
    MODIFY_PURCHASED_INVOICE: 'm_EFCWWW_PurchasedInvoice',
    MODIFY_DISCOUNT_INVOICE: 'm_EFCWWW_DiscountInvoice',
    MODIFY_LIABILITY_INVOICE: 'm_EFCWWW_LiabilityInvoice',
    MODIFY_REVERSE_INVOICE: 'm_EFCWWW_ReverseInvoice',
    MODIFY_DISCOUNT_INVOICE_REVERSE: 'm_EFCWWW_DiscountInvoiceReverse',
    VIEW_BATCH: 'v_EFCWWW_Batches',
    INSERT_PURCHASED_INVOICE: 'i_EFCWWW_PurchasedInvoice',
    VIEW_PURCHASED_INVOICE_FOR_CEDING_BATCH: 'v_EFCWWW_PurchasedForCeding_Batches',
    INSERT_PURCHASED_INVOICE_FOR_CEDING: 'i_EFCWWW_PurchasedInvoiceForCeding',
    INSERT_LIABILITY_INVOICE: 'i_EFCWWW_LiabilityInvoice',
    INSERT_REVERSE_INVOICE: 'i_EFCWWW_ReverseInvoice',
    VIEW_DISCOUNT_INVOICE_BATCH: 'v_EFCWWW_Discount_Batches',
    INSERT_DISCOUNT_INVOICE: 'i_EFCWWW_DiscountInvoice',
    VIEW_DISCOUNT_INVOICE_REVERSE_BATCH: 'v_EFCWWW_Discount_Reverse_Batches',
    INSERT_DISCOUNT_INVOICE_REVERSE: 'i_EFCWWW_DiscountInvoiceReverse',
    EXPORT_DISCOUNT_INVOICE_INSTALMENTS_WITH_SETTLEMENTS: 'e_EFCWWW_DiscountInvoice_InstalmentsWithSettlements',
    VIEW_BBS_EXPORT: 'v_EFCWWW_BBSExports',
    VIEW_PDF_EXPORT: 'v_EFCWWW_PDFExports',
    VIEW_EXPORT_SCHEDULE: 'v_EFCWWW_ExportSchedule',
    VIEW_LIMIT_HISTORY: 'v_EFCWWW_LimitsHistory',
    REVERSE_FINANCING: 'm_EFCWWW_ReverseFinancing',
    VIEW_EXPORTNOTICEEMAILS: 'v_EFCWWW_ExportNoticeEmails',
    MODIFY_EXPORTNOTICEEMAILS: 'm_EFCWWW_ExportNoticeEmails',
} as const;

export type OperationPermissionType = ValuesOf<typeof OperationPermission>;
