import { ImportInvoiceRequestModelImportType } from '@workspace/api';

export enum InvoiceImportType {
    PURCHASED = 'purchased',
    DISCOUNT = 'discount',
    DISCOUNT_REVERSE = 'discount-reverse',
    LIABILITY = 'liability',
    PURCHASED_REVERSE = 'purchased-reverse',
    PURCHASED_INVOICE_CEDING = 'purchased-ceding',
}

export const invoiceTypes = new Set(Object.values(InvoiceImportType));

export const invoiceTypeToAPI = {
    [InvoiceImportType.PURCHASED]: 'PURCHASED_INVOICE',
    [InvoiceImportType.PURCHASED_REVERSE]: 'PURCHASED_INVOICE_REVERSE',
    [InvoiceImportType.PURCHASED_INVOICE_CEDING]: 'PURCHASED_INVOICE_CEDING',
    [InvoiceImportType.DISCOUNT]: 'DISCOUNT_INVOICE',
    [InvoiceImportType.DISCOUNT_REVERSE]: 'DISCOUNT_INVOICE_REVERSE',
    [InvoiceImportType.LIABILITY]: 'LIABILITY_INVOICE',
} as const satisfies Record<InvoiceImportType, ImportInvoiceRequestModelImportType>;
