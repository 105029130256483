import type { UseStore } from 'idb-keyval';
import type { z } from 'zod';

import { logger } from '@workspace/logger';

import { DATABASE_NAME, DATABASE_VERSION, STORE_NAMES, STORES, type StoreName } from '../../settings';
import type { StoreWithSchema, UnknownSchema } from '../../types';

export const isSupported = () => typeof indexedDB !== 'undefined';

export function getValueValidator<Schema extends UnknownSchema, Key extends keyof z.infer<Schema>>(
    schema: Schema,
    key: Key,
) {
    const validator = schema.shape[key];

    if (!validator) {
        throw new Error(`No validator found for key "${String(key)}, ${JSON.stringify(schema.shape, null, 2)}"`);
    }

    return validator;
}

export function createFallbackStore<Name extends StoreName>(name: Name) {
    const schema = STORES[name];

    type StoreSchema = typeof schema;
    type Schema = z.infer<StoreSchema>;
    type StoreKey = keyof Schema;

    return {
        name,

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async get<Key extends StoreKey>(key: Key) {
            return undefined as Schema[Key];
        },

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async set<Key extends StoreKey>(key: Key, value: Required<Schema[Key]>) {},

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async remove<Key extends StoreKey>(key: Key) {},

        async clear() {},
    } as const satisfies StoreWithSchema<Name, StoreSchema>;
}

export async function createConnection() {
    return new Promise<IDBDatabase>((res, rej) => {
        const request = indexedDB.open(DATABASE_NAME, DATABASE_VERSION);

        request.onupgradeneeded = () => {
            const db = request.result;

            logger.debug('Creating IndexedDB stores:', STORE_NAMES);

            STORE_NAMES.filter(storeName => !db.objectStoreNames.contains(storeName)).forEach(storeName => {
                db.createObjectStore(storeName, {
                    keyPath: null,
                    autoIncrement: false,
                });
            });

            logger.debug('IndexedDB stores created', db.objectStoreNames);
        };

        request.onsuccess = () => {
            res(request.result);
        };
        request.onerror = () => {
            rej(request.error);
        };
    });
}

export function createCustomUseStore(connection: Promise<IDBDatabase>, storeName: string): UseStore {
    return async (txMode, callback) => {
        const db = await connection;

        const transaction = db.transaction(storeName, txMode);

        const store = transaction.objectStore(storeName);

        return callback(store);
    };
}
