import type { SafeContents } from 'pkijs';

/**
 * PKCS #12 integrity mode
 * @url https://datatracker.ietf.org/doc/html/rfc7292#section-3.1
 */
export enum IntegrityMode {
    /**
     * HMAC-based integrity mode
     * Used when password-based encryption is used for private key
     */
    HMACBased = 0,
    /**
     * Public key-based integrity mode
     * Used when public key-based encryption is used for private key
     */
    PublicKeyBased = 1,
}

/**
 * PKCS #12 privacy mode
 * @url https://datatracker.ietf.org/doc/html/rfc7292#section-3.1
 */
export enum PrivacyMode {
    /**
     * No privacy, clear data
     */
    NoPrivacy = 0,
    /**
     * Password-based privacy mode
     */
    PasswordBased = 1,
    /**
     * Public-key privacy mode
     */
    PublicKeyBased = 2,
}

/**
 * PKCS #12 SafeBag identifiers (globally unique object identifier)
 */
export enum SafeBagId {
    /**
     * Key Bag
     */
    PrivateKeyInfo = '1.2.840.113549.1.12.10.1.1',

    /**
     * PKCS #8 Shrouded Key Bag
     */
    PKCS8ShroudedKeyBag = '1.2.840.113549.1.12.10.1.2',

    /**
     * Certificate Bag
     */
    CertBag = '1.2.840.113549.1.12.10.1.3',

    /**
     * Certificate Revocation List (CRL) bag
     */
    CRLBag = '1.2.840.113549.1.12.10.1.4',

    /**
     * Secret Bag
     */
    SecretBag = '1.2.840.113549.1.12.10.1.5',

    /**
     * Safe Contents Bag
     */
    SafeContents = '1.2.840.113549.1.12.10.1.6',
}

/**
 * PKCS #12 structure of a SafeContent[] in `AuthenticatedSafe`
 */
export type AuthSafeSafeContent = {
    privacyMode: PrivacyMode.PasswordBased;
    value: SafeContents;
};

/**
 * @url https://datatracker.ietf.org/doc/html/rfc2985
 */
export enum AttributeType {
    FriendlyName = '1.2.840.113549.1.9.20',
    LocalKeyId = '1.2.840.113549.1.9.21',
}

export enum CertificateAttributeType {
    /**
     * `2.5.4.42` translates to `givenName`
     */
    FirstName = '2.5.4.42',

    /**
     * Alias `sn` stands for `surname`
     */
    LastName = '2.5.4.4',

    /**
     * `uid`
     */
    PersonalId = '0.9.2342.19200300.100.1.1',

    /**
     * `description`, general-purpose attribute
     */
    IdNumber = '2.5.4.13',

    /**
     * `mail`
     */
    Email = '0.9.2342.19200300.100.1.3',

    /**
     * `telephoneNumber`
     */
    PhoneNumber = '2.5.4.20',

    /**
     * Alias 'l' stands for Locality Name
     */
    Address = '2.5.4.7',

    /**
     * Alias 'c' stands for Country Name
     */
    CountryCode = '2.5.4.6',

    /**
     * Alias 'st' stands for State or Province Name
     */
    CountryName = '2.5.4.8',

    /**
     * Alias `dc`
     */
    DomainComponent = '0.9.2342.19200300.100.1.25',

    /**
     * Alias `cn`
     */
    CommonName = '2.5.4.3',

    /**
     * PIN
     */
    ChallengePassword = '1.2.840.113549.1.9.7',
}
