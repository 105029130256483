import { styled } from '@mui/material';

export const ExclamationMark = styled('span', { shouldForwardProp: propName => propName !== 'warn' })<{
    warn: boolean;
}>(({ theme, warn }) => ({
    display: 'inline-flex',
    width: 16,
    height: 16,
    borderRadius: '100%',
    alignItems: 'center',
    backgroundColor: 'currentcolor',
    [`*`]: {
        color: 'white',
        textDecoration: 'none!important',
    },
    justifyContent: 'center',
    ...(warn && {
        ['@keyframes fadeOut']: {
            '0%, 100%': {
                opacity: 1,
            },
            '50%': {
                opacity: 0,
            },
        },
        animation: 'fadeOut 5s infinite ease',
    }),
}));
