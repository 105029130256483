import { useCallback, type ReactNode } from 'react';
import { useSetAtom } from 'jotai/index';

import type { FormattedMessageProps } from '@workspace/localization/intl';
import { logger } from '@workspace/logger';

import { isFormattedMessageProps } from '~modules/intl';
import { messageContentAtom, messageOpenedAtom, messageResultAtom } from '~modules/message-popup/atoms';
import type { MessageType } from '~modules/message-popup/types';
import { PromptError } from '~modules/prompt/errors';
import { usePrompt } from '~modules/prompt/hooks';

export function useShowMessagePopup() {
    const { promptUser } = usePrompt({
        openAtom: messageOpenedAtom,
        resultAtom: messageResultAtom,
    });

    const setContent = useSetAtom(messageContentAtom);

    return useCallback(
        async (type: MessageType, message: FormattedMessageProps | ReactNode) => {
            const content = isFormattedMessageProps(message) ? { message, type } : { type, rawContent: message };

            setContent(content);
            try {
                await promptUser();
            } catch (e) {
                if (e instanceof PromptError) {
                    return;
                }
                logger.error(e);
            }
        },
        [promptUser, setContent],
    );
}
