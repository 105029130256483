import type { RequestResult } from '@workspace/api';
import { QueryKey } from '@workspace/api/constants';
import { useInvalidateQuery } from '@workspace/api/hooks';

import { useShowMessagePopup } from '~modules/message-popup/hooks';
import { usePrompt } from '~modules/prompt/hooks';

import { captchaModalAtom, captchaResultAtom, type CaptchaResult } from '../atoms';

type DefaultResult = {
    result?: string;
    [key: string]: any;
};

/**
 * Note that before running this hook, `CaptchaDialog` must be rendered somewhere in the app.
 * 1. Sets `captchaModalAtom` to `true`
 * 2. Returns promise that resolves to `CaptchaResult` when user completes captcha
 */
export function useSubmitWithCaptcha() {
    const { promptUser } = usePrompt<CaptchaResult>({
        openAtom: captchaModalAtom,
        resultAtom: captchaResultAtom,
    });

    const { invalidateQuery } = useInvalidateQuery();
    const showMessage = useShowMessagePopup();

    /**
     * - Prompt user with captcha
     * - If captcha is invalid, show error and prompt again
     * - Else, resolve with result status
     */
    async function submitWithCaptcha<Result extends DefaultResult>(
        callback: (params: { captcha: string; captchaHash: string }) => Promise<RequestResult<Result | void>>,
    ): Promise<Result> {
        while (true) {
            const captcha = await promptUser();

            const { data } = await callback({
                captcha: captcha.code,
                captchaHash: captcha.hash,
            });

            const status = data ? data.result : null;

            if (status === 'CAPTCHA_TIMEOUT' || status === 'CAPTCHA_ERROR') {
                await showMessage('error', { id: `error.auth.${status}` });

                if (status === 'CAPTCHA_TIMEOUT') {
                    await invalidateQuery(QueryKey.CAPTCHA);
                }
            } else {
                return data!;
            }
        }
    }

    return submitWithCaptcha;
}
