/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type ReverseFinancingRequestModelOrderByFilter =
    (typeof ReverseFinancingRequestModelOrderByFilter)[keyof typeof ReverseFinancingRequestModelOrderByFilter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReverseFinancingRequestModelOrderByFilter = {
    DUE_DATE: 'DUE_DATE',
    CUSTOMER_DUE_DATE: 'CUSTOMER_DUE_DATE',
} as const;
