import { forwardRef, useId } from 'react';
import * as Styled from './RadioGroup.styles';
export const RadioGroup = forwardRef(function RadioGroupInner({ children, label, error, ...rest }, ref) {
    const labelId = useId();
    return (<Styled.FormControl>
            {label && <Styled.Label id={labelId}>{label}</Styled.Label>}
            <Styled.RadioGroup {...rest} aria-labelledby={labelId} ref={ref}>
                {children}
            </Styled.RadioGroup>
            {error}
        </Styled.FormControl>);
});
