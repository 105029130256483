/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type { InvoiceFinancDefaultRequestModel, PartnerPreferencesModel } from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Get partner preferences
 */
export const partnerPreferencesService_getPartnerPreferences = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<PartnerPreferencesModel | void>(
        { url: `/factoring/partnerpreferencesservice/getPartnerPreferences`, method: 'POST' },
        options,
    );
};

export const getPartnerPreferencesServiceGetPartnerPreferencesMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof partnerPreferencesService_getPartnerPreferences>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof partnerPreferencesService_getPartnerPreferences>>,
    TError,
    void,
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof partnerPreferencesService_getPartnerPreferences>>,
        void
    > = () => {
        return partnerPreferencesService_getPartnerPreferences(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PartnerPreferencesServiceGetPartnerPreferencesMutationResult = NonNullable<
    Awaited<ReturnType<typeof partnerPreferencesService_getPartnerPreferences>>
>;

export type PartnerPreferencesServiceGetPartnerPreferencesMutationError = ErrorType<unknown>;

/**
 * @summary Get partner preferences
 */
export const usePartnerPreferencesServiceGetPartnerPreferences = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof partnerPreferencesService_getPartnerPreferences>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPartnerPreferencesServiceGetPartnerPreferencesMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get financing preference setting or a default value
 */
export const partnerPreferencesService_getPinvFinancingPreferenceOrDefault = (
    invoiceFinancDefaultRequestModel: BodyType<InvoiceFinancDefaultRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<string | void>(
        {
            url: `/factoring/partnerpreferencesservice/getPinvFinancingPreferenceOrDefault`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: invoiceFinancDefaultRequestModel,
        },
        options,
    );
};

export const getPartnerPreferencesServiceGetPinvFinancingPreferenceOrDefaultMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof partnerPreferencesService_getPinvFinancingPreferenceOrDefault>>,
        TError,
        { data: BodyType<InvoiceFinancDefaultRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof partnerPreferencesService_getPinvFinancingPreferenceOrDefault>>,
    TError,
    { data: BodyType<InvoiceFinancDefaultRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof partnerPreferencesService_getPinvFinancingPreferenceOrDefault>>,
        { data: BodyType<InvoiceFinancDefaultRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return partnerPreferencesService_getPinvFinancingPreferenceOrDefault(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PartnerPreferencesServiceGetPinvFinancingPreferenceOrDefaultMutationResult = NonNullable<
    Awaited<ReturnType<typeof partnerPreferencesService_getPinvFinancingPreferenceOrDefault>>
>;
export type PartnerPreferencesServiceGetPinvFinancingPreferenceOrDefaultMutationBody =
    BodyType<InvoiceFinancDefaultRequestModel>;
export type PartnerPreferencesServiceGetPinvFinancingPreferenceOrDefaultMutationError = ErrorType<unknown>;

/**
 * @summary Get financing preference setting or a default value
 */
export const usePartnerPreferencesServiceGetPinvFinancingPreferenceOrDefault = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof partnerPreferencesService_getPinvFinancingPreferenceOrDefault>>,
        TError,
        { data: BodyType<InvoiceFinancDefaultRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPartnerPreferencesServiceGetPinvFinancingPreferenceOrDefaultMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Set partner preferences
 */
export const partnerPreferencesService_setPartnerPreferences = (
    partnerPreferencesModel: BodyType<PartnerPreferencesModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<PartnerPreferencesModel | void>(
        {
            url: `/factoring/partnerpreferencesservice/setPartnerPreferences`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: partnerPreferencesModel,
        },
        options,
    );
};

export const getPartnerPreferencesServiceSetPartnerPreferencesMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof partnerPreferencesService_setPartnerPreferences>>,
        TError,
        { data: BodyType<PartnerPreferencesModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof partnerPreferencesService_setPartnerPreferences>>,
    TError,
    { data: BodyType<PartnerPreferencesModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof partnerPreferencesService_setPartnerPreferences>>,
        { data: BodyType<PartnerPreferencesModel> }
    > = props => {
        const { data } = props ?? {};

        return partnerPreferencesService_setPartnerPreferences(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PartnerPreferencesServiceSetPartnerPreferencesMutationResult = NonNullable<
    Awaited<ReturnType<typeof partnerPreferencesService_setPartnerPreferences>>
>;
export type PartnerPreferencesServiceSetPartnerPreferencesMutationBody = BodyType<PartnerPreferencesModel>;
export type PartnerPreferencesServiceSetPartnerPreferencesMutationError = ErrorType<unknown>;

/**
 * @summary Set partner preferences
 */
export const usePartnerPreferencesServiceSetPartnerPreferences = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof partnerPreferencesService_setPartnerPreferences>>,
        TError,
        { data: BodyType<PartnerPreferencesModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPartnerPreferencesServiceSetPartnerPreferencesMutationOptions(options);

    return useMutation(mutationOptions);
};
