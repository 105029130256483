/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvSupplierCustomerModel,
    GetByIdRequestModelOfEfcvSupplierCustomerModelAndstring,
    ViewDataRequestModelOfEfcvSupplierCustomerModel,
    ViewDataResultModelOfEfcvSupplierCustomerModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const supplierCustomer_findByAll = (
    efcvSupplierCustomerModel: BodyType<EfcvSupplierCustomerModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSupplierCustomerModel[] | void>(
        {
            url: `/factoring/efcvsuppliercustomer/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvSupplierCustomerModel,
        },
        options,
    );
};

export const getSupplierCustomerFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomer_findByAll>>,
        TError,
        { data: BodyType<EfcvSupplierCustomerModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierCustomer_findByAll>>,
    TError,
    { data: BodyType<EfcvSupplierCustomerModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierCustomer_findByAll>>,
        { data: BodyType<EfcvSupplierCustomerModel> }
    > = props => {
        const { data } = props ?? {};

        return supplierCustomer_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierCustomerFindByAllMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierCustomer_findByAll>>
>;
export type SupplierCustomerFindByAllMutationBody = BodyType<EfcvSupplierCustomerModel>;
export type SupplierCustomerFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useSupplierCustomerFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomer_findByAll>>,
        TError,
        { data: BodyType<EfcvSupplierCustomerModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierCustomerFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const supplierCustomer_findById = (
    getByIdRequestModelOfEfcvSupplierCustomerModelAndstring: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerModelAndstring>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSupplierCustomerModel | void>(
        {
            url: `/factoring/efcvsuppliercustomer/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvSupplierCustomerModelAndstring,
        },
        options,
    );
};

export const getSupplierCustomerFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomer_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerModelAndstring> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierCustomer_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerModelAndstring> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierCustomer_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerModelAndstring> }
    > = props => {
        const { data } = props ?? {};

        return supplierCustomer_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierCustomerFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof supplierCustomer_findById>>>;
export type SupplierCustomerFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvSupplierCustomerModelAndstring>;
export type SupplierCustomerFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useSupplierCustomerFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomer_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerModelAndstring> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierCustomerFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const supplierCustomer_findByViewDataRequest = (
    viewDataRequestModelOfEfcvSupplierCustomerModel: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvSupplierCustomerModel | void>(
        {
            url: `/factoring/efcvsuppliercustomer/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvSupplierCustomerModel,
        },
        options,
    );
};

export const getSupplierCustomerFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomer_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierCustomer_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierCustomer_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerModel> }
    > = props => {
        const { data } = props ?? {};

        return supplierCustomer_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierCustomerFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierCustomer_findByViewDataRequest>>
>;
export type SupplierCustomerFindByViewDataRequestMutationBody =
    BodyType<ViewDataRequestModelOfEfcvSupplierCustomerModel>;
export type SupplierCustomerFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useSupplierCustomerFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomer_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierCustomerFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
