import { FileType } from '../constants';
import { createCSRFromSelfSignedCertificate, getSelfSignedCertificate } from '../lib/pki';
import { createFilenameFromCertificate, retrievePkcs12 } from '../lib/storage';
import { loadCertificates } from './loadCertificates';

/**
 *
 * @param signingRequest - PEM encoded certificate signing request (returned from `createCertificateSigningRequest`)
 * @param filename - `${string}.csr`
 */
export async function downloadSigningRequest() {
    const certificates = await loadCertificates();
    const selfSignedCertificate = getSelfSignedCertificate(certificates);

    const csrPem = await createCSRFromSelfSignedCertificate(selfSignedCertificate);
    const filename = createFilenameFromCertificate(selfSignedCertificate.certificate);

    return {
        name: `${filename}.csr`,
        content: csrPem,
        type: FileType.SigningRequest,
    } as const;
}

/**
 * Downloads content PKCS #12 as `{firstName}_{lastName}.p12` file from IndexedDB storage or throws `AuthError` with `NO_CERT_IN_STORAGE` error.
 */
export async function downloadPersonalInformationContainer() {
    const pkcs12 = await retrievePkcs12();

    return {
        name: pkcs12.name,
        content: pkcs12.content,
        type: FileType.PersonalInformationContainer,
    } as const;
}
