import { Typography as MUITypography, styled } from '@mui/material';

export const Typography = styled(MUITypography, {
    shouldForwardProp: props => props !== 'notifyUser' && props !== 'warnUser',
})<{ notifyUser?: boolean; warnUser?: boolean }>(({ theme, notifyUser = false, warnUser = false }) => ({
    color: warnUser ? theme.color.alerts.error : theme.palette.text.primary,
    fontWeight: notifyUser || warnUser ? theme.typography.fontWeightBold : theme.typography.paragraphSmall.fontWeight,

    display: 'flex',
    gap: theme.spacing(0.25),

    '& > a': {
        color: 'inherit',
        fontWeight: 'inherit',
        fontSize: 'inherit',
        letterSpacing: 'inherit',
        textDecoration: 'none',
        // unset display:grid
        display: 'unset',

        ...(warnUser && {
            '&:hover': {
                color: theme.color.buttons.primary.redHover,
            },
            '&:active': {
                color: theme.color.buttons.primary.redPressed,
            },
        }),
    },
}));
