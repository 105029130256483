import { forwardRef } from 'react';
import { InputAutocomplete } from '../../../../../modules/form/types';
import { useController, useFormContext } from 'react-hook-form';
import { FieldContainer } from '../../FieldContainer';
import { Select } from '../../inputs';
const SelectFieldComponent = ({ name, onChange, disabled, ...rest }, ref) => {
    const { control, formState: { errors, isSubmitting }, } = useFormContext();
    const { field, fieldState: { error }, } = useController({ control, name });
    const isDisabled = disabled || field.disabled || isSubmitting;
    return (<FieldContainer errors={errors} name={name}>
            {/* @ts-expect-error invalid value type */}
            <Select autoComplete={InputAutocomplete.Off} {...rest} {...field} onChange={value => {
            const nextValue = value || null;
            field.onChange({ target: { value: nextValue } });
            onChange?.(nextValue);
        }} error={!!error} disabled={isDisabled} ref={ref}/>
        </FieldContainer>);
};
export const SelectField = forwardRef(SelectFieldComponent);
