export enum QueryParam {
    DoId = 'doId',
    Id = 'id',
    CertificateIdFrom = 'certificateIdFrom',
    FileId = 'fileId',
    InvoiceType = 'invoiceType',
    SupplierLimitCurrency = 'currency',
    SupplierProductLimitCurrency = 'currency',
    SupplierProductLimitProductId = 'productId',
    SupplierContractLimitCurrency = 'currency',
    SupplierContractLimitContractId = 'contractId',
    SupplierCustomerLimitId = 'limitId',
    PaymentDetailId = 'paymentId',
    BackHref = 'backHref',
    CustomerId = 'customerId',
    PreloadData = 'preloadData',

    CertificateManagementSuccessType = 'successType',

    NewlyCreatedInvoiceId = 'newInvoiceId',
    NewlyCreatedInvoiceMatchingCode = 'newInvoiceMatchingCode',
    BatchImported = 'imported',
}
