import NextLink from 'next/link';
import { FormattedMessage } from 'react-intl';

import { Link } from '@workspace/ui';

import { authRoutes } from '~constants';
import { useGenerateDoIdPath } from '~hooks/useGenerateDoIdPath';

import { ExclamationMark } from './CertificateValidity.styles';
import { useCertificateValidity } from './hooks/useCertificateValidity';
import { ValidityText } from './ValidityText';

export const CertificateValidity = () => {
    const generateDoIdPath = useGenerateDoIdPath();
    const result = useCertificateValidity();

    const displayExclamationMark = Boolean(result.data?.notifyUser || result.data?.warnUser);

    return (
        <ValidityText notifyUser={result.data?.notifyUser} warnUser={result.data?.warnUser}>
            <p>
                <FormattedMessage id='certificateValidity.label' />:
            </p>
            {Number.isInteger(result.data?.remainingDays) ? (
                <Link tabIndex={1} href={generateDoIdPath(authRoutes.createCertificateRenewalRequest)} as={NextLink}>
                    <FormattedMessage
                        id='certificateValidity.validity'
                        values={{ validity: result.data?.remainingDays }}
                    />
                    {displayExclamationMark && (
                        <>
                            &nbsp;
                            <ExclamationMark warn={!!result.data?.warnUser}>
                                <span>!</span>
                            </ExclamationMark>
                        </>
                    )}
                </Link>
            ) : (
                '–'
            )}
        </ValidityText>
    );
};
