import { useQuery } from '@tanstack/react-query';

import { dataService_getSessionTimeout, QueryKey } from '@workspace/api';

export function useGetSessionTimeout() {
    const result = useQuery({
        queryKey: [QueryKey.SESSION_TIMEOUT],
        queryFn: async () => {
            const response = await dataService_getSessionTimeout();
            const timeout = response.data!.response!;

            return Number.isInteger(timeout) ? timeout * 1000 : 0;
        },
        staleTime: Infinity,
        gcTime: Infinity,
    });

    return result.data ?? 0;
}
