import { useMemo, useRef, useState } from 'react';
/**
 *
 * @param ids
 * @param defaultTab
 * @returns
 *
 * @example:
 * ```ts
 * const { activeTab, setActiveTab, tabKeys, tabIds } = useTabs(['holderData', 'issuerData'] as const);
 * ```
 */
export function useTabs(tabIds, defaultTabId = tabIds[0]) {
    const [activeTab, setActiveTab] = useState(defaultTabId);
    const tabIdsRef = useRef(tabIds);
    const tabKeys = useMemo(() => Object.fromEntries(tabIdsRef.current.map(tabId => [tabId, tabId])), []);
    return {
        activeTab,
        setActiveTab,
        tabKeys,
        tabIds,
    };
}
