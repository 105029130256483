import { Stack, Typography } from '@mui/material';
import type { Path } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import {
    IconInfoCircle,
    InputAutocomplete,
    TextField,
    Tooltip,
    type TextFieldProps,
    type UnknownFormValues,
} from '@workspace/ui';

import { TooltipIcon } from './PinField.styles';

export interface PinFieldProps<FormValues extends UnknownFormValues> extends TextFieldProps<FormValues> {
    name: Path<FormValues>;
    confirmation?: boolean;
    tooltip?: boolean;
}

export const PinField = <FormValues extends UnknownFormValues>({
    name,
    confirmation = false,
    tooltip = false,
    ...rest
}: PinFieldProps<FormValues>) => {
    return (
        <Stack direction='row' gap={0.5} alignItems='center'>
            <TextField<FormValues>
                label={<FormattedMessage id={`certificateRequest.form.${confirmation ? 'pinConfirmation' : 'pin'}`} />}
                {...rest}
                autoComplete={InputAutocomplete.Off}
                name={name}
                type='password'
                inputMode='numeric'
            />
            {tooltip && (
                <Tooltip
                    id='certificateRequest.pin.tooltip'
                    title={
                        <Typography variant='paragraphSmall'>
                            <FormattedMessage id='certificateRequest.pin.tooltip' />
                        </Typography>
                    }
                >
                    <TooltipIcon icon={IconInfoCircle} />
                </Tooltip>
            )}
        </Stack>
    );
};
