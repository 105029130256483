import type { ReactNode } from 'react';

import { Typography } from './ValidityText.styles';

export interface ValidityTextProps {
    children: ReactNode;
    notifyUser?: boolean;
    warnUser?: boolean;
}

export const ValidityText = ({ children, notifyUser, warnUser }: ValidityTextProps) => {
    return (
        <Typography variant='paragraphSmall' notifyUser={notifyUser} warnUser={warnUser}>
            {children}
        </Typography>
    );
};
