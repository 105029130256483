import { useMemo, useRef } from 'react';
import { useRouter } from 'next/router';

import { logger } from '@workspace/logger';

export function useRouterQuery<const Keys extends ReadonlyArray<string>, Required extends boolean = true>(
    keys: Keys,
    required: Required = true as Required,
) {
    const { query, isReady } = useRouter();
    const keysRef = useRef(keys);

    return useMemo(() => {
        if (!isReady) {
            return null;
        }

        const entries = keysRef.current.map(key => {
            const value = query[key];
            const parsedValue = typeof value === 'string' ? value : null;

            if (query && parsedValue === null && required) {
                logger.error(
                    new Error(`Missing required query parameter "${key}" in ${JSON.stringify(value, null, 2)}`),
                );
            }

            return [key, parsedValue] as const;
        });

        return Object.fromEntries(entries) as Record<Keys[number], Required extends true ? string : string | null>;
    }, [isReady, query, required]);
}
