import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Button, IconRight, type ButtonProps } from '@workspace/ui';

export interface FormSubmitButtonProps extends ButtonProps {
    startIcon?: React.ReactNode;
}

export const FormSubmitButton = ({
    startIcon = <IconRight />,
    children = <FormattedMessage id='button.next' />,
    ...rest
}: FormSubmitButtonProps) => {
    const {
        formState: { isSubmitting },
    } = useFormContext();

    return (
        <Button variant='primary' color='success' startIcon={startIcon} type='submit' loading={isSubmitting} {...rest}>
            {children}
        </Button>
    );
};
