/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type WhereItemOperator = (typeof WhereItemOperator)[keyof typeof WhereItemOperator];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WhereItemOperator = {
    EQUALS: 'EQUALS',
    EQUALS_CASE_INSENSITIVE: 'EQUALS_CASE_INSENSITIVE',
    EQUALS_OR_NULL: 'EQUALS_OR_NULL',
    OPTIONAL_EQUALS: 'OPTIONAL_EQUALS',
    OPTIONAL_LESS_OR_EQUALS: 'OPTIONAL_LESS_OR_EQUALS',
    NOT_EQUALS: 'NOT_EQUALS',
    GREATER: 'GREATER',
    LESS: 'LESS',
    GREATER_OR_EQUALS: 'GREATER_OR_EQUALS',
    LESS_OR_EQUALS: 'LESS_OR_EQUALS',
    LIKE: 'LIKE',
    LIKE_CASE_INSENSITIVE: 'LIKE_CASE_INSENSITIVE',
    NOT_LIKE: 'NOT_LIKE',
    IS_NULL: 'IS_NULL',
    IS_NOT_NULL: 'IS_NOT_NULL',
    EXISTS: 'EXISTS',
    NOT_EXISTS: 'NOT_EXISTS',
    IN: 'IN',
} as const;
