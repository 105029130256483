import { styled } from '@mui/material';
const x = 2;
export const LoaderContainer = styled('div')(({ theme, height = 'fullBox' }) => ({
    width: '100%',
    margin: 'auto',
    ...(typeof height === 'number' && {
        height: theme.spacing(height),
        maxWidth: theme.spacing(42),
    }),
    ...(height === 'fullBox' && {
        height: '100%',
        minHeight: theme.spacing(32),
        maxWidth: theme.spacing(42),
    }),
    ...(height === 'fullPage' && {
        height: `calc(100% - 72px)`,
        position: 'absolute',
    }),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));
