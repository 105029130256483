import { useCallback } from 'react';

import { QueryKey, useInvalidateQuery } from '@workspace/api';
import { logger } from '@workspace/logger';

import { useShowMessagePopup } from '~modules/message-popup/hooks';

export function useResendVerificationCode(start: () => void) {
    const showMessage = useShowMessagePopup();

    const { invalidateQuery } = useInvalidateQuery();

    const resendVerificationCode = useCallback(async () => {
        try {
            await invalidateQuery(QueryKey.AUTH_SEND_SMS_CODE);
            start();
        } catch (error) {
            logger.error(error);

            await showMessage('error', { id: 'general.error' });
        }
    }, [invalidateQuery, showMessage, start]);

    return {
        resendVerificationCode,
    };
}
