/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvDueInvoiceModel,
    GetByIdRequestModelOfEfcvDueInvoiceModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvDueInvoiceModel,
    ViewDataResultModelOfEfcvDueInvoiceModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const dueInvoice_findByAll = (
    efcvDueInvoiceModel: BodyType<EfcvDueInvoiceModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvDueInvoiceModel[] | void>(
        {
            url: `/factoring/efcvdueinvoice/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvDueInvoiceModel,
        },
        options,
    );
};

export const getDueInvoiceFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dueInvoice_findByAll>>,
        TError,
        { data: BodyType<EfcvDueInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dueInvoice_findByAll>>,
    TError,
    { data: BodyType<EfcvDueInvoiceModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dueInvoice_findByAll>>,
        { data: BodyType<EfcvDueInvoiceModel> }
    > = props => {
        const { data } = props ?? {};

        return dueInvoice_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DueInvoiceFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof dueInvoice_findByAll>>>;
export type DueInvoiceFindByAllMutationBody = BodyType<EfcvDueInvoiceModel>;
export type DueInvoiceFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useDueInvoiceFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dueInvoice_findByAll>>,
        TError,
        { data: BodyType<EfcvDueInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDueInvoiceFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const dueInvoice_findById = (
    getByIdRequestModelOfEfcvDueInvoiceModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvDueInvoiceModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvDueInvoiceModel | void>(
        {
            url: `/factoring/efcvdueinvoice/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvDueInvoiceModelAndbigdecimal,
        },
        options,
    );
};

export const getDueInvoiceFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dueInvoice_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvDueInvoiceModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dueInvoice_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvDueInvoiceModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dueInvoice_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvDueInvoiceModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return dueInvoice_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DueInvoiceFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof dueInvoice_findById>>>;
export type DueInvoiceFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvDueInvoiceModelAndbigdecimal>;
export type DueInvoiceFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useDueInvoiceFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dueInvoice_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvDueInvoiceModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDueInvoiceFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const dueInvoice_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvDueInvoiceModel | void>(
        {
            url: `/factoring/efcvdueinvoice/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getDueInvoiceFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dueInvoice_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dueInvoice_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dueInvoice_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return dueInvoice_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DueInvoiceFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof dueInvoice_findByLightDataRequest>>
>;
export type DueInvoiceFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type DueInvoiceFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useDueInvoiceFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dueInvoice_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDueInvoiceFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const dueInvoice_findByViewDataRequest = (
    viewDataRequestModelOfEfcvDueInvoiceModel: BodyType<ViewDataRequestModelOfEfcvDueInvoiceModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvDueInvoiceModel | void>(
        {
            url: `/factoring/efcvdueinvoice/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvDueInvoiceModel,
        },
        options,
    );
};

export const getDueInvoiceFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dueInvoice_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvDueInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof dueInvoice_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvDueInvoiceModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof dueInvoice_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvDueInvoiceModel> }
    > = props => {
        const { data } = props ?? {};

        return dueInvoice_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DueInvoiceFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof dueInvoice_findByViewDataRequest>>
>;
export type DueInvoiceFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvDueInvoiceModel>;
export type DueInvoiceFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useDueInvoiceFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof dueInvoice_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvDueInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDueInvoiceFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
