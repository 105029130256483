import { useRouter } from 'next/router';
import { Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Card } from '@workspace/ui';

import { appConfig } from '~config';
import { routes } from '~constants';
import { phoneNumberAtom } from '~modules/auth/atoms';
import { FormSubmitButton, PhoneNumberField } from '~modules/form/components';
import { useLocalizedResolver } from '~modules/form/hooks';

import { setPhoneNumberSchema, type SetPhoneNumberSchema } from './schema';

export const SetPhoneNumberForm = () => {
    const { push } = useRouter();
    const [phoneNumber, setPhoneNumber] = useAtom(phoneNumberAtom);
    const form = useForm<SetPhoneNumberSchema>({
        resolver: useLocalizedResolver(setPhoneNumberSchema),
        defaultValues: { phoneNumber },
        mode: appConfig.formValidationMode,
    });

    const onSubmit = async ({ phoneNumber }: SetPhoneNumberSchema) => {
        setPhoneNumber(phoneNumber);
        await push(routes.verifyPhoneNumber);
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Card>
                    <PhoneNumberField<SetPhoneNumberSchema>
                        name='phoneNumber'
                        label={<FormattedMessage id='auth.phone.label' />}
                    />
                </Card>

                <Stack mt={2} flexDirection='row' justifyContent='flex-end'>
                    <FormSubmitButton>
                        <FormattedMessage id='auth.phone.submit' />
                    </FormSubmitButton>
                </Stack>
            </form>
        </FormProvider>
    );
};
