import { CertificationRequest } from 'pkijs';

import { AuthError } from '@workspace/errors';

import { parseAttributes } from './attributes';
import { getSubjectFields } from './parsedCertificate';
import { decodeBase64, parsePem } from './utils';

/**
 * Parse PKCS #10 v1.7 – Certificate Signing Request (CSR) object encoded in PEM format that's again encoded in Based64.
 */
export async function parseCertificateSigningRequest(csrPemBased64: string) {
    const csrBuffer = parsePem(decodeBase64(csrPemBased64));

    const pkcs10 = CertificationRequest.fromBER(csrBuffer);

    const verified = await pkcs10.verify();

    if (!verified) {
        throw new AuthError('UNVERIFIED_CERTIFICATE_REQUEST');
    }

    return pkcs10;
}

export function extractCertificationRequestSubject(pkcs10: CertificationRequest) {
    return getSubjectFields(parseAttributes(pkcs10.subject.typesAndValues));
}
