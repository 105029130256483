import { useCallback } from 'react';
import countries from 'i18n-iso-countries';
import { useIntl } from 'react-intl';

import { registerLocalizedCountries } from '../utils';

export function useGetCountryName() {
    const { locale } = useIntl();

    return useCallback(
        async (countryCode: string) => {
            await registerLocalizedCountries(locale);

            return countries.getName(countryCode, locale) ?? '';
        },
        [locale],
    );
}
