import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Dialog } from '@workspace/ui';

export interface InactivityPromptDialogProps {
    open: boolean;
    onCancel: () => void | Promise<void>;
    onSubmit: () => void | Promise<void>;
}

export const InactivityPromptDialog = ({ open, onCancel, onSubmit }: InactivityPromptDialogProps) => {
    return (
        <Dialog
            heading={<FormattedMessage id='autoLogout.prompt.text1' />}
            open={open}
            minWidth='unset'
            buttons={{
                cancel: {
                    children: <FormattedMessage id='button.no' />,
                    onClick: onCancel,
                },
                submit: {
                    children: <FormattedMessage id='button.yes' />,
                    onClick: onSubmit,
                },
            }}
        >
            <Typography variant='paragraphMedium' mt={1.5}>
                <FormattedMessage id='autoLogout.prompt.text2' tagName='p' />
            </Typography>
        </Dialog>
    );
};
