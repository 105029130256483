import { styled } from '@mui/material';
export const CheckboxGrid = styled('div', { shouldForwardProp: prop => prop !== 'columnCount' })(({ theme, columnCount = 3 }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
    columnGap: theme.spacing(0.5),
    rowGap: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflowY: 'auto',
    position: 'relative',
}));
