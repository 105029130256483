import { useState } from 'react';
import { Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { TwoFactorRequirement, useSession } from '@workspace/auth';
import { Card } from '@workspace/ui';

import { appConfig } from '~config';
import { routes } from '~constants';
import { loginPromptModalAtom } from '~modules/auth/atoms/loginPrompt';
import { FormBackButton, FormSubmitButton, PhoneNumberField } from '~modules/form/components';
import { useLocalizedResolver } from '~modules/form/hooks';
import { ResendEmailVerificationCode } from '~modules/phone-number/components';
import { useSmsCodeValidity } from '~modules/phone-number/hooks';

import { AuthenticateModal } from '../../AuthenticateModal';
import { useSubmitVerifyNumberForm } from './hooks/useSubmitVerifyNumberForm';
import { smsCodeFormSchema, type SmsCodeFormSchema } from './schema';

export interface VerifyPhoneNumberFormProps {
    phoneNumber: string;
}

export const VerifyPhoneNumberForm = ({ phoneNumber }: VerifyPhoneNumberFormProps) => {
    const [openModal, setOpenModal] = useAtom(loginPromptModalAtom);
    const [codeExpired, setCodeExpired] = useState(false);
    const validity = useSmsCodeValidity(phoneNumber);
    const { status, data } = useSession();

    const form = useForm<SmsCodeFormSchema>({
        resolver: useLocalizedResolver(smsCodeFormSchema),
        mode: appConfig.formValidationMode,
    });

    const submit = useSubmitVerifyNumberForm(form);

    return (
        <>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(submit)}>
                    <Card>
                        <Stack direction='row' alignItems='flex-start' gap={1}>
                            <div>
                                <PhoneNumberField<SmsCodeFormSchema>
                                    name='code'
                                    label={<FormattedMessage id='phoneNumber.verify.code' />}
                                />
                            </div>
                            <ResendEmailVerificationCode validity={validity} setCodeExpired={setCodeExpired} />
                        </Stack>
                    </Card>

                    <Stack mt={1.5} mb={2.5} flexDirection='row' justifyContent='space-between'>
                        <FormBackButton
                            backRoute={
                                status === '2FA-requirement' && data === TwoFactorRequirement.SET_PHONE_NUMBER
                                    ? routes.setPhoneNumber
                                    : routes.login
                            }
                        />
                        <FormSubmitButton disabled={codeExpired}>
                            <FormattedMessage id='button.continue' />
                        </FormSubmitButton>
                    </Stack>
                </form>
            </FormProvider>

            <AuthenticateModal open={openModal} onClose={setOpenModal} />
        </>
    );
};
