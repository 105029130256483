const SvgEn = (props) => (<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' viewBox='0 0 24 24' {...props}>
        <g clipPath='url(#EN_svg__a)'>
            <g clipPath='url(#EN_svg__b)'>
                <rect width={16} height={11.429} x={4} y={6.286} fill='#1A47B8' rx={2}/>
                <path fill='#fff' fillRule='evenodd' d='M5.702 6.286H4V8.19l14.289 9.524H20V15.81L5.702 6.286Z' clipRule='evenodd'/>
                <path fill='#F93939' d='M4.568 6.286 20 16.598v1.116h-.554L4 7.391V6.286h.568Z'/>
                <path fill='#fff' fillRule='evenodd' d='M18.476 6.286H20V8.19s-9.897 6.346-14.476 9.524H4V15.81l14.476-9.524Z' clipRule='evenodd'/>
                <path fill='#F93939' d='M20 6.286h-.517L4 16.607v1.107h.568L20 7.4V6.286Z'/>
                <path fill='#fff' fillRule='evenodd' d='M9.819 6.286h4.376V9.81H20v4.376h-5.805v3.527H9.819v-3.527H4V9.81h5.819V6.286Z' clipRule='evenodd'/>
                <path fill='#F93939' fillRule='evenodd' d='M10.737 6.286h2.526v4.395H20v2.638h-6.737v4.395h-2.526V13.32H4V10.68h6.737V6.286Z' clipRule='evenodd'/>
            </g>
        </g>
        <defs>
            <clipPath id='EN_svg__a'>
                <path fill='#fff' d='M4 6.286h16v11.429H4z'/>
            </clipPath>
            <clipPath id='EN_svg__b'>
                <rect width={16} height={11.429} x={4} y={6.286} fill='#fff' rx={2}/>
            </clipPath>
        </defs>
    </svg>);
export default SvgEn;
