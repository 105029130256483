import type { PermData } from '@workspace/api';

import type { UserUtils } from '../hooks';
import type { PermUtils } from '../hooks/usePermissionUtils';
import { OperationPermission } from './operationPermission';

type PermissionsCheckParams = {
    userUtils: UserUtils;
    permUtils: PermUtils;
    permData: PermData;
};

export type PermissionsCheckFn = (params: PermissionsCheckParams) => boolean;

const checkCommonExportsPermissions: PermissionsCheckFn = ({ permUtils }) =>
    permUtils.hasRoleDInGroupF || permUtils.hasRoleOInGroupZ;

const withCommonExportsPermissions: (fn: PermissionsCheckFn) => PermissionsCheckFn = fn => {
    return params => checkCommonExportsPermissions(params) && fn(params);
};

export const modulePermissions = {
    // limits
    supplierLimits: ({ permData, userUtils }) =>
        !!permData.supplierLimitsExist && userUtils.isAllowedOperation(OperationPermission.VIEW_SUPPLIER_LIMIT),
    supplierProductLimits: ({ permData, userUtils }) =>
        !!permData.productLimitsExist && userUtils.isAllowedOperation(OperationPermission.VIEW_SUPPLIER_PRODUCT_LIMIT),
    supplierContractLimits: ({ permData, userUtils }) =>
        !!permData.contractLimitsExist &&
        userUtils.isAllowedOperation(OperationPermission.VIEW_SUPPLIER_CONTRACT_LIMIT),
    supplierCustomerLimits: ({ permData, userUtils }) =>
        !!permData.supplierCustomerLimitsExist &&
        userUtils.isAllowedOperation(OperationPermission.VIEW_SUPPLIER_CUSTOMER_LIMIT),

    // invoices
    purchasedInvoices: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.VIEW_PURCHASED_INVOICE) && permUtils.hasRoleDInGroupF,
    nonFinancedInvoices: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.REVERSE_FINANCING) && permUtils.hasRoleDInGroupF,
    openInvoices: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.VIEW_OPEN_INVOICE) && permUtils.hasRoleDInGroupF,
    discountInvoices: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.VIEW_DISCOUNT_INVOICE) && permUtils.hasRoleDInGroupD,
    liabilityInvoices: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.VIEW_LIABILITY_INVOICE) && permUtils.hasRoleOInGroupZ,
    issuedInvoices: ({ userUtils }) => userUtils.isAllowedOperation(OperationPermission.VIEW_ISSUED_INVOICE),
    dueInvoices: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.VIEW_DUE_INVOICE) && permUtils.hasRoleOInOneOfGroupsFID,
    payments: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.VIEW_PAYMENT) && permUtils.hasRoleDInOneOfGroupsFZD,

    // new invoices
    newPurchasedInvoice: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.MODIFY_PURCHASED_INVOICE) &&
        permUtils.hasRoleDProductFAndScfInactive,
    newDiscountInvoice: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.MODIFY_DISCOUNT_INVOICE) &&
        permUtils.hasRoleDProductDAndScfInactive,
    newLiabilityInvoice: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.MODIFY_LIABILITY_INVOICE) && permUtils.hasRoleOInGroupZ,
    newPurchasedInvoiceReverse: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.MODIFY_REVERSE_INVOICE) &&
        permUtils.hasRoleOProductFAndScfActive,
    newDiscountInvoiceReverse: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.MODIFY_DISCOUNT_INVOICE_REVERSE) &&
        permUtils.hasRoleOProductDAndScfActive,

    // batches
    purchasedInvoiceBatches: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.VIEW_BATCH) && permUtils.hasRoleDInGroupF,
    purchasedInvoiceImport: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.INSERT_PURCHASED_INVOICE) &&
        permUtils.canViewInFileDetail &&
        permUtils.hasRoleDInGroupF,
    purchasedInvoiceForCedingBatches: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.VIEW_PURCHASED_INVOICE_FOR_CEDING_BATCH) &&
        permUtils.hasRoleDInGroupF,
    importOfPurchasedInvoiceForCeding: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.INSERT_PURCHASED_INVOICE_FOR_CEDING) &&
        permUtils.canViewInFileDetail &&
        permUtils.hasRoleDInGroupF,
    discountInvoiceBatches: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.VIEW_DISCOUNT_INVOICE_BATCH) && permUtils.hasRoleDInGroupD,
    discountInvoiceImport: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.INSERT_DISCOUNT_INVOICE) &&
        permUtils.canViewInFileDetail &&
        permUtils.hasRoleDInGroupD,
    liabilityInvoiceBatches: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.VIEW_BATCH) && permUtils.hasRoleOInGroupZ,
    liabilityInvoiceImport: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.INSERT_LIABILITY_INVOICE) &&
        permUtils.canViewInFileDetail &&
        permUtils.hasRoleOInGroupZ,
    reverseInvoiceBatches: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.VIEW_BATCH) && permUtils.hasRoleOProductFAndScfActive,
    reverseInvoiceImport: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.INSERT_REVERSE_INVOICE) &&
        permUtils.canViewInFileDetail &&
        permUtils.hasRoleOProductFAndScfActive,
    discountInvoiceBatchesReverse: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.VIEW_DISCOUNT_INVOICE_REVERSE_BATCH) &&
        permUtils.hasRoleOProductDAndScfActive,
    discountInvoiceImportReverse: ({ userUtils, permUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.INSERT_DISCOUNT_INVOICE_REVERSE) &&
        permUtils.canViewInFileDetail &&
        permUtils.hasRoleOProductDAndScfActive,

    // certificates - does not seem we need to check anything
    // exports
    datExports: withCommonExportsPermissions(({ userUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.VIEW_BBS_EXPORT),
    ),
    pdfExports: withCommonExportsPermissions(({ userUtils }) =>
        userUtils.isAllowedOperation(OperationPermission.VIEW_PDF_EXPORT),
    ),
    exportsSchedule: withCommonExportsPermissions(
        ({ userUtils, permData }) =>
            userUtils.isAllowedOperation(OperationPermission.VIEW_EXPORT_SCHEDULE) &&
            !!permData.scheduleManualExportEnabled,
    ),
    // tools - does not seem we need to check anything
} as const satisfies Readonly<Record<string, PermissionsCheckFn>>;
