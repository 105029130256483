import { useQuery } from '@tanstack/react-query';

import { permissionService_getPermissionData, QueryKey } from '@workspace/api';

import { useSelectedSupplier } from '~modules/suppliers/hooks';

export function usePermissionData() {
    const supplier = useSelectedSupplier();
    const { data } = useQuery({
        queryKey: [QueryKey.AUTH_PERMISSIONS, supplier.doId],
        queryFn: () => permissionService_getPermissionData(),
        enabled: !!supplier,
        staleTime: Infinity,
        gcTime: Infinity,
    });

    const permData = data?.data;

    return permData as Exclude<typeof permData, void> | undefined;
}
