import { useCallback } from 'react';
import { encode } from 'js-base64';

import type { GetFileUsingGETType } from '@workspace/api';
import { createAutoClickLink } from '@workspace/common/utils';

export type DownloadParams = {
    name: string;
    type: GetFileUsingGETType;
};

export type Download = (url: URL, params?: DownloadParams) => void;

export function useDownload() {
    const downloadFile = useCallback<Download>((url, params) => {
        const newUrl = new URL(url);

        if (params) {
            newUrl.searchParams.append('fn', encode(encodeURIComponent(params.name), false));
            newUrl.searchParams.append('type', params.type);
        }

        createAutoClickLink(newUrl.toString(), params?.name);
    }, []);

    return downloadFile;
}
