/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvDoidProdLimitHistModel,
    GetByIdRequestModelOfEfcvDoidProdLimitHistModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvDoidProdLimitHistModel,
    ViewDataResultModelOfEfcvDoidProdLimitHistModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const doidProdLimitHist_findByAll = (
    efcvDoidProdLimitHistModel: BodyType<EfcvDoidProdLimitHistModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvDoidProdLimitHistModel[] | void>(
        {
            url: `/factoring/efcvdoidprodlimithist/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvDoidProdLimitHistModel,
        },
        options,
    );
};

export const getDoidProdLimitHistFindByAllMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidProdLimitHist_findByAll>>,
        TError,
        { data: BodyType<EfcvDoidProdLimitHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof doidProdLimitHist_findByAll>>,
    TError,
    { data: BodyType<EfcvDoidProdLimitHistModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof doidProdLimitHist_findByAll>>,
        { data: BodyType<EfcvDoidProdLimitHistModel> }
    > = props => {
        const { data } = props ?? {};

        return doidProdLimitHist_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DoidProdLimitHistFindByAllMutationResult = NonNullable<
    Awaited<ReturnType<typeof doidProdLimitHist_findByAll>>
>;
export type DoidProdLimitHistFindByAllMutationBody = BodyType<EfcvDoidProdLimitHistModel>;
export type DoidProdLimitHistFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useDoidProdLimitHistFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidProdLimitHist_findByAll>>,
        TError,
        { data: BodyType<EfcvDoidProdLimitHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDoidProdLimitHistFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const doidProdLimitHist_findById = (
    getByIdRequestModelOfEfcvDoidProdLimitHistModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvDoidProdLimitHistModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvDoidProdLimitHistModel | void>(
        {
            url: `/factoring/efcvdoidprodlimithist/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvDoidProdLimitHistModelAndbigdecimal,
        },
        options,
    );
};

export const getDoidProdLimitHistFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidProdLimitHist_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvDoidProdLimitHistModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof doidProdLimitHist_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvDoidProdLimitHistModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof doidProdLimitHist_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvDoidProdLimitHistModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return doidProdLimitHist_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DoidProdLimitHistFindByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof doidProdLimitHist_findById>>
>;
export type DoidProdLimitHistFindByIdMutationBody =
    BodyType<GetByIdRequestModelOfEfcvDoidProdLimitHistModelAndbigdecimal>;
export type DoidProdLimitHistFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useDoidProdLimitHistFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidProdLimitHist_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvDoidProdLimitHistModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDoidProdLimitHistFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const doidProdLimitHist_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvDoidProdLimitHistModel | void>(
        {
            url: `/factoring/efcvdoidprodlimithist/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getDoidProdLimitHistFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidProdLimitHist_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof doidProdLimitHist_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof doidProdLimitHist_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return doidProdLimitHist_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DoidProdLimitHistFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof doidProdLimitHist_findByLightDataRequest>>
>;
export type DoidProdLimitHistFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type DoidProdLimitHistFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useDoidProdLimitHistFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidProdLimitHist_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDoidProdLimitHistFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const doidProdLimitHist_findByViewDataRequest = (
    viewDataRequestModelOfEfcvDoidProdLimitHistModel: BodyType<ViewDataRequestModelOfEfcvDoidProdLimitHistModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvDoidProdLimitHistModel | void>(
        {
            url: `/factoring/efcvdoidprodlimithist/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvDoidProdLimitHistModel,
        },
        options,
    );
};

export const getDoidProdLimitHistFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidProdLimitHist_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvDoidProdLimitHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof doidProdLimitHist_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvDoidProdLimitHistModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof doidProdLimitHist_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvDoidProdLimitHistModel> }
    > = props => {
        const { data } = props ?? {};

        return doidProdLimitHist_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DoidProdLimitHistFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof doidProdLimitHist_findByViewDataRequest>>
>;
export type DoidProdLimitHistFindByViewDataRequestMutationBody =
    BodyType<ViewDataRequestModelOfEfcvDoidProdLimitHistModel>;
export type DoidProdLimitHistFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useDoidProdLimitHistFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof doidProdLimitHist_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvDoidProdLimitHistModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDoidProdLimitHistFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
