import { styled } from '@mui/material';

import { Button as UIButton } from '@workspace/ui';

export const Button = styled(UIButton)({
    textTransform: 'uppercase',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,

    '& > svg': {
        width: 24,
        height: 24,
    },
});
