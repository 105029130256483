/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvCustomerSettlementModel,
    GetByIdRequestModelOfEfcvCustomerSettlementModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvCustomerSettlementModel,
    ViewDataResultModelOfEfcvCustomerSettlementModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const customerSettlement_findByAll = (
    efcvCustomerSettlementModel: BodyType<EfcvCustomerSettlementModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvCustomerSettlementModel[] | void>(
        {
            url: `/factoring/efcvcustomersettlement/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvCustomerSettlementModel,
        },
        options,
    );
};

export const getCustomerSettlementFindByAllMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof customerSettlement_findByAll>>,
        TError,
        { data: BodyType<EfcvCustomerSettlementModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof customerSettlement_findByAll>>,
    TError,
    { data: BodyType<EfcvCustomerSettlementModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof customerSettlement_findByAll>>,
        { data: BodyType<EfcvCustomerSettlementModel> }
    > = props => {
        const { data } = props ?? {};

        return customerSettlement_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CustomerSettlementFindByAllMutationResult = NonNullable<
    Awaited<ReturnType<typeof customerSettlement_findByAll>>
>;
export type CustomerSettlementFindByAllMutationBody = BodyType<EfcvCustomerSettlementModel>;
export type CustomerSettlementFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useCustomerSettlementFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof customerSettlement_findByAll>>,
        TError,
        { data: BodyType<EfcvCustomerSettlementModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getCustomerSettlementFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const customerSettlement_findById = (
    getByIdRequestModelOfEfcvCustomerSettlementModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvCustomerSettlementModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvCustomerSettlementModel | void>(
        {
            url: `/factoring/efcvcustomersettlement/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvCustomerSettlementModelAndbigdecimal,
        },
        options,
    );
};

export const getCustomerSettlementFindByIdMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof customerSettlement_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvCustomerSettlementModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof customerSettlement_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvCustomerSettlementModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof customerSettlement_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvCustomerSettlementModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return customerSettlement_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CustomerSettlementFindByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof customerSettlement_findById>>
>;
export type CustomerSettlementFindByIdMutationBody =
    BodyType<GetByIdRequestModelOfEfcvCustomerSettlementModelAndbigdecimal>;
export type CustomerSettlementFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useCustomerSettlementFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof customerSettlement_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvCustomerSettlementModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getCustomerSettlementFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const customerSettlement_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvCustomerSettlementModel | void>(
        {
            url: `/factoring/efcvcustomersettlement/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getCustomerSettlementFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof customerSettlement_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof customerSettlement_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof customerSettlement_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return customerSettlement_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CustomerSettlementFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof customerSettlement_findByLightDataRequest>>
>;
export type CustomerSettlementFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type CustomerSettlementFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useCustomerSettlementFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof customerSettlement_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getCustomerSettlementFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const customerSettlement_findByViewDataRequest = (
    viewDataRequestModelOfEfcvCustomerSettlementModel: BodyType<ViewDataRequestModelOfEfcvCustomerSettlementModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvCustomerSettlementModel | void>(
        {
            url: `/factoring/efcvcustomersettlement/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvCustomerSettlementModel,
        },
        options,
    );
};

export const getCustomerSettlementFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof customerSettlement_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvCustomerSettlementModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof customerSettlement_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvCustomerSettlementModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof customerSettlement_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvCustomerSettlementModel> }
    > = props => {
        const { data } = props ?? {};

        return customerSettlement_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CustomerSettlementFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof customerSettlement_findByViewDataRequest>>
>;
export type CustomerSettlementFindByViewDataRequestMutationBody =
    BodyType<ViewDataRequestModelOfEfcvCustomerSettlementModel>;
export type CustomerSettlementFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useCustomerSettlementFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof customerSettlement_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvCustomerSettlementModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getCustomerSettlementFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
