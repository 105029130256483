import { useQuery } from '@tanstack/react-query';

import { QueryKey, xmlService_getNoticeBoard } from '@workspace/api';

import { useLang } from '~modules/intl';

export function useGetNoticeboard() {
    const lang = useLang();

    const result = useQuery({
        queryKey: [QueryKey.GET_NOTICE_BOARD, lang],
        queryFn: async () => await xmlService_getNoticeBoard({ lang }),
    });

    return { data: result.data?.data?.rowDataList };
}
