/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    CheckFileFormatRequestModel,
    CheckFileFormatResponseModel,
    ImportInvoiceRequestModel,
    ImportInvoiceResponseModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Check the format of import file
 */
export const importService_checkFileFormat = (
    checkFileFormatRequestModel: BodyType<CheckFileFormatRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<CheckFileFormatResponseModel | void>(
        {
            url: `/factoring/importservice/checkFileFormat`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: checkFileFormatRequestModel,
        },
        options,
    );
};

export const getImportServiceCheckFileFormatMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof importService_checkFileFormat>>,
        TError,
        { data: BodyType<CheckFileFormatRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof importService_checkFileFormat>>,
    TError,
    { data: BodyType<CheckFileFormatRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof importService_checkFileFormat>>,
        { data: BodyType<CheckFileFormatRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return importService_checkFileFormat(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ImportServiceCheckFileFormatMutationResult = NonNullable<
    Awaited<ReturnType<typeof importService_checkFileFormat>>
>;
export type ImportServiceCheckFileFormatMutationBody = BodyType<CheckFileFormatRequestModel>;
export type ImportServiceCheckFileFormatMutationError = ErrorType<unknown>;

/**
 * @summary Check the format of import file
 */
export const useImportServiceCheckFileFormat = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof importService_checkFileFormat>>,
        TError,
        { data: BodyType<CheckFileFormatRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getImportServiceCheckFileFormatMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Import file with invoices
 */
export const importService_importInvoice = (
    importInvoiceRequestModel: BodyType<ImportInvoiceRequestModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ImportInvoiceResponseModel | void>(
        {
            url: `/factoring/importservice/importInvoice`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: importInvoiceRequestModel,
        },
        options,
    );
};

export const getImportServiceImportInvoiceMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof importService_importInvoice>>,
        TError,
        { data: BodyType<ImportInvoiceRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof importService_importInvoice>>,
    TError,
    { data: BodyType<ImportInvoiceRequestModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof importService_importInvoice>>,
        { data: BodyType<ImportInvoiceRequestModel> }
    > = props => {
        const { data } = props ?? {};

        return importService_importInvoice(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ImportServiceImportInvoiceMutationResult = NonNullable<
    Awaited<ReturnType<typeof importService_importInvoice>>
>;
export type ImportServiceImportInvoiceMutationBody = BodyType<ImportInvoiceRequestModel>;
export type ImportServiceImportInvoiceMutationError = ErrorType<unknown>;

/**
 * @summary Import file with invoices
 */
export const useImportServiceImportInvoice = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof importService_importInvoice>>,
        TError,
        { data: BodyType<ImportInvoiceRequestModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getImportServiceImportInvoiceMutationOptions(options);

    return useMutation(mutationOptions);
};
