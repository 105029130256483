import { Link as MUILink, styled } from '@mui/material';
export const Link = styled(MUILink)(({ theme, as }) => ({
    color: theme.color.foregrounds.primary,
    fontFamily: theme.typography.fontFamily,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: theme.spacing(0.5),
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    cursor: 'pointer',
    '&:hover': {
        color: theme.color.buttons.secondary.hover,
    },
    '&:active': {
        color: theme.color.buttons.secondary.pressed,
    },
    ...(as === 'button' && {
        padding: 0,
        background: 'transparent',
        border: 'none',
        textDecoration: 'underline',
        '&:focus-within': {
            outline: '2px solid black',
        },
        '&:focus-visible': {
            outline: '2px solid black',
        },
    }),
}));
export const Icon = styled('span')(({ iconSize = 24 }) => ({
    display: 'block',
    width: iconSize,
    height: iconSize,
    '& > svg': {
        width: '100%',
        height: '100%',
        color: 'inherit',
    },
}));
