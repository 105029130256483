import * as asn1js from 'asn1js';
import {
    Attribute,
    AuthenticatedSafe,
    CertBag,
    PFX,
    PKCS8ShroudedKeyBag,
    SafeBag,
    SafeContents,
    type Certificate,
    type PrivateKeyInfo,
} from 'pkijs';

import { AuthError } from '@workspace/errors';

import { getStoredPassword } from '../password';
import { P12_HASHING_CONFIG, SYMMETRIC_ENCRYPTION_CONFIG } from './config';
import { iterateSafeBags } from './safeBags';
import { AttributeType, IntegrityMode, PrivacyMode, SafeBagId, type AuthSafeSafeContent } from './types';
import { getRandomBytes } from './utils';

export type SafeBagsGroups = {
    [SafeBagId.PKCS8ShroudedKeyBag]: SafeBag<PKCS8ShroudedKeyBag>[];
    [SafeBagId.CertBag]: SafeBag<CertBag>[];
    // NOTE: There're could be rest of the bags, but we don't need them in this app yet.
};

type PKCS8ShroudedKeyBagEncryptionParams = Parameters<
    SafeBag<PKCS8ShroudedKeyBag>['bagValue']['makeInternalValues']
>[0];

// IDEA:
// We use could Argon2id then pass the reuslt to PBKDF2 with low iteration count, thus improve performance and maintain security.
export async function encryptPKCS12(pkcs12: PFX) {
    const authSafe = pkcs12.parsedValue?.authenticatedSafe;

    if (!authSafe) {
        throw new AuthError('UNKNOWN', 'Failed to encrypt PKCS #12, the authenticatedSafe is empty.');
    }

    const password = await getStoredPassword();

    const encryptionParams = {
        contentEncryptionAlgorithm: {
            name: SYMMETRIC_ENCRYPTION_CONFIG.name,
            length: SYMMETRIC_ENCRYPTION_CONFIG.length,
            iv: getRandomBytes(SYMMETRIC_ENCRYPTION_CONFIG.IVBytesLength),
        },
        hmacHashAlgorithm: P12_HASHING_CONFIG.privacy.hash,
        iterationCount: P12_HASHING_CONFIG.privacy.iterations,
        password,
    } satisfies PKCS8ShroudedKeyBagEncryptionParams;

    const safeContents = authSafe.parsedValue.safeContents;

    // Encode internal values for "PKCS8ShroudedKeyBag" (protect private keys with password)
    for (const safeBag of iterateSafeBags(safeContents, [SafeBagId.PKCS8ShroudedKeyBag])) {
        const keyBag = safeBag as SafeBag<PKCS8ShroudedKeyBag>;

        await keyBag.bagValue.makeInternalValues(encryptionParams);
    }

    // Encode internal values for all "SafeContents" firts (create all "Privacy Protection" envelopes)
    await authSafe.makeInternalValues({
        safeContents: safeContents.map(() => encryptionParams),
    });

    // Encode internal values for "Integrity Protection" envelope
    await pkcs12.makeInternalValues({
        pbkdf2HashAlgorithm: P12_HASHING_CONFIG.integrity.hash,
        hmacHashAlgorithm: P12_HASHING_CONFIG.integrity.hash,
        iterations: P12_HASHING_CONFIG.integrity.iterations,
        password,
    });
}

// ?? why the bags are in separate safeContents
/**
 * Creates PKCS #12 v1.1 PFX object with certificate and private key, protected by a password.
 * - Each bags group is stored in separate SafeContent.
 */
export async function createPKCS12FromSafeBags(safeContents: AuthSafeSafeContent[]) {
    const authenticatedSafe = new AuthenticatedSafe({
        parsedValue: { safeContents },
    });

    const pkcs12 = new PFX({
        parsedValue: {
            integrityMode: IntegrityMode.HMACBased,
            authenticatedSafe,
        },
    });

    await encryptPKCS12(pkcs12);

    return pkcs12;
}

export function createCertBag(certificate: Certificate, bagAttributes: Attribute[]) {
    return new SafeBag({
        bagId: SafeBagId.CertBag,
        bagValue: new CertBag({ parsedValue: certificate }),
        bagAttributes,
    });
}

export function createPKCS8ShroudedKeyBag(privateKeyInfo: PrivateKeyInfo, bagAttributes: Attribute[]) {
    return new SafeBag({
        bagId: SafeBagId.PKCS8ShroudedKeyBag,
        bagValue: new PKCS8ShroudedKeyBag({
            parsedValue: privateKeyInfo,
        }),
        bagAttributes,
    });
}

/**
 * Creates common attributes for certificate & key bag to link them together.
 */
async function createCommonAttributes(certificate: Certificate | undefined) {
    const localKeyIdBuffer = certificate ? await certificate.getKeyHash('SHA-256') : getRandomBytes(20);

    return [
        new Attribute({
            type: AttributeType.LocalKeyId,
            values: [new asn1js.OctetString({ valueHex: localKeyIdBuffer })],
        }),
    ] as const satisfies Attribute[];
}

export const createPasswordProtectedSafeContent = (safeBags: SafeBag[]): AuthSafeSafeContent => ({
    privacyMode: PrivacyMode.PasswordBased,
    value: new SafeContents({ safeBags }),
});

export type CertificateWithKey = readonly [PrivateKeyInfo | null, Certificate];

/**
 * Creates PKCS #12 v1.1 PFX object with certificate and private key, protected by a password.

 * - Privacy mode: Password-based privacy mode
 *      - Content encryption algorithm: AES-GCM with 256-bit key
 *      - HMAC hash algorithm: SHA-512
 *      - Iteration count: 210_000

 * - Integrity mode: HMAC-based integrity mode
 *      - PBKDF2 hash algorithm: SHA-512
 *      - HMAC hash algorithm: SHA-512
 *      - Iteration count: 100_000

 * Checkout `SYMMETRIC_ENCRYPTION_CONFIG` and `P12_HASHING_CONFIG` for current setting values.
 */
export async function createPersonalInformationContainer(
    keys: PrivateKeyInfo[],
    certificatesChains: ReadonlyArray<Certificate>[],
) {
    // TODO: find out how to link keys with certificates, how to derive public key from private key and compare it with certificate's public key

    const safeContens: AuthSafeSafeContent[] = [];

    for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
        const key = keys[keyIndex];
        const certificatesChain = certificatesChains[keyIndex];

        const bagAttributes = await createCommonAttributes(certificatesChain[0]);

        const certBags = certificatesChain.map(certificate => createCertBag(certificate, bagAttributes));

        safeContens.push(createPasswordProtectedSafeContent(certBags));

        const keyBag = createPKCS8ShroudedKeyBag(key, bagAttributes);

        safeContens.push(createPasswordProtectedSafeContent([keyBag]));
    }

    return createPKCS12FromSafeBags(safeContens);
}
