/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvOutFileModel,
    GetByIdRequestModelOfEfcvOutFileModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvOutFileModel,
    ViewDataResultModelOfEfcvOutFileModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const outFile_findByAll = (
    efcvOutFileModel: BodyType<EfcvOutFileModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvOutFileModel[] | void>(
        {
            url: `/factoring/efcvoutfile/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvOutFileModel,
        },
        options,
    );
};

export const getOutFileFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof outFile_findByAll>>,
        TError,
        { data: BodyType<EfcvOutFileModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof outFile_findByAll>>,
    TError,
    { data: BodyType<EfcvOutFileModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof outFile_findByAll>>,
        { data: BodyType<EfcvOutFileModel> }
    > = props => {
        const { data } = props ?? {};

        return outFile_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type OutFileFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof outFile_findByAll>>>;
export type OutFileFindByAllMutationBody = BodyType<EfcvOutFileModel>;
export type OutFileFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useOutFileFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof outFile_findByAll>>,
        TError,
        { data: BodyType<EfcvOutFileModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getOutFileFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const outFile_findById = (
    getByIdRequestModelOfEfcvOutFileModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvOutFileModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvOutFileModel | void>(
        {
            url: `/factoring/efcvoutfile/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvOutFileModelAndbigdecimal,
        },
        options,
    );
};

export const getOutFileFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof outFile_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvOutFileModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof outFile_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvOutFileModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof outFile_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvOutFileModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return outFile_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type OutFileFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof outFile_findById>>>;
export type OutFileFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvOutFileModelAndbigdecimal>;
export type OutFileFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useOutFileFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof outFile_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvOutFileModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getOutFileFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const outFile_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvOutFileModel | void>(
        {
            url: `/factoring/efcvoutfile/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getOutFileFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof outFile_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof outFile_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof outFile_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return outFile_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type OutFileFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof outFile_findByLightDataRequest>>
>;
export type OutFileFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type OutFileFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useOutFileFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof outFile_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getOutFileFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const outFile_findByViewDataRequest = (
    viewDataRequestModelOfEfcvOutFileModel: BodyType<ViewDataRequestModelOfEfcvOutFileModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvOutFileModel | void>(
        {
            url: `/factoring/efcvoutfile/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvOutFileModel,
        },
        options,
    );
};

export const getOutFileFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof outFile_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvOutFileModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof outFile_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvOutFileModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof outFile_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvOutFileModel> }
    > = props => {
        const { data } = props ?? {};

        return outFile_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type OutFileFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof outFile_findByViewDataRequest>>
>;
export type OutFileFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvOutFileModel>;
export type OutFileFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useOutFileFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof outFile_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvOutFileModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getOutFileFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
