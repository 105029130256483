/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvCommissionModel,
    GetByIdRequestModelOfEfcvCommissionModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvCommissionModel,
    ViewDataResultModelOfEfcvCommissionModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const commission_findByAll = (
    efcvCommissionModel: BodyType<EfcvCommissionModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvCommissionModel[] | void>(
        {
            url: `/factoring/efcvcommission/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvCommissionModel,
        },
        options,
    );
};

export const getCommissionFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof commission_findByAll>>,
        TError,
        { data: BodyType<EfcvCommissionModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof commission_findByAll>>,
    TError,
    { data: BodyType<EfcvCommissionModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof commission_findByAll>>,
        { data: BodyType<EfcvCommissionModel> }
    > = props => {
        const { data } = props ?? {};

        return commission_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CommissionFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof commission_findByAll>>>;
export type CommissionFindByAllMutationBody = BodyType<EfcvCommissionModel>;
export type CommissionFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useCommissionFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof commission_findByAll>>,
        TError,
        { data: BodyType<EfcvCommissionModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getCommissionFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const commission_findById = (
    getByIdRequestModelOfEfcvCommissionModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvCommissionModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvCommissionModel | void>(
        {
            url: `/factoring/efcvcommission/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvCommissionModelAndbigdecimal,
        },
        options,
    );
};

export const getCommissionFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof commission_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvCommissionModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof commission_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvCommissionModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof commission_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvCommissionModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return commission_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CommissionFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof commission_findById>>>;
export type CommissionFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvCommissionModelAndbigdecimal>;
export type CommissionFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useCommissionFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof commission_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvCommissionModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getCommissionFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const commission_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvCommissionModel | void>(
        {
            url: `/factoring/efcvcommission/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getCommissionFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof commission_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof commission_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof commission_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return commission_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CommissionFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof commission_findByLightDataRequest>>
>;
export type CommissionFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type CommissionFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useCommissionFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof commission_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getCommissionFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const commission_findByViewDataRequest = (
    viewDataRequestModelOfEfcvCommissionModel: BodyType<ViewDataRequestModelOfEfcvCommissionModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvCommissionModel | void>(
        {
            url: `/factoring/efcvcommission/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvCommissionModel,
        },
        options,
    );
};

export const getCommissionFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof commission_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvCommissionModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof commission_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvCommissionModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof commission_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvCommissionModel> }
    > = props => {
        const { data } = props ?? {};

        return commission_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CommissionFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof commission_findByViewDataRequest>>
>;
export type CommissionFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvCommissionModel>;
export type CommissionFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useCommissionFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof commission_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvCommissionModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getCommissionFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
