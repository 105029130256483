const SvgLogo = (props) => (<svg width='1em' height='1em' viewBox='0 0 104 35' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d='M32.8147 0H0V16.3363H32.8147V0Z' fill='currentColor'/>
        <path d='M32.8147 16.0087H0V32.0174H32.8147V16.0087Z' fill='black'/>
        <path d='M27.0736 15.0371H5.72955V16.9803H27.0736V15.0371Z' fill='white'/>
        <path d='M42.7343 15.9409V2.55326H45.2808V8.80084L52.1678 2.55326H55.293L48.1977 8.9929L55.5824 15.9296H52.1678L45.2808 9.43351V15.9296H42.7343V15.9409Z' fill='black'/>
        <path d='M69.7269 8.9929C70.9307 9.60297 71.6252 10.7214 71.6252 12.0094C71.6252 14.2237 69.9815 15.9296 66.8332 15.9296H57.7469V2.55326H66.7753C69.2986 2.55326 71.1274 4.05584 71.1274 6.31536C71.1274 7.47902 70.6181 8.31504 69.7153 8.9929H69.7269ZM66.9258 8.06649C68.0717 8.06649 68.6041 7.28696 68.6041 6.43964C68.6041 5.51323 68.0022 4.76759 66.7753 4.76759H60.2702V8.06649H66.9258ZM68.9861 12.0094C68.9861 10.8909 68.3842 10.2808 67.0878 10.2808H60.2702V13.7266H66.7753C68.2916 13.7266 68.9861 13.0826 68.9861 12.0094Z' fill='black'/>
        <path d='M42.7459 20.8215H48.4176L47.943 22.1885H44.4358V25.2389H47.8736V26.6059H44.4358V31.2605H42.7343V20.8328L42.7459 20.8215Z' fill='black'/>
        <path d='M53.9388 30.4923C53.3716 31.0119 52.654 31.4074 51.7048 31.4074C50.258 31.4074 49.2047 30.4697 49.2047 29.0349C49.2047 28.0407 49.7024 27.3854 50.4895 27.0126C51.0219 26.7414 51.6701 26.6511 52.4572 26.6172L53.8346 26.572V26.2556C53.8346 25.2728 53.198 24.866 52.2952 24.866C51.4849 24.866 50.7904 25.1146 50.258 25.4196L49.6792 24.3238C50.5821 23.8154 51.5544 23.5781 52.5614 23.5781C54.4597 23.5781 55.5245 24.4367 55.5245 26.2556V29.1365C55.5245 30.2098 55.5593 30.8651 55.675 31.2605H54.2282L53.9504 30.5036L53.9388 30.4923ZM52.6771 27.7356C52.1215 27.7469 51.7396 27.826 51.4386 27.9842C51.0798 28.1762 50.9062 28.5039 50.9062 28.9332C50.9062 29.6449 51.3923 30.0855 52.1563 30.0855C52.9202 30.0855 53.5221 29.6449 53.823 29.3738V27.6791L52.6771 27.7243V27.7356Z' fill='black'/>
        <path d='M57.0408 27.5662C57.0408 25.2841 58.7076 23.5781 61.011 23.5781C61.9139 23.5781 62.5968 23.8154 63.0366 24.0639V25.4761C62.5042 25.1259 61.9139 24.9225 61.1731 24.9225C59.6799 24.9225 58.7771 26.0297 58.7771 27.521C58.7771 29.1478 59.6799 30.0629 61.0805 30.0629C61.8213 30.0629 62.3653 29.837 62.8977 29.5546L63.3839 30.6956C62.8051 31.091 61.856 31.4074 60.849 31.4074C58.3951 31.4074 57.0408 29.7127 57.0408 27.5662Z' fill='black'/>
        <path d='M65.2243 29.4868V25.0694H63.8816V23.7702H65.2243V22.0416L66.9142 21.5332V23.7589H69.2176L68.8009 25.0581H66.9258V29.1139C66.9258 29.7918 67.1804 30.0629 67.6897 30.0629C68.1643 30.0629 68.581 29.8935 68.8935 29.6788L69.4259 30.8199C68.8935 31.1814 68.0485 31.4074 67.2846 31.4074C65.9998 31.4074 65.2474 30.6956 65.2474 29.4755L65.2243 29.4868Z' fill='black'/>
        <path d='M70.213 27.4871C70.213 25.2615 71.7062 23.5781 73.9633 23.5781C76.2204 23.5781 77.6904 25.2615 77.6904 27.4871C77.6904 29.7127 76.1972 31.4074 73.9633 31.4074C71.7293 31.4074 70.213 29.7127 70.213 27.4871ZM75.9541 27.4871C75.9541 25.9958 75.2365 24.9225 73.9633 24.9225C72.69 24.9225 71.9492 25.9958 71.9492 27.4871C71.9492 28.9784 72.6785 30.0629 73.9633 30.0629C75.2481 30.0629 75.9541 28.9897 75.9541 27.4871Z' fill='black'/>
        <path d='M80.6535 23.7702L80.8503 24.6401C81.5911 23.8944 82.2509 23.5781 82.9685 23.5781C83.3273 23.5781 83.6977 23.6685 83.9755 23.8493L83.3736 25.2615C83.0842 25.1033 82.8065 25.0581 82.5634 25.0581C82.0425 25.0581 81.5911 25.2954 80.9198 25.8941V31.2605H79.2298V23.7702H80.6535Z' fill='black'/>
        <path d='M84.7742 21.3751C84.7742 20.8102 85.214 20.3583 85.8275 20.3583C86.441 20.3583 86.8808 20.7876 86.8808 21.3751C86.8808 21.9626 86.4294 22.3919 85.8275 22.3919C85.2256 22.3919 84.7742 21.9513 84.7742 21.3751ZM84.9825 23.7589H86.6725V31.2492H84.9825V23.7589Z' fill='black'/>
        <path d='M90.1102 23.7702L90.307 24.6627C91.1056 24.0187 91.9737 23.5894 92.9344 23.5894C94.1498 23.5894 95.3304 24.2221 95.3304 26.2782V31.2605H93.6405V26.7979C93.6405 25.815 93.409 25.0242 92.3904 25.0242C91.6728 25.0242 90.9783 25.4196 90.3648 25.928V31.2605H88.6749V23.7702H90.1102Z' fill='black'/>
        <path d='M96.5226 32.8196C96.5226 32.0174 97.0551 31.3509 97.9116 30.9668C97.5875 30.7973 97.3908 30.4245 97.3908 30.0516C97.3908 29.6223 97.6338 29.0914 98.282 28.6169C97.4834 28.1536 97.0319 27.3176 97.0319 26.3234C97.0319 24.8999 98.0968 23.5894 100.053 23.5894C100.736 23.5894 101.234 23.7476 101.558 23.9057C101.639 23.9509 101.673 23.9396 101.731 23.8944C102.136 23.499 102.634 23.2505 103.502 23.2505C103.676 23.2505 103.896 23.2844 103.988 23.2957L103.653 24.4932C103.479 24.4593 103.282 24.4254 103.12 24.4254C102.947 24.4254 102.68 24.4706 102.507 24.5836C102.993 25.0694 103.236 25.7247 103.236 26.4025C103.236 28.0633 101.974 29.1591 100.134 29.1591C99.7752 29.1591 99.4395 29.1139 99.1733 29.0123C98.9649 29.2834 98.8955 29.4755 98.8955 29.6562C98.8955 31.0458 103.942 29.7805 103.942 32.5823C103.942 33.9606 102.542 35 100.018 35C97.8306 35 96.5111 34.1301 96.5111 32.8196H96.5226ZM102.31 32.7518C102.31 31.5768 99.972 31.8141 98.8029 31.4074C98.5135 31.5994 98.0389 31.9609 98.0389 32.5371C98.0389 33.328 98.8955 33.8138 100.215 33.8138C101.535 33.8138 102.31 33.3505 102.31 32.7518ZM101.616 26.3573C101.616 25.5326 101.037 24.8547 100.111 24.8547C99.2659 24.8547 98.664 25.397 98.664 26.346C98.664 27.2046 99.1964 27.8599 100.134 27.8599C100.956 27.8599 101.604 27.3402 101.604 26.3573H101.616Z' fill='black'/>
    </svg>);
export default SvgLogo;
