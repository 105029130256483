import { Container as MuiContainer, styled } from '@mui/material';

import { IconLogo, LayoutComponentSize } from '@workspace/ui';

export const Container = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: 0,
    right: 0,
    left: 0,
    height: LayoutComponentSize.headerHeight,
    zIndex: 10,

    boxShadow: theme.shadows[1],
    backgroundColor: theme.color.buttons.secondary.background,
}));

export const Content = styled(MuiContainer)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    height: '100%',

    [theme.breakpoints.up('lg')]: {
        maxWidth: 'unset',
    },
}));

export const Logo = styled(IconLogo)(({ theme }) => ({
    height: 28,
    width: 84,
    color: theme.color.accent.primaryRed,
}));
