import { Certificate, ContentInfo, SignedData } from 'pkijs';

import { parsePem } from './utils';

export async function parseCertificatesChain(certificateChainPem: string) {
    const buffer = parsePem(certificateChainPem);

    const contentInfo = ContentInfo.fromBER(buffer);
    const signedData = new SignedData({ schema: contentInfo.content });

    const issuedCertificateChain = (signedData.certificates ?? []) as Certificate[];

    return issuedCertificateChain;
}
