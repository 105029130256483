import { Radio, radioClasses, styled, typographyClasses } from '@mui/material';
export const StyledRadio = styled(Radio)(({ theme }) => ({
    [`&.${radioClasses.root}`]: {
        padding: 'unset',
        [`&+ p.${typographyClasses.root}`]: {
            color: theme.color.inputs.text,
        },
        [`&.${radioClasses.checked} + p.${typographyClasses.root}`]: {
            color: theme.color.foregrounds.primary,
        },
        [`&.${radioClasses.disabled} + p.${typographyClasses.root}`]: {
            color: theme.color.inputs.text,
            opacity: 0.9,
        },
        ['svg']: {
            fontSize: '1.25rem',
        },
        '&:focus:not(:focus-visible)': {
            outline: 'none',
        },
        '&:focus-within': {
            outline: `1px solid ${theme.color.foregrounds.primary}`,
            backgroundColor: 'rgba(2, 2, 3, 0.04)',
            outlineOffset: -2,
        },
    },
}));
