import {
    AMOUNT_FORMAT_OPTIONS,
    AMOUNT_IN_CURRENCY_FORMAT_OPTIONS,
    AMOUNT_IN_PERCENT_FORMAT_OPTIONS,
} from '../constants';
import type { AmountFormatters } from '../hooks/useFormatAmountFallback';
import type { Language } from '../types';
import { formatAndReplaceParts, type Parts } from './formatAndReplaceParts';

export interface CreateConfigurableAmountFormattersProps {
    locale: Language;
    separators: Parts;
}

export function createConfigurableAmountFormatters({ locale, separators }: CreateConfigurableAmountFormattersProps) {
    const basicFormatter = new Intl.NumberFormat(locale, AMOUNT_FORMAT_OPTIONS);

    return {
        formatAmount: (v: number) => {
            return formatAndReplaceParts(basicFormatter, separators, v);
        },
        formatAmountInCurrency: (v, currency) => {
            const formattter = new Intl.NumberFormat(locale, AMOUNT_IN_CURRENCY_FORMAT_OPTIONS);

            return formatAndReplaceParts(formattter, separators, v) + ' ' + currency;
        },
        formatPercentage: (v, options) => {
            const percentageFormatter = new Intl.NumberFormat(locale, {
                ...AMOUNT_IN_PERCENT_FORMAT_OPTIONS,
                ...options,
            });

            return formatAndReplaceParts(percentageFormatter, separators, v);
        },
    } satisfies AmountFormatters;
}
