import { debug, disableAll, info, levels, error as loglevelError, setLevel, trace, warn } from 'loglevel';

import { isAuthError } from '@workspace/errors';
import { logErrorToApi } from '@workspace/errors/hooks';

export function initLogger({ outputToConsole }: { outputToConsole: boolean }) {
    if (outputToConsole) {
        setLevel(levels.TRACE);
    } else {
        disableAll();
    }
}

function error<E extends unknown>(err: E, extras?: Record<string, any>) {
    loglevelError(err);
    logErrorToApi(err);
}

export const logger = {
    warn,
    info,
    debug,
    trace,
    error,
} as const;
