export enum FundingType {
    IMMEDIATELY = 'b',
    LATER = 'n',
    PAYMENT_ONLY = 'i',
}

export enum APIStringBoolean {
    TRUE = 'y',
    FALSE = 'n',
    BOTH = '',
}
