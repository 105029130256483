import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export function useFormatDate() {
    const { formatDate: intlFormatDate } = useIntl();

    const formatDate = useCallback(
        (d: string | Date | number | undefined) => {
            if (!d) return undefined;

            return intlFormatDate(d, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        [intlFormatDate],
    );

    const formatDateTime = useCallback(
        (d: string | Date | number | undefined) => {
            if (!d) return undefined;

            return intlFormatDate(d, {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            });
        },
        [intlFormatDate],
    );

    return { formatDate, formatDateTime };
}
