import { useMemo } from 'react';

import { usePermissionsMatch } from '~modules/auth/hooks';

import { useNavigationItems, type NavigationItems } from './useNavigationItems';

export function usePermittedNavigationItems() {
    const checkPermissions = usePermissionsMatch();
    const items = useNavigationItems();

    return useMemo(() => {
        const groupsWithAccessibleChildren = items.map(({ childrenItems, title }) => {
            const accessibleChildren = childrenItems
                .map(items =>
                    items
                        .filter(
                            ({ requiredPermissions }) =>
                                !requiredPermissions || checkPermissions?.(requiredPermissions),
                        )

                        .map(({ requiredPermissions, ...rest }) => ({ ...rest })),
                )
                .filter(v => !!v.length);

            return { title, childrenItems: accessibleChildren } as const satisfies NavigationItems;
        });

        return groupsWithAccessibleChildren.filter(
            ({ childrenItems }) => !!childrenItems.length,
        ) satisfies ReadonlyArray<NavigationItems>;
    }, [checkPermissions, items]);
}
