/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

/**
 * application language code used by the user
 */
export type ExportCsvRequestModelLang = (typeof ExportCsvRequestModelLang)[keyof typeof ExportCsvRequestModelLang];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExportCsvRequestModelLang = {
    cs: 'cs',
    en: 'en',
} as const;
