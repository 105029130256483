import { useRouter } from 'next/router';

import type { DoIdAuthRoute } from '~constants';

import { useGenerateDoIdPath, type DoIdParams } from './useGenerateDoIdPath';

export function useDoIdRoute(doIdRoute: DoIdAuthRoute, params?: Partial<DoIdParams>) {
    const { push } = useRouter();
    const generateDoIdPath = useGenerateDoIdPath();
    const doIdPath = generateDoIdPath(doIdRoute, params as DoIdParams);

    const pushDoIdRoute = async () => {
        await push(doIdPath);
    };

    return {
        redirect: pushDoIdRoute,
        path: doIdPath,
    } as const;
}
