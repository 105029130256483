import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { TwoFactorRequirement } from '@workspace/auth';

import { routes, type Route } from '~constants';

const twoFactorRoutes = {
    [TwoFactorRequirement.SET_PIN]: routes.setPin,
    [TwoFactorRequirement.VERIFY_PIN]: routes.verifyPin,
    [TwoFactorRequirement.SET_PHONE_NUMBER]: routes.setPhoneNumber,
    [TwoFactorRequirement.VERIFY_PHONE_NUMBER]: routes.verifyPhoneNumber,
} as const satisfies Record<TwoFactorRequirement, Route>;

/**
 * Redirect user to correct 2FA page
 */
export function useStartTwoFactorAuth() {
    const { push } = useRouter();

    return useCallback(
        async (twoFactorRequirement: TwoFactorRequirement) => {
            await push(twoFactorRoutes[twoFactorRequirement]);
        },
        [push],
    );
}
