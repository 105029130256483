/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvPaymentModel,
    GetByIdRequestModelOfEfcvPaymentModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvPaymentModel,
    ViewDataResultModelOfEfcvPaymentModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const payment_findByAll = (
    efcvPaymentModel: BodyType<EfcvPaymentModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvPaymentModel[] | void>(
        {
            url: `/factoring/efcvpayment/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvPaymentModel,
        },
        options,
    );
};

export const getPaymentFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof payment_findByAll>>,
        TError,
        { data: BodyType<EfcvPaymentModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof payment_findByAll>>,
    TError,
    { data: BodyType<EfcvPaymentModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof payment_findByAll>>,
        { data: BodyType<EfcvPaymentModel> }
    > = props => {
        const { data } = props ?? {};

        return payment_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PaymentFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof payment_findByAll>>>;
export type PaymentFindByAllMutationBody = BodyType<EfcvPaymentModel>;
export type PaymentFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const usePaymentFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof payment_findByAll>>,
        TError,
        { data: BodyType<EfcvPaymentModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPaymentFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const payment_findById = (
    getByIdRequestModelOfEfcvPaymentModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvPaymentModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvPaymentModel | void>(
        {
            url: `/factoring/efcvpayment/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvPaymentModelAndbigdecimal,
        },
        options,
    );
};

export const getPaymentFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof payment_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvPaymentModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof payment_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvPaymentModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof payment_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvPaymentModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return payment_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PaymentFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof payment_findById>>>;
export type PaymentFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvPaymentModelAndbigdecimal>;
export type PaymentFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const usePaymentFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof payment_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvPaymentModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPaymentFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const payment_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvPaymentModel | void>(
        {
            url: `/factoring/efcvpayment/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getPaymentFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof payment_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof payment_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof payment_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return payment_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PaymentFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof payment_findByLightDataRequest>>
>;
export type PaymentFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type PaymentFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const usePaymentFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof payment_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPaymentFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const payment_findByViewDataRequest = (
    viewDataRequestModelOfEfcvPaymentModel: BodyType<ViewDataRequestModelOfEfcvPaymentModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvPaymentModel | void>(
        {
            url: `/factoring/efcvpayment/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvPaymentModel,
        },
        options,
    );
};

export const getPaymentFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof payment_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvPaymentModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof payment_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvPaymentModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof payment_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvPaymentModel> }
    > = props => {
        const { data } = props ?? {};

        return payment_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type PaymentFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof payment_findByViewDataRequest>>
>;
export type PaymentFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvPaymentModel>;
export type PaymentFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const usePaymentFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof payment_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvPaymentModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getPaymentFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
