/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvDiscountInvoiceModel,
    GetByIdRequestModelOfEfcvDiscountInvoiceModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvDiscountInvoiceModel,
    ViewDataResultModelOfEfcvDiscountInvoiceModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const discountInvoice_findByAll = (
    efcvDiscountInvoiceModel: BodyType<EfcvDiscountInvoiceModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvDiscountInvoiceModel[] | void>(
        {
            url: `/factoring/efcvdiscountinvoice/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvDiscountInvoiceModel,
        },
        options,
    );
};

export const getDiscountInvoiceFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof discountInvoice_findByAll>>,
        TError,
        { data: BodyType<EfcvDiscountInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof discountInvoice_findByAll>>,
    TError,
    { data: BodyType<EfcvDiscountInvoiceModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof discountInvoice_findByAll>>,
        { data: BodyType<EfcvDiscountInvoiceModel> }
    > = props => {
        const { data } = props ?? {};

        return discountInvoice_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DiscountInvoiceFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof discountInvoice_findByAll>>>;
export type DiscountInvoiceFindByAllMutationBody = BodyType<EfcvDiscountInvoiceModel>;
export type DiscountInvoiceFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useDiscountInvoiceFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof discountInvoice_findByAll>>,
        TError,
        { data: BodyType<EfcvDiscountInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDiscountInvoiceFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const discountInvoice_findById = (
    getByIdRequestModelOfEfcvDiscountInvoiceModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvDiscountInvoiceModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvDiscountInvoiceModel | void>(
        {
            url: `/factoring/efcvdiscountinvoice/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvDiscountInvoiceModelAndbigdecimal,
        },
        options,
    );
};

export const getDiscountInvoiceFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof discountInvoice_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvDiscountInvoiceModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof discountInvoice_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvDiscountInvoiceModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof discountInvoice_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvDiscountInvoiceModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return discountInvoice_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DiscountInvoiceFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof discountInvoice_findById>>>;
export type DiscountInvoiceFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvDiscountInvoiceModelAndbigdecimal>;
export type DiscountInvoiceFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useDiscountInvoiceFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof discountInvoice_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvDiscountInvoiceModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDiscountInvoiceFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const discountInvoice_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvDiscountInvoiceModel | void>(
        {
            url: `/factoring/efcvdiscountinvoice/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getDiscountInvoiceFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof discountInvoice_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof discountInvoice_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof discountInvoice_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return discountInvoice_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DiscountInvoiceFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof discountInvoice_findByLightDataRequest>>
>;
export type DiscountInvoiceFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type DiscountInvoiceFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useDiscountInvoiceFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof discountInvoice_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDiscountInvoiceFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const discountInvoice_findByViewDataRequest = (
    viewDataRequestModelOfEfcvDiscountInvoiceModel: BodyType<ViewDataRequestModelOfEfcvDiscountInvoiceModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvDiscountInvoiceModel | void>(
        {
            url: `/factoring/efcvdiscountinvoice/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvDiscountInvoiceModel,
        },
        options,
    );
};

export const getDiscountInvoiceFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof discountInvoice_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvDiscountInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof discountInvoice_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvDiscountInvoiceModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof discountInvoice_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvDiscountInvoiceModel> }
    > = props => {
        const { data } = props ?? {};

        return discountInvoice_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type DiscountInvoiceFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof discountInvoice_findByViewDataRequest>>
>;
export type DiscountInvoiceFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvDiscountInvoiceModel>;
export type DiscountInvoiceFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useDiscountInvoiceFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof discountInvoice_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvDiscountInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getDiscountInvoiceFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
