import { forwardRef } from 'react';
/**
 * This replaces default Modal root, which wrapped backdrop and menu paper in one div
 *  That caused backdrop and paper to be in a different stacking context and therefore unable to set proper zIndex
 *  in relation to other selects' form controls
 */
export const SelectMenuModalRootComponent = (props, _) => {
    return <>{props.children}</>;
};
export const SelectMenuModalRoot = forwardRef(SelectMenuModalRootComponent);
