import * as asn1js from 'asn1js';
import * as forge from 'node-forge';
import * as pkijs from 'pkijs';
import { PrivateKeyInfo } from 'pkijs';

import { getStoredPassword } from '../../password';
import {
    createCertBag,
    createPasswordProtectedSafeContent,
    createPKCS8ShroudedKeyBag,
    createPKCS12FromSafeBags,
} from '../createPersonalInformationContainer';
import { AttributeType, SafeBagId } from '../types';
import { arrayBufferToString, parsePem, stringToArrayBuffer } from '../utils';

type Pkcs12 = ReturnType<(typeof forge.pkcs12)['pkcs12FromAsn1']>;
type Bag = Pkcs12['safeContents'][0]['safeBags'][0];

function getCertificateBagsGroup(pkcs12: Pkcs12) {
    const bagType = forge.pki.oids.certBag;
    const bags = pkcs12.getBags({ bagType });
    const bagsGroup = bags[bagType];

    return bagsGroup ?? [];
}

function getPrivateKeyBagsGroup(pkcs12: Pkcs12) {
    const bagType = forge.pki.oids.pkcs8ShroudedKeyBag;
    const bags = pkcs12.getBags({ bagType });
    const bagsGroup = bags[bagType];

    return bagsGroup ?? [];
}

function getBagLocalKeyId(bag: Bag) {
    if (bag.attributes.localKeyId) {
        return forge.util.bytesToHex(bag.attributes.localKeyId[0]);
    }

    return null;
}

function getBagFriendlyName(bag: Bag) {
    if (bag.attributes.friendlyName) {
        return bag.attributes.friendlyName[0] as string;
    }

    return null;
}

function migrateBagAttributes(bag: Bag) {
    const localKeyId = getBagLocalKeyId(bag);
    const friendlyName = getBagFriendlyName(bag);

    return [
        friendlyName &&
            new pkijs.Attribute({
                type: AttributeType.FriendlyName,
                values: [new asn1js.Utf8String({ value: friendlyName })],
            }),
        localKeyId &&
            new pkijs.Attribute({
                type: AttributeType.LocalKeyId,
                values: [new asn1js.OctetString({ valueHex: stringToArrayBuffer(localKeyId) })],
            }),
    ].filter(Boolean) as pkijs.Attribute[];
}

/**
 * Extract safe bags and use them to create a new PKCS #12 object with pkijs
 * Note those bags are linked via their attributes (__localKeyId__, friendlyName).
 */
async function migrateSafeBags(pkcs12b64: string) {
    const pkcs12Asn1 = forge.asn1.fromDer(forge.util.decode64(pkcs12b64));

    const password = await getStoredPassword();

    const pkcs12 = forge.pkcs12.pkcs12FromAsn1(pkcs12Asn1, false, arrayBufferToString(password));

    const keys = getPrivateKeyBagsGroup(pkcs12);
    const certs = getCertificateBagsGroup(pkcs12);
    const PEM_LINE_MAX_CHARS_LENGTH = 60;

    return {
        [SafeBagId.PKCS8ShroudedKeyBag]: keys.map(bag => {
            // Convert the private key to ASN.1 format (and wrap it in a PKCS#8 structure)
            const asn1PrivateKey = forge.pki.privateKeyToAsn1(bag.key!);
            const forgePrivateKeyInfo = forge.pki.wrapRsaPrivateKey(asn1PrivateKey);

            // Convert the PKCS#8 structure to PEM format
            const pemPrivateKeyInfo = forge.pki.privateKeyInfoToPem(forgePrivateKeyInfo);

            // Parse the PEM structure to get the private key info with PKI.js
            const privateKeyInfoBer = parsePem(pemPrivateKeyInfo);
            const privateKeyInfo = PrivateKeyInfo.fromBER(privateKeyInfoBer);

            return createPKCS8ShroudedKeyBag(privateKeyInfo, migrateBagAttributes(bag));
        }),

        [SafeBagId.CertBag]: certs.map(bag => {
            const certPem = forge.pki.certificateToPem(bag.cert!, PEM_LINE_MAX_CHARS_LENGTH);

            const certificate = pkijs.Certificate.fromBER(parsePem(certPem));

            return createCertBag(certificate, migrateBagAttributes(bag));
        }),
    };
}

export async function migratePkcs12FromNodeForgeToPkijs(pkcs12b64: string) {
    const safeBagGroups = await migrateSafeBags(pkcs12b64);

    const keyBagsSafeContent = createPasswordProtectedSafeContent(safeBagGroups[SafeBagId.PKCS8ShroudedKeyBag]);
    const certBagsSafeContents = createPasswordProtectedSafeContent(safeBagGroups[SafeBagId.CertBag]);

    return createPKCS12FromSafeBags([keyBagsSafeContent, certBagsSafeContents]);
}
