import { isAntonioError } from '@ackee/antonio-core';

import { AuthError } from '@workspace/errors';

import type { CreateApiRequest } from './createApiRequest';

const clientChangeAborter = new AbortController();

/**
 * Fetcher that handles client change error
 * - If the client change error is detected, it aborts all other requests and throws AuthError('CLIENT_CHANGED') error
 * - The error is detected and by the app where logout is done.
 */
export const clientChangeProxyFetcher = async <T>(
    createApiRequest: CreateApiRequest<T>,
    ...params: Parameters<CreateApiRequest<T>>
): Promise<ReturnType<CreateApiRequest<T>>> => {
    try {
        if (clientChangeAborter.signal.aborted) {
            throw new AuthError('CLIENT_CHANGED');
        }

        const [props, options] = params;
        const result = await createApiRequest(props, {
            ...options,

            // It's not possible to compose signals,
            // so if given fetcher is called with signal,
            // it overrides this cancelation logic.
            signal: options?.signal ?? clientChangeAborter.signal,
        });

        // This occurs when mutliple requests are in progress, one of them receives 403, then the others finish.
        if (clientChangeAborter.signal.aborted) {
            throw new AuthError('CLIENT_CHANGED');
        }

        return result;
    } catch (e) {
        if (!isAntonioError(e)) {
            throw e;
        }

        const errorContent = e.response.bodyUsed ? e.data : await e.response.text();

        if (e.response.status === 403 && errorContent === 'CLIENT_CHANGED') {
            clientChangeAborter.abort(new AuthError('CLIENT_CHANGED'));

            throw new AuthError('CLIENT_CHANGED');
        }

        throw e;
    }
};
