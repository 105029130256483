import { FormattedMessage } from 'react-intl';

import { Alert } from '@workspace/ui';

import { useRouterQuery } from '~hooks';

export interface ClientChangedErrorAlertProps {}

export const ClientChangedErrorAlert = ({}: ClientChangedErrorAlertProps) => {
    const query = useRouterQuery(['clientChanged'], false);

    if (!query?.clientChanged) {
        return null;
    }

    return (
        <Alert severity='error'>
            <FormattedMessage id='error.auth.CLIENT_CHANGED' />
        </Alert>
    );
};
