export enum QueryKey {
    SUPPLIER_CUSTOMER_SELECT = 'suppliercustomerselect',
    SUPPLIER_CUST_LIMIT = 'supplierCustLimit',
    SUPPLIER_CUST = 'supplierCust',
    AUTH_GET_GLOBAL_PARAMS = 'authGetGlobalParams',
    AUTH_SEND_SMS_CODE = 'authServices_sendSmsCode',
    SUPPLIER_BIND = 'supplierBlind',
    SUPPLIER_LIMIT_HISTORY = 'supplierLimitHistory',
    SUPPLIER_PRODUCT_LIMIT_HISTORY = 'supplierProductLimitHistory',
    PRODUCT_DETAIL = 'productDetail',
    SUPPLIER_CONTRACT_LIMIT_HISTORY = 'supplierContractLimitHistory',
    CONTRACT_DETAIL = 'contractDetail',

    SUPPLIER_CUSTOMER_LIMIT = 'supplierCustomerLimit',

    SUPPLIER_CONTRACTS = 'supplierContracts',

    GET_PRODUCTS = 'dataService_getProducts',
    SET_USER_PHONENUMBER = 'dataService_setUsersPhoneNumber',
    VERIFY_SMS_CODE = 'authServices_verifySmsCode',

    GET_FILTER_SETTINGS = 'filterService_getFilterSettings',
    DELETE_FILTER = 'filterService_deleteFilter',
    FETCH_DATA_WITH_LIGHT_FILTER = 'fetchDataWithLightFilter',

    BOUND_INVOICE_LIGHT_DATA_REQUEST = 'boundInvoice_findByLightDataRequest',
    INTEREST_LIGHT_DATA_REQUEST = 'interest_findByLightDataRequest',
    SUPPLIER_SETTLEMENT_LIGHT_DATA_REQUEST = 'supplierSettlement_findByLightDataRequest',
    COMMISSION_LIGHT_DATA_REQUEST = 'commission_findByLightDataRequest',
    CUSTOMER_SETTLEMENT_LIGHT_DATA_REQUEST = 'customerSettlement_findByLightDataRequest',
    FEE_LIGHT_DATA_REQUEST = 'fee_findByLightDataRequest',
    PENALTY_LIGHT_DATA_REQUEST = 'penalty_findByLightDataRequest',
    DUE_INVOICE = 'dueInvoice_findByAll',

    GET_ISSUED_INVOICES_CURRENCIES = 'dataService_getIssuedInvoiceCurrencies',
    BUSINESS_TRANSACTION_CURRENCIES = 'dataService_getBusinessTransactionCurrencies',

    GET_CURRENT_COMPANY_EXPORT_SETTINGS = 'exportSetting_getCurrentCompanyExportSettings',
    ENUMARATION_FIND_BY_ALL = 'enumerationService_findByAll',

    CAPTCHA = 'captcha',

    GET_PDF_EXPORT_TYPE_LIST = 'exportService_getPdfExportTypeList',
    GET_DOCUMENT_DOWNLOAD = 'xmlService_getDocumentDownload',
    GET_NOTICE_BOARD = 'xmlService_getNoticeBoard',

    PAYMENT_DETAIL = 'paymentDetail',
    PAYMENT_ORDERS = 'paymentOrders',

    IN_FILE_FIND_BY_ALL = 'efcvinfile_findByAll',
    IN_FILE_LINE = 'efcvinfileline_findByLightDataRequest',

    GET_PARTNER_PREFERENCES = 'partnerPreferencesService_getPartnerPreferences',
    GET_USER_IP = 'getUserIp',
    CERTIFICATE_INFO = 'certificateInfo',
    LOCAL_CERTIFICATES = 'localCertificates',
    LIABILITY_INVOICE = 'liabilityInvoice_findByAll',
    DISCOUNT_INVOICE = 'discountInvoice_findByAll',
    PURCHASED_INVOICE = 'purchasedInvoice_findByAll',

    NONFINANCED_CURRENCIES = 'dataService_getCurrenciesForNonFinancedInvoiceFilter',

    GET_PHONE_NUMBER = 'dataService_getUsersPhoneNumber',

    SESSION_TIMEOUT = 'sessionTimeout',

    CERTIFICATE_WARNING_LIMIT = 'dataService_getCertificateWarningLimit',

    GET_MARKED_TO_PREFINANCE = 'exportservice_getMarkedToPrefinance',
    GET_CURRENCY_SUMS_TO_PREFINANCE = 'exportservice_getCurrencySumsToPrefinance',
    AUTH_USER_DATA = 'userData',
    AUTH_PERMISSIONS = 'userPermission',

    BANK_ACCOUNTS = 'bankAccounts',
    PURCHASED_INVOICE_CONFIRMATION_CHECK = 'purchasedInvoiceConfirmationCheck',
    VERSION_DISPLAY = 'appInfo_getAppVersionToDisplay',
    NODE_INFO = 'nodeInfo',
    SHOW_CERTIFICATE_EXPIRATION_WARNING = 'showCertificateExpirationWarning',
    ISSUED_CERTIFICATE_VALIDITY = 'issuedCertificateValidity',
    SHOW_NEW_CERTIFICATE_WARNING = 'showNewCertificateWarning',
}
