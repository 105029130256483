import type { ReactNode } from 'react';
import type { AlertProps } from '@mui/material';
import type { UseQueryResult } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';

import type { FormattedMessageProps } from '@workspace/localization/intl';
import { Icon, IconRefreshCcw05 } from '@workspace/ui';

import { isTimeoutError } from '../../utils/timeoutError';
import { ActionButton, ErrorAlert } from './QueryError.styles';

type QueryErrorBase = Pick<UseQueryResult, 'error' | 'refetch' | 'isFetching'>;

export type QueryErrorCompactMode = 'default' | 'inline';

export interface QueryErrorProps<QueryResult extends QueryErrorBase> extends AlertProps {
    children?: ReactNode | ReactNode[];
    result: QueryResult;
    message: FormattedMessageProps;
    compactMode?: QueryErrorCompactMode;
    fullWidth?: boolean;
}

export const QueryError = <QueryResult extends QueryErrorBase>({
    result,
    message,
    children = null,
    compactMode = 'default',
    fullWidth = false,
    ...rest
}: QueryErrorProps<QueryResult>) => {
    if (result.error) {
        return (
            <ErrorAlert
                fullWidth={fullWidth}
                severity='error'
                compactMode={compactMode}
                action={
                    <ActionButton
                        compactMode={compactMode}
                        onClick={() => {
                            result.refetch();
                        }}
                        disabled={result.isFetching}
                        fetching={result.isFetching}
                        startIcon={<Icon icon={IconRefreshCcw05} />}
                    />
                }
                {...rest}
            >
                {isTimeoutError(result.error) ? (
                    <FormattedMessage id='general.timeoutError' />
                ) : (
                    <FormattedMessage
                        id={message.id}
                        values={{
                            ...message.values,
                            br: <br />,
                        }}
                    />
                )}
            </ErrorAlert>
        );
    }

    return <>{children}</>;
};
