import { useContext } from 'react';
import { useRouter } from 'next/router';
import type { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useAuthUserInfo } from '@workspace/auth';
import { localSignIn } from '@workspace/auth/services/localSignIn';
import { worker } from '@workspace/certificates';
import { isAuthError, isAuthErrorWithCodes } from '@workspace/errors';
import { logger } from '@workspace/logger';

import { authRoutes, CertificateManagementSuccessType, QueryParam, routes } from '~constants';
import { useGenerateDoIdPath } from '~hooks/useGenerateDoIdPath';
import { PasswordPrompt } from '~modules/auth/contexts';
import { usePromptLogin } from '~modules/auth/hooks';
import { formError } from '~modules/form/errors';
import { isPromptError } from '~modules/prompt/errors';

import type { ChangePasswordSchema } from '../schemas';

async function checkPassword(oldPassword: string) {
    await worker.storePassword(oldPassword);

    await localSignIn();
}

export const usePasswordChange = ({ setError, reset }: UseFormReturn<ChangePasswordSchema>) => {
    const { formatMessage } = useIntl();
    const { push, route } = useRouter();
    const authUserInfo = useAuthUserInfo();

    const generateDoIdPath = useGenerateDoIdPath();
    const { promptUser } = usePromptLogin();
    const promptForPassword = useContext(PasswordPrompt);

    async function onSubmit({ oldPassword, newPassword }: ChangePasswordSchema) {
        try {
            const splitName: string[] = (authUserInfo?.name?.toLowerCase() ?? '').split(' ').filter(Boolean);

            const pwdIncludesName = splitName.some(name => newPassword.toLowerCase().includes(name));

            if (pwdIncludesName) {
                setError('newPassword', { message: formatMessage({ id: formError.passwordUniqness }) });

                return;
            }

            const shouldAskForPassword = promptForPassword && !(await worker.hasStoredPassword());

            await checkPassword(oldPassword).catch(async e => {
                // Remove password set during checking
                await worker.resetStoredPassword();

                throw e;
            });

            if (shouldAskForPassword) {
                const passwordResult = await promptUser();

                if (passwordResult === 'no') {
                    // Remove password set during checking
                    await worker.resetStoredPassword();

                    return;
                }
            }

            await worker.changePKCS12Password(oldPassword, newPassword);

            reset();

            await push({
                pathname:
                    route === authRoutes.changePassword
                        ? generateDoIdPath(authRoutes.certificateManagement)
                        : routes.certificateManagement,
                search: `?${new URLSearchParams({
                    [QueryParam.CertificateManagementSuccessType]: CertificateManagementSuccessType.PASSWORD_CHANGED,
                })}`,
            });
        } catch (e) {
            logger.error(e);
            if (isPromptError(e)) {
                // Remove password set during checking
                await worker.resetStoredPassword();

                return;
            }

            if (isAuthErrorWithCodes(e, ['CERTIFICATE_INVALID_PASSWORD'])) {
                setError('oldPassword', {
                    message: formatMessage({ id: 'error.INVALID_KEYSTORE_PASSWORD' }),
                });

                return;
            }

            if (isAuthError(e)) {
                setError('root', {
                    message: formatMessage({ id: `error.auth.${e.code}` }),
                });
            } else {
                setError('oldPassword', {
                    message: formatMessage({ id: 'error.INVALID_KEYSTORE_PASSWORD' }),
                });
            }
        }
    }

    return onSubmit;
};
