/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    BooleanResponseModel,
    EfcvSupplierContractLimitModel,
    EfcvSupplierContractLimitModelBody,
    GetByIdRequestModelOfEfcvSupplierContractLimitModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvSupplierContractLimitModel,
    ViewDataResultModelOfEfcvSupplierContractLimitModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const supplierContractLimit_findByAll = (
    efcvSupplierContractLimitModelBody: BodyType<EfcvSupplierContractLimitModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSupplierContractLimitModel[] | void>(
        {
            url: `/factoring/efcvsuppliercontractlimit/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvSupplierContractLimitModelBody,
        },
        options,
    );
};

export const getSupplierContractLimitFindByAllMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierContractLimit_findByAll>>,
        TError,
        { data: BodyType<EfcvSupplierContractLimitModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierContractLimit_findByAll>>,
    TError,
    { data: BodyType<EfcvSupplierContractLimitModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierContractLimit_findByAll>>,
        { data: BodyType<EfcvSupplierContractLimitModelBody> }
    > = props => {
        const { data } = props ?? {};

        return supplierContractLimit_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierContractLimitFindByAllMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierContractLimit_findByAll>>
>;
export type SupplierContractLimitFindByAllMutationBody = BodyType<EfcvSupplierContractLimitModelBody>;
export type SupplierContractLimitFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useSupplierContractLimitFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierContractLimit_findByAll>>,
        TError,
        { data: BodyType<EfcvSupplierContractLimitModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierContractLimitFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByAllHasData
 */
export const supplierContractLimit_findByAllHasData = (
    efcvSupplierContractLimitModelBody: BodyType<EfcvSupplierContractLimitModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<BooleanResponseModel | void>(
        {
            url: `/factoring/efcvsuppliercontractlimit/findByAllHasData`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvSupplierContractLimitModelBody,
        },
        options,
    );
};

export const getSupplierContractLimitFindByAllHasDataMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierContractLimit_findByAllHasData>>,
        TError,
        { data: BodyType<EfcvSupplierContractLimitModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierContractLimit_findByAllHasData>>,
    TError,
    { data: BodyType<EfcvSupplierContractLimitModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierContractLimit_findByAllHasData>>,
        { data: BodyType<EfcvSupplierContractLimitModelBody> }
    > = props => {
        const { data } = props ?? {};

        return supplierContractLimit_findByAllHasData(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierContractLimitFindByAllHasDataMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierContractLimit_findByAllHasData>>
>;
export type SupplierContractLimitFindByAllHasDataMutationBody = BodyType<EfcvSupplierContractLimitModelBody>;
export type SupplierContractLimitFindByAllHasDataMutationError = ErrorType<unknown>;

/**
 * @summary findByAllHasData
 */
export const useSupplierContractLimitFindByAllHasData = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierContractLimit_findByAllHasData>>,
        TError,
        { data: BodyType<EfcvSupplierContractLimitModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierContractLimitFindByAllHasDataMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const supplierContractLimit_findById = (
    getByIdRequestModelOfEfcvSupplierContractLimitModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvSupplierContractLimitModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSupplierContractLimitModel | void>(
        {
            url: `/factoring/efcvsuppliercontractlimit/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvSupplierContractLimitModelAndbigdecimal,
        },
        options,
    );
};

export const getSupplierContractLimitFindByIdMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierContractLimit_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierContractLimitModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierContractLimit_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvSupplierContractLimitModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierContractLimit_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierContractLimitModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return supplierContractLimit_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierContractLimitFindByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierContractLimit_findById>>
>;
export type SupplierContractLimitFindByIdMutationBody =
    BodyType<GetByIdRequestModelOfEfcvSupplierContractLimitModelAndbigdecimal>;
export type SupplierContractLimitFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useSupplierContractLimitFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierContractLimit_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierContractLimitModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierContractLimitFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const supplierContractLimit_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvSupplierContractLimitModel | void>(
        {
            url: `/factoring/efcvsuppliercontractlimit/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getSupplierContractLimitFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierContractLimit_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierContractLimit_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierContractLimit_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return supplierContractLimit_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierContractLimitFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierContractLimit_findByLightDataRequest>>
>;
export type SupplierContractLimitFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type SupplierContractLimitFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useSupplierContractLimitFindByLightDataRequest = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierContractLimit_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierContractLimitFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const supplierContractLimit_findByViewDataRequest = (
    viewDataRequestModelOfEfcvSupplierContractLimitModel: BodyType<ViewDataRequestModelOfEfcvSupplierContractLimitModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvSupplierContractLimitModel | void>(
        {
            url: `/factoring/efcvsuppliercontractlimit/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvSupplierContractLimitModel,
        },
        options,
    );
};

export const getSupplierContractLimitFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierContractLimit_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierContractLimitModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierContractLimit_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvSupplierContractLimitModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierContractLimit_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierContractLimitModel> }
    > = props => {
        const { data } = props ?? {};

        return supplierContractLimit_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierContractLimitFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierContractLimit_findByViewDataRequest>>
>;
export type SupplierContractLimitFindByViewDataRequestMutationBody =
    BodyType<ViewDataRequestModelOfEfcvSupplierContractLimitModel>;
export type SupplierContractLimitFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useSupplierContractLimitFindByViewDataRequest = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierContractLimit_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierContractLimitModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierContractLimitFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
