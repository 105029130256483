import { arrayBufferToString, encodeBase64 } from '../pki/utils';

export function readFileAsBase64(file: File) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onerror = () => {
            reject(
                new Error(
                    `Failed to read file: ${JSON.stringify(
                        {
                            name: file.name,
                            size: file.size,
                            type: file.type,
                        },
                        null,
                        2,
                    )}`,
                ),
            );
        };

        reader.onloadend = ref => {
            const data = ref.target?.result as ArrayBuffer;

            const binaryString = arrayBufferToString(data);
            const base64 = encodeBase64(binaryString);

            resolve(base64);
        };

        reader.readAsArrayBuffer(file);
    });
}
