import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Dialog } from '@workspace/ui';

export interface LogoutDialogProps {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

export const LogoutDialog = ({ open, onConfirm, onCancel }: LogoutDialogProps) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            minWidth={480}
            buttons={{
                cancel: {
                    children: <FormattedMessage id='button.no' />,
                    onClick: onCancel,
                },
                submit: {
                    children: <FormattedMessage id='button.yes' />,
                    onClick: onConfirm,
                },
            }}
        >
            <Typography>
                <FormattedMessage id='general.logout.confirmation' />
            </Typography>
        </Dialog>
    );
};
