import * as asn1js from 'asn1js';
import * as pkijs from 'pkijs';
import { AttributeTypeAndValue } from 'pkijs';

import { logger } from '@workspace/logger';

import { createPrivateKeyInfo, generateKeyPair } from './keyPair';
import { CertificateAttributeType } from './types';
import { arrayBufferToDecimal, bufferToHexCodes } from './utils';

export type CreateCertificateAttributes = {
    /**
     * pin required only when creating cert. request, not while renewal.
     */
    pin?: string;

    /**
     * phoneNumber required only when creating cert. request, not while renewal.
     */
    phoneNumber?: string;

    idNumber: string;
    personalId: string;
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    countryCode: string;
    countryName: string;
};

export function createCertificateAttributes<Attributes extends CreateCertificateAttributes>({
    idNumber,
    personalId,
    firstName,
    lastName,
    email,
    address,
    countryCode,
    countryName,
    phoneNumber,
    pin,
}: Attributes): AttributeTypeAndValue[] {
    const attrs: {
        type: CertificateAttributeType;
        value: string;
    }[] = [
        {
            type: CertificateAttributeType.IdNumber,
            value: idNumber,
        },
        {
            type: CertificateAttributeType.PersonalId,
            value: personalId,
        },
        {
            type: CertificateAttributeType.FirstName,
            value: firstName,
        },
        {
            type: CertificateAttributeType.LastName,
            value: lastName,
        },
        {
            type: CertificateAttributeType.CommonName,
            value: `${lastName} ${firstName}`,
        },
        {
            type: CertificateAttributeType.Email,
            value: email,
        },
        {
            type: CertificateAttributeType.Address,
            value: address,
        },
        {
            type: CertificateAttributeType.CountryCode,
            value: countryCode,
        },
        {
            type: CertificateAttributeType.CountryName,
            value: countryName,
        },
    ];

    if (phoneNumber && phoneNumber.length > 0) {
        attrs.push({
            type: CertificateAttributeType.PhoneNumber,
            value: phoneNumber,
        });
    }

    if (pin && pin?.length > 0) {
        attrs.push({
            type: CertificateAttributeType.ChallengePassword,
            value: pin,
        });
    }

    return attrs.map(
        ({ type, value }) =>
            new AttributeTypeAndValue({
                type,
                value: new asn1js.Utf8String({ value }),
            }),
    );
}

/**
 * Creates a timestamp 1 year from now.
 */
function createNotAfterTimestamp() {
    const notAfter = new Date();

    notAfter.setFullYear(notAfter.getFullYear() + 1);

    return notAfter;
}

const SERIAL_NUMBER_LENGTH = 12;

/**
 * Generates random serial number of given length
 */
function generateSerialNumber(length: number = SERIAL_NUMBER_LENGTH) {
    // Generate the first digit (1 to 9)
    let number = Math.floor(Math.random() * 9) + 1;

    // Generate the next 5 digits (0 to 9)
    for (let i = 0; i < length - 1; i++) {
        number = number * 10 + Math.floor(Math.random() * 10);
    }

    return number;
}

export function extractSerialNumber(cert: pkijs.Certificate): string {
    const { valueBlock } = cert.serialNumber;

    if (valueBlock.isHexOnly) {
        if (valueBlock.blockLength > SERIAL_NUMBER_LENGTH / 2) {
            return bufferToHexCodes(valueBlock.valueHexView);
        } else {
            return arrayBufferToDecimal(valueBlock.valueHexView).toString();
        }
    }

    return valueBlock.valueDec.toString();
}

/**
 * - Generates RSA-PSS key pair with SHA-256 hash algorithm.
 * - Creates X.509v3 self-signed certificate (cert. issuer is equal to cert. subject) with given key pair, arbitrary attributes (RFC5280) and validity 1 year from now.
 * - Returns self-signed certificate and private key as private key info (in PKCS #8 standard).
 */
export async function createSelfSignedCertificate(attributes: AttributeTypeAndValue[]) {
    const keyPair = await generateKeyPair();

    const certificate = new pkijs.Certificate();

    certificate.version = 3;

    certificate.serialNumber = new asn1js.Integer({ value: generateSerialNumber() });

    if (certificate.serialNumber.error ?? certificate.serialNumber.warnings) {
        logger.error(certificate.serialNumber.error ?? certificate.serialNumber.warnings);
    }

    certificate.notBefore.value = new Date();
    certificate.notAfter.value = createNotAfterTimestamp();

    certificate.issuer.typesAndValues = attributes;
    certificate.subject.typesAndValues = attributes;

    await certificate.subjectPublicKeyInfo.importKey(keyPair.publicKey);

    await certificate.sign(keyPair.privateKey, keyPair.algorithm.hash.name);

    const privateKeyInfo = await createPrivateKeyInfo(keyPair.privateKey);

    return {
        certificate,
        privateKeyInfo,
    } as const;
}
