import { useQuery } from '@tanstack/react-query';

import { authServices_getBlockedCertificates } from '@workspace/api';

import { QueryParam } from '~constants';
import { useRouterQuery } from '~hooks';

import { useUsername } from './useUsername';

export function useHydrateBlockedCertificates() {
    const query = useRouterQuery([QueryParam.CertificateIdFrom as const]);
    const certificateId = query?.certificateIdFrom;
    const { getUsername } = useUsername();

    const queryresult = useQuery({
        queryKey: ['authServices_getBlockedCertificates', certificateId],
        queryFn: async () => {
            const username = (await getUsername()) as string;

            return authServices_getBlockedCertificates({
                username,
                // @ts-expect-error
                certificateIdFrom: certificateId,
            });
        },
    });

    return queryresult;
}
