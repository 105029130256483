import { Backdrop } from '@mui/material';

import { Loader, LoaderContainer } from '@workspace/ui';

import * as Styled from './AppLoader.styles';

interface AppLoaderProps {
    isLoading: boolean;
}

export const AppLoader = ({ isLoading }: AppLoaderProps) => {
    if (!isLoading) return;

    return (
        <Styled.Container>
            <Backdrop open={true}>
                <LoaderContainer height='fullPage'>
                    <Loader />
                </LoaderContainer>
            </Backdrop>
        </Styled.Container>
    );
};
