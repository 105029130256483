import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
    AMOUNT_FORMAT_OPTIONS,
    AMOUNT_IN_CURRENCY_FORMAT_OPTIONS,
    AMOUNT_IN_PERCENT_FORMAT_OPTIONS,
} from '../constants';

export type FormatPercentageOptions = Pick<Intl.NumberFormatOptions, 'maximumFractionDigits' | 'minimumFractionDigits'>;

export function useFormatAmountFallback() {
    const { formatNumber } = useIntl();

    const formatAmount = useCallback(
        (amount: number) => {
            if (isNaN(amount)) {
                return '';
            }

            return formatNumber(amount, AMOUNT_FORMAT_OPTIONS);
        },
        [formatNumber],
    );

    const formatAmountInCurrency = useCallback(
        (amount: number, currencyCode: string) => {
            if (isNaN(amount)) {
                return '';
            }

            return formatNumber(amount, AMOUNT_IN_CURRENCY_FORMAT_OPTIONS) + ' ' + currencyCode;
        },
        [formatNumber],
    );

    const formatPercentage = useCallback(
        (amount: number, options?: FormatPercentageOptions) => {
            if (isNaN(amount)) {
                return '';
            }

            return formatNumber(amount, {
                ...AMOUNT_IN_PERCENT_FORMAT_OPTIONS,
                ...options,
            });
        },
        [formatNumber],
    );

    return { formatAmountInCurrency, formatAmount, formatPercentage };
}

export type AmountFormatters = ReturnType<typeof useFormatAmountFallback>;
