import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useUserSettings } from '~modules/auth/hooks';

import { createConfigurableAmountFormatters } from '../utils/createConfigurableAmountFormatters';
import { useFormatAmountFallback } from './useFormatAmountFallback';

export function useFormatAmount() {
    const fallback = useFormatAmountFallback();
    const userSettings = useUserSettings();
    const { locale } = useIntl();

    return useMemo(() => {
        if (!userSettings) {
            return fallback;
        }

        if (!userSettings?.DecimalSeparator || !userSettings.ThousandSeparator) {
            return fallback;
        }

        return createConfigurableAmountFormatters({
            locale,
            separators: {
                decimalSeparator: userSettings.DecimalSeparator,
                thousandsSeparator: userSettings.ThousandSeparator,
            },
        });
    }, [fallback, userSettings, locale]);
}
