import { AttributeTypeAndValue, CertificationRequest } from 'pkijs';

import { ASYMMETRIC_ENCRYPTION_CONFIG } from './config';
import { importPrivateKey } from './keyPair';
import type { ParsedCertificate } from './parsedCertificate';
import { PemType, toPem } from './utils';

/**
 * Creates PKCS #10 v1.7 – Certificate Signing Request (CSR) object.
 */
async function createCertificateSigningRequest<
    KeyPair extends {
        publicKey: CryptoKey;
        privateKey: CryptoKey;
        algorithm: {
            hash: {
                name: string;
            };
        };
    },
>(keyPair: KeyPair, subjectAttributes: AttributeTypeAndValue[]) {
    const pkcs10 = new CertificationRequest();

    await pkcs10.subjectPublicKeyInfo.importKey(keyPair.publicKey);

    pkcs10.subject.typesAndValues = subjectAttributes;

    await pkcs10.sign(keyPair.privateKey, keyPair.algorithm.hash.name);

    return pkcs10;
}

/**
 * Use provided self-signed certificate to create certificate signing request and return it in PEM format.
 */
export async function createCSRFromSelfSignedCertificate(selfSignedCertificate: ParsedCertificate<true>) {
    const { privateKey, algorithm } = await importPrivateKey(selfSignedCertificate.privateKey, {
        name: ASYMMETRIC_ENCRYPTION_CONFIG.selfSignedCertificate.name,
        hash: ASYMMETRIC_ENCRYPTION_CONFIG.selfSignedCertificate.hash,
        operation: 'sign',
    });

    const keyPair = {
        algorithm,
        privateKey,
        publicKey: await selfSignedCertificate.certificate.getPublicKey(),
    };

    const csr = await createCertificateSigningRequest(
        keyPair,
        selfSignedCertificate.certificate.subject.typesAndValues,
    );

    const csrPem = toPem(csr, PemType.CertificateRequest);

    return csrPem;
}
