import { useEffect, useState } from 'react';

import type { AuthSession } from '../../contexts';
import { AuthSessionProvider } from './AuthSessionProvider';
import { useRetrieveAuthSession } from './hooks/useRetrieveAuthSession';

export interface AuthProviderProps {
    children: React.ReactNode;
    initialSession?: AuthSession;
    Loader?: React.ComponentType;
    onSessionRetrieved?: (session: AuthSession) => Promise<void>;
}

export const AuthProvider = ({
    children,
    Loader = () => null,
    onSessionRetrieved = async () => {},
}: AuthProviderProps) => {
    const [retrievedAuthSession, setRetrievedAuthSession] = useState<AuthSession | null>(null);
    const retrieveAuthSession = useRetrieveAuthSession(onSessionRetrieved);

    useEffect(() => {
        retrieveAuthSession().then(retrievedSession => {
            if (retrievedSession) {
                setRetrievedAuthSession(retrievedSession);
            }
        });
    }, [retrieveAuthSession]);

    if (!retrievedAuthSession) {
        return <Loader />;
    }

    return <AuthSessionProvider initialSession={retrievedAuthSession}>{children}</AuthSessionProvider>;
};
