import type { ValuesOf } from '@workspace/ts-utils';

export const routes = {
    home: '/',
    help: '/help_[lang]/index.htm',
    faq: '/help_[lang]/helper/faq.html',
    news: '/help_[lang]/news_current.html',

    /**
     * If changed, also change update `packages/api/fetcher/clientSecurityChangeHandler.ts`
     */
    login: '/auth',
    passwordLogin: '/auth/login',
    setPin: '/auth/set-pin',
    verifyPin: '/auth/verify-pin',
    setPhoneNumber: '/auth/set-phone-number',
    verifyPhoneNumber: '/auth/verify-phone-number',

    downloadable: '/documents-download',
    certificateBlocking: '/block-certificate',
    certificateBlockingResult: '/block-certificate/[certificateIdFrom]',

    requestCertificateDetails: '/certificate-request',
    requestCertificatePassword: '/certificate-request/password',
    requestCertificateReview: '/certificate-request/review',

    certificateManagement: '/certificate-management',
    certificateDetail: '/certificate-management/[serialNumber]',
    changePassword: '/certificate-management/change-password',
} as const satisfies Record<string, string>;

export type Route = ValuesOf<typeof routes>;

export const generalAuthRoutes = {
    home: '/',
} as const satisfies Record<string, string>;

export const doIdAuthRoutes = {
    // Purchased invoices
    purchasedInvoices: '/[doId]/invoices/purchased',
    purchasedInvoicesFilter: '/[doId]/invoices/purchased/filter',
    newPurchasedInvoice: '/[doId]/invoices/purchased/new',
    newPurchasedInvoiceConfirmation: '/[doId]/invoices/purchased/confirmation',
    purchasedInvoiceDetail: '/[doId]/invoices/purchased/detail/[id]',

    // Discount Invoices
    discountInvoices: '/[doId]/invoices/discount',
    discountInvoicesFilter: '/[doId]/invoices/discount/filter',
    newDiscountInvoice: '/[doId]/invoices/discount/new',
    newDiscountInvoiceConfirmation: '/[doId]/invoices/discount/confirmation',
    discountInvoiceDetail: '/[doId]/invoices/discount/detail/[id]',

    // Issued invoices
    issuedInvoiceDetail: '/[doId]/invoices/issued/detail/[id]',
    issuedInvoices: '/[doId]/invoices/issued',
    issuedInvoicesFilter: '/[doId]/invoices/issued/filter',

    // Liability Invoices
    liabilityInvoices: '/[doId]/invoices/liability',
    liabilityInvoicesFilter: '/[doId]/invoices/liability/filter',
    liabilityInvoiceDetail: '/[doId]/invoices/liability/detail/[id]',

    // Due invoices
    dueInvoices: '/[doId]/invoices/due',
    dueInvoicesFilter: '/[doId]/invoices/due/filter',
    dueInvoiceDetail: '/[doId]/invoices/due/detail/[id]',

    // Unfunded invoices
    nonfinancedInvoices: '/[doId]/invoices/nonfinanced',
    nonfinancedInvoicesFilter: '/[doId]/invoices/nonfinanced/filter',
    nonfinancedInvoiceDetail: '/[doId]/invoices/nonfinanced/[id]',

    // Batches
    batchImport: '/[doId]/batches/[invoiceType]/import',
    batchImportFileInfo: '/[doId]/batches/[invoiceType]/file',
    batchEntityFilter: '/[doId]/batches/[invoiceType]/filter',
    batchEntityTable: '/[doId]/batches/[invoiceType]',
    batchImportDetail: '/[doId]/batches/[invoiceType]/detail/[fileId]',

    // Liability invoices
    newInvoiceLiability: '/[doId]/invoices/liability/new',
    newInvoiceLiabilityConfirmation: '/[doId]/invoices/liability/confirmation',

    // Reverse invoices
    newInvoiceDiscountReverse: '/[doId]/invoices/discount-reverse/new',
    newInvoiceDiscountReverseConfirmation: '/[doId]/invoices/discount-reverse/confirmation',
    newInvoicePurchasedReverse: '/[doId]/invoices/purchased-reverse/new',
    newInvoicePurchasedReverseConfirmation: '/[doId]/invoices/purchased-reverse/confirmation',

    supplierDetail: '/[doId]',
    supplierLimits: '/[doId]/limits/supplier',
    supplierLimitsHistory: '/[doId]/limits/supplier/[currency]/history',
    supplierProductLimits: '/[doId]/limits/supplier-product',
    supplierProductLimitsHistory: '/[doId]/limits/supplier-product/currency/[currency]/product/[productId]/history',
    supplierContractLimits: '/[doId]/limits/supplier-contract',
    supplierContractLimitHistory: '/[doId]/limits/supplier-contract/currency/[currency]/contract/[contractId]/history',

    // Exports
    datExports: '/[doId]/exports/dat',
    datExportsFilter: '/[doId]/exports/dat/filter',
    exportSchedule: '/[doId]/exports-schedule',

    supplierCustomerLimits: '/[doId]/limits/supplier-customer',
    supplierCustomerLimitsFilter: '/[doId]/limits/supplier-customer/filter',
    supplierCustomerLimitHistory: '/[doId]/limits/supplier-customer/[limitId]/history',

    // TODO create
    customerDetail: '/[doId]/customer/[customerId]',
    supplierPreviewDetail: '/[doId]/supplier',

    // Cert. management
    certificateManagement: '/[doId]/certificate-management',
    certificateDetail: '/[doId]/certificate-management/[serialNumber]',

    createCertificateRenewalRequest: '/[doId]/certificate-management/renewal-request/create',
    reviewCertificateRenewalRequest: '/[doId]/certificate-management/renewal-request/review',

    changePin: '/[doId]/certificate-management/change-pin',
    changePassword: '/[doId]/certificate-management/change-password',

    // Tools
    userSettings: '/[doId]/tools/user-settings',
    userSettingsNumberChange: '/[doId]/tools/user-settings/number-change',
    userSettingsNumberVerify: '/[doId]/tools/user-settings/number-verify',
    documentDownload: '/[doId]/tools/documents-download',
    partnerPreferences: '/[doId]/tools/partner-preferences',
    loginHistory: '/[doId]/tools/login-history',

    payments: '/[doId]/payments',
    paymentsFilter: '/[doId]/payments/filter',
    paymentDetail: '/[doId]/payments/[paymentId]',

    pdfExports: '/[doId]/exports/pdf',
    pdfExportsFilter: '/[doId]/exports/pdf/filter',

    // open invoices
    openInvoices: '/[doId]/invoices/open',
    openInvoicesFilter: '/[doId]/invoices/open/filter',
} as const satisfies Record<string, string>;

export const externalRoutes = {
    arbesWebsite: 'https://arbes.com/[locale]',
} as const satisfies Record<string, string>;

export const authRoutes = {
    ...generalAuthRoutes,
    ...doIdAuthRoutes,
};

export type AuthRoute = ValuesOf<typeof authRoutes>;
export type DoIdAuthRoute = ValuesOf<typeof doIdAuthRoutes>;
export type ExternalRoute = ValuesOf<typeof externalRoutes>;
