import { atom } from 'jotai';

import type { CaptchaResponseModel } from '@workspace/api';

export const captchaResponseAtom = atom<NonNullable<CaptchaResponseModel> | null>(null);

export const captchaModalAtom = atom<boolean>(false);

export type CaptchaResult = {
    /**
     * Fetched from the server ("public key" of the captcha image)
     */
    hash: string;

    /**
     * The code entered by the user
     */
    code: string;
};

export const captchaResultAtom = atom<CaptchaResult | null>(null);
