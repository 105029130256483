/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type { FilterModel, FilterRequestModelBody, FilterSettingsModel, StringModel } from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Delete filter
 */
export const filterService_deleteFilter = (
    filterRequestModelBody: BodyType<FilterRequestModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<boolean | void>(
        {
            url: `/factoring/filter/deleteFilter`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: filterRequestModelBody,
        },
        options,
    );
};

export const getFilterServiceDeleteFilterMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof filterService_deleteFilter>>,
        TError,
        { data: BodyType<FilterRequestModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof filterService_deleteFilter>>,
    TError,
    { data: BodyType<FilterRequestModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof filterService_deleteFilter>>,
        { data: BodyType<FilterRequestModelBody> }
    > = props => {
        const { data } = props ?? {};

        return filterService_deleteFilter(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type FilterServiceDeleteFilterMutationResult = NonNullable<
    Awaited<ReturnType<typeof filterService_deleteFilter>>
>;
export type FilterServiceDeleteFilterMutationBody = BodyType<FilterRequestModelBody>;
export type FilterServiceDeleteFilterMutationError = ErrorType<unknown>;

/**
 * @summary Delete filter
 */
export const useFilterServiceDeleteFilter = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof filterService_deleteFilter>>,
        TError,
        { data: BodyType<FilterRequestModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getFilterServiceDeleteFilterMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get filter settings
 */
export const filterService_getFilterSettings = (
    stringModel: BodyType<StringModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<FilterSettingsModel | void>(
        {
            url: `/factoring/filter/getFilterSettings`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: stringModel,
        },
        options,
    );
};

export const getFilterServiceGetFilterSettingsMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof filterService_getFilterSettings>>,
        TError,
        { data: BodyType<StringModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof filterService_getFilterSettings>>,
    TError,
    { data: BodyType<StringModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof filterService_getFilterSettings>>,
        { data: BodyType<StringModel> }
    > = props => {
        const { data } = props ?? {};

        return filterService_getFilterSettings(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type FilterServiceGetFilterSettingsMutationResult = NonNullable<
    Awaited<ReturnType<typeof filterService_getFilterSettings>>
>;
export type FilterServiceGetFilterSettingsMutationBody = BodyType<StringModel>;
export type FilterServiceGetFilterSettingsMutationError = ErrorType<unknown>;

/**
 * @summary Get filter settings
 */
export const useFilterServiceGetFilterSettings = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof filterService_getFilterSettings>>,
        TError,
        { data: BodyType<StringModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getFilterServiceGetFilterSettingsMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get list of filters for given view
 */
export const filterService_getFilters = (
    filterRequestModelBody: BodyType<FilterRequestModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<FilterModel[] | void>(
        {
            url: `/factoring/filter/getFilters`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: filterRequestModelBody,
        },
        options,
    );
};

export const getFilterServiceGetFiltersMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof filterService_getFilters>>,
        TError,
        { data: BodyType<FilterRequestModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof filterService_getFilters>>,
    TError,
    { data: BodyType<FilterRequestModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof filterService_getFilters>>,
        { data: BodyType<FilterRequestModelBody> }
    > = props => {
        const { data } = props ?? {};

        return filterService_getFilters(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type FilterServiceGetFiltersMutationResult = NonNullable<Awaited<ReturnType<typeof filterService_getFilters>>>;
export type FilterServiceGetFiltersMutationBody = BodyType<FilterRequestModelBody>;
export type FilterServiceGetFiltersMutationError = ErrorType<unknown>;

/**
 * @summary Get list of filters for given view
 */
export const useFilterServiceGetFilters = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof filterService_getFilters>>,
        TError,
        { data: BodyType<FilterRequestModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getFilterServiceGetFiltersMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Save filter
 */
export const filterService_saveFilter = (
    filterModel: BodyType<FilterModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<FilterModel | void>(
        {
            url: `/factoring/filter/saveFilter`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: filterModel,
        },
        options,
    );
};

export const getFilterServiceSaveFilterMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof filterService_saveFilter>>,
        TError,
        { data: BodyType<FilterModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof filterService_saveFilter>>,
    TError,
    { data: BodyType<FilterModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof filterService_saveFilter>>,
        { data: BodyType<FilterModel> }
    > = props => {
        const { data } = props ?? {};

        return filterService_saveFilter(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type FilterServiceSaveFilterMutationResult = NonNullable<Awaited<ReturnType<typeof filterService_saveFilter>>>;
export type FilterServiceSaveFilterMutationBody = BodyType<FilterModel>;
export type FilterServiceSaveFilterMutationError = ErrorType<unknown>;

/**
 * @summary Save filter
 */
export const useFilterServiceSaveFilter = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof filterService_saveFilter>>,
        TError,
        { data: BodyType<FilterModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getFilterServiceSaveFilterMutationOptions(options);

    return useMutation(mutationOptions);
};
