import { Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Button, Icon, IconRefreshCcw05, TextField } from '@workspace/ui';

import type { useFetchCaptcha } from '~modules/captcha/hooks';

import { Caption } from '../CaptchaDialogContent/CaptchaDialogContent.styles';

export interface CaptchaWidgetContentProps {
    result: ReturnType<typeof useFetchCaptcha>;
}

type DefaultCaptchaFormValues = { captcha: string; [k: string]: unknown };

export const CaptchaWidgetContent = ({ result }: CaptchaWidgetContentProps) => {
    return (
        <>
            <Stack direction='row' spacing={0.75} justifyContent='space-between' alignItems='center'>
                <Caption variant='caption'>
                    <FormattedMessage id='captcha.image.label' />
                </Caption>
                <Stack direction='row'>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img src={`data:image/png;base64,${result.data?.base64Content}`} alt='Captcha image' />
                    <Button
                        iconOnly
                        variant='secondary'
                        disabled={result.isLoading}
                        onClick={() => {
                            result.refetch();
                        }}
                    >
                        <Icon icon={IconRefreshCcw05} />
                    </Button>
                </Stack>
            </Stack>

            <TextField<DefaultCaptchaFormValues> name='captcha' label={<FormattedMessage id='captcha.input.label' />} />
        </>
    );
};
