import {
    loginPromptModalAtom,
    loginPromptResultAtom,
    type LoginPromptResultValue,
} from '~modules/auth/atoms/loginPrompt';
import { usePrompt } from '~modules/prompt/hooks';

export function usePromptLogin() {
    const { promptUser, setResult, setOpen, open } = usePrompt<LoginPromptResultValue>({
        openAtom: loginPromptModalAtom,
        resultAtom: loginPromptResultAtom,
    });

    const closeModal = () => {
        setOpen(false);
        setResult('no');
    };

    const confirmModal = () => {
        setOpen(false);
        setResult('yes');
    };

    return { promptUser, closeModal, confirmModal, open };
}
