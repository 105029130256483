import { useCallback } from 'react';

import type { AuthContextType, AuthSession } from '../contexts';
import { signIn, type SignInProps } from '../services/signIn';

export function useSignIn(setSession: AuthContextType['setSession']) {
    return useCallback<(payload: SignInProps, ignoreTwoFactorRequirement?: boolean) => Promise<AuthSession>>(
        async (payload, ignoreTwoFactorRequirement) => {
            const { userData, twoFactorRequirement } = await signIn(payload, ignoreTwoFactorRequirement);

            const result: AuthSession = twoFactorRequirement
                ? {
                      status: '2FA-requirement',
                      data: twoFactorRequirement,
                  }
                : {
                      status: 'authenticated',
                      data: userData,
                  };

            setSession(result);

            return result;
        },
        [setSession],
    );
}
