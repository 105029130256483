import { z } from 'zod';

import { captchaFormSchema } from '~modules/captcha/schema';
import * as validators from '~modules/form/validators';

export const certificateBlockingFormSchema = z
    .object({
        username: validators.requiredNonEmpty,
        pin: validators.requiredNonEmpty,
    })
    .merge(captchaFormSchema);

export type CertificateBlockingFormSchema = z.infer<typeof certificateBlockingFormSchema>;
