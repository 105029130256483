import { signOut } from '@workspace/auth/services/signOut';

import { routes } from '~constants';

export async function unauthorized() {
    await signOut();

    const url = new URL(window.location.href);

    url.pathname = routes.login;
    // // Make hard reload to clear all data in memory and auth session
    window.location.replace(url);
}
