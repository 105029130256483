import { Checkbox, styled } from '@mui/material';
export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    '&.MuiCheckbox-root': {
        padding: theme.spacing(0),
        [`svg`]: {
            fontSize: '1.25rem',
        },
        '&:focus-within': {
            '&  svg': {
                fill: 'black',
                '&[data-testid="CheckBoxIcon"] path': {
                    outline: `1px solid ${theme.color.buttons.secondary.hover}`,
                    borderRadius: 2,
                },
            },
        },
        '&:focus:not(:focus-visible)': {
            outline: 'none',
        },
    },
}));
