import type { Path } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { InputAutocomplete, TextField, type TextFieldProps, type UnknownFormValues } from '@workspace/ui';

export interface PhoneNumberFieldProps<FormValues extends UnknownFormValues> extends TextFieldProps<FormValues> {
    name: Path<FormValues>;
}

export const PhoneNumberField = <FormValues extends UnknownFormValues>({
    name,
    ...rest
}: PhoneNumberFieldProps<FormValues>) => {
    return (
        <TextField<FormValues>
            label={<FormattedMessage id='certificateRequest.form.phoneNumber' />}
            autoComplete={InputAutocomplete.Off}
            {...rest}
            name={name}
            type='tel'
            inputProps={{ maxLength: 24 }}
        />
    );
};
