import { useQuery } from '@tanstack/react-query';

import { appInfo_getAppVersionToDisplay, QueryKey } from '@workspace/api';

export function useAppVersion() {
    return useQuery({
        queryKey: [QueryKey.VERSION_DISPLAY],
        queryFn: () => appInfo_getAppVersionToDisplay(),
        staleTime: Infinity,
        gcTime: Infinity,
    });
}
