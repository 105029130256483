import { useRouter } from 'next/router';
import type { UseFormResetField } from 'react-hook-form';

import { authServices_blockCertificates, isAntonioError, QueryKey, useInvalidateQuery } from '@workspace/api';
import { logger } from '@workspace/logger';

import { routes } from '~constants';
import { useShowMessagePopup } from '~modules/message-popup/hooks';
import { ConfirmationResponse } from '~modules/prompt/constants';
import { useConfirmation } from '~modules/prompt/hooks';
import { generatePath } from '~utils/generatePath';

import type { CertificateBlockingFormSchema } from '../components/CertificateBlockingForm/schema';

export function useBlockCertificate(captchaHash: string, resetField: UseFormResetField<CertificateBlockingFormSchema>) {
    const { promptUser, setConfirmationContent } = useConfirmation();

    const { push } = useRouter();

    const { invalidateQuery } = useInvalidateQuery();
    const showMessage = useShowMessagePopup();

    async function onSubmit(data: CertificateBlockingFormSchema) {
        setConfirmationContent([
            {
                id: 'certificateBlocking.form.DO_YOU_REALLY_WANT_TO_DO_AN_IRREVERSIBLE_BLOCKING_OF_ALL_CERTIFICATES_FOR_EFACTORING_ACCESS',
            },
        ]);

        const userResponse = await promptUser();

        if (userResponse === ConfirmationResponse.NO) return;

        try {
            const response = await authServices_blockCertificates({
                ...data,
                captchaHash,
            });

            if (response.data?.result !== 'OK') {
                await invalidateQuery(QueryKey.CAPTCHA);
                resetField('captcha');
            }

            switch (response.data?.result) {
                case 'CAPTCHA_ERROR':
                case 'CAPTCHA_TIMEOUT':
                    return await showMessage('error', { id: `error.auth.${response.data.result!}` });

                case 'USERNAME_OR_PIN_ERROR':
                    return await showMessage('error', {
                        id: 'certificateBlocking.form.INCORRECT_COMBINATION_OF_USERNAME_AND_PIN',
                    });

                case 'OK':
                    await push(
                        generatePath(routes.certificateBlockingResult, {
                            certificateIdFrom: String(response.data.lowestCertificateId),
                        }),
                    );
            }
        } catch (e) {
            if (isAntonioError(e) && e.response.status === 429) {
                await showMessage('error', {
                    id: 'certificateBlocking.form.TOO_MANY_ATTEMPTS_AT_CERTIFICATE_BLOCKING_YOU_CAN_TRY_AGAIN_IN_X_MINUTES',
                    values: { timeout: process.env.NEXT_PUBLIC_TRY_AGAIN_TIMEOUT },
                });

                return;
            }
            logger.error(e);
            await showMessage('error', { id: 'general.error' });
        }
    }

    return onSubmit;
}
