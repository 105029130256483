/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvUserSupplierBindModel,
    GetByIdRequestModelOfEfcvUserSupplierBindModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvUserSupplierBindModel,
    ViewDataResultModelOfEfcvUserSupplierBindModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const userSupplierBind_findByAll = (
    efcvUserSupplierBindModel: BodyType<EfcvUserSupplierBindModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvUserSupplierBindModel[] | void>(
        {
            url: `/factoring/efcvusersupplierbind/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvUserSupplierBindModel,
        },
        options,
    );
};

export const getUserSupplierBindFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof userSupplierBind_findByAll>>,
        TError,
        { data: BodyType<EfcvUserSupplierBindModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof userSupplierBind_findByAll>>,
    TError,
    { data: BodyType<EfcvUserSupplierBindModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof userSupplierBind_findByAll>>,
        { data: BodyType<EfcvUserSupplierBindModel> }
    > = props => {
        const { data } = props ?? {};

        return userSupplierBind_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type UserSupplierBindFindByAllMutationResult = NonNullable<
    Awaited<ReturnType<typeof userSupplierBind_findByAll>>
>;
export type UserSupplierBindFindByAllMutationBody = BodyType<EfcvUserSupplierBindModel>;
export type UserSupplierBindFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useUserSupplierBindFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof userSupplierBind_findByAll>>,
        TError,
        { data: BodyType<EfcvUserSupplierBindModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getUserSupplierBindFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const userSupplierBind_findById = (
    getByIdRequestModelOfEfcvUserSupplierBindModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvUserSupplierBindModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvUserSupplierBindModel | void>(
        {
            url: `/factoring/efcvusersupplierbind/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvUserSupplierBindModelAndbigdecimal,
        },
        options,
    );
};

export const getUserSupplierBindFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof userSupplierBind_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvUserSupplierBindModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof userSupplierBind_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvUserSupplierBindModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof userSupplierBind_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvUserSupplierBindModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return userSupplierBind_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type UserSupplierBindFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof userSupplierBind_findById>>>;
export type UserSupplierBindFindByIdMutationBody =
    BodyType<GetByIdRequestModelOfEfcvUserSupplierBindModelAndbigdecimal>;
export type UserSupplierBindFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useUserSupplierBindFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof userSupplierBind_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvUserSupplierBindModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getUserSupplierBindFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const userSupplierBind_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvUserSupplierBindModel | void>(
        {
            url: `/factoring/efcvusersupplierbind/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getUserSupplierBindFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof userSupplierBind_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof userSupplierBind_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof userSupplierBind_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return userSupplierBind_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type UserSupplierBindFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof userSupplierBind_findByLightDataRequest>>
>;
export type UserSupplierBindFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type UserSupplierBindFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useUserSupplierBindFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof userSupplierBind_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getUserSupplierBindFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const userSupplierBind_findByViewDataRequest = (
    viewDataRequestModelOfEfcvUserSupplierBindModel: BodyType<ViewDataRequestModelOfEfcvUserSupplierBindModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvUserSupplierBindModel | void>(
        {
            url: `/factoring/efcvusersupplierbind/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvUserSupplierBindModel,
        },
        options,
    );
};

export const getUserSupplierBindFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof userSupplierBind_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvUserSupplierBindModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof userSupplierBind_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvUserSupplierBindModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof userSupplierBind_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvUserSupplierBindModel> }
    > = props => {
        const { data } = props ?? {};

        return userSupplierBind_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type UserSupplierBindFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof userSupplierBind_findByViewDataRequest>>
>;
export type UserSupplierBindFindByViewDataRequestMutationBody =
    BodyType<ViewDataRequestModelOfEfcvUserSupplierBindModel>;
export type UserSupplierBindFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useUserSupplierBindFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof userSupplierBind_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvUserSupplierBindModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getUserSupplierBindFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
