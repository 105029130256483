import { AuthError } from '@workspace/errors';
import { logger } from '@workspace/logger';

import { DEBUG_LOGGER } from '../config';
import {
    executeServiceTaskFailure,
    executeServiceTaskRequest,
    executeServiceTaskSuccess,
    registerServiceTask,
    unregisterServiceTask,
} from '../messages';
import type { ServiceName, Services, ServiceTaskRequest, ServiceTaskSuccessResult } from '../types';
import * as services from '../worker/services';

let workerRef: Worker | null = null;

function getWorker() {
    if (!('Worker' in globalThis) || workerRef !== null) {
        return workerRef;
    }

    workerRef = new Worker(new URL('../worker/certificates.worker.ts', import.meta.url), {
        name: 'certificates',
        type: 'module',
        credentials: 'omit',
    });

    workerRef.onerror = event => {
        logger.error(event);
    };

    workerRef.onmessageerror = event => {
        logger.error(event.data);
    };

    return workerRef;
}

function isAuthErrorObject(parsedError: unknown): parsedError is AuthError {
    return (
        typeof parsedError === 'object' &&
        parsedError !== null &&
        'name' in parsedError &&
        parsedError.name === 'AuthError'
    );
}

/**
 * Reconstructs AuthError object from stringified error from worker.
 */
function parseServiceTaskError(error: unknown) {
    try {
        const parsedError = typeof error === 'string' ? JSON.parse(error) : error;

        if (isAuthErrorObject(parsedError)) {
            return new AuthError(parsedError.code, parsedError.description, parsedError.originalError);
        }

        return parsedError;
    } catch {
        return error;
    }
}

function executeTaskMessageHandler<Resolve extends (...args: any[]) => any, Reject extends (err: unknown) => void>(
    taskId: string,
    res: Resolve,
    rej: Reject,
    once: boolean,
) {
    const worker = getWorker()!;

    type MessageData = ReturnType<typeof executeServiceTaskSuccess | typeof executeServiceTaskFailure>;

    function messageListener(event: MessageEvent<MessageData>) {
        const response = event.data;

        if (response.task.id !== taskId) return;

        if (once) {
            worker.removeEventListener('message', messageListener);
        }

        if (DEBUG_LOGGER) {
            logger.debug(response);
        }

        switch (response.type) {
            case 'EXECUTE_SERVICE_TASK_SUCCESS':
                res(response.task.result);
                break;

            case 'EXECUTE_SERVICE_TASK_FAILURE':
                rej(parseServiceTaskError(response.task.error));
                break;

            default:
                // @ts-expect-error
                rej(`Received unknown message type: ${response.type}`);
        }
    }

    worker.addEventListener('message', messageListener, { passive: true });

    return () => {
        worker.removeEventListener('message', messageListener);
    };
}

export async function executeInWorker<Service extends ServiceName>(
    name: Service,
    args: ServiceTaskRequest<Service>['args'],
) {
    return new Promise<ServiceTaskSuccessResult<Service>['result']>((res, rej) => {
        const worker = getWorker()!;

        let msg = executeServiceTaskRequest(name, args);

        if (DEBUG_LOGGER) logger.debug(msg);

        const taskId = msg.task.id;

        executeTaskMessageHandler(taskId, res, rej, true);

        worker.postMessage(msg);

        // @ts-expect-error
        msg = null;
    });
}

/**
 * It supports only one callback argument!
 */
export function registerInWorker<Service extends ServiceName>(
    name: Service,
    args: ServiceTaskRequest<Service>['args'],
) {
    const callback = args.find(arg => typeof arg === 'function');

    const worker = getWorker()!;

    let msg = registerServiceTask(name);

    const taskId = msg.task.id;

    const removeMessageHandler = executeTaskMessageHandler(
        taskId,
        // @ts-expect-error
        callback,
        error => {
            throw error;
        },
        false,
    );

    worker.postMessage(msg);

    // @ts-expect-error
    msg = null;

    return () => {
        worker.postMessage(unregisterServiceTask(taskId, name));
        removeMessageHandler();
    };
}

export const worker = (() => {
    const servicesEntries = (Object.keys(services) as ServiceName[]).map(serviceName => {
        type Service = Services[typeof serviceName];

        const service = (...args: Parameters<Service>) => {
            const callback = args.find(arg => typeof arg === 'function');

            if (callback) {
                return registerInWorker(serviceName, args);
            }

            return executeInWorker(serviceName, args) as Promise<ReturnType<Service>>;
        };

        return [serviceName, service] as const;
    });

    // @ts-expect-error
    return Object.fromEntries(servicesEntries) as Services;
})();
