import { authRoutes, routes } from '~constants';

export const routesWithPasswordAction = new Set<string>([
    authRoutes.newDiscountInvoice,
    authRoutes.newDiscountInvoiceConfirmation,
    authRoutes.newInvoiceDiscountReverse,

    // New invoice flow
    authRoutes.newPurchasedInvoice,
    authRoutes.newDiscountInvoice,
    authRoutes.newInvoiceLiability,
    authRoutes.newInvoiceDiscountReverse,
    authRoutes.newInvoicePurchasedReverse,

    authRoutes.newPurchasedInvoiceConfirmation,
    authRoutes.newDiscountInvoiceConfirmation,
    authRoutes.newInvoiceLiabilityConfirmation,
    authRoutes.newInvoiceDiscountReverseConfirmation,
    authRoutes.newInvoicePurchasedReverseConfirmation,

    // Import flow
    authRoutes.batchImport,
    authRoutes.batchImportFileInfo,
    authRoutes.batchImportDetail,

    // Certificates
    routes.certificateManagement,
    routes.certificateDetail,

    authRoutes.changePassword,
    authRoutes.changePin,

    routes.setPin,
    routes.verifyPin,
    routes.setPhoneNumber,
    routes.verifyPhoneNumber,
    routes.changePassword,

    routes.requestCertificateDetails,
    routes.requestCertificatePassword,
    routes.requestCertificateReview,

    authRoutes.certificateManagement,
    authRoutes.certificateDetail,
    authRoutes.createCertificateRenewalRequest,
    authRoutes.reviewCertificateRenewalRequest,

    routes.certificateBlocking,
    routes.certificateBlockingResult,
]);
