import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
    maxWidth: 620,
    width: '100%',
    padding: `${theme.spacing(3.5)} ${theme.spacing(5)}`,

    backgroundColor: theme.color.backgrounds.primary,
    margin: '0 auto',
}));
