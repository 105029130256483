import { Stack, Typography } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { FormattedMessage } from 'react-intl';

import { Dialog } from '@workspace/ui';

import { confirmationModalAtom, confirmationPayloadAtom, confirmationResultAtom } from '~modules/prompt/atoms';
import { ConfirmationResponse } from '~modules/prompt/constants';

export const ConfirmationDialog = () => {
    const [open, setOpen] = useAtom(confirmationModalAtom);
    const [confirmationPayload, setConfirmationPayload] = useAtom(confirmationPayloadAtom);

    const setConfirmationResult = useSetAtom(confirmationResultAtom);

    const closeModal = () => {
        setConfirmationResult(ConfirmationResponse.NO);
        setOpen(false);
        setConfirmationPayload([]);
    };

    const confirmModal = () => {
        setConfirmationResult(ConfirmationResponse.YES);
        setOpen(false);
        setConfirmationPayload([]);
    };

    return (
        <Dialog
            open={open}
            onClose={closeModal}
            buttons={{
                cancel: {
                    variant: 'primary',
                    color: 'primary',
                    onClick: closeModal,
                    children: <FormattedMessage id='button.no' />,
                },
                submit: {
                    variant: 'primary',
                    color: 'success',
                    onClick: () => confirmModal(),
                    children: <FormattedMessage id='button.yes' />,
                },
            }}
            minWidth={500}
        >
            <Stack gap={1.5}>
                {confirmationPayload.map(confirmationItem => (
                    <Typography key={confirmationItem.id}>
                        <FormattedMessage {...confirmationItem} />
                    </Typography>
                ))}
            </Stack>
        </Dialog>
    );
};
