/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type ErrorType } from '../../fetcher/fetcher';
import type { BounceFileUsingPOSTParams } from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * Used as an alternative to Data URLs to supported older browsers
 * @summary Return input data as a file with given filename
 */
export const fileBounceService_bounceFile = (
    params: BounceFileUsingPOSTParams,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<void>({ url: `/bounceFile`, method: 'POST', params }, options);
};

export const getFileBounceServiceBounceFileMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof fileBounceService_bounceFile>>,
        TError,
        { params: BounceFileUsingPOSTParams },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof fileBounceService_bounceFile>>,
    TError,
    { params: BounceFileUsingPOSTParams },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof fileBounceService_bounceFile>>,
        { params: BounceFileUsingPOSTParams }
    > = props => {
        const { params } = props ?? {};

        return fileBounceService_bounceFile(params, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type FileBounceServiceBounceFileMutationResult = NonNullable<
    Awaited<ReturnType<typeof fileBounceService_bounceFile>>
>;

export type FileBounceServiceBounceFileMutationError = ErrorType<unknown>;

/**
 * @summary Return input data as a file with given filename
 */
export const useFileBounceServiceBounceFile = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof fileBounceService_bounceFile>>,
        TError,
        { params: BounceFileUsingPOSTParams },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getFileBounceServiceBounceFileMutationOptions(options);

    return useMutation(mutationOptions);
};
