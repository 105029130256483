import { Box, Grid as MUIGrid, MenuItem as MUIMenuItem, styled } from '@mui/material';

import type { MenuProps } from './Menu';

export const MenuItem = styled(MUIMenuItem)(({ theme }) => ({
    padding: 0,

    fontSize: 12,

    '&:hover': {
        background: 'transparent',
        color: theme.color.accent.secondary,
        textDecoration: 'underline',
    },
})) as typeof MUIMenuItem;

const ignoredProps = ['activeItem', 'itemTitle', 'compact'];

export const Grid = styled(MUIGrid, { shouldForwardProp: props => !ignoredProps.includes(props as string) })<
    Pick<MenuProps, 'activeItem' | 'compact'> & { itemTitle?: string }
>(({ theme, activeItem, itemTitle, compact }) => ({
    backgroundColor: theme.color.backgrounds.primary,
    position: 'absolute',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    display: `${activeItem === itemTitle ? 'block' : 'none'}`,
    borderRadius: 2,
    boxShadow: theme.shadows[1],
    zIndex: 1000,

    width: 'fit-content',
    top: compact ? 39 : 50,
    marginLeft: '-2rem',
}));

export const Divider = styled(Box)(({ theme }) => ({
    backgroundColor: theme.color.accent.primary,
    opacity: 0.12,
    borderBottom: `1px solid ${theme.color.accent.primary}`,
    margin: `${theme.spacing(0.75)} 0`,
}));
