import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '@workspace/api';
import { logger } from '@workspace/logger';

const fetchNodeInfo = async () => {
    try {
        const response = await fetch('/api/nodeInfo');

        if (!response.ok) {
            logger.error('Failed to fetch node information');

            return null;
        }

        // FIXME: what type does it return?
        // @ts-expect-error
        return response.json() as string;
    } catch {
        return null;
    }
};

export function useNodeInfo() {
    return useQuery({
        queryKey: [QueryKey.NODE_INFO],
        queryFn: fetchNodeInfo,
        refetchOnMount: false,
        refetchInterval: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
    });
}
