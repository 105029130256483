import {
    applyCSRFTokenInterceptor,
    authServices_getChallenge,
    authServices_login,
    LoginRequestModelGlobalLocale,
    security_getUserData,
} from '@workspace/api';
import { worker } from '@workspace/certificates';
import { AuthError, isAuthError } from '@workspace/errors';
import { logger } from '@workspace/logger';

import { clearTwoFactorRequirement, handleStartOfTwoFactorAuth } from './twoFactor';

async function handleCsrtToken(headers: Response['headers']) {
    const csrfToken = headers.get('X-CSRF-TOKEN');

    if (!csrfToken) {
        throw new AuthError('NO_CSRF_TOKEN');
    }

    await applyCSRFTokenInterceptor(csrfToken);
}

export interface SignInProps {
    username: string;
    locale: LoginRequestModelGlobalLocale;
}

/**
 * __Be aware__ this method just does actual sign in but doesn't handle UI, nor changes auth state.
 * You've probably ment to use `signIn` method from `useSession` hook.
 */
export async function signIn({ username, locale }: SignInProps, ignoreTwoFactorRequirement: boolean = false) {
    try {
        const result = await authServices_getChallenge({ username });
        const challenge = result.data?.challenge!;

        const { fingerprint, signature } = await worker.signInSignatureAndFingerprint(challenge);

        const loginResult = await authServices_login({
            certificateFingerprint: fingerprint,
            challenge,
            clientLanguage: navigator.language,
            clientPlatform: navigator.platform,
            locale,
            signature,
            username,
        });

        if (loginResult.data!.result && loginResult.data!.result !== 'OK') {
            throw new AuthError(
                loginResult.data!.result,
                undefined,
                null,
                loginResult.data!.certificateCheckResponse?.response,
            );
        }

        await handleCsrtToken(loginResult.response.headers);

        let twoFactorRequirement = handleStartOfTwoFactorAuth(loginResult.data!);

        if (twoFactorRequirement && !ignoreTwoFactorRequirement) {
            return {
                userData: null,
                twoFactorRequirement,
            };
        }

        if (ignoreTwoFactorRequirement) {
            twoFactorRequirement = null;
            clearTwoFactorRequirement();
        }

        const userDataResult = await security_getUserData(true);

        return {
            userData: userDataResult.data!,
            twoFactorRequirement,
        };
    } catch (error) {
        await worker.resetStoredPassword();

        const e = error as Error;

        if (isAuthError(e)) {
            throw e;
        }

        logger.error(e);

        throw new AuthError('UNKNOWN', e.message, e);
    }
}
