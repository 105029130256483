import type { ReactNode } from 'react';
import { useRouter } from 'next/router';
import type { ContainerProps } from '@mui/material';

import { useSession } from '@workspace/auth';
import { Container, type ContainerSize } from '@workspace/ui';

import { authRoutes } from '~constants';

import { FooterLink } from '../FooterLink';
import { Header } from '../Header';
import { Navigation } from '../Navigation';
import * as Styled from './AppLayout.styles';

interface AppLayoutProps extends ContainerProps {
    children: ReactNode;
    withNav?: boolean;
    size?: ContainerSize;
    largeNav?: boolean;
}

export const AppLayout = ({
    children,
    size = 'md',
    maxWidth = 'xl',
    withNav = true,
    largeNav = false,
    ...props
}: AppLayoutProps) => {
    const { status } = useSession();
    const { route } = useRouter();

    const isAuth = status === 'authenticated' && Object.values(authRoutes).includes(route);

    return (
        <Styled.Layout>
            <Header isAuth={isAuth} />
            {isAuth && withNav && <Navigation compact={!largeNav} />}
            <Styled.Container largeNav={largeNav}>
                <Container size={size} maxWidth={maxWidth} {...props}>
                    {children}
                </Container>
            </Styled.Container>
            <FooterLink />
        </Styled.Layout>
    );
};
