import { useCallback, useEffect, useId, useState } from 'react';
import { ClickAwayListener, Typography, } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Icon, IconCheck, IconDownInput } from '../../../../../components';
import { useMenuProps } from './hooks';
import { FormControl, InputLabel, MenuItem, MenuItemBox, StyledSelect } from './Select.styles';
export const Select = ({ options, onChange, disabled, label, error, fullWidth = true, defaultValue = '', value, omitEmptyOption, className, ...rest }) => {
    const id = useId();
    const [opened, setOpened] = useState(false);
    const handleOpen = () => setOpened(true);
    const handleClose = () => setOpened(false);
    const [shrinkLabel, setShrinkLabel] = useState(false);
    const [currentValue, setCurrentValue] = useState(value ?? defaultValue);
    useEffect(() => {
        setCurrentValue(value ?? defaultValue);
    }, [defaultValue, value]);
    useEffect(() => {
        if (!!value && Array.isArray(value) && value.length === 0)
            setShrinkLabel(false);
        else
            setShrinkLabel(true);
    }, [value, setShrinkLabel]);
    const handleSelect = (event) => {
        const nextValue = event.target.value;
        onChange?.(nextValue);
        setCurrentValue(nextValue);
    };
    const isSelected = useCallback((option) => {
        if (typeof currentValue === 'string') {
            return option.value === currentValue;
        }
        if (Array.isArray(currentValue)) {
            return currentValue.includes(option.value);
        }
        return false;
    }, [currentValue]);
    const MenuProps = useMenuProps(rest.MenuProps);
    return (<ClickAwayListener onClickAway={() => handleClose()} mouseEvent='onMouseDown'>
            <FormControl disabled={disabled} fullWidth={fullWidth} className={className}>
                <InputLabel id={id} error={error} shrink={opened || (!!value && shrinkLabel)}>
                    {label}
                </InputLabel>
                <StyledSelect fullWidth={fullWidth} labelId={id} value={currentValue} IconComponent={props => <Icon icon={IconDownInput} {...props}/>} onChange={value => handleSelect(value)} disabled={disabled} label={label} open={opened} onOpen={handleOpen} onClose={handleClose} error={error} defaultValue={defaultValue} MenuProps={MenuProps} {...rest}>
                    {!omitEmptyOption && (<MenuItem value=''>
                            <MenuItemBox direction='row' justifyContent='space-between'>
                                <Typography variant='formItem'>
                                    <FormattedMessage id='general.select.empty'/>
                                </Typography>
                            </MenuItemBox>
                        </MenuItem>)}
                    {options.map(option => {
            const optionSelected = isSelected(option);
            return (<MenuItem disableRipple key={option.value} value={option.value} selected={optionSelected}>
                                <MenuItemBox direction='row' justifyContent='space-between' alignItems='center'>
                                    <Typography variant='formItem'>{option.label}</Typography>
                                    {optionSelected && <Icon icon={IconCheck}/>}
                                </MenuItemBox>
                            </MenuItem>);
        })}
                </StyledSelect>
            </FormControl>
        </ClickAwayListener>);
};
