export interface Parts {
    thousandsSeparator: string;
    decimalSeparator: string;
}

export function formatAndReplaceParts(formatter: Intl.NumberFormat, parts: Parts, value: number) {
    if (isNaN(value)) {
        return '';
    }

    const formattedParts = formatter.formatToParts(value);

    return formattedParts
        .map(({ type, value }) => {
            if (type === 'decimal') {
                return parts.decimalSeparator;
            }
            if (type === 'group') {
                return parts.thousandsSeparator;
            }

            return value;
        })
        .join('');
}
