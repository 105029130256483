import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { LogActions } from '~modules/session-log/constants';
import { useLogActionOnShow } from '~modules/session-log/hooks';

import { PasswordChangeForm } from './PasswordChangeForm';

export const PasswordChangePage = () => {
    useLogActionOnShow(LogActions.SYSTEM_PASSWORD_CHANGE);

    return (
        <>
            <Typography variant='h1'>
                <FormattedMessage id='passwordChange.title' />
            </Typography>

            <PasswordChangeForm />
        </>
    );
};
