import type { LoginResponseModelGlobal } from '@workspace/api';

export enum TwoFactorRequirement {
    SET_PIN = 'SET_PIN',
    VERIFY_PIN = 'VERIFY_PIN',
    SET_PHONE_NUMBER = 'SET_PHONE_NUMBER',
    VERIFY_PHONE_NUMBER = 'VERIFY_PHONE_NUMBER',
}

function resolveTwoFactorRequirement({ pinSet, phoneNumberSet, twoFactorAuthType }: LoginResponseModelGlobal) {
    // Enforce PIN set regardless the value of `twoFactorAuthType`
    if (!pinSet) {
        return TwoFactorRequirement.SET_PIN;
    }

    if (twoFactorAuthType === 'PIN') {
        return TwoFactorRequirement.VERIFY_PIN;
    }

    if (twoFactorAuthType === 'SMS') {
        if (!phoneNumberSet) {
            return TwoFactorRequirement.SET_PHONE_NUMBER;
        }

        return TwoFactorRequirement.VERIFY_PHONE_NUMBER;
    }
}

const storageKey = 'twoFactorRequirement';

export function retrieveTwoFactorRequirement() {
    const value = sessionStorage.getItem(storageKey);

    return value ? (value as TwoFactorRequirement) : null;
}

export function clearTwoFactorRequirement() {
    sessionStorage.removeItem(storageKey);
}

export function handleStartOfTwoFactorAuth(loginResult: LoginResponseModelGlobal) {
    const twoFactorRequirement = resolveTwoFactorRequirement(loginResult);

    if (!twoFactorRequirement) {
        // Clear any existing two factor requirement from prev. session
        clearTwoFactorRequirement();

        return null;
    }

    // Let user freely to browse the app even without phone number or pin
    if (loginResult.twoFactorAuthType !== 'DISABLED') {
        sessionStorage.setItem(storageKey, twoFactorRequirement);
    }

    return twoFactorRequirement;
}
