import { backdropClasses, FormControl as FormControlMUI, iconClasses, inputBaseClasses, inputLabelClasses, InputLabel as InputLabelMUI, listClasses, menuClasses, menuItemClasses, MenuItem as MenuItemMui, outlinedInputClasses, selectClasses, Select as SelectMUI, Stack, styled, typographyClasses, } from '@mui/material';
const SELECT_INPUT_HEIGHT = 32;
export const MenuItemBox = styled(Stack)(() => ({
    width: '100%',
    [`& .${listClasses.root}`]: {
        padding: 'unset',
    },
}));
export const FormControl = styled(FormControlMUI)(({ theme }) => ({
    position: 'relative',
    minWidth: 220,
    [`& .${outlinedInputClasses.root}:hover .${selectClasses.nativeInput}[value]  ~  fieldset`]: {
        borderColor: theme.color.inputs.borderHover,
    },
    [`& .${outlinedInputClasses.root}.Mui-disabled fieldset`]: {
        borderColor: theme.color.inputs.border,
    },
    [`& .${outlinedInputClasses.root}.Mui-error fieldset.${outlinedInputClasses.notchedOutline}`]: {
        borderColor: theme.color.alerts.error,
    },
    [`& .${outlinedInputClasses.root}.Mui-focused fieldset.${outlinedInputClasses.notchedOutline}`]: {
        borderColor: theme.color.inputs.borderHover,
    },
    [`& .${selectClasses.icon}`]: {
        top: 'unset',
    },
}));
export const StyledSelect = styled(SelectMUI)(({ theme, value }) => ({
    height: SELECT_INPUT_HEIGHT,
    borderRadius: 2,
    [`& .${selectClasses.nativeInput}[value]  ~  fieldset`]: {
        borderColor: value ? theme.color.inputs.text : theme.color.inputs.border,
    },
    [`& .${selectClasses.select}`]: {
        display: 'flex',
        alignItems: 'center',
        [`& .${iconClasses.root}`]: {
            display: 'none',
        },
    },
    [`& .${selectClasses.select} .${typographyClasses.root}`]: {
        ...theme.typography.formItem,
    },
    [`&.${outlinedInputClasses.root}:hover .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: theme.color.inputs.borderHover,
    },
    [`&.${outlinedInputClasses.root}:focus-within .${outlinedInputClasses.notchedOutline}`]: {
        border: `1.625px solid ${theme.color.foregrounds.primary}`,
        '& legend': {
            display: !value ? 'none' : 'inherit',
        },
    },
    [`& .${inputBaseClasses.input}`]: {
        paddingLeft: theme.spacing(0.625),
    },
    [`& .${backdropClasses.root}`]: {
        visibility: 'hidden',
    },
    [`& .${menuClasses.root}`]: {
        pointerEvents: 'none',
    },
    [`& .${menuClasses.paper}`]: {
        pointerEvents: 'auto',
    },
}));
export const InputLabel = styled(InputLabelMUI)(({ theme, error }) => ({
    ...theme.typography.formItem,
    [`&.${inputLabelClasses.root}`]: {
        color: error ? theme.color.alerts.error : theme.color.inputs.textLabel,
        transform: 'translate(10px, 7px)',
        '&.Mui-disabled': {
            opacity: 0.4,
        },
    },
    [`&.${inputLabelClasses.shrink}`]: {
        fontSize: '0.75rem',
        transform: 'translate(14px, -11px)',
        '&.Mui-disabled': {
            opacity: 0.4,
        },
    },
}));
export const MenuItem = styled(MenuItemMui)(({ theme }) => ({
    height: SELECT_INPUT_HEIGHT,
    [`& .${menuItemClasses.gutters}`]: {
        margin: theme.spacing(1),
    },
    [`&.${menuItemClasses.root}`]: {
        padding: theme.spacing(0.25, 0.75),
        color: theme.color.inputs.text,
        '&:hover': {
            backgroundColor: theme.color.inputs.grey,
        },
        [`&.${menuItemClasses.selected}`]: {
            backgroundColor: theme.color.inputs.background,
            color: theme.color.inputs.borderHover,
        },
    },
    [`& .${typographyClasses.root}`]: {
        margin: theme.spacing(0.1),
    },
}));
