/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcWebSessionModel,
    GetByIdRequestModelOfEfcWebSessionModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcWebSessionModel,
    ViewDataResultModelOfEfcWebSessionModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const webSession_findByAll = (
    efcWebSessionModel: BodyType<EfcWebSessionModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcWebSessionModel[] | void>(
        {
            url: `/factoring/efcwebsession/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcWebSessionModel,
        },
        options,
    );
};

export const getWebSessionFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof webSession_findByAll>>,
        TError,
        { data: BodyType<EfcWebSessionModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof webSession_findByAll>>,
    TError,
    { data: BodyType<EfcWebSessionModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof webSession_findByAll>>,
        { data: BodyType<EfcWebSessionModel> }
    > = props => {
        const { data } = props ?? {};

        return webSession_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type WebSessionFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof webSession_findByAll>>>;
export type WebSessionFindByAllMutationBody = BodyType<EfcWebSessionModel>;
export type WebSessionFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useWebSessionFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof webSession_findByAll>>,
        TError,
        { data: BodyType<EfcWebSessionModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getWebSessionFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const webSession_findById = (
    getByIdRequestModelOfEfcWebSessionModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcWebSessionModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcWebSessionModel | void>(
        {
            url: `/factoring/efcwebsession/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcWebSessionModelAndbigdecimal,
        },
        options,
    );
};

export const getWebSessionFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof webSession_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcWebSessionModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof webSession_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcWebSessionModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof webSession_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcWebSessionModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return webSession_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type WebSessionFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof webSession_findById>>>;
export type WebSessionFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcWebSessionModelAndbigdecimal>;
export type WebSessionFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useWebSessionFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof webSession_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcWebSessionModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getWebSessionFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const webSession_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcWebSessionModel | void>(
        {
            url: `/factoring/efcwebsession/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getWebSessionFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof webSession_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof webSession_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof webSession_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return webSession_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type WebSessionFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof webSession_findByLightDataRequest>>
>;
export type WebSessionFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type WebSessionFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useWebSessionFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof webSession_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getWebSessionFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const webSession_findByViewDataRequest = (
    viewDataRequestModelOfEfcWebSessionModel: BodyType<ViewDataRequestModelOfEfcWebSessionModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcWebSessionModel | void>(
        {
            url: `/factoring/efcwebsession/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcWebSessionModel,
        },
        options,
    );
};

export const getWebSessionFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof webSession_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcWebSessionModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof webSession_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcWebSessionModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof webSession_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcWebSessionModel> }
    > = props => {
        const { data } = props ?? {};

        return webSession_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type WebSessionFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof webSession_findByViewDataRequest>>
>;
export type WebSessionFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcWebSessionModel>;
export type WebSessionFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useWebSessionFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof webSession_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcWebSessionModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getWebSessionFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
