import { createTheme } from '@mui/material';
import { IconCheckBig, IconInfoBig } from '../../components';
import { color } from './color';
import { lexend } from './font';
import { shadows } from './shadows';
import { baseFontSize } from './typography';
export const theme = createTheme({
    color: color,
    palette: {
        text: {
            primary: color.foregrounds.primary,
            secondary: color.foregrounds.secondary,
        },
        primary: {
            main: color.foregrounds.primary,
        },
        secondary: {
            main: color.buttons.secondary.enabled,
        },
        error: {
            main: color.alerts.error,
        },
        success: {
            main: color.alerts.success,
        },
    },
    zIndex: {
        // Display menu above modal backdrop, which is 1300
        selectMenuPaper: 1301,
    },
    spacing: baseFontSize,
    shadows,
    typography: {
        fontFamily: lexend.style.fontFamily,
        fontSize: 0.875 * baseFontSize,
        htmlFontSize: baseFontSize,
        formItem: {
            fontSize: 0.875 * baseFontSize,
            lineHeight: 1.3,
        },
        h1: {
            fontSize: '1.325rem',
            lineHeight: '1.875rem',
            fontWeight: 500,
            letterSpacing: '0.01em',
            marginBottom: '0.75rem',
        },
        h2: {
            fontSize: '1.125rem',
            lineHeight: '1.5rem',
            fontWeight: 500,
            letterSpacing: '0.5px',
            marginBottom: '0.75rem',
        },
        h3: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
            fontWeight: 500,
            letterSpacing: '0.17px',
        },
        paragraphMedium: {
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.17px',
            fontWeight: 500,
        },
        paragraphNormal: {
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.17px',
            fontWeight: 400,
        },
        paragraphSmall: {
            fontSize: '0.75rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.17px',
            fontWeight: 400,
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiContainer: {
            styleOverrides: {
                maxWidthXl: () => ({
                    maxWidth: 1406,
                    [theme.breakpoints.up('xl')]: {
                        maxWidth: 1406,
                    },
                }),
            },
        },
        MuiAlert: {
            defaultProps: {
                iconMapping: {
                    success: <IconCheckBig />,
                    info: <IconInfoBig />,
                },
            },
        },
        MuiPopover: {
            defaultProps: {
                disableScrollLock: true,
            },
        },
    },
});
