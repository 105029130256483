export const baseFontSize = 16;
const roundTo = (value, roundTo) => {
    const exp = 10 ** roundTo;
    return Math.round(value * exp) / exp;
};
/**
 *
 * @param fontSize in px
 * @param lineHeight in px
 * @returns
 */
export const sizeAndHeight = (fontSize, lineHeight) => ({
    fontSize: `${roundTo(fontSize / baseFontSize, 2)}rem`,
    ...(lineHeight && {
        lineHeight: `${roundTo(lineHeight / baseFontSize, 2)}`,
    }),
});
