/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type SaveInvoiceRequestModelInvoiceType =
    (typeof SaveInvoiceRequestModelInvoiceType)[keyof typeof SaveInvoiceRequestModelInvoiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaveInvoiceRequestModelInvoiceType = {
    Purchased: 'Purchased',
    PurchasedReverse: 'PurchasedReverse',
    Liability: 'Liability',
    Discount: 'Discount',
    DiscountReverse: 'DiscountReverse',
} as const;
