import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Link } from '@mui/material';

import { useLang } from '~modules/intl';

import { languagesConfig } from '../../constants';
import * as Styled from './LanguageSwitch.styles';

export const LanguageSwitch = () => {
    const language = useLang();

    const nextLocale = languagesConfig[language];

    const { route } = useRouter();

    return (
        <Link component={NextLink} href={route} locale={nextLocale.locale} underline='none' tabIndex={-1}>
            <Styled.Button variant='unstyled' tabIndex={1}>
                {nextLocale.locale}
                {nextLocale.flag}
            </Styled.Button>
        </Link>
    );
};
