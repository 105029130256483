import { Box, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Card, FieldsStack, FormError, IconSave02 } from '@workspace/ui';

import { appConfig } from '~config';
import { FormBackButton, FormSubmitButton, PasswordField } from '~modules/form/components';
import { useLocalizedResolver } from '~modules/form/hooks';

import { usePasswordChange } from './hooks/usePasswordChange';
import { changePasswordFormSchema, type ChangePasswordSchema } from './schemas';

export const PasswordChangeForm = () => {
    const form = useForm<ChangePasswordSchema>({
        resolver: useLocalizedResolver(changePasswordFormSchema),
        defaultValues: {
            oldPassword: '',
            newPassword: '',
            verifyNewPassword: '',
        },
        mode: appConfig.formValidationMode,
    });

    const submit = usePasswordChange(form);

    return (
        <FormProvider<ChangePasswordSchema> {...form}>
            <form onSubmit={form.handleSubmit(submit)}>
                <Card>
                    <FieldsStack>
                        <PasswordField<ChangePasswordSchema>
                            name='oldPassword'
                            label={<FormattedMessage id='passwordChange.form.oldPassword' />}
                        />
                        <PasswordField<ChangePasswordSchema>
                            name='newPassword'
                            label={<FormattedMessage id='passwordChange.form.password' />}
                        />
                        <PasswordField<ChangePasswordSchema>
                            name='verifyNewPassword'
                            label={<FormattedMessage id='passwordChange.form.verifyPassword' />}
                        />
                        <FormError />
                    </FieldsStack>
                </Card>

                <Box mt={1} />

                <Stack direction='row' justifyContent='space-between'>
                    <FormBackButton />
                    <FormSubmitButton startIcon={<IconSave02 />}>
                        <FormattedMessage id='button.save' />
                    </FormSubmitButton>
                </Stack>
            </form>
        </FormProvider>
    );
};
