import { useEffect, type ReactNode } from 'react';
import { useRouter } from 'next/router';

import { useSession } from '@workspace/auth';
import { Loader, LoaderContainer } from '@workspace/ui';

import { routes } from '~constants';

interface AuthenticatedProps {
    children: ReactNode;
    allowPreAuthenticated?: boolean;
}

export const Authenticated = ({ children, allowPreAuthenticated = false }: AuthenticatedProps) => {
    const { status, data } = useSession();
    const { replace } = useRouter();

    useEffect(() => {
        if (
            status === 'unauthenticated' ||
            status === '2FA-requirement' ||
            (status === 'pre-authenticated' && !allowPreAuthenticated)
        ) {
            replace(routes.login);
        }
    }, [data, replace, status, allowPreAuthenticated]);

    switch (status) {
        case 'pre-authenticated':
            return allowPreAuthenticated ? <>{children}</> : null;

        case 'authenticated':
            return <>{children}</>;

        case 'unauthenticated':
        case 'loading':
        case '2FA-requirement':
            return (
                <LoaderContainer height='fullPage'>
                    <Loader />
                </LoaderContainer>
            );
    }
};
