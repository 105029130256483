import { useCallback, useEffect, useRef } from 'react';
import { useAtom, type PrimitiveAtom } from 'jotai';

import { logger } from '@workspace/logger';

import { PromptError } from '~modules/prompt/errors';

export interface PromptResult<R> {
    openAtom: PrimitiveAtom<boolean>;
    resultAtom: PrimitiveAtom<R | null>;
}

type PromptPromise<R> = {
    resolve: (value: R | PromiseLike<R>) => void;
    reject: (reason: PromptError) => void;
};

export function usePrompt<Result extends unknown>({ openAtom, resultAtom }: PromptResult<Result>) {
    const [open, setOpen] = useAtom(openAtom);
    const [result, setResult] = useAtom(resultAtom);

    const promiseRef = useRef<PromptPromise<Result> | null>(null);

    useEffect(() => {
        return () => {
            if (!promiseRef.current) return;

            logger.error('Unmounted with active prompt');
            promiseRef.current?.reject(new PromptError('MODAL_CLOSED_WITHOUT_RESULT'));
            promiseRef.current = null;
        };
    }, []);

    const promptUser = useCallback(() => {
        setOpen(true);

        if (promiseRef.current) {
            logger.error('Re-prompted with active prompt');
            promiseRef.current?.reject(new PromptError('MODAL_CLOSED_WITHOUT_RESULT'));
        }

        return new Promise<Result>((resolve, reject) => {
            promiseRef.current = { resolve, reject };
        });
    }, [setOpen]);

    /**
     * Clear promise references
     */
    const resetPrompt = useCallback(() => {
        promiseRef.current = null;
    }, []);

    useEffect(() => {
        if (result && promiseRef.current) {
            promiseRef.current.resolve(result);
            setResult(null);

            resetPrompt();
        }
    }, [resetPrompt, result, setResult]);

    useEffect(() => {
        // Modal was closed without result
        if (!open && !result && promiseRef.current) {
            promiseRef.current.reject(new PromptError('MODAL_CLOSED_WITHOUT_RESULT'));
            resetPrompt();
        }
    }, [result, resetPrompt, open]);

    return { open, setOpen, setResult, promptUser } as const;
}
