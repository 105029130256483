/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type { IsCaptchaOnLoginUsingPOSTInputparameterBody, UserClient } from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary createData
 */
export const security_createData = (userClient: BodyType<UserClient>, options?: SecondParameter<typeof fetcher>) => {
    return fetcher<UserClient | void>(
        {
            url: `/security/security/createData`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: userClient,
        },
        options,
    );
};

export const getSecurityCreateDataMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof security_createData>>,
        TError,
        { data: BodyType<UserClient> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof security_createData>>,
    TError,
    { data: BodyType<UserClient> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof security_createData>>,
        { data: BodyType<UserClient> }
    > = props => {
        const { data } = props ?? {};

        return security_createData(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SecurityCreateDataMutationResult = NonNullable<Awaited<ReturnType<typeof security_createData>>>;
export type SecurityCreateDataMutationBody = BodyType<UserClient>;
export type SecurityCreateDataMutationError = ErrorType<unknown>;

/**
 * @summary createData
 */
export const useSecurityCreateData = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof security_createData>>,
        TError,
        { data: BodyType<UserClient> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSecurityCreateDataMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary getUserData
 */
export const security_getUserData = (
    isCaptchaOnLoginUsingPOSTInputparameterBody: BodyType<IsCaptchaOnLoginUsingPOSTInputparameterBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<UserClient | void>(
        {
            url: `/security/security/getUserData`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: isCaptchaOnLoginUsingPOSTInputparameterBody,
        },
        options,
    );
};

export const getSecurityGetUserDataMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof security_getUserData>>,
        TError,
        { data: BodyType<IsCaptchaOnLoginUsingPOSTInputparameterBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof security_getUserData>>,
    TError,
    { data: BodyType<IsCaptchaOnLoginUsingPOSTInputparameterBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof security_getUserData>>,
        { data: BodyType<IsCaptchaOnLoginUsingPOSTInputparameterBody> }
    > = props => {
        const { data } = props ?? {};

        return security_getUserData(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SecurityGetUserDataMutationResult = NonNullable<Awaited<ReturnType<typeof security_getUserData>>>;
export type SecurityGetUserDataMutationBody = BodyType<IsCaptchaOnLoginUsingPOSTInputparameterBody>;
export type SecurityGetUserDataMutationError = ErrorType<unknown>;

/**
 * @summary getUserData
 */
export const useSecurityGetUserData = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof security_getUserData>>,
        TError,
        { data: BodyType<IsCaptchaOnLoginUsingPOSTInputparameterBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSecurityGetUserDataMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary updateData
 */
export const security_updateData = (userClient: BodyType<UserClient>, options?: SecondParameter<typeof fetcher>) => {
    return fetcher<UserClient | void>(
        {
            url: `/security/security/updateData`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: userClient,
        },
        options,
    );
};

export const getSecurityUpdateDataMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof security_updateData>>,
        TError,
        { data: BodyType<UserClient> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof security_updateData>>,
    TError,
    { data: BodyType<UserClient> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof security_updateData>>,
        { data: BodyType<UserClient> }
    > = props => {
        const { data } = props ?? {};

        return security_updateData(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SecurityUpdateDataMutationResult = NonNullable<Awaited<ReturnType<typeof security_updateData>>>;
export type SecurityUpdateDataMutationBody = BodyType<UserClient>;
export type SecurityUpdateDataMutationError = ErrorType<unknown>;

/**
 * @summary updateData
 */
export const useSecurityUpdateData = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof security_updateData>>,
        TError,
        { data: BodyType<UserClient> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSecurityUpdateDataMutationOptions(options);

    return useMutation(mutationOptions);
};
