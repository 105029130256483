import { Grid, Typography } from '@mui/material';

import { useGetNoticeboard } from '../hooks/useGetNoticeboard';
import { MessageStack } from './Noticeboard.styles';

export const Noticeboard = () => {
    const { data } = useGetNoticeboard();

    return (
        <MessageStack>
            {!!data &&
                data.map(el => {
                    const content = el.text?.split('/n');

                    return (
                        <Grid key={el.text} container className={el.style} mb={0.5}>
                            <Grid item sm={1.5} md={1}>
                                <Typography variant='paragraphSmall'>{el.date}</Typography>
                            </Grid>
                            <Grid item sm={10}>
                                <Typography variant='paragraphSmall'>
                                    {content?.map((item, i) => <div key={i}>{item}</div>)}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                })}
        </MessageStack>
    );
};
