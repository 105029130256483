import { useQuery } from '@tanstack/react-query';
import countries from 'i18n-iso-countries';
import { useIntl } from 'react-intl';

import { registerLocalizedCountries } from '~modules/intl/utils';

export type CountryOptionItem = {
    value: string;
    label: string;
};

export function useCountryOptions() {
    const { locale } = useIntl();

    return useQuery<CountryOptionItem[]>({
        queryKey: ['countryOptions', locale],
        placeholderData: () => [],
        queryFn: async () => {
            await registerLocalizedCountries(locale);

            const countryNames = countries.getNames(locale);

            const options = Object.entries(countryNames).map(([key, value]) => ({
                value: key,
                label: value,
            }));

            const czIndex = options.findIndex(option => option.value === 'CZ');

            if (czIndex !== -1) {
                const cz = options[czIndex];

                // Set Czech Republic as first option
                options.splice(0, 0, cz);

                // Remove Czech Republic from original position
                options.splice(czIndex + 1, 1);
            }

            return options;
        },
    });
}
