import { Modal, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { PasswordLoginForm } from '../PasswordLoginForm';
import { Container } from './AuthenticateModal.styles';

interface AuthenticateModalProps {
    open: boolean;
    onClose: (value: boolean) => void;
    backShouldClose?: boolean;
    handleSuccess?: () => void;
}

export const AuthenticateModal = ({ open, onClose, backShouldClose, handleSuccess }: AuthenticateModalProps) => {
    const handleBack = !backShouldClose ? undefined : () => onClose(false);

    return (
        <Modal open={open} onClose={onClose}>
            <Stack height='100%' justifyContent='center'>
                <Container>
                    <Typography variant='h1' mb={1.5}>
                        <FormattedMessage id='login.passwordForm.title' />
                    </Typography>
                    <PasswordLoginForm
                        isAuthModal={true}
                        onBack={handleBack}
                        localLogin={true}
                        handleSuccess={handleSuccess}
                    />
                </Container>
            </Stack>
        </Modal>
    );
};
