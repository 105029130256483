import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';
const notForwardedProps = ['iconOnly', 'active'];
export const StyledLoadingButton = styled(LoadingButton, {
    shouldForwardProp: (propName) => !notForwardedProps.includes(propName),
})(({ theme, variant, color = 'primary', startIcon, iconOnly }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    gap: theme.spacing(0.375),
    lineHeight: '1.5rem',
    letterSpacing: '0.5px',
    textTransform: 'none',
    borderRadius: 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    textDecoration: 'none',
    // @ts-ignore
    minWidth: 130,
    ...theme.typography.formItem,
    // styles outline when a button is selected with <TAB>
    '&:focus:not(:focus-visible)': {
        outline: 'none',
    },
    '&:focus-within': {
        outline: '2px solid black',
    },
    '&:focus-visible': {
        outline: '2px solid black !important',
    },
    '& > .MuiButton-iconSizeMedium': {
        marginLeft: 0,
        marginRight: 0,
        '& > svg': {
            width: 18,
            height: 18,
        },
    },
    ...(iconOnly
        ? {
            padding: theme.spacing(0.375),
            minWidth: 'unset',
        }
        : {
            paddingTop: theme.spacing(0.375),
            paddingLeft: theme.spacing(Boolean(startIcon) ? 0.5 : 1),
            paddingRight: theme.spacing(Boolean(startIcon) ? 0.8 : 1),
            paddingBottom: theme.spacing(0.375),
        }),
    ...(variant === 'primary' && {
        color: theme.color.buttons.primary.text,
        ...(color === 'primary' && {
            backgroundColor: theme.color.buttons.primary.enabled,
            '&:hover': {
                backgroundColor: theme.color.buttons.primary.hover,
            },
            '&:active': {
                backgroundColor: theme.color.buttons.primary.pressed,
            },
            '&.Mui-disabled': {
                backgroundColor: theme.color.buttons.primary.disabled,
                pointerEvents: 'auto',
            },
        }),
        ...(color === 'success' && {
            backgroundColor: theme.color.buttons.primary.green,
            '&:hover': {
                backgroundColor: theme.color.buttons.primary.greenHover,
            },
            '&:active': {
                backgroundColor: theme.color.buttons.primary.greenPressed,
            },
            '&.Mui-disabled': {
                backgroundColor: theme.color.buttons.primary.greenDisabled,
                pointerEvents: 'auto',
            },
        }),
        ...(color === 'error' && {
            backgroundColor: theme.color.buttons.primary.red,
            '&:hover': {
                backgroundColor: theme.color.buttons.primary.redHover,
            },
            '&:active': {
                backgroundColor: theme.color.buttons.primary.redPressed,
            },
            '&.Mui-disabled': {
                backgroundColor: theme.color.buttons.primary.redDisabled,
                pointerEvents: 'auto',
            },
        }),
    }),
    ...(variant === 'secondary' && {
        backgroundColor: theme.color.buttons.secondary.background,
        color: theme.color.buttons.secondary.enabled,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'inherit',
        '&:hover': {
            color: theme.color.buttons.secondary.hover,
            backgroundColor: theme.color.buttons.secondary.background,
        },
        '&:active': {
            color: theme.color.buttons.secondary.pressed,
            backgroundColor: theme.color.buttons.secondary.background,
        },
        '&.Mui-disabled': {
            backgroundColor: theme.color.buttons.secondary.disabled,
            pointerEvents: 'auto',
            color: theme.color.buttons.secondary.background,
        },
    }),
    ...(variant === 'text' && {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        gap: theme.spacing(0.5),
        color: theme.color.buttons.secondary.enabled,
        minWidth: 'unset',
        '&:hover': {
            color: theme.color.buttons.secondary.hover,
            background: 'transparent',
        },
        '&:active': {
            color: theme.color.buttons.secondary.pressed,
            background: 'transparent',
        },
    }),
    ...(variant === 'unstyled' && {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        background: 'transparent',
        border: 'none',
        gap: 0,
        minWidth: 'unset',
    }),
    ...(variant === 'icon' && {
        minWidth: 'unset',
        padding: theme.spacing(0.375),
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${theme.color.foregrounds.primary}`,
    }),
}));
