/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvSupplierCustomerSelectModel,
    GetByIdRequestModelOfEfcvSupplierCustomerSelectModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvSupplierCustomerSelectModel,
    ViewDataResultModelOfEfcvSupplierCustomerSelectModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const supplierCustomerSelect_findByAll = (
    efcvSupplierCustomerSelectModel: BodyType<EfcvSupplierCustomerSelectModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSupplierCustomerSelectModel[] | void>(
        {
            url: `/factoring/efcvsuppliercustomerselect/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvSupplierCustomerSelectModel,
        },
        options,
    );
};

export const getSupplierCustomerSelectFindByAllMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerSelect_findByAll>>,
        TError,
        { data: BodyType<EfcvSupplierCustomerSelectModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierCustomerSelect_findByAll>>,
    TError,
    { data: BodyType<EfcvSupplierCustomerSelectModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierCustomerSelect_findByAll>>,
        { data: BodyType<EfcvSupplierCustomerSelectModel> }
    > = props => {
        const { data } = props ?? {};

        return supplierCustomerSelect_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierCustomerSelectFindByAllMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierCustomerSelect_findByAll>>
>;
export type SupplierCustomerSelectFindByAllMutationBody = BodyType<EfcvSupplierCustomerSelectModel>;
export type SupplierCustomerSelectFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useSupplierCustomerSelectFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerSelect_findByAll>>,
        TError,
        { data: BodyType<EfcvSupplierCustomerSelectModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierCustomerSelectFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const supplierCustomerSelect_findById = (
    getByIdRequestModelOfEfcvSupplierCustomerSelectModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerSelectModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSupplierCustomerSelectModel | void>(
        {
            url: `/factoring/efcvsuppliercustomerselect/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvSupplierCustomerSelectModelAndbigdecimal,
        },
        options,
    );
};

export const getSupplierCustomerSelectFindByIdMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerSelect_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerSelectModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierCustomerSelect_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerSelectModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierCustomerSelect_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerSelectModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return supplierCustomerSelect_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierCustomerSelectFindByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierCustomerSelect_findById>>
>;
export type SupplierCustomerSelectFindByIdMutationBody =
    BodyType<GetByIdRequestModelOfEfcvSupplierCustomerSelectModelAndbigdecimal>;
export type SupplierCustomerSelectFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useSupplierCustomerSelectFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerSelect_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierCustomerSelectModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierCustomerSelectFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const supplierCustomerSelect_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvSupplierCustomerSelectModel | void>(
        {
            url: `/factoring/efcvsuppliercustomerselect/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getSupplierCustomerSelectFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerSelect_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierCustomerSelect_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierCustomerSelect_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return supplierCustomerSelect_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierCustomerSelectFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierCustomerSelect_findByLightDataRequest>>
>;
export type SupplierCustomerSelectFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type SupplierCustomerSelectFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useSupplierCustomerSelectFindByLightDataRequest = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerSelect_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierCustomerSelectFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const supplierCustomerSelect_findByViewDataRequest = (
    viewDataRequestModelOfEfcvSupplierCustomerSelectModel: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerSelectModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvSupplierCustomerSelectModel | void>(
        {
            url: `/factoring/efcvsuppliercustomerselect/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvSupplierCustomerSelectModel,
        },
        options,
    );
};

export const getSupplierCustomerSelectFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerSelect_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerSelectModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierCustomerSelect_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerSelectModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierCustomerSelect_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerSelectModel> }
    > = props => {
        const { data } = props ?? {};

        return supplierCustomerSelect_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierCustomerSelectFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierCustomerSelect_findByViewDataRequest>>
>;
export type SupplierCustomerSelectFindByViewDataRequestMutationBody =
    BodyType<ViewDataRequestModelOfEfcvSupplierCustomerSelectModel>;
export type SupplierCustomerSelectFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useSupplierCustomerSelectFindByViewDataRequest = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierCustomerSelect_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierCustomerSelectModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierCustomerSelectFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
