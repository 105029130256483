export const AMOUNT_FORMAT_OPTIONS = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
} as const satisfies Readonly<Intl.NumberFormatOptions>;

export const AMOUNT_IN_CURRENCY_FORMAT_OPTIONS = {
    ...AMOUNT_FORMAT_OPTIONS,
} as const satisfies Readonly<Intl.NumberFormatOptions>;

export const AMOUNT_IN_PERCENT_FORMAT_OPTIONS = {
    ...AMOUNT_FORMAT_OPTIONS,
    style: 'percent',
} as const satisfies Readonly<Intl.NumberFormatOptions>;
