import { useCallback } from 'react';
import { useAtom, useSetAtom } from 'jotai';

import { messageContentAtom, messageOpenedAtom, messageResultAtom } from '~modules/message-popup/atoms';

export function useControlMessagePopup() {
    const [opened, setOpened] = useAtom(messageOpenedAtom);
    const setResult = useSetAtom(messageResultAtom);

    const [content, setContent] = useAtom(messageContentAtom);

    const close = useCallback(() => {
        setContent(null);
        setResult(true);
        setOpened(false);
    }, [setContent, setResult, setOpened]);

    return { close, content, opened };
}
