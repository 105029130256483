/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvLiabilityInvoiceModel,
    GetByIdRequestModelOfEfcvLiabilityInvoiceModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvLiabilityInvoiceModel,
    ViewDataResultModelOfEfcvLiabilityInvoiceModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const liabilityInvoice_findByAll = (
    efcvLiabilityInvoiceModel: BodyType<EfcvLiabilityInvoiceModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvLiabilityInvoiceModel[] | void>(
        {
            url: `/factoring/efcvliabilityinvoice/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvLiabilityInvoiceModel,
        },
        options,
    );
};

export const getLiabilityInvoiceFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof liabilityInvoice_findByAll>>,
        TError,
        { data: BodyType<EfcvLiabilityInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof liabilityInvoice_findByAll>>,
    TError,
    { data: BodyType<EfcvLiabilityInvoiceModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof liabilityInvoice_findByAll>>,
        { data: BodyType<EfcvLiabilityInvoiceModel> }
    > = props => {
        const { data } = props ?? {};

        return liabilityInvoice_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type LiabilityInvoiceFindByAllMutationResult = NonNullable<
    Awaited<ReturnType<typeof liabilityInvoice_findByAll>>
>;
export type LiabilityInvoiceFindByAllMutationBody = BodyType<EfcvLiabilityInvoiceModel>;
export type LiabilityInvoiceFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useLiabilityInvoiceFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof liabilityInvoice_findByAll>>,
        TError,
        { data: BodyType<EfcvLiabilityInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getLiabilityInvoiceFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const liabilityInvoice_findById = (
    getByIdRequestModelOfEfcvLiabilityInvoiceModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvLiabilityInvoiceModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvLiabilityInvoiceModel | void>(
        {
            url: `/factoring/efcvliabilityinvoice/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvLiabilityInvoiceModelAndbigdecimal,
        },
        options,
    );
};

export const getLiabilityInvoiceFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof liabilityInvoice_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvLiabilityInvoiceModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof liabilityInvoice_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvLiabilityInvoiceModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof liabilityInvoice_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvLiabilityInvoiceModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return liabilityInvoice_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type LiabilityInvoiceFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof liabilityInvoice_findById>>>;
export type LiabilityInvoiceFindByIdMutationBody =
    BodyType<GetByIdRequestModelOfEfcvLiabilityInvoiceModelAndbigdecimal>;
export type LiabilityInvoiceFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useLiabilityInvoiceFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof liabilityInvoice_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvLiabilityInvoiceModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getLiabilityInvoiceFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const liabilityInvoice_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvLiabilityInvoiceModel | void>(
        {
            url: `/factoring/efcvliabilityinvoice/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getLiabilityInvoiceFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof liabilityInvoice_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof liabilityInvoice_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof liabilityInvoice_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return liabilityInvoice_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type LiabilityInvoiceFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof liabilityInvoice_findByLightDataRequest>>
>;
export type LiabilityInvoiceFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type LiabilityInvoiceFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useLiabilityInvoiceFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof liabilityInvoice_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getLiabilityInvoiceFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const liabilityInvoice_findByViewDataRequest = (
    viewDataRequestModelOfEfcvLiabilityInvoiceModel: BodyType<ViewDataRequestModelOfEfcvLiabilityInvoiceModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvLiabilityInvoiceModel | void>(
        {
            url: `/factoring/efcvliabilityinvoice/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvLiabilityInvoiceModel,
        },
        options,
    );
};

export const getLiabilityInvoiceFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof liabilityInvoice_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvLiabilityInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof liabilityInvoice_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvLiabilityInvoiceModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof liabilityInvoice_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvLiabilityInvoiceModel> }
    > = props => {
        const { data } = props ?? {};

        return liabilityInvoice_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type LiabilityInvoiceFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof liabilityInvoice_findByViewDataRequest>>
>;
export type LiabilityInvoiceFindByViewDataRequestMutationBody =
    BodyType<ViewDataRequestModelOfEfcvLiabilityInvoiceModel>;
export type LiabilityInvoiceFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useLiabilityInvoiceFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof liabilityInvoice_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvLiabilityInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getLiabilityInvoiceFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
