/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import {
    useQuery,
    type QueryFunction,
    type QueryKey,
    type UseQueryOptions,
    type UseQueryResult,
} from '@tanstack/react-query';

import { fetcher, type ErrorType } from '../../fetcher/fetcher';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Get application version to be displayed to the user.
 */
export const appInfo_getAppVersionToDisplay = (options?: SecondParameter<typeof fetcher>, signal?: AbortSignal) => {
    return fetcher<string>({ url: `/factoring/app/versionDisplay`, method: 'GET', signal }, options);
};

export const getAppInfoGetAppVersionToDisplayQueryKey = () => {
    return [`/factoring/app/versionDisplay`] as const;
};

export const getAppInfoGetAppVersionToDisplayQueryOptions = <
    TData = Awaited<ReturnType<typeof appInfo_getAppVersionToDisplay>>,
    TError = ErrorType<void>,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof appInfo_getAppVersionToDisplay>>, TError, TData>;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getAppInfoGetAppVersionToDisplayQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof appInfo_getAppVersionToDisplay>>> = ({ signal }) =>
        appInfo_getAppVersionToDisplay(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof appInfo_getAppVersionToDisplay>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type AppInfoGetAppVersionToDisplayQueryResult = NonNullable<
    Awaited<ReturnType<typeof appInfo_getAppVersionToDisplay>>
>;
export type AppInfoGetAppVersionToDisplayQueryError = ErrorType<void>;

/**
 * @summary Get application version to be displayed to the user.
 */
export const useAppInfoGetAppVersionToDisplay = <
    TData = Awaited<ReturnType<typeof appInfo_getAppVersionToDisplay>>,
    TError = ErrorType<void>,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof appInfo_getAppVersionToDisplay>>, TError, TData>;
    request?: SecondParameter<typeof fetcher>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = getAppInfoGetAppVersionToDisplayQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
};
