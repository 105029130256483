import { useCallback } from 'react';

import { security_getUserData } from '@workspace/api';

import { clearTwoFactorRequirement } from '../services/twoFactor';
import { isUserAuthenticated } from '../utils';
import { useAuthContext } from './useAuthContext';

export function useFinish2FA() {
    const { setSession } = useAuthContext();

    const finish2FA = useCallback(async () => {
        const userDataResult = await security_getUserData(true);

        if (isUserAuthenticated(userDataResult.data)) {
            setSession({
                data: userDataResult.data,
                status: 'authenticated',
            });
        } else {
            setSession({
                data: null,
                status: 'unauthenticated',
            });
        }

        clearTwoFactorRequirement();
    }, [setSession]);

    return finish2FA;
}
