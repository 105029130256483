import * as idb from 'idb-keyval';
import { z } from 'zod';

import { STORES, type StoreName } from '../settings';
import type { StoreWithSchema } from '../types';
import { createConnection, createCustomUseStore, createFallbackStore, getValueValidator, isSupported } from './utils';

let connection: Promise<IDBDatabase> | undefined;

/**
 * Creates a strongly-typed store in IndexedDB.
 * - Put a new store name with its schema in `packages/storage/settings.ts` to register it.
 * - After adding a new store, you have to delete the IndexedDB database in the browser or increase the database version.
 */
export function getIDBStore<Name extends StoreName>(name: Name) {
    if (!Object.hasOwn(STORES, name)) {
        throw new Error(
            `No store found with name "${name}". You have to register it in "packages/storage/settings.ts"`,
        );
    }

    const schema = STORES[name];

    type StoreSchema = typeof schema;
    type Schema = z.infer<StoreSchema>;
    type StoreKey = keyof Schema;

    if (!isSupported()) {
        return createFallbackStore(name);
    }

    // Create one database connection for all stores:
    if (!connection) {
        connection = createConnection();
    }

    // Using a custom function instead of idb.crateStore which doesn't support creating multiple stores:
    const useStore = createCustomUseStore(connection, name);

    const store = {
        name,

        async get<Key extends StoreKey>(key: Key) {
            const validator = getValueValidator(schema, key);

            const unknownValue = await idb.get<Schema[StoreKey]>(key as IDBValidKey, useStore);

            const result = validator.parse(unknownValue);

            return result as Schema[Key];
        },

        async set<Key extends StoreKey>(key: Key, value: Required<Schema[Key]>) {
            const validator = getValueValidator(schema, key);

            const unknownValue = validator.parse(value);

            await idb.set(key as IDBValidKey, unknownValue, useStore);
        },

        async remove<Key extends StoreKey>(key: Key) {
            await idb.del(key as IDBValidKey, useStore);
        },

        async clear() {
            await idb.clear(useStore);
        },
    } as const satisfies StoreWithSchema<Name, StoreSchema>;

    return store;
}
