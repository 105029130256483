import { useEffect, useState } from 'react';
import type { GridPaginationModel } from '@mui/x-data-grid';

import { appConfig } from '~config';
import { useUserSettings } from '~modules/auth/hooks';

export function usePaginationModel(defaultPage = 0) {
    const userSettings = useUserSettings();
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: defaultPage,
        pageSize: userSettings?.NumberOfRecordsInList ?? appConfig.defaultPageSize,
    });

    const pageSize =
        userSettings?.NumberOfRecordsInList && userSettings?.NumberOfRecordsInList !== paginationModel.pageSize
            ? userSettings.NumberOfRecordsInList
            : null;

    if (pageSize && pageSize !== paginationModel.pageSize) {
        setPaginationModel(m => ({
            ...m,
            pageSize,
        }));
    }

    const isUserSettingsLoaded = userSettings != null && !userSettings.isLoading;

    return [paginationModel, setPaginationModel, isUserSettingsLoaded] as const;
}
