import { useQuery } from '@tanstack/react-query';

import { dataService_getUsersPhoneNumber, QueryKey } from '@workspace/api';

export function useGetPhoneNumber() {
    return useQuery({
        queryKey: [QueryKey.GET_PHONE_NUMBER],
        queryFn: async () => {
            const result = await dataService_getUsersPhoneNumber();

            const phoneNumber = result?.data?.response;

            return phoneNumber;
        },
    });
}
