import type { Path } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { SelectField, type UnknownFormValues } from '@workspace/ui';

import { useCountryOptions } from './hooks/useCountryOptions';

export const CountryField = <FormValues extends UnknownFormValues>({ name }: { name: Path<FormValues> }) => {
    const { formatMessage } = useIntl();
    const countryOptionsResult = useCountryOptions();

    return (
        <SelectField
            label={formatMessage({ id: 'certificateRequest.form.countryName' })}
            name={name}
            disabled={countryOptionsResult.isLoading}
            options={countryOptionsResult.data!}
        />
    );
};
