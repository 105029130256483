import { useCallback, useMemo, useState } from 'react';
import { useGridApiContext } from '@mui/x-data-grid';
import * as Styled from './SortIcon.styles';
const IconUp = Styled.createIcon(true);
const IconDown = Styled.createIcon(false);
export const SortIcon = ({ active, ...props }) => {
    const apiRef = useGridApiContext();
    const [ref, setRef] = useState(null);
    const fieldName = useMemo(() => {
        if (!ref) {
            return null;
        }
        // Find column header ancestor with information about current field
        const columnAncestor = ref.parentElement?.parentElement?.parentElement?.parentElement?.parentElement;
        // Get name of current column
        const dataField = columnAncestor?.dataset['field'];
        if (!dataField) {
            throw new Error('attribute `data-field` not found');
        }
        return dataField;
    }, [ref]);
    const getSortDirectionHandler = useCallback((sort) => {
        return (e) => {
            if (!fieldName) {
                throw new Error('fieldName not set');
            }
            // Do not fire default click handler, which toggles sort for this column
            e.stopPropagation();
            apiRef.current.setSortModel([{ sort, field: fieldName }]);
        };
    }, [fieldName, apiRef]);
    // Remove sortingOrder from the properties, it is not a DOM element attribute
    // @ts-ignore
    const { sortingOrder, ...styledProps } = props;
    return (<Styled.Stack gap={0} ref={setRef} {...styledProps}>
            <IconUp onClick={getSortDirectionHandler('asc')} active={active === 'asc'}/>
            <IconDown onClick={getSortDirectionHandler('desc')} active={active === 'desc'}/>
        </Styled.Stack>);
};
