import { Grid, Stack } from '@mui/material';

import type { NavigationItems } from '~modules/layout/hooks';

import * as Styled from './Menu.styles';
import { MenuItem } from './MenuItem';

export interface MenuProps {
    item: NavigationItems;
    handleClose: () => void;
    activeItem: string | null;
    compact: boolean;
}

export const Menu = ({ item, handleClose, activeItem, compact }: MenuProps) => {
    return (
        <Styled.Grid container activeItem={activeItem} itemTitle={item.title} compact={compact}>
            {item.childrenItems.map((childrenItems, index) => (
                <Grid item key={`${item.title}-${index}`} rowGap={1.75}>
                    <Stack spacing={0.625}>
                        {childrenItems.map(childrenItem => (
                            <MenuItem childrenItem={childrenItem} handleClose={handleClose} key={childrenItem.title} />
                        ))}
                    </Stack>
                    {index < item.childrenItems.length - 1 && <Styled.Divider />}
                </Grid>
            ))}
        </Styled.Grid>
    );
};
