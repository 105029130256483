/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvIssuedInvoiceModel,
    GetByIdRequestModelOfEfcvIssuedInvoiceModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvIssuedInvoiceModel,
    ViewDataResultModelOfEfcvIssuedInvoiceModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const issuedInvoice_findByAll = (
    efcvIssuedInvoiceModel: BodyType<EfcvIssuedInvoiceModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvIssuedInvoiceModel[] | void>(
        {
            url: `/factoring/efcvissuedinvoice/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvIssuedInvoiceModel,
        },
        options,
    );
};

export const getIssuedInvoiceFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof issuedInvoice_findByAll>>,
        TError,
        { data: BodyType<EfcvIssuedInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof issuedInvoice_findByAll>>,
    TError,
    { data: BodyType<EfcvIssuedInvoiceModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof issuedInvoice_findByAll>>,
        { data: BodyType<EfcvIssuedInvoiceModel> }
    > = props => {
        const { data } = props ?? {};

        return issuedInvoice_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type IssuedInvoiceFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof issuedInvoice_findByAll>>>;
export type IssuedInvoiceFindByAllMutationBody = BodyType<EfcvIssuedInvoiceModel>;
export type IssuedInvoiceFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useIssuedInvoiceFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof issuedInvoice_findByAll>>,
        TError,
        { data: BodyType<EfcvIssuedInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getIssuedInvoiceFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const issuedInvoice_findById = (
    getByIdRequestModelOfEfcvIssuedInvoiceModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvIssuedInvoiceModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvIssuedInvoiceModel | void>(
        {
            url: `/factoring/efcvissuedinvoice/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvIssuedInvoiceModelAndbigdecimal,
        },
        options,
    );
};

export const getIssuedInvoiceFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof issuedInvoice_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvIssuedInvoiceModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof issuedInvoice_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvIssuedInvoiceModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof issuedInvoice_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvIssuedInvoiceModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return issuedInvoice_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type IssuedInvoiceFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof issuedInvoice_findById>>>;
export type IssuedInvoiceFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvIssuedInvoiceModelAndbigdecimal>;
export type IssuedInvoiceFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useIssuedInvoiceFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof issuedInvoice_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvIssuedInvoiceModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getIssuedInvoiceFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const issuedInvoice_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvIssuedInvoiceModel | void>(
        {
            url: `/factoring/efcvissuedinvoice/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getIssuedInvoiceFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof issuedInvoice_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof issuedInvoice_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof issuedInvoice_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return issuedInvoice_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type IssuedInvoiceFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof issuedInvoice_findByLightDataRequest>>
>;
export type IssuedInvoiceFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type IssuedInvoiceFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useIssuedInvoiceFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof issuedInvoice_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getIssuedInvoiceFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const issuedInvoice_findByViewDataRequest = (
    viewDataRequestModelOfEfcvIssuedInvoiceModel: BodyType<ViewDataRequestModelOfEfcvIssuedInvoiceModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvIssuedInvoiceModel | void>(
        {
            url: `/factoring/efcvissuedinvoice/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvIssuedInvoiceModel,
        },
        options,
    );
};

export const getIssuedInvoiceFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof issuedInvoice_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvIssuedInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof issuedInvoice_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvIssuedInvoiceModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof issuedInvoice_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvIssuedInvoiceModel> }
    > = props => {
        const { data } = props ?? {};

        return issuedInvoice_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type IssuedInvoiceFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof issuedInvoice_findByViewDataRequest>>
>;
export type IssuedInvoiceFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvIssuedInvoiceModel>;
export type IssuedInvoiceFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useIssuedInvoiceFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof issuedInvoice_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvIssuedInvoiceModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getIssuedInvoiceFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
