import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { pkcs12Storage, worker } from '@workspace/certificates';

import { AuthenticateModal } from '../AuthenticateModal';

export interface PasswordInMemoryRequiredProps {
    children: React.ReactNode;
}

/**
 * Renders children only if the user's password is stored in runtine memory.
 * Else it will display a modal to ask the user to enter his password.
 */
export const PasswordInMemoryRequired = ({ children }: PasswordInMemoryRequiredProps) => {
    const [passwordRequired, setPasswordRequired] = useState<boolean | undefined>(undefined);
    const [modalOpened, setModalOpened] = useState(false);
    const [hasCertificate, setHasCertificate] = useState<boolean>();

    const { push } = useRouter();

    useEffect(() => {
        if (hasCertificate === false) {
            void push('/');
        }
    }, [hasCertificate, push]);

    useEffect(() => {
        pkcs12Storage.get('name').then(v => {
            setHasCertificate(!!v);
        });
        worker.hasStoredPassword().then(hasPassword => {
            setModalOpened(!hasPassword);
            setPasswordRequired(!hasPassword);
        });

        return worker.onStoredPasswordChange(hasPassword => {
            if (!hasPassword) {
                setModalOpened(true);
            }
        });
    }, [modalOpened]);

    return (
        <>
            <AuthenticateModal
                open={modalOpened && Boolean(hasCertificate)}
                onClose={setModalOpened}
                handleSuccess={() => setModalOpened(false)}
            />
            {passwordRequired === undefined || modalOpened || !hasCertificate ? null : <>{children}</>}
        </>
    );
};
