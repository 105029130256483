/**
 * Create a link element, click it, and then delete it
 */
export function createAutoClickLink(url: string, name: string | undefined) {
    const a = document.createElement('a');

    a.href = url;
    if (name) {
        a.download = name;
    }
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function downloadFile(name: string, content: Blob | File) {
    const url = URL.createObjectURL(content);

    createAutoClickLink(url, name);

    URL.revokeObjectURL(url);
}

export function downloadBase64(name: string, content: string, mimeType: string) {
    createAutoClickLink(`data:${mimeType};base64,${content}`, name);
}
