import { createContext, type Dispatch, type SetStateAction } from 'react';

import type { UserClient } from '@workspace/api';

import type { TwoFactorRequirement } from '../services/twoFactor';

export type AuthSession =
    | {
          status: 'pre-authenticated';
          data: null;
      }
    | {
          status: '2FA-requirement';
          data: TwoFactorRequirement;
      }
    | {
          status: 'authenticated';
          data: UserClient;
      }
    | {
          status: 'unauthenticated' | 'loading';
          data: null;
      };

export type AuthContextType = Readonly<{
    session: AuthSession;
    setSession: Dispatch<SetStateAction<AuthSession>>;
}>;

export const AuthContext = createContext<AuthContextType | null>(null);
