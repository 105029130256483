import { useMemo } from 'react';

import { getIDBStore } from '@workspace/storage';

export function useUsername() {
    const authStore = useMemo(() => getIDBStore('auth'), []);

    return {
        setUsername: (value: string) => authStore.set('username', value),
        getUsername: () => authStore.get('username'),
    } as const;
}
