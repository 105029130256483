import { useCallback } from 'react';

import { useSession } from '@workspace/auth';

import { useLang } from '~modules/intl';

import { useUsername } from './useUsername';

/**
 * Background sign-in assumes, `storePassword` has been called before.
 */
export function useBackgroundSignIn() {
    const { signIn } = useSession();
    const locale = useLang();
    const { getUsername } = useUsername();

    const backgroundSignIn = useCallback(async () => {
        const username = (await getUsername()) as string;

        // Force login, even though the PIN hasn't been set properly, for example
        return signIn({ username, locale }, true);
    }, [getUsername, locale, signIn]);

    return backgroundSignIn;
}
