import { Stack, styled } from '@mui/material';

export const Separator = styled('div')(({ theme }) => ({
    width: 1,
    height: 24,
    backgroundColor: theme.color.accent.primary,
    display: 'block',
}));

export const IconButtons = styled(Stack)(() => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: '1.5rem',
    '& svg': {
        scale: '1.25',
    },
}));
