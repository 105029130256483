/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    BooleanResponseModel,
    EfcvSupplierProductLimitModel,
    EfcvSupplierProductLimitModelBody,
    GetByIdRequestModelOfEfcvSupplierProductLimitModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvSupplierProductLimitModel,
    ViewDataResultModelOfEfcvSupplierProductLimitModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const supplierProductLimit_findByAll = (
    efcvSupplierProductLimitModelBody: BodyType<EfcvSupplierProductLimitModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSupplierProductLimitModel[] | void>(
        {
            url: `/factoring/efcvsupplierproductlimit/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvSupplierProductLimitModelBody,
        },
        options,
    );
};

export const getSupplierProductLimitFindByAllMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierProductLimit_findByAll>>,
        TError,
        { data: BodyType<EfcvSupplierProductLimitModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierProductLimit_findByAll>>,
    TError,
    { data: BodyType<EfcvSupplierProductLimitModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierProductLimit_findByAll>>,
        { data: BodyType<EfcvSupplierProductLimitModelBody> }
    > = props => {
        const { data } = props ?? {};

        return supplierProductLimit_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierProductLimitFindByAllMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierProductLimit_findByAll>>
>;
export type SupplierProductLimitFindByAllMutationBody = BodyType<EfcvSupplierProductLimitModelBody>;
export type SupplierProductLimitFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useSupplierProductLimitFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierProductLimit_findByAll>>,
        TError,
        { data: BodyType<EfcvSupplierProductLimitModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierProductLimitFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByAllHasData
 */
export const supplierProductLimit_findByAllHasData = (
    efcvSupplierProductLimitModelBody: BodyType<EfcvSupplierProductLimitModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<BooleanResponseModel | void>(
        {
            url: `/factoring/efcvsupplierproductlimit/findByAllHasData`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvSupplierProductLimitModelBody,
        },
        options,
    );
};

export const getSupplierProductLimitFindByAllHasDataMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierProductLimit_findByAllHasData>>,
        TError,
        { data: BodyType<EfcvSupplierProductLimitModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierProductLimit_findByAllHasData>>,
    TError,
    { data: BodyType<EfcvSupplierProductLimitModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierProductLimit_findByAllHasData>>,
        { data: BodyType<EfcvSupplierProductLimitModelBody> }
    > = props => {
        const { data } = props ?? {};

        return supplierProductLimit_findByAllHasData(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierProductLimitFindByAllHasDataMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierProductLimit_findByAllHasData>>
>;
export type SupplierProductLimitFindByAllHasDataMutationBody = BodyType<EfcvSupplierProductLimitModelBody>;
export type SupplierProductLimitFindByAllHasDataMutationError = ErrorType<unknown>;

/**
 * @summary findByAllHasData
 */
export const useSupplierProductLimitFindByAllHasData = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierProductLimit_findByAllHasData>>,
        TError,
        { data: BodyType<EfcvSupplierProductLimitModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierProductLimitFindByAllHasDataMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const supplierProductLimit_findById = (
    getByIdRequestModelOfEfcvSupplierProductLimitModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvSupplierProductLimitModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvSupplierProductLimitModel | void>(
        {
            url: `/factoring/efcvsupplierproductlimit/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvSupplierProductLimitModelAndbigdecimal,
        },
        options,
    );
};

export const getSupplierProductLimitFindByIdMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierProductLimit_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierProductLimitModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierProductLimit_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvSupplierProductLimitModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierProductLimit_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierProductLimitModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return supplierProductLimit_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierProductLimitFindByIdMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierProductLimit_findById>>
>;
export type SupplierProductLimitFindByIdMutationBody =
    BodyType<GetByIdRequestModelOfEfcvSupplierProductLimitModelAndbigdecimal>;
export type SupplierProductLimitFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useSupplierProductLimitFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierProductLimit_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvSupplierProductLimitModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierProductLimitFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const supplierProductLimit_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvSupplierProductLimitModel | void>(
        {
            url: `/factoring/efcvsupplierproductlimit/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getSupplierProductLimitFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierProductLimit_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierProductLimit_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierProductLimit_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return supplierProductLimit_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierProductLimitFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierProductLimit_findByLightDataRequest>>
>;
export type SupplierProductLimitFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type SupplierProductLimitFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useSupplierProductLimitFindByLightDataRequest = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierProductLimit_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierProductLimitFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const supplierProductLimit_findByViewDataRequest = (
    viewDataRequestModelOfEfcvSupplierProductLimitModel: BodyType<ViewDataRequestModelOfEfcvSupplierProductLimitModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvSupplierProductLimitModel | void>(
        {
            url: `/factoring/efcvsupplierproductlimit/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvSupplierProductLimitModel,
        },
        options,
    );
};

export const getSupplierProductLimitFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierProductLimit_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierProductLimitModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof supplierProductLimit_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvSupplierProductLimitModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof supplierProductLimit_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierProductLimitModel> }
    > = props => {
        const { data } = props ?? {};

        return supplierProductLimit_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type SupplierProductLimitFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof supplierProductLimit_findByViewDataRequest>>
>;
export type SupplierProductLimitFindByViewDataRequestMutationBody =
    BodyType<ViewDataRequestModelOfEfcvSupplierProductLimitModel>;
export type SupplierProductLimitFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useSupplierProductLimitFindByViewDataRequest = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof supplierProductLimit_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvSupplierProductLimitModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getSupplierProductLimitFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
