import type { AppProps } from 'next/app';
import { HydrationBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { env } from '@workspace/env';
import { ErrorBoundary } from '@workspace/errors';
import { initLogger } from '@workspace/logger';
import { CacheProvider, createEmotionCache, ThemeProvider, type EmotionCache } from '@workspace/ui';

import { AppQueryProvider } from '~modules/api/components';
import { AuthProviderContainer } from '~modules/auth/components';
import { Intl, MUILocalizationProvider } from '~modules/intl';
import { PasswordInMemoryReset } from '~modules/password-reset/components';

import 'normalize.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'reset.css';

import { MessagePopup } from '~modules/message-popup/components/ErrorPopup';

initLogger({
    outputToConsole: env.NEXT_PUBLIC_BUILD_ENV === 'development',
});

export interface ExtendedAppProps extends AppProps {
    emotionCache?: EmotionCache;
}

const clientSideEmotionCache = createEmotionCache();

function App({ Component, pageProps, emotionCache = clientSideEmotionCache }: ExtendedAppProps) {
    return (
        <Intl>
            <ErrorBoundary>
                <CacheProvider value={emotionCache}>
                    <ThemeProvider>
                        <AppQueryProvider>
                            <HydrationBoundary state={pageProps.dehydratedState}>
                                <PasswordInMemoryReset>
                                    <AuthProviderContainer>
                                        <MUILocalizationProvider>
                                            <Component {...pageProps} />
                                            <MessagePopup />
                                        </MUILocalizationProvider>
                                        <ReactQueryDevtools initialIsOpen={false} />
                                    </AuthProviderContainer>
                                </PasswordInMemoryReset>
                            </HydrationBoundary>
                        </AppQueryProvider>
                    </ThemeProvider>
                </CacheProvider>
            </ErrorBoundary>
        </Intl>
    );
}

export default App;
