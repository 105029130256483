import type { Path } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { InputAutocomplete, TextField, type TextFieldProps, type UnknownFormValues } from '@workspace/ui';

export interface AddressFieldProps<FormValues extends UnknownFormValues> extends TextFieldProps<FormValues> {
    name: Path<FormValues>;
}

export const AddressField = <FormValues extends UnknownFormValues>({
    name,
    ...rest
}: AddressFieldProps<FormValues>) => {
    return (
        <TextField<FormValues>
            label={<FormattedMessage id='cert.renewal.field.address' />}
            {...rest}
            name={name}
            autoComplete={InputAutocomplete.Off}
        />
    );
};
