import { useRouter } from 'next/router';
import { Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import type { UserClient } from '@workspace/api';
import { useSession } from '@workspace/auth';
import { Button, FieldsStack, FormError, IconBack } from '@workspace/ui';

import { appConfig } from '~config';
import { authRoutes, routes } from '~constants';
import { usePromptLogin } from '~modules/auth/hooks';
import {
    CertificateNameField,
    FormSubmitButton,
    PasswordField,
    PreventPasswordAutoFill,
} from '~modules/form/components';
import { useLocalizedResolver } from '~modules/form/hooks';
import { generatePath } from '~utils/generatePath';

import { useHydratePasswordLoginForm } from './hooks/useHydratePasswordLoginForm';
import { useSubmitPasswordLoginForm } from './hooks/useSubmitPasswordLoginForm';
import { passwordLoginFormSchema, type PasswordLoginFormSchema } from './schema';
import { getSubjects } from './utils';

interface PasswordLoginFormProps {
    isAuthModal: boolean;
    localLogin: boolean;
    onBack?: () => void;
    handleSuccess?: () => void;
}

export const PasswordLoginForm = ({ isAuthModal, localLogin, onBack, handleSuccess }: PasswordLoginFormProps) => {
    const { push, back } = useRouter();
    const { status, signOut } = useSession();
    const { formatMessage } = useIntl();

    const defaultValues = useHydratePasswordLoginForm(localLogin);
    const form = useForm<PasswordLoginFormSchema>({
        resolver: useLocalizedResolver(passwordLoginFormSchema),
        defaultValues,
        mode: appConfig.formValidationMode,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = form;

    const { open, confirmModal } = usePromptLogin();

    const qc = useQueryClient();

    const submit = useSubmitPasswordLoginForm({
        form,
        localLogin,
        async onSuccess(result) {
            if (handleSuccess) {
                return handleSuccess();
            }

            if (isAuthModal && open) {
                return confirmModal();
            }

            // Don't redirect when 2FA is in progress, i.e. status === '2FA-requirement'
            if (result.status !== 'authenticated') {
                return;
            }

            const subjects = await getSubjects((result.data as UserClient).securityData?.user?.loginName, qc);

            if (subjects?.length === 0) {
                const message = formatMessage({ id: 'selectSupplier.noSubjectFound' });

                form.setError('root', { message });
                await signOut();

                return;
            }

            if (subjects?.length === 1) {
                await push(generatePath(authRoutes.supplierDetail, { doId: subjects[0].doId as string }));

                return;
            }

            await push(routes.home);
        },
    });

    function handleBackButton() {
        if (status !== 'authenticated') {
            return push(routes.login);
        }

        if (onBack) {
            return onBack();
        }

        return back();
    }

    return (
        <FormProvider<PasswordLoginFormSchema> {...form}>
            <form onSubmit={handleSubmit(submit)}>
                <FieldsStack>
                    <PreventPasswordAutoFill name='p1' />
                    <CertificateNameField<PasswordLoginFormSchema> name='filename' />
                    <PasswordField<PasswordLoginFormSchema> name='password' />

                    <FormError />

                    <Stack direction='row' justifyContent='space-between'>
                        <Button
                            variant='primary'
                            color='primary'
                            startIcon={<IconBack />}
                            onClick={handleBackButton}
                            disabled={isSubmitting}
                        >
                            <FormattedMessage id='login.passwordForm.buttons.back' />
                        </Button>
                        <FormSubmitButton />
                    </Stack>
                </FieldsStack>
            </form>
        </FormProvider>
    );
};
