/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcCompanyExportSettingsModel,
    EfcExportSettingModel,
    EfcExportSettingModelBody,
    GetByIdRequestModelOfEfcExportSettingModelAndstring,
    ScheduleExportUsingPOSTParams,
    ViewDataRequestModelOfEfcExportSettingModel,
    ViewDataResultModelOfEfcExportSettingModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const exportSetting_findByAll = (
    efcExportSettingModelBody: BodyType<EfcExportSettingModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcExportSettingModel[] | void>(
        {
            url: `/factoring/efcexportsetting/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcExportSettingModelBody,
        },
        options,
    );
};

export const getExportSettingFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportSetting_findByAll>>,
        TError,
        { data: BodyType<EfcExportSettingModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportSetting_findByAll>>,
    TError,
    { data: BodyType<EfcExportSettingModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportSetting_findByAll>>,
        { data: BodyType<EfcExportSettingModelBody> }
    > = props => {
        const { data } = props ?? {};

        return exportSetting_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportSettingFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof exportSetting_findByAll>>>;
export type ExportSettingFindByAllMutationBody = BodyType<EfcExportSettingModelBody>;
export type ExportSettingFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useExportSettingFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportSetting_findByAll>>,
        TError,
        { data: BodyType<EfcExportSettingModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getExportSettingFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const exportSetting_findById = (
    getByIdRequestModelOfEfcExportSettingModelAndstring: BodyType<GetByIdRequestModelOfEfcExportSettingModelAndstring>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcExportSettingModel | void>(
        {
            url: `/factoring/efcexportsetting/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcExportSettingModelAndstring,
        },
        options,
    );
};

export const getExportSettingFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportSetting_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcExportSettingModelAndstring> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportSetting_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcExportSettingModelAndstring> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportSetting_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcExportSettingModelAndstring> }
    > = props => {
        const { data } = props ?? {};

        return exportSetting_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportSettingFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof exportSetting_findById>>>;
export type ExportSettingFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcExportSettingModelAndstring>;
export type ExportSettingFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useExportSettingFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportSetting_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcExportSettingModelAndstring> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getExportSettingFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const exportSetting_findByViewDataRequest = (
    viewDataRequestModelOfEfcExportSettingModel: BodyType<ViewDataRequestModelOfEfcExportSettingModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcExportSettingModel | void>(
        {
            url: `/factoring/efcexportsetting/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcExportSettingModel,
        },
        options,
    );
};

export const getExportSettingFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportSetting_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcExportSettingModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportSetting_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcExportSettingModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportSetting_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcExportSettingModel> }
    > = props => {
        const { data } = props ?? {};

        return exportSetting_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportSettingFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof exportSetting_findByViewDataRequest>>
>;
export type ExportSettingFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcExportSettingModel>;
export type ExportSettingFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useExportSettingFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportSetting_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcExportSettingModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getExportSettingFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get export schedule settings for currently selected company.
 */
export const exportSetting_getCurrentCompanyExportSettings = (options?: SecondParameter<typeof fetcher>) => {
    return fetcher<EfcCompanyExportSettingsModel | void>(
        { url: `/factoring/efcexportsetting/getCurrentExportSettings`, method: 'POST' },
        options,
    );
};

export const getExportSettingGetCurrentCompanyExportSettingsMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportSetting_getCurrentCompanyExportSettings>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportSetting_getCurrentCompanyExportSettings>>,
    TError,
    void,
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportSetting_getCurrentCompanyExportSettings>>,
        void
    > = () => {
        return exportSetting_getCurrentCompanyExportSettings(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportSettingGetCurrentCompanyExportSettingsMutationResult = NonNullable<
    Awaited<ReturnType<typeof exportSetting_getCurrentCompanyExportSettings>>
>;

export type ExportSettingGetCurrentCompanyExportSettingsMutationError = ErrorType<unknown>;

/**
 * @summary Get export schedule settings for currently selected company.
 */
export const useExportSettingGetCurrentCompanyExportSettings = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportSetting_getCurrentCompanyExportSettings>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getExportSettingGetCurrentCompanyExportSettingsMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Schedule export for currently selected company.
 */
export const exportSetting_scheduleExport = (
    params?: ScheduleExportUsingPOSTParams,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcCompanyExportSettingsModel | void>(
        { url: `/factoring/efcexportsetting/scheduleExport`, method: 'POST', params },
        options,
    );
};

export const getExportSettingScheduleExportMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportSetting_scheduleExport>>,
        TError,
        { params?: ScheduleExportUsingPOSTParams },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportSetting_scheduleExport>>,
    TError,
    { params?: ScheduleExportUsingPOSTParams },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportSetting_scheduleExport>>,
        { params?: ScheduleExportUsingPOSTParams }
    > = props => {
        const { params } = props ?? {};

        return exportSetting_scheduleExport(params, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportSettingScheduleExportMutationResult = NonNullable<
    Awaited<ReturnType<typeof exportSetting_scheduleExport>>
>;

export type ExportSettingScheduleExportMutationError = ErrorType<unknown>;

/**
 * @summary Schedule export for currently selected company.
 */
export const useExportSettingScheduleExport = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportSetting_scheduleExport>>,
        TError,
        { params?: ScheduleExportUsingPOSTParams },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getExportSettingScheduleExportMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary update
 */
export const exportSetting_update = (
    efcExportSettingModelBody: BodyType<EfcExportSettingModelBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcExportSettingModel | void>(
        {
            url: `/factoring/efcexportsetting/update`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcExportSettingModelBody,
        },
        options,
    );
};

export const getExportSettingUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportSetting_update>>,
        TError,
        { data: BodyType<EfcExportSettingModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof exportSetting_update>>,
    TError,
    { data: BodyType<EfcExportSettingModelBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof exportSetting_update>>,
        { data: BodyType<EfcExportSettingModelBody> }
    > = props => {
        const { data } = props ?? {};

        return exportSetting_update(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type ExportSettingUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof exportSetting_update>>>;
export type ExportSettingUpdateMutationBody = BodyType<EfcExportSettingModelBody>;
export type ExportSettingUpdateMutationError = ErrorType<unknown>;

/**
 * @summary update
 */
export const useExportSettingUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof exportSetting_update>>,
        TError,
        { data: BodyType<EfcExportSettingModelBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getExportSettingUpdateMutationOptions(options);

    return useMutation(mutationOptions);
};
