import { useQuery } from '@tanstack/react-query';

import { authServices_getCaptcha, QueryKey } from '@workspace/api';

export function useFetchCaptcha(enabled: boolean = true) {
    return useQuery({
        enabled,
        queryKey: [QueryKey.CAPTCHA],
        queryFn: async () => {
            const { data } = await authServices_getCaptcha();

            return data!;
        },
    });
}
