import type { MessageKey } from '~modules/intl/types';

export const formError = {
    required: 'form.error.required',
    email: 'form.error.email',
    mustBeNumber: 'form.error.validNumber',
    mustBePositiveNumber: 'form.error.positiveNumber',

    // Password
    passwordLength: 'form.error.password.length',
    passwordCasing: 'form.error.password.casing',
    passwordNumeric: 'form.error.password.numeric',
    passwordSpecialChar: 'form.error.password.specialChar',
    passwordSequence: 'form.error.password.sequence',
    passwordEquality: 'form.error.password.equality',
    passwordUniqness: 'form.error.password.uniqness',

    invalidDate: 'form.error.invalidDate',
    valueTooLarge: 'form.error.valueTooLarge',
    zeroValue: 'form.error.zeroValue',
    invalidPeriodFilter: 'form.error.invalidPeriodFilter',
    pin: 'form.error.invalidPin',
    minDate: 'form.error.minDate',
    maxDate: 'form.error.maxDate',

    // Certificate Request Page
    max20Chars: 'form.error.max20Chars',
    phoneNumber: 'form.error.phoneNumber',

    variableSymbolTooLong: 'form.error.max20Chars',
    specificSymbolTooLong: 'form.error.max20Chars',
    noteTooLong: 'form.error.max250Chars',
    specialCharacters: 'form.error.specialCharacters',
    dueDateNotGreaterThanIssuedDate: 'newPurchasedInvoice.error.dueDateNotGreaterThanIssuedDate',
    taxDateHigherIssueDate: 'newPurchasedInvoice.error.taxDateHigherIssueDate',
    issueDateAfterToday: 'newPurchasedInvoice.error.issueDateAfterToday',
} as const satisfies Record<string, MessageKey>;
