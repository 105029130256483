export default function Unknown(props) {
    return (<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='none' {...props}>
            <g clipPath='url(#clip0_1393_2441)'>
                <path stroke='#020203' strokeMiterlimit='10' strokeWidth='1.5' d='M7.75 12.55V4.57c0-1.01.8-1.82 1.81-1.82h9.46s.06 0 .09.02c.15.01.3.08.4.18l6.19 6.19c.1.1.17.25.18.4.01.02.01.05.01.07V25.8c0 1.01-.82 1.81-1.82 1.81H9.56c-1.01 0-1.81-.8-1.81-1.81V12.55Z'/>
                <path stroke='#020203' strokeMiterlimit='10' strokeWidth='1.5' d='M19.11 2.75v6.06c0 .4.33.73.73.73h6.06'/>
                <path stroke='#020203' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' d='M14 15.6c0-1.17 1.23-2.15 2.87-2.11 1.61 0 2.77.95 2.77 2.11.05.94-.52 1.8-1.41 2.11-.89.42-1.46 1.56-1.41 2.82'/>
                <circle cx='16.75' cy='22.75' r='.75' fill='#020203'/>
            </g>
            <defs>
                <clipPath id='clip0_1393_2441'>
                    <path fill='#fff' d='M7 2h19.64v26.36H7z'/>
                </clipPath>
            </defs>
        </svg>);
}
