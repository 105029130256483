import { usePermissionData } from './usePermissionData';
import { usePermissionUtils } from './usePermissionUtils';
import { useUserUtils } from './useUserUtils';

export function useAuthUtils() {
    const userUtils = useUserUtils();
    const permissionUtils = usePermissionUtils();
    const permissionData = usePermissionData();

    return { userUtils, permissionUtils, permissionData } as const;
}
