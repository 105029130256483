/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type {
    EfcvInFileLineModel,
    GetByIdRequestModelOfEfcvInFileLineModelAndbigdecimal,
    LightDataRequestBody,
    ViewDataRequestModelOfEfcvInFileLineModel,
    ViewDataResultModelOfEfcvInFileLineModel,
} from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary findByAll
 */
export const inFileLine_findByAll = (
    efcvInFileLineModel: BodyType<EfcvInFileLineModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvInFileLineModel[] | void>(
        {
            url: `/factoring/efcvinfileline/findByAll`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: efcvInFileLineModel,
        },
        options,
    );
};

export const getInFileLineFindByAllMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFileLine_findByAll>>,
        TError,
        { data: BodyType<EfcvInFileLineModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof inFileLine_findByAll>>,
    TError,
    { data: BodyType<EfcvInFileLineModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof inFileLine_findByAll>>,
        { data: BodyType<EfcvInFileLineModel> }
    > = props => {
        const { data } = props ?? {};

        return inFileLine_findByAll(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type InFileLineFindByAllMutationResult = NonNullable<Awaited<ReturnType<typeof inFileLine_findByAll>>>;
export type InFileLineFindByAllMutationBody = BodyType<EfcvInFileLineModel>;
export type InFileLineFindByAllMutationError = ErrorType<unknown>;

/**
 * @summary findByAll
 */
export const useInFileLineFindByAll = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFileLine_findByAll>>,
        TError,
        { data: BodyType<EfcvInFileLineModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getInFileLineFindByAllMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findById
 */
export const inFileLine_findById = (
    getByIdRequestModelOfEfcvInFileLineModelAndbigdecimal: BodyType<GetByIdRequestModelOfEfcvInFileLineModelAndbigdecimal>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<EfcvInFileLineModel | void>(
        {
            url: `/factoring/efcvinfileline/findById`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: getByIdRequestModelOfEfcvInFileLineModelAndbigdecimal,
        },
        options,
    );
};

export const getInFileLineFindByIdMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFileLine_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvInFileLineModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof inFileLine_findById>>,
    TError,
    { data: BodyType<GetByIdRequestModelOfEfcvInFileLineModelAndbigdecimal> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof inFileLine_findById>>,
        { data: BodyType<GetByIdRequestModelOfEfcvInFileLineModelAndbigdecimal> }
    > = props => {
        const { data } = props ?? {};

        return inFileLine_findById(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type InFileLineFindByIdMutationResult = NonNullable<Awaited<ReturnType<typeof inFileLine_findById>>>;
export type InFileLineFindByIdMutationBody = BodyType<GetByIdRequestModelOfEfcvInFileLineModelAndbigdecimal>;
export type InFileLineFindByIdMutationError = ErrorType<unknown>;

/**
 * @summary findById
 */
export const useInFileLineFindById = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFileLine_findById>>,
        TError,
        { data: BodyType<GetByIdRequestModelOfEfcvInFileLineModelAndbigdecimal> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getInFileLineFindByIdMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const inFileLine_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvInFileLineModel | void>(
        {
            url: `/factoring/efcvinfileline/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getInFileLineFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFileLine_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof inFileLine_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof inFileLine_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return inFileLine_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type InFileLineFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof inFileLine_findByLightDataRequest>>
>;
export type InFileLineFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type InFileLineFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get data for lists using filter, orderBy and row range
 */
export const useInFileLineFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFileLine_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getInFileLineFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * @summary findByViewDataRequest
 */
export const inFileLine_findByViewDataRequest = (
    viewDataRequestModelOfEfcvInFileLineModel: BodyType<ViewDataRequestModelOfEfcvInFileLineModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvInFileLineModel | void>(
        {
            url: `/factoring/efcvinfileline/findByViewDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: viewDataRequestModelOfEfcvInFileLineModel,
        },
        options,
    );
};

export const getInFileLineFindByViewDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFileLine_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvInFileLineModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof inFileLine_findByViewDataRequest>>,
    TError,
    { data: BodyType<ViewDataRequestModelOfEfcvInFileLineModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof inFileLine_findByViewDataRequest>>,
        { data: BodyType<ViewDataRequestModelOfEfcvInFileLineModel> }
    > = props => {
        const { data } = props ?? {};

        return inFileLine_findByViewDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type InFileLineFindByViewDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof inFileLine_findByViewDataRequest>>
>;
export type InFileLineFindByViewDataRequestMutationBody = BodyType<ViewDataRequestModelOfEfcvInFileLineModel>;
export type InFileLineFindByViewDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary findByViewDataRequest
 */
export const useInFileLineFindByViewDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof inFileLine_findByViewDataRequest>>,
        TError,
        { data: BodyType<ViewDataRequestModelOfEfcvInFileLineModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getInFileLineFindByViewDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
