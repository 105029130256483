/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type { CoreClientErrorLogModel } from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary insert
 */
export const coreClientErrorLog_insert = (
    coreClientErrorLogModel: BodyType<CoreClientErrorLogModel>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<CoreClientErrorLogModel | void>(
        {
            url: `/audit/coreclienterrorlog/insert`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: coreClientErrorLogModel,
        },
        options,
    );
};

export const getCoreClientErrorLogInsertMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof coreClientErrorLog_insert>>,
        TError,
        { data: BodyType<CoreClientErrorLogModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof coreClientErrorLog_insert>>,
    TError,
    { data: BodyType<CoreClientErrorLogModel> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof coreClientErrorLog_insert>>,
        { data: BodyType<CoreClientErrorLogModel> }
    > = props => {
        const { data } = props ?? {};

        return coreClientErrorLog_insert(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type CoreClientErrorLogInsertMutationResult = NonNullable<Awaited<ReturnType<typeof coreClientErrorLog_insert>>>;
export type CoreClientErrorLogInsertMutationBody = BodyType<CoreClientErrorLogModel>;
export type CoreClientErrorLogInsertMutationError = ErrorType<unknown>;

/**
 * @summary insert
 */
export const useCoreClientErrorLogInsert = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof coreClientErrorLog_insert>>,
        TError,
        { data: BodyType<CoreClientErrorLogModel> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getCoreClientErrorLogInsertMutationOptions(options);

    return useMutation(mutationOptions);
};
