/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */

export type GetGlobalParamRequestModelDataType =
    (typeof GetGlobalParamRequestModelDataType)[keyof typeof GetGlobalParamRequestModelDataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetGlobalParamRequestModelDataType = {
    DATE: 'DATE',
    NUMBER: 'NUMBER',
    FLOAT: 'FLOAT',
    TEXT: 'TEXT',
    BOOLEAN: 'BOOLEAN',
    LIST: 'LIST',
} as const;
