import { alertClasses, keyframes, styled } from '@mui/material';

import { Alert, Button } from '@workspace/ui';

import type { QueryErrorCompactMode } from './QueryError';

const rotatingKeyframe = keyframes`
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
    
`;

export const ActionButton = styled(Button, {
    shouldForwardProp: prop => prop !== 'compactMode' && prop !== 'fetching',
})<{ compactMode: QueryErrorCompactMode; fetching: boolean }>(({ theme, compactMode, fetching }) => ({
    width: theme.spacing(compactMode === 'inline' ? 2 : 4),
    height: theme.spacing(compactMode === 'inline' ? 2 : 4),

    ...(compactMode === 'inline' && {
        minWidth: 'unset',
    }),
    svg: {
        ...(fetching && {
            transformOrigin: 'center',
            animationName: `${rotatingKeyframe}`,
            animationIterationCount: 'infinite',
            animationDuration: '1s',
            animationTimingFunction: 'linear',
        }),
    },
}));

export const ErrorAlert = styled(Alert, {
    shouldForwardProp: prop => prop !== 'compactMode' && prop !== 'fullWidth',
})<{ compactMode: QueryErrorCompactMode; fullWidth: boolean }>(({ theme, compactMode, fullWidth }) => ({
    ...(compactMode === 'default' &&
        !fullWidth && {
            maxWidth: theme.spacing(75),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }),
    ...(compactMode === 'default' &&
        fullWidth && {
            width: '100%',
            maxWidth: 'unset',
            boxSizing: 'border-box',
        }),

    ...(compactMode === 'inline' && {
        paddingTop: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),

        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center',
        columnGap: theme.spacing(1),

        [`& .${alertClasses.icon}`]: {
            marginRight: 'unset',
            padding: 'unset',
        },

        [`& .${alertClasses.message}`]: {
            padding: 'unset',
        },

        [`& .${alertClasses.action}`]: {
            all: 'unset',
        },
    }),

    marginLeft: 'auto',
    marginRight: 'auto',
}));
