/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * eFactoring Server API
 * OpenAPI spec version: 4.6.1-SNAPSHOT
 */
import { useMutation, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { fetcher, type BodyType, type ErrorType } from '../../fetcher/fetcher';
import type { LightDataRequestBody, ViewDataResultModelOfEfcvBoundInvoiceExtendedModel } from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Get list of invoices for the bound invoices tab on invoice detail
 */
export const boundInvoice_findByLightDataRequest = (
    lightDataRequestBody: BodyType<LightDataRequestBody>,
    options?: SecondParameter<typeof fetcher>,
) => {
    return fetcher<ViewDataResultModelOfEfcvBoundInvoiceExtendedModel | void>(
        {
            url: `/factoring/efcvboundinvoice/findByLightDataRequest`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: lightDataRequestBody,
        },
        options,
    );
};

export const getBoundInvoiceFindByLightDataRequestMutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown,
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof boundInvoice_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof boundInvoice_findByLightDataRequest>>,
    TError,
    { data: BodyType<LightDataRequestBody> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof boundInvoice_findByLightDataRequest>>,
        { data: BodyType<LightDataRequestBody> }
    > = props => {
        const { data } = props ?? {};

        return boundInvoice_findByLightDataRequest(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type BoundInvoiceFindByLightDataRequestMutationResult = NonNullable<
    Awaited<ReturnType<typeof boundInvoice_findByLightDataRequest>>
>;
export type BoundInvoiceFindByLightDataRequestMutationBody = BodyType<LightDataRequestBody>;
export type BoundInvoiceFindByLightDataRequestMutationError = ErrorType<unknown>;

/**
 * @summary Get list of invoices for the bound invoices tab on invoice detail
 */
export const useBoundInvoiceFindByLightDataRequest = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof boundInvoice_findByLightDataRequest>>,
        TError,
        { data: BodyType<LightDataRequestBody> },
        TContext
    >;
    request?: SecondParameter<typeof fetcher>;
}) => {
    const mutationOptions = getBoundInvoiceFindByLightDataRequestMutationOptions(options);

    return useMutation(mutationOptions);
};
